import React, { useContext, useEffect, useState } from "react";
import Navbar from "../PackagesList/Navbar/navbar";
import Footer from "../footer/footer";
import { Base64 } from "js-base64";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import summary from "../../assets/images/summary.png";
import travellersImg from "../../assets/images/travel-luggage.png";
import cart from "../../assets/images/shopping-cart.png";
import Skeleton from "@material-ui/lab/Skeleton";
import "./bookingpage.css";
import {
  countryCodeList,
  createBooking,
  getBookingIntent,
  updateTravellersCount,
} from "../Services/services";
import { UserContext } from "../hooks/UserContext";
import SnackbarComponent, { SnackbarStyle } from "../Snackbar/Snackbar";
import ErrorModal from "./ErrorModal";
import { useHistory, useParams } from "react-router-dom";
import SuccessBooking from "./SuccessBooking";
import { capitalizeSentences, validateEmail } from "../../../utils/utility";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaleInfo from "../../models/listing/saleInfo";
import { bookingTravelerCountChangeEvent } from "../../../tracking/events/bookingTravelerCountChangeEvent";
import { Tracker } from "../../../tracking/tracker";
import { bookingConfirmClickEvent } from "../../../tracking/events/bookingConfirmClickEvent";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
      width: "25ch",
    },
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--text-secondary-color)",
      },

      "&.Mui-focused fieldset": {
        borderColor: "var(--text-primary-color)",
      },
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "13px",

      color: "var(--text-secondary-color)",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "13px",
      color: "var(--text-primary-color)",
      height: "20px",
    },
    "& .MuiInputLabel-shrink": {
      fontSize: "18px",
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: "var(--text-secondary-color)",
    },

    margin: "10px 0px",
  },
  selectField: {
    flex: 3,
    "& .MuiOutlinedInput-root": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      "& fieldset": {
        borderRight: "0px",
      },
      "&.Mui-focused": {
        borderRight: "2px solid black",
        borderColor: "var(--text-primary-color)",
      },
      "& .MuiSelect-select.MuiSelect-select": {
        textAlign: "right",
        direction: "rtl",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
  },
  phoneField: {
    flex: 10,
    "& .MuiOutlinedInput-root": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
  },
}));
function BookingPage() {
  const classes = useStyles();
  const history = useHistory();
  const { intentId } = useParams();
  const decodedUuid = Base64.decode(intentId);
  const { userInfo } = useContext(UserContext);
  const isLoggedIn = localStorage.getItem("access_token");
  const [travellers, setTravellers] = useState(0);
  const [loadingCountryList, setLoadingCountryList] = useState(false);
  const [contryCodeList, setContryCodeList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [userEnteredFieldsArray, setUserEnteredFieldsArray] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [bookingSuccessView, setBookingSuccessView] = useState(false);
  const [bookingIntentData, setBookingIntentData] = useState({});
  const [loadingBookingData, setLoadingBookingData] = useState(false);
  const [expiryMessage, setExpiryMessage] = useState("");
  const [loadingBookingButton, setLoadingBookingButton] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    countryCode: "",

    travellerCount: 0,
  });
  useEffect(() => {
    if (userInfo) {
      setFormData({
        name: userInfo.name || "",
        email: userInfo.email || "",
        phone: userInfo.phone_number || "",
        countryCode: userInfo.country_code || "",
        message: "",
        travellerCount: bookingIntentData?.adults_count,
      });
    }
  }, [userInfo, bookingIntentData]);

  const handleTravellersCount = async (adults_count) => {
    const response = await updateTravellersCount(adults_count, decodedUuid);
    if (response.status === 200) {
      getBookingIntentData(decodedUuid);
    }
  };

  const handleIncrement = (event) => {
    event.preventDefault();
    const newCount = formData.travellerCount + 1;
    setFormData({
      ...formData,
      travellerCount: newCount,
    });
    handleTravellersCount(newCount);
    Tracker.getInstance().trackEvent(
      new bookingTravelerCountChangeEvent(newCount)
    );
  };

  const handleDecrement = (event) => {
    event.preventDefault();
    const newCount = formData.travellerCount - 1;
    if (formData.travellerCount > 1) {
      setFormData({
        ...formData,
        travellerCount: newCount,
      });
      handleTravellersCount(newCount);
    }
    Tracker.getInstance().trackEvent(
      new bookingTravelerCountChangeEvent(newCount)
    );
  };
  const createTourBooking = async () => {
    setLoadingBookingButton(true);
    const response = await createBooking(decodedUuid);

    if (response.status === 200) {
      const bookingId = response.data.data.booking_id;

      window.location.href = `/booking-success/${bookingId}`;
      setLoadingBookingButton(false);
    }
    Tracker.getInstance().trackEvent(new bookingConfirmClickEvent());
  };

  const getCountryCodeList = async () => {
    setLoadingCountryList(true);
    const response = await countryCodeList();

    if (response.status >= 200 && response.status < 300) {
      setLoadingCountryList(false);
      const defaultCountryCode = response.data.data.countries.filter(
        (data) => data.is_default == true
      );
      setSelectedCountry(defaultCountryCode[0]?.calling_code);

      setContryCodeList(response.data.data.countries);
    }
  };
  useEffect(() => {
    getCountryCodeList();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "travellerCount" ? parseInt(value) || "" : value,
    });

    const keyForArray = name === "travellerCount" ? "travelers_count" : name;
    setUserEnteredFieldsArray((prevKeysArray) => {
      if (!prevKeysArray.includes(keyForArray)) {
        return [...prevKeysArray, keyForArray];
      }
      return prevKeysArray;
    });
  };
  const handleCloseSnackbar = () => {
    setOpenSnackBar(false);
  };
  const handleOpenSnackbar = () => {
    setOpenSnackBar(true);
  };

  const handleBooking = () => {
    const isValidateEmail = validateEmail(formData.email);
    if (
      parseInt(formData.travellerCount) === 0 ||
      formData.email === "" ||
      formData.name === "" ||
      !isValidateEmail
    ) {
      handleOpenSnackbar();

      return;
    } else {
      createTourBooking();
    }
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
    window.location.href = "/";
  };
  const handleOpenLinkExipryModal = () => {
    handleOpenErrorModal();
  };

  const getBookingIntentData = async (uuid) => {
    handleOpen();
    const response = await getBookingIntent(uuid);
    if (response.status === 200) {
      setBookingIntentData(response.data.data);
      setLoadingBookingData(false);
      handleClose();
    }
    if (response.status === 400) {
      setExpiryMessage(response.data.message);
      handleOpenLinkExipryModal();
      handleClose();
    }
  };

  useEffect(() => {
    getBookingIntentData(decodedUuid);
  }, [decodedUuid]);

  const date = new Date(bookingIntentData?.availability?.date);
  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-GB", options);

  // const saleInfo =
  //   bookingIntentData && new SaleInfo(bookingIntentData.package.sale_info);
  // const salePrice = saleInfo && saleInfo.listPrice;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <SnackbarComponent
        openSnackBar={openSnackBar}
        handleCloseSnackbar={handleCloseSnackbar}
        handleOpenSnackbar={handleOpenSnackbar}
        message="Please provide travellers details."
        snackBarStyle={SnackbarStyle.ERROR}
      />
      <ErrorModal
        handleCloseErrorModal={handleCloseErrorModal}
        openErrorModal={openErrorModal}
        errorMessage={expiryMessage}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}></div>
        </Fade>
      </Modal>
      <div>
        <Navbar />

        <main class="container">
          <section className="left-box-container">
            <div className="booking-summary-container">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  lineHeight: window.innerWidth <= 768 ? "0px" : "10px",
                }}
              >
                {" "}
                <img src={summary} alt="" width="25px" height="25px" />{" "}
                <h3 className="booking-heading-title">Booking Summary</h3>
              </div>

              <p
                style={{
                  padding: "0px",
                  margin: "0px",
                  lineHeight: window.innerWidth <= 768 ? "18px" : "",
                }}
              >
                <strong style={{ color: "var(--text-primary-color)" }}>
                  {" "}
                  {capitalizeSentences(bookingIntentData?.package?.name)}
                </strong>
              </p>
              {window.innerWidth <= 768 ? (
                <>
                  {" "}
                  <article className="booking-data-row">
                    <p>Days:</p>

                    <p>
                      {bookingIntentData?.package?.nights} Nights
                      {bookingIntentData?.package?.days} Days{" "}
                    </p>
                  </article>
                  <article className="booking-data-row">
                    <p>Start: </p>

                    <p> {formattedDate}</p>
                  </article>
                </>
              ) : (
                <>
                  {" "}
                  <article className="desktop-booking-data-row">
                    <div className="desktop-content-wrapper1">
                      {" "}
                      <p>Days:</p>
                      <p className="booking-data">
                        {bookingIntentData?.package?.days} Days{" "}
                        {bookingIntentData?.package?.nights} Nights
                      </p>
                    </div>
                    <div className="desktop-content-wrapper2">
                      {" "}
                      <p>Start: </p>
                      <p className="booking-data"> {formattedDate}</p>
                    </div>
                  </article>
                </>
              )}
            </div>
            <div
              className="booking-summary-container"
              style={{ marginTop: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {" "}
                <img
                  src={travellersImg}
                  alt=""
                  width="25px"
                  height="25px"
                />{" "}
                <h3 className="booking-heading-title">Traveller Details</h3>
              </div>

              <form onSubmit={null}>
                <div className="traveller-counter">
                  <label className="label">How many Travellers?</label>
                  <div className="input-group">
                    <button className="btn decrement" onClick={handleDecrement}>
                      -
                    </button>
                    {loadingBookingData ? (
                      <Skeleton
                        style={{
                          backgroundColor: "var(--text-ternary-color)",
                          width: "40px",
                        }}
                      />
                    ) : (
                      <input
                        type="text"
                        name="travellerCount"
                        value={formData.travellerCount}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          if (value >= 0) {
                            setFormData({
                              ...formData,
                              [e.target.name]: value,
                            });
                          } else if (value === 0) {
                            setFormData({
                              ...formData,
                              [e.target.name]: null,
                            });
                          }
                        }}
                        // min="1"
                        className="input"
                      />
                    )}

                    <button className="btn increment" onClick={handleIncrement}>
                      +
                    </button>
                  </div>
                </div>
                <div autoComplete="off">
                  <TextField
                    size="small"
                    name="name"
                    type="text"
                    required
                    id="standard-required"
                    className={classes.textField}
                    value={formData.name}
                    onChange={handleChange}
                    label="Enter full name"
                    variant="outlined"
                    autoComplete="name"
                    fullWidth
                  />
                  <TextField
                    size="small"
                    required
                    type="email"
                    id="standard-required"
                    className={classes.textField}
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    label="Email Id"
                    variant="outlined"
                    fullWidth
                    autoComplete="email"
                  />

                  {isLoggedIn ? (
                    <TextField
                      size="small"
                      required
                      style={{ flex: "9" }}
                      id="standard-required"
                      className={classes.textField}
                      name="phone"
                      disabled={userInfo ? true : false}
                      value={`+${formData.countryCode}${formData.phone}`}
                      onChange={handleChange}
                      label="Phone Number"
                      variant="outlined"
                      fullWidth
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <TextField
                        id="outlined-select-currency-native"
                        select
                        size="small"
                        style={{ flex: "4" }}
                        className={`${classes.textField} ${classes.selectField}`}
                        name="countryCode"
                        label="Country"
                        value={formData.countryCode || selectedCountry || ""}
                        onChange={handleChange}
                        SelectProps={{
                          native: true,
                          displayEmpty: true,
                        }}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      >
                        {contryCodeList?.map((country) => (
                          <option
                            key={country.calling_code}
                            value={country.calling_code}
                            style={{ padding: "30px 0" }}
                          >
                            {country.name} (+{country.calling_code})
                          </option>
                        ))}
                      </TextField>
                      <TextField
                        size="small"
                        required
                        style={{ flex: "8" }}
                        id="phone-number"
                        className={`${classes.textField} ${classes.phoneField}`}
                        name="phone"
                        type="tel"
                        disabled={userInfo ? true : false}
                        value={formData.phone}
                        onChange={handleChange}
                        label="Phone Number"
                        autoComplete="tel"
                        variant="outlined"
                        fullWidth
                      />
                    </div>
                  )}
                </div>
              </form>
            </div>
            {window.innerWidth <= 768 ? (
              <>
                {" "}
                <div
                  className="booking-summary-container"
                  style={{ marginTop: "10px" }}
                >
                  <h3 className="booking-heading-title">Terms & Conditions</h3>
                  <ul>
                    <li className="booking-terms-list-title">
                      This is not an actual booking confirmation
                    </li>
                    <li className="booking-terms-list-title">
                      Your seats are on hold for 24 hours, you need to make deposit payment to confirm the booking
                    </li>
                    <li className="booking-terms-list-title">
                      Price & seats are subject to availability at the time of confirmation
                    </li>
                    <li className="booking-terms-list-title">
                      Free cancellation within 24 hours
                    </li>
                  </ul>
                </div>
              </>
            ) : null}
          </section>
          <div className="booking-floating-bottom-bar">
            <div className="booking-price-container">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img src={cart} alt="" width="25px" height="25px" />{" "}
                <h3 className="booking-heading-title">
                  {" "}
                  Subtotal: ₹0
                  {/* {(salePrice.amount * formData.travellerCount).toLocaleString(
                    "en-IN"
                  )}
                  /-{" "} */}
                </h3>
              </div>
              <p className="booking-note">
                Note: Reservation is held for 24 hrs.
              </p>
              <button onClick={handleBooking}>
                {loadingBookingButton ? (
                  <CircularProgress color="inherit" size={12} />
                ) : (
                  "Book my Seats"
                )}{" "}
              </button>
            </div>
          </div>

          <aside class="price-box">
            {window.innerWidth <= 768 ? null : (
              <>
                {" "}
                <div className="booking-price-container">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                   
                      justifyContent: "flex-start",
                    }}
                  >
                    <img src={cart} alt="" width="25px" height="25px" />{" "}
                    <h3 className="booking-heading-title">
                      {" "}
                      Subtotal: ₹0
                      {/* {(
                        salePrice.amount * formData.travellerCount
                      ).toLocaleString("en-IN")}
                      /-{" "} */}
                    </h3>
                  </div>
                  <p className="booking-note">
                    Note: Reservation is held for 24 hrs.
                  </p>
                  <button onClick={handleBooking}>
                    {loadingBookingButton ? (
                      <CircularProgress color="inherit" size={12} />
                    ) : (
                      "Book my Seats"
                    )}{" "}
                  </button>
                </div>
                <div className="booking-terms-container">
                  <h3 className="booking-heading-title">Terms & Conditions</h3>
                  <ul>
                    <li className="booking-terms-list-title">
                      This is not an actual booking confirmation
                    </li>
                    <li className="booking-terms-list-title">
                      Your seats are on hold for 24 hours, you need to make deposit payment to confirm the booking
                    </li>
                    <li className="booking-terms-list-title">
                      Price & seats are subject to availability at the time of confirmation
                    </li>
                    <li className="booking-terms-list-title">
                      Free cancellation within 24 hours
                    </li>
                  </ul>
                </div>
              </>
            )}
          </aside>
        </main>

        <Footer />
      </div>
    </>
  );
}

export default BookingPage;
