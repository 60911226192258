// DateContext.js
import React, { createContext, useState, useContext } from 'react';

const CalendarContext = createContext();

export const DateProvider = ({ children }) => {
  const [selectedData, setSelectedData] = useState(null);

  return (
    <CalendarContext.Provider value={{ selectedData, setSelectedData }}>
      {children}
    </CalendarContext.Provider>
  );
};

export const useDateData = () => useContext(CalendarContext);
