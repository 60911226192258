import { TrackerPropertyKey, TrackingEventName } from "../../trackingConstants"
import { TrackableEvent } from "../trackableEvent"

export class ListingCompareEvent extends TrackableEvent {
	constructor(packageIds, packagePrices, packageNames) {
		let properties = {}
		properties[TrackerPropertyKey.PackageIds] = packageIds
		properties[TrackerPropertyKey.PackagePrices] = packagePrices
		properties[TrackerPropertyKey.PackageNames] = packageNames

		super(TrackingEventName.ListingCompareAction, properties)
	}
}
