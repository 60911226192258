import { Box, Typography, withStyles } from "@material-ui/core"
import React from "react"
import Button from "@material-ui/core/Button"
import Skeleton from "react-loading-skeleton"
import HighlightBadge from "./highlightBadge"

const style = (theme) => ({
	root: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		overflow: "hidden",
		position: "relative",
		height: "100%",
		width: "100%",
		minHeight: 200,
	},
	skeleton: {
		width: "calc(100% - 2 * 8px)",
		height: "calc(100% - 2 * 8px)",
		position: "absolute",
		top: "8px", // Added this line
		left: "8px", // Added this line
	},
	img: {
		objectFit: "cover",
		width: "100%",
		height: "100%",
		position: "absolute",
		cursor: "pointer",
	},
	compareButton: {
		position: "absolute",
		bottom: theme.spacing(2),
		background: "rgba(255, 255, 255, 0.6)",
		width: "60%",
		"&:hover": {
			backgroundColor: "rgba(255, 255, 255)",
		},
		// [theme.breakpoints.down("sm")]: {
		// 	display: "none",
		// },
	},
	compareText: {
		textTransform: "none",
		color: "#343434",
		fontSize: 14,
		fontWeight: 600,
	},
	badge: {
		position: "absolute", // Added this line
		top: theme.spacing(1.5), // Added this line
		left: theme.spacing(1.5), // Added this line
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
		zIndex: 100,
	},
	hyperlink: {
		position: "absolute",
		width: "calc(100% - 2 * 8px)",
		height: "calc(100% - 2 * 8px)",
	},
})

const PackageCardImage = (props) => {
	const { packageData, classes } = props
	const images = packageData.images
	const badge = packageData.highlights.badge
	const badgeColor = packageData.highlights.color ?? "#3582BD"
	const shouldShowCompareButton = props.shouldShowCompareButton ?? false

	const [imgLoaded, setImgLoaded] = React.useState(false)

	const handleImageLoad = () => {
		setImgLoaded(true)
	}

	return (
		<React.Fragment>
			<div className={classes.root}>
				{!imgLoaded && <Skeleton className={classes.skeleton} />}
				<a href={`/packagedetails/${packageData.slug}`} className={classes.hyperlink}>
					<img src={images[0]} alt="Package" className={classes.img} onLoad={handleImageLoad} style={{ display: imgLoaded ? "block" : "none" }} onClick={props.onImageClick} />
				</a>
				{imgLoaded ? (
					<div className={classes.badge}>
						<HighlightBadge text={badge} backgroundColor={badgeColor} />
					</div>
				) : (
					""
				)}
				{imgLoaded && shouldShowCompareButton ? (
					window.innerWidth <= 768 ? (<Button variant="contained" color="primary" className={classes.compareButton} onClick={props.onCompareClick}>
						<Typography variant="caption" className={classes.compareText}>
							Add to Compare
						</Typography>
					</Button>):(<Button variant="contained" color="primary" className={classes.compareButton} onClick={props.onCompareClick}>
						<Typography variant="caption" className={classes.compareText}>
							Add to Compare
						</Typography>
					</Button>)
					
				) : (
					""
				)}
			</div>
		</React.Fragment>
	)
}

export default withStyles(style)(PackageCardImage)
