import axios from "axios"

export const getDepartureCity = (request) => {
        return axios
                .get("/get/departure-city", request.data)
                .then((response) => {
                        return response.data
                })
                .catch((err) => {
                        return err.response.data
                })
}
