import posthog from "posthog-js"
import { TrackingEventName } from "./trackingConstants"

export class PosthogTracker {
	configure() {
		const is_debug = !(process.env.REACT_APP_NODE_ENV === "production")
		const posthogToken = process.env.REACT_APP_POSTHOG_KEY
		if (!is_debug && posthogToken) {
			posthog.init(posthogToken, { api_host: "https://app.posthog.com" })
		}
	}

	/**
	 *
	 * @param TrackableEvent event
	 */
	trackEvent(event) {
		posthog.capture(event.name, event.properties)
	}

	/**
	 *
	 * @param TrackableEvent event
	 */
	trackPageviewEvent(event) {
		posthog.capture(TrackingEventName.PageView, event.properties)
	}

	setUserIdentifier(phoneNumber) {
		posthog.identify(phoneNumber)
		posthog.capture('$set', {phone: phoneNumber})
	}

	setUserProperties(name, email) {
		posthog.capture('$set', {name: name, email: email})
	}

	logout() {
		posthog.reset()
	}
}
