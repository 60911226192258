import React from "react";
import "./mobilewhytripstore.css";
import TwoItemsSlider from "../Slider/TwoItemsSlider";
import WhyTripStoreCard from "../WhyTripStoreCard/WhyTripStoreCard";

function MobileWhyTripStore({ data }) {
  const themeDivs = data?.list?.items.map((item, index) => {
    return (
      <WhyTripStoreCard
        key={index}
        imageUrl={item.image}
        title={item.title}
        subtitle={item.subtitle}
      />
    );
  });

  return <TwoItemsSlider divs={themeDivs} />;
}

export default MobileWhyTripStore;
