import axios from "axios"

export const getMetaDetailService = (request) => {
        let Agentdata = request && request.data
        let id = Agentdata && Agentdata.params

        let headers = new Headers()
        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "http://localhost:3000")
        const params = new URLSearchParams()
        params.append("page_id", id.page)
        return axios({
                method: "POST",
                url: "/meta-data",
                data: params,
                headers: headers,
                // responseType: "blob"
        })
                .then((response) => {
                        return response
                })
                .catch((error) => {
                        return error.response
                })
}
