import { put, call } from "redux-saga/effects";
import { getMetaDetailService } from "../services/getMeta.service";

import { getMetaDetailSuccessAction } from "../actions/getMeta.action";
import * as types from "../action.types";
export function* getMetaDetailSaga(data) {
  try {
    const response = yield call(getMetaDetailService, data);
    if (response.status === 200) {
      yield put(getMetaDetailSuccessAction(response));
    }
  } catch (error) {
    yield put({ type: types.GET_META_ERROR, error });
  }
}
