import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants"
import { TrackableEvent } from "./trackableEvent"

export const HomeWidgetType = Object.freeze({
	EUROPE_DESTINATION: "europe_destination",
	THEME: "theme",
	OTHER_DESTINATION: "other_destination",
	POPULAR_PACKAGE: "popular_package",
	SEASON: "season",
})

export class HomeWidgetItemClickEvent extends TrackableEvent {
	/**
	 *@var HomeWidgetType widgetType
	 */
	constructor(widgetType, itemTitle) {
		let properties = {}
		properties[TrackerPropertyKey.WidgetType] = widgetType
		properties[TrackerPropertyKey.ItemTitle] = itemTitle

		super(TrackingEventName.HomeWidgetItemClick, properties)
	}
}
