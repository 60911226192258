import { Box, Button, Grid, Typography, withStyles } from "@material-ui/core";

import React, { useState } from "react";
import { Link } from "react-router-dom";
import icon_whatsapp from "../../../assets/images/icon_whatsapp.svg";
import FormModal from "../../FormModal/FormModal";
import RequestCallForm from "../../RequestCall/RequestCallForm";
import ModalComponent from "../../ModalComponent/ModalComponent";
import LoginUser from "../../Auth/Login/LoginUser";
import SaleInfo from "../../../models/listing/saleInfo";
import { TrackingOrigin } from "../../../../tracking/trackingConstants";
import { loginEndEvent } from "../../../../tracking/events/loginEndEvent";
import { Tracker } from "../../../../tracking/tracker";

const style = (theme) => ({
  requestCallText: {
    color: "#ff6840",
    textTransform: "none",
    fontSize: 18,
    fontWeight: 600,
  },
  tertiaryButton: {
    textDecoration: "underline",
    textDecorationColor: "#ff6840",
    paddingBottom: theme.spacing(1),
  },
  viewDetailText: {
    color: "#fff",
    textTransform: "none",
    fontSize: 14,
    fontWeight: 600,
  },
  whatsappButton: {
    backgroundColor: "transparent",
    hover: "none",
    padding: 0,
    paddingRight: theme.spacing(1),
    margin: 0,
    minWidth: "auto",
  },
  whatsappImage: {
    width: "32px",
    height: "32px",
  },
});

const Actions = (props) => {
  const [openCallRequestModal, setOpenCallRequestModal] = useState(false);
  const [isrequestCallButtonClicked, setIsRequestCallButtonClicked] =
    useState("");
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const { classes, packageData, shouldShowRetailPrice } = props;
  const isLoggedIn = localStorage.getItem("access_token");
  const [currentView, setCurrentView] = useState("");
  // const saleInfo =  new SaleInfo(packageData?.saleInfo);

  // console.log("the package data",packageData)
  // const shouldShowRetailPrice =
  // (saleInfo && saleInfo.retailPrice && saleInfo.shouldShowRetailPrice) ??
  // false;
  const packageDetails = {
    packageName: packageData?.name,
    packageImage: packageData?.images[0],
    packageListPrice: packageData?.saleInfo?.listPrice,
    packageRetailPrice: packageData?.saleInfo?.retailPrice,
    packageId: packageData?.id,
    shouldShowRetailPrice: shouldShowRetailPrice,
    slug: packageData?.slug,
    discount: packageData?.saleInfo?.discountPercentage,
  };

  const handleRequestCallClick = () => {
    handleOpenRequestCallModal();
  };
 
  const handleCloseRequestModal = () => {
    setOpenCallRequestModal(false);
  
  };
  const handleOpenRequestCallModal = () => {
    setOpenCallRequestModal(true);
  };
  // requestCallSubmitEvent
  return (
    <React.Fragment>
   
      <FormModal
        packageDetails={packageDetails}
        openCustomizeModal={openCallRequestModal}
        handleCloseCustomizeModal={handleCloseRequestModal}
        children={<RequestCallForm />}
        origin={TrackingOrigin.LeadForm.PackageList}
        // setCurrentView={setCurrentView}
      />

      <Grid item>
        <Grid
          container
          item
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Button
            variant="text"
            className={classes.tertiaryButton}
            onClick={() => {
              handleRequestCallClick();
            }}
            // onClick={props.onRequestCallClick}
          >
            <Typography variant="body2" className={classes.requestCallText}>
              Request call
            </Typography>
          </Button>
          <Grid container item direction="row">
            <Button
              className={classes.whatsappButton}
              onClick={props.onWhatsAppClick}
            >
              <img
                src={icon_whatsapp}
                alt="WhatsApp"
                className={classes.whatsappImage}
              />
            </Button>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={`/packagedetails/${packageData.slug}`}
              onClick={props.onPackageDetailClick}
            >
              <Typography variant="body2" className={classes.viewDetailText}>
                View Details
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(style)(Actions);
