import { put, call } from "redux-saga/effects";
import { getThemeService } from "../services/getTheme.service";
import { getThemeActionSuccess } from "../actions/getTheme.action";
import * as types from "../action.types";


export function* getThemeSaga() {
    try {
        const response = yield call(getThemeService);
        yield put(getThemeActionSuccess(response.data))

    }
    catch (error) {
        yield put({ type: types.GET_THEME_ERROR, error })

    }
}