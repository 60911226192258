import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants"
import { TrackableEvent } from "./trackableEvent"

export class HomeWidgetItemClickedNamedEvent extends TrackableEvent {
	constructor(widgetType, widgetIdentifier, widgetItemTitle1, widgetItemTitle2,) {
		 // Titles will be lowercased and spaces will be replaced by underscore
		const title1 = widgetItemTitle1 ? widgetItemTitle1.toLowerCase().replace(" ", "_") : null
		const title2 = widgetItemTitle2 ? widgetItemTitle2.toLowerCase().replace(" ", "_") : null

		// Event name will be a combination of widgetIdentifier and titles if not null

		const eventName = widgetIdentifier + (title1 ? `_${title1}` : "") + (title2 ? `_${title2}` : "")

		let properties = {}
		properties[TrackerPropertyKey.WidgetType] = widgetType
		properties[TrackerPropertyKey.widgetIdentifier] = widgetIdentifier
		
		if (widgetItemTitle1)
			properties[TrackerPropertyKey.widgetItemTitle1] = widgetItemTitle1
		
		if (widgetItemTitle2)
			properties[TrackerPropertyKey.widgetItemTitle2] = widgetItemTitle2

		super(eventName, properties)
	}
}