import { combineReducers } from "redux"
import { getDestinationReducer } from "./getDestination.reducer"
import { getThemeReducer } from "./getTheme.reducer"
import { getSeasonReducer } from "./getSeason.reducer"
import { getMonthReducers } from "./getMonth.reducers"
import { getInclusionReducer } from "./getInclusion.reducer"
import { getPopularPackageReducer } from "./getPopularPackage.reducer"
import { getPackageListReducer } from "./getPackagelist.reducer"
import { getBannerReducer } from "./getBanner.reducer"
import { getRequestCallReducer } from "./PostrequestCall.reducer"
import { getCompareListReducer } from "./getCompareList.reducer"
import { getPackageDetailReducer } from "./getPackageDetail.reducer"
import { getRequestPackageReducer } from "./GetRequestcall.reducer"
import { agentReducer } from "./agent.reducer"
import { termsConditionReducer } from "./termsCondition.reducer"
import { getdeparturCityReducer } from "../reducers/getdeparturecity.reducer"
import { getMetaDetailReducer } from "../reducers/getMeta.reducer"
import { getEuropeDestinationReducer } from "./getEuropeDestination.reducer"
import { getOtherDestinationReducer } from "./getOtherDestination.reducer"
import { listingReducer } from "./listing.reducer"
import { filtersReducer } from "./filters.reducer"

const rootReducer = combineReducers({
    getDestinationReducer,
    getThemeReducer,
    getSeasonReducer,
    getMonthReducers,
    getInclusionReducer,
    getPopularPackageReducer,
    getPackageListReducer,
    getBannerReducer,
    getRequestCallReducer,
    getCompareListReducer,
    getPackageDetailReducer,
    getRequestPackageReducer,
    agentReducer,
    termsConditionReducer,
    getdeparturCityReducer,
    getMetaDetailReducer,
    getEuropeDestinationReducer,
    getOtherDestinationReducer,
    listingReducer,
    filtersReducer,
})

export default rootReducer
