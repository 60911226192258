import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Container, IconButton, Avatar, Typography } from "@material-ui/core"
import { Link } from "react-router-dom"
import Skeleton from "@material-ui/lab/Skeleton"
import ItemsCarousel from "react-items-carousel"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 10,
		[theme.breakpoints.down("xs")]: {
			paddingRight: 0,
		},
	},
	title: {
		color: "#16222b",
		//  padding:theme.spacing(3)
		margin: "4% 0% 2% 0%",
		[theme.breakpoints.down("xs")]: {
			margin: "8% 0% 2% 0%",
			fontWeight: "500",
		},
	},
	avatar: {
		flex: "0 content",
		width: "120px",
		paddingRight: "20%",
	},

	name: {
		color: "#16222b",
		// marginRight: theme.spacing(4),
		marginTop: theme.spacing(1),
		top: 0,
		textAlign: "center",
		lineHeight: "normal",
		[theme.breakpoints.down("sm")]: {
			marginRight: 0,
			textAlign: "center",
			// marginLeft: theme.spacing(2),
		},
	},
	large: {
		width: theme.spacing(20),
		height: theme.spacing(20),
	},
	avatarImage: {
		height: "auto",
		width: "97%",
		[theme.breakpoints.down("sm")]: {
			// height: 100,
			width: "100%",
		},
		// [theme.breakpoints.down("sm")]: {
		//   height: 100,
		//   width: 100
		// }
	},
}))

const chevronWidth = 0
const Theme = (props) => {
	const [activeItemIndex, setActiveItemIndex] = useState(0)
	const matches1 = useMediaQuery((theme) => theme.breakpoints.down("xs"))
	const matches2 = useMediaQuery((theme) => theme.breakpoints.down("sm"))
	const matches3 = useMediaQuery((theme) => theme.breakpoints.up("md"))
	const matches4 = useMediaQuery((theme) => theme.breakpoints.up("xs") && theme.breakpoints.down("sm"))
	const classes = useStyles()
	const { data } = props

	const onClick = (name) => {
		props.onClick(name)
	}

	return (
		<React.Fragment>
			<Container className={classes.root}>
				<Grid md={12} item>
					<Typography variant={matches1 ? "h6" : "h5"} className={classes.title}>
						Explore Themes
					</Typography>
				</Grid>
				{/* <Grid justify="space-between"> */}
				<ItemsCarousel
					gutter={16}
					numberOfCards={matches1 ? 3 : matches2 ? 6 : matches3 ? 9 : matches4 ? 5 : 9}
					freeScrolling={true}
					activeItemIndex={activeItemIndex}
					requestToChangeActive={setActiveItemIndex}
					showSlither={window.innerWidth <= 576 ? true : false}
					leftChevron={
						<IconButton
							style={{
								borderRadius: "50%",
								background: "#fff",
								outline: "none",
								right: -20,
								boxShadow: "1px 0px 20px rgb(0,0,0,0.1)",
								display: matches2 ? "none" : "",
								top: 40,
								position: "absolute",
							}}>
							<KeyboardArrowLeftIcon fontSize="default" style={{ color: "#16222b", fontWeight: 800 }} />
						</IconButton>
					}
					rightChevron={
						<IconButton
							style={{
								borderRadius: "50%",
								background: "#fff",
								outline: "none",

								boxShadow: "1px 0px 4px rgb(0,0,0,0.1)",
								display: matches2 ? "none" : "",
								position: "absolute",
								top: 40,
							}}>
							<KeyboardArrowRightIcon fontSize="default" style={{ color: "#16222b", fontWeight: 800 }} />
						</IconButton>
					}
					chevronWidth={chevronWidth}>
					{(!data.data ? Array.from(new Array(8)) : data && data.data).map((obj) => (
						<div>
							{obj ? (
								<Grid>
									<Link to={`/packages?theme=${encodeURIComponent(obj.theme_name)}`} onClick={() => onClick(obj.theme_name)}>
										<Avatar alt="themes" round={true} src={obj.theme_images} className={classes.avatarImage} />
									</Link>
									<Grid container justify="center">
										<Typography variant={matches1 ? "body2" : "subtitle1"} className={classes.name}>
											{obj.theme_name}
										</Typography>
									</Grid>
								</Grid>
							) : (
								<Skeleton
									variant="circle"
									style={{
										height: window.innerWidth >= 768 ? 180 : window.innerWidth <= 576 ? 120 : "",
										width: window.innerWidth >= 1024 ? 120 : window.innerWidth <= 576 ? 80 : "",
										// borderRadius: "50%",
									}}
								/>
							)}
						</div>
					))}
				</ItemsCarousel>
				{/* </Grid> */}
			</Container>
		</React.Fragment>
	)
}

export default Theme
