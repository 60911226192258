
import * as types from "../action.types";
export const getPopularPackageReducer = (state = {}, action) => {
    const response = action.values;
   
    switch (action.type) {
        case types.GET_POPULAR_PACKAGES_SUCCESS:
            return response;
        case types.GET_POPULAR_PACKAGES_ERROR:
            return state;
        default:
            return state;
    }
};
