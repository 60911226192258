import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Success from "../../assets/images/sucessform.png";
import { Grid } from "@material-ui/core";
import Header from "../PackageDetails/PackageInformation/termsHeader";
import { getMetaDetailAction } from "../../store/actions/getMeta.action";
import Footer from "../footer/footer";
import { TrackingPageName } from "../../../tracking/trackingConstants";
import { NavigationContext } from "../../navigation/navigationContext";
import successImage from "../../assets/images/success.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

class SuccessForm extends Component {
  static contextType = NavigationContext;

  componentDidMount = () => {
    // Tracking Page View
    this.context.setBrowsingPage(TrackingPageName.ThankYou);

    this.props.getMetaDetailAction({ params: { page: "thanks" } });
    window.scrollTo(0, 0);
  };

  render() {
    const handleClose = () => {
      const previousPage = sessionStorage.getItem("previousPage");
      if (previousPage) {
        window.location.href = previousPage;
      } else {
        window.location.href = "/";
      }
    };
    const { _metaDetail } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>
            {_metaDetail.data ? _metaDetail.data.data.meta_title : ""}
          </title>
          <meta charset="UTF-8" />
          <meta
            name="description"
            content={
              _metaDetail.data ? _metaDetail.data.data.meta_description : ""
            }
          />
          <meta
            name="keywords"
            content={_metaDetail.data ? _metaDetail.data.data.meta_keyword : ""}
          />
          <meta name="author" content="Tripstore" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
        <Header />
        <Grid md={12} xs={12} sm={12} item container>
          <Grid md={4} />
          <Grid md={4} item container justifyContent="center">
            <Grid md={11} xs={11} sm={11} item style={{ marginTop: 100 }}>
              <LazyLoadImage
                src={successImage}
                effect="blur"
                width="100%"
                loading="lazy"
                alt="success-image"
                class="success-image"
              />
            </Grid>
            <Grid
              md={12}
              item
              container
              justifyContent="center"
              style={{ marginTop: 20 }}
            >
              <Typography
                // variant="h6"
                color="primary"
                style={{ fontWeight: "normal", fontSize: 20 }}
              >
                Always be trip-ing!!
              </Typography>
            </Grid>

            <Grid
              md={12}
              item
              container
              justifyContent="center"
              style={{ marginTop: 8 }}
            >
              <Typography
                // variant="body2"
                style={{
                  color: "#616e78",
                  textAlignLast: "center",
                  width: "58%",
                  fontSize: 14,

                  //  textAlign: "center"
                }}
              >
                Thank you for sharing your details. Our team will reach out to
                you shortly.
              </Typography>
            </Grid>
            <Grid
              md={12}
              xs={12}
              sm={12}
              container
              item
              justifyContent="center"
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth={false}
                onClick={handleClose}
                style={{
                  textTransform: "none",
                  margin: "24px 0",
                  color: "white",
                }}
              >
                <Typography style={{ fontSize: 18 }}>
                  Back To Browsing
                </Typography>
              </Button>
            </Grid>
          </Grid>{" "}
          <Grid md={4} item />
        </Grid>
        <footer>
          <Footer />
        </footer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ getMetaDetailReducer }) => {
  return {
    _metaDetail: getMetaDetailReducer,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    getMetaDetailAction,
  })(SuccessForm)
);
