import axios from "axios"
export const getRequestPackageService = (request) => {
        let Packagedata = request.data

        let headers = new Headers()
        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "http://localhost:3000")

        const params = new URLSearchParams()
        params.append("name", Packagedata.name)
        params.append("email", Packagedata.email)
        params.append("phone", Packagedata.phone)
        params.append("package_id", Packagedata.package_id)
        params.append("compareListUrl", Packagedata.compareListUrl)
        params.append("itinerary_pdf", Packagedata.Itinerary_pdf)
        params.append("page_number", Packagedata.page_number)
        params.append("package_url", Packagedata.package_url)

        return axios({
                method: "POST",
                url: "/request/call",
                data: params,
                headers: headers,
        })
                .then((response) => {
                        return response
                })
                .catch((err) => {
                        return err.response.data
                })
}
