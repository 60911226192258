import { put, call } from "redux-saga/effects";
import { getSeasonService } from "../services/getSeason.service";

import { getSeasonActionSuccess } from "../actions/getSeason.action";
import * as types from "../action.types";
export function* getSeasonSaga() {
    try {
        const response = yield call(getSeasonService);
        yield put(getSeasonActionSuccess(response.data));

    } catch (error) {
        yield put({ type: types.GET_SEASON_ERROR, error });
    }
}
