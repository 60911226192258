import React from "react";
import "./carouselfive.css";
import MobileWhyTripStore from "../MobileWhyTripStore/MobileWhyTripStore";
import { getScreenSize } from "../../../utils/utility";
import DesktopWhyTripStore from "../DesktopWhyTripStore/DesktopWhyTripStore";

function CarouselFive({ data }) {
  const screenSize = getScreenSize().width;

  return screenSize <= 768 ? (
    <div className="c5-main-container">
      <div className="global-widget-headings">{data.title}</div>
      <MobileWhyTripStore data={data} />
    </div>
  ) : (
    // "Desktop Why Trip Store View"
    <div className="c5-main-container">
      <div className="global-widget-headings">{data.title}</div>
      <DesktopWhyTripStore data={data} />
    </div>
  );
}

export default CarouselFive;
