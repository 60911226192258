import React from "react";
import { Grid, Typography, Container, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  parent: {
    paddingBottom: 32,
    [theme.breakpoints.down("sm")]: {
      marginTop: 70,
    },
  },
  containerGrid: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  ContentGrid: {
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  parentTitle: {
    flex: 1,
  },
  mainHeading: {
    color: "#16222B",
    fontSize: 24,
    marginTop: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  Title: {
    fontSize: 16,
    color: "#16222B",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
    // fontSize: 18,
  },
  // linksGrid: {
  //   position: "sticky",
  //   top: "30%",
  //   [theme.breakpoints.down("sm")]: {
  //     display: "none",
  //   },
  // },
  box: {
    position: "absolute",
    bottom: 20,
    left: -20,
  },
  navlinkGrid: {
    height: "74vh",
    // maxHeight: "100vh",
    position: "sticky",
    display: "block",
    // bottom: 0,
    top: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  subtitle: {
    color: "#616E78",
    marginTop: 8,
    fontSize: 16,
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  url: {
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  titleandsubtitle: {
    marginTop: 24,
  },
  links: {
    padding: "12px 12px 0px 12px",
  },
}));
const Model = (props) => {
  // const [seturl] = useState("");
  const { Picture, completeData } = props;
  const classes = useStyles();

  return (
    <Container className={classes.containerGrid}>
      <Grid md={12} sm={12} xs={12} item container className={classes.parent}>
        <Grid md={2} item />
        <Grid md={8} xs={12} sm={12} container item style={{ width: "100%" }}>
          <Grid md={12} sm={12} xs={12} item>
            <img
              src={Picture}
              alt="imagesrc"
              height="auto"
              width="100%"
              style={{ objectFit: "fill" }}
            />
          </Grid>
          <Grid md={12} xs={12} sm={12} item className={classes.ContentGrid}>
            <Typography className={classes.mainHeading}>
              {completeData.mainHeading}
            </Typography>

            {completeData.perInfo.map((obj, i) => (
              <span>
                <Grid style={{ paddingTop: obj.mainText ? 24 : 0 }}>
                  <Typography>{obj.mainSubHeading}</Typography>
                  <Typography className={classes.Title}>
                    {obj.mainText}
                  </Typography>
                  <Typography className={classes.subtitle}>
                    {obj.body}
                  </Typography>
                </Grid>

                {obj.Listings ? (
                  <ol>
                    {Array.isArray(obj.Listings) &&
                      obj.Listings.map((listItems, i) => (
                        <span>
                          {listItems.title ? (
                            <span>
                              <li>
                                <Typography
                                  variant="body1"
                                  className={classes.Title}
                                >
                                  <u>{listItems.title} </u>
                                </Typography>
                              </li>
                              <ol style={{ paddingTop: 16, paddingBottom: 16 }}>
                                {listItems.subpoints
                                  ? Array.isArray(listItems.subpoints) &&
                                    listItems.subpoints.map((item) => (
                                      <span
                                        style={{
                                          paddingTop: 16,
                                          paddingBottom: 16,
                                        }}
                                      >
                                        <li style={{ paddingTop: 16 }}>
                                          <Typography
                                            className={classes.subtitle}
                                            style={{ color: "#616E78" }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                color: "#16222B",
                                              }}
                                            >
                                              {`"${item.head}"`}
                                            </span>{" "}
                                            {item.desc}
                                          </Typography>
                                        </li>
                                      </span>
                                    ))
                                  : ""}
                              </ol>
                            </span>
                          ) : listItems.subpoints ? (
                            <ol type="I">
                              {Array.isArray(listItems.subpoints) &&
                                listItems.subpoints.map((item) => (
                                  <li className={classes.subtitle}>
                                    <span style={{ display: "flex" }}>
                                      <Typography className={classes.subtitle}>
                                        {item.head} {item.desc}
                                      </Typography>
                                    </span>
                                  </li>
                                ))}
                            </ol>
                          ) : listItems.Address ? (
                            Array.isArray(listItems.Address) &&
                            listItems.Address.map((item) => (
                              <span>
                                <Typography className={classes.subtitle}>
                                  {item.head} {item.desc}
                                </Typography>
                              </span>
                            ))
                          ) : (
                            ""
                          )}
                        </span>
                      ))}
                  </ol>
                ) : (
                  ""
                )}
                <span style={{ display: "contents" }}>
                  <Typography className={classes.subtitle}>
                    {obj.bodyBottom}
                  </Typography>
                  {obj.url ? (
                    <a
                      href={obj.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography className={classes.url}>{obj.url}</Typography>
                    </a>
                  ) : (
                    ""
                  )}
                  {obj.completeText ? (
                    <Typography className={classes.subtitle}>
                      {obj.completeText}
                    </Typography>
                  ) : (
                    ""
                  )}
                </span>
              </span>
            ))}
          </Grid>
        </Grid>
        <Grid md={2} />
      </Grid>
    </Container>
  );
};
export default Model;
