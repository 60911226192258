

import { TrackingEventName } from "../trackingConstants"
import { TrackableEvent } from "./trackableEvent"

export class ListingFilterApplyClickEvent extends TrackableEvent {
	constructor() {
		let properties = {}
		super(TrackingEventName.ListingFilterApplyClick, properties)
	}
}