import * as types from "../action.types"

export const getPackageListAction = (data) => {
    return {
        type: types.GET_PACKAGE_LIST,
        data,
    }
}

export const getPackageListSuccessAction = (data) => {
    return {
        type: types.GET_PACKAGE_LIST_SUCCESS,
        data,
    }
}
export const getResponseNullAction = () => {
    return {
        type: types.GET_RESPONSE_NULL,
    }
}
