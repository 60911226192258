import * as types from "../action.types";

export const getDepartureCityAction = (data) => {
  return {
    type: types.GET_DEPARTURE_CITY,
    data,
  };
};

export const getDepartureCitySuccessAction = (data) => {
  return {
    type: types.GET_DEPARTURE_CITY_SUCCESS,
    data,
  };
};
export const getResponseNullAction = () => {
  return {
    type: types.GET_RESPONSE_NULL,
  };
};
