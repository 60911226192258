import * as types from "../action.types"

export const getSeasonAction = () => {
    return {
        type: types.GET_SEASON
    };
};

export const getSeasonActionSuccess = values => {
    return {
        type: types.GET_SEASON_SUCCESS,
        values
    }
}