import axios from "axios"

export const agentService = (request) => {
        let Agentdata = request && request.data

        let headers = new Headers()
        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "http://localhost:3000")
        const params = new URLSearchParams()
        params.append("first_name", Agentdata.firstName)
        params.append("last_name", Agentdata.LastName)
        params.append("company_name", Agentdata.CompanyName)
        params.append("website_url", Agentdata.Website)
        params.append("email", Agentdata.email)
        params.append("phone", Agentdata.phone)
        params.append("message", Agentdata.agent_info)

        return axios({
                method: "POST",
                url: "/add/agent",
                data: params,
                headers: headers,
        })
                .then((response) => {
                        return response
                })
                .catch((error) => {
                        return error.response
                })
}
