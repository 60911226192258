import { put, call } from "redux-saga/effects";
import { getDepartureCity } from "../services/getdepartureCity.service";

import { getDepartureCitySuccessAction } from "../actions/getdeparturecity.action";
import * as types from "../action.types";
export function* getDeparturecitySaga(data) {
  try {
    const response = yield call(getDepartureCity, data);

    yield put(getDepartureCitySuccessAction(response));
  } catch (error) {
    yield put({ type: types.GET_DEPARTURE_CITY_ERROR, error });
  }
}
