import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { getScreenSize } from "../../../utils/utility";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    outline: "none",
    border: "none",
    // width:window.innerWidth <= 768 ? "90%":"",
    boxShadow: theme.shadows[1],
  },
}));

function FormModal({
  packageDetails,
  openCustomizeModal,
  handleCloseCustomizeModal,
  children,
  setCurrentView,
  origin,
  countryCode,
  mobileNumber,
  shouldShowSuccessView
  
}) {

  const classes = useStyles();
  //   const closeSuccesModal=()=>{
  //  window.location.reload()
  //   handleCloseCustomizeModal();
  // }
  // const handleBackdropClick = (event) => {
  //   event.stopPropagation();
  // };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} animate-slide-up`}
      open={openCustomizeModal}
      onClose={handleCloseCustomizeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        // onClick: handleBackdropClick,
      }}
    >
      <Fade in={openCustomizeModal}>
        <div className={classes.paper}>
          {" "}
          {React.cloneElement(children, {
            packageDetails,
            handleCloseCustomizeModal,
            // setCurrentView,
            origin,
            countryCode,
            mobileNumber,
            shouldShowSuccessView
          })}
        </div>
      </Fade>
    </Modal>
  );
}

export default FormModal;
