import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { Typography, Card, Icon, Button } from "@material-ui/core";
import "./searchbar.css";
import Modal from "@material-ui/core/Modal";
import SearchIcon from "../../assets/images/search_icon.svg";
import ClearIcon from "@material-ui/icons/Clear";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PlaceIcon from "@material-ui/icons/Place";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/images/drawerLogo.png";
import {
  PrimaryButton,
  PrimaryDisabledButton,
  PrimarySubmitButton,
} from "../../../utils/Buttons";
import TuneIcon from "@material-ui/icons/Tune";
import "./mobilesearchbar.css";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import place from "../../assets/images/placeholder.png";
import calender from "../../assets/images/calendar.png";
import budget from "../../assets/images/money-bag.png";
import { SearchType } from "./SearchBar";

const style = makeStyles((theme) => ({
  textField: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: window.innerWidth < 768 ? ".8rem" : "1rem",
    },
  },
  input: {
    marginBottom: 10,
    outline: "none",
  },
  button: {},
  search: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 10,
    "&:hover": {
      background: "none",
    },
  },
  cards: {
    width: "90%",
    // vertical: "top",
    // horizontal: "left",
    zIndex: 1000,
    left: "30px",
    maxHeight: "300px",
    position: "relative",
    overflowY: "scroll",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "100%",
    height: "100%",
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1, 3, 1),
    backgroundColor: "white",
  },
}));
var searchOptions = {
  types: ["(regions)"],
};

function MobileSearchBar({
  handleOpenModal,
  handleCloseModal,
  openSearchModal,
  handleChange,
  handleSelect,
  address,
  isGoogleListOpen,
  handleFocus,
  handleBlur,
  isDestinationsListOpen,
  isDatesListOpen,
  setIsDatesListOpen,
  setTripDate,
  isBudgetsListOpen,
  setIsBudgetsListOpen,
  suggestionMetaData,
  handledestination,
  datesSuggestionsMetaData,
  handleSearchDates,
  tripDate,
  getNavLinkQueryString,
  isGoogleSearched,
  budgetSuggestionsMetaData,
  handleSearchBudgets,
  tripBudget,
  setTripBudget,
  handleClearAllInputFields,
  dateswrapperRef,
  budgetswrapperRef,
  googlesearchwrapperRef,
  handleCompleteSearchEvent,
  handleClickInput,
  handleMouseDown,
  setSearchEndLabel,
  setSearchTypeLebel,
  source,
}) {
  const classes = style();
  const handleClick = () => {
    const queryString = getNavLinkQueryString(isGoogleSearched);
    handleCompleteSearchEvent();
    handleCloseModal();

    window.location.assign(`/packages${queryString}`);
  };
  return source === "nav_bar" ? (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} animate-popup-animation`}
      open={openSearchModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openSearchModal}>
        <div className={classes.paper} onClick={(e) => e.stopPropagation()}>
          <div className="mobile-modal-container">
            <div className="mobile-topbar animate-slide-down">
              {" "}
              <ClearIcon
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  padding: "5px",
                  border: "1px solid grey",
                }}
                onClick={handleCloseModal}
              />
              <img src={logo} height="100%" width="130" alt="logo" />
            </div>
            <div className="mobile-middleContainer animate-slide-down">
              <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                searchOptions={searchOptions}
                shouldFetchSuggestions={true}
                highlightFirstSuggestion={true}
                onSelect={handleSelect}
                onBlur={handleBlur}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                  <div
                    className=""
                    style={{
                      width: "100%",

                      display: "flex",
                      flexDirection: "column",

                      boxShadow: "0 0 8px rgba(0, 0, 0, 0.4)",
                      gap: "10px",
                      alignItems: "center",

                      borderRadius: "10px",
                    }}
                  >
                    <div className="mobile-input-field-container">
                      <div className="mobile-input-field-icons-wrapper">
                        <img
                          src={place}
                          alt="img"
                          className="mobile-input-field-icons"
                        />
                      </div>
                      <div
                        className="mobile-input-field-text-wrapper"
                        ref={googlesearchwrapperRef}
                      >
                        <label
                          for="destinations"
                          className="mobile-input-field-text"
                        >
                          WHERE
                        </label>
                        <div style={{ position: "relative" }}>
                          <input
                            onClick={() => handleClickInput(SearchType.WHERE)}
                            id="destinations"
                            className="mobile-input-field-search"
                            type="text"
                            {...getInputProps({
                              placeholder: "Select Your Destination",
                            })}
                            onFocus={() => handleFocus(SearchType.WHERE)}
                            onBlur={handleBlur}
                          />
                          <div
                            className="autocomplete-dropdown-container"
                            style={{
                              position: "absolute",

                              right: "-10px",
                              // left:"20px",
                              width: "120%",
                              zIndex: "1",
                            }}
                          >
                            {isGoogleListOpen ? (
                              <Card
                                onClick={(e) => e.stopPropagation()}
                                id="custom-scrollbar"
                                className={`${classes.cards} animate-popup-animation`}
                              >
                                {suggestions.map((suggestion) => {
                                  return (
                                    <div
                                      {...getSuggestionItemProps(
                                        suggestion,
                                        {}
                                      )}
                                    >
                                      <div
                                        fullWidth
                                        className="suggestion-list-items"
                                        onClick={() =>
                                          handleFocus(SearchType.WHEN)
                                        }
                                      >
                                        <PlaceIcon className="mobile-suggestions-icons" />

                                        <Typography className="mobile-suggestions-text">
                                          {suggestion.description}
                                        </Typography>
                                      </div>
                                    </div>
                                  );
                                })}
                              </Card>
                            ) : isDestinationsListOpen ? (
                              <Card
                                onClick={(e) => e.stopPropagation()}
                                id="custom-scrollbar"
                                // style={{ backgroundColor: "red" }}
                                className={`${classes.cards} animate-popup-animation`}
                              >
                                {suggestionMetaData.map((suggestion) => {
                                  return (
                                    <div
                                      {...getSuggestionItemProps(
                                        suggestion,
                                        {}
                                      )}
                                    >
                                      <Button
                                        fullWidth
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                          cursor: "pointer",
                                          padding: "8px 5px",
                                          fontSize: "16px",
                                        }}
                                        // className="suggestion-list-items"
                                        onClick={() =>
                                          handledestination(
                                            suggestion.description
                                          )
                                        }
                                      >
                                        <PlaceIcon className="mobile-suggestions-icons" />

                                        <Typography     style={{ fontSize: "16px" }} className="mobile-suggestions-text">
                                          {suggestion.description}
                                        </Typography>
                                      </Button>
                                    </div>
                                  );
                                })}
                              </Card>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mobile-input-field-container">
                      {" "}
                      <div className="mobile-input-field-icons-wrapper">
                        <img
                          src={calender}
                          alt="img"
                          className="mobile-input-field-icons"
                        />
                      </div>
                      <div
                        className="mobile-input-field-text-wrapper"
                        ref={dateswrapperRef}
                      >
                        <label for="months" className="mobile-input-field-text">
                          WHEN
                        </label>
                        <div style={{ position: "relative" }}>
                          <input
                            id="months"
                            type="text"
                            value={tripDate}
                            onBlur={handleBlur}
                            onClick={() => handleClickInput(SearchType.WHEN)}
                            onChange={handleSearchDates}
                            onFocus={() => handleFocus(SearchType.WHEN)}
                            className="mobile-input-field-search"
                            placeholder="Select Your Month"
                            autoComplete="off"
                          />
                          {isDatesListOpen ? (
                            <Card
                              onClick={(e) => e.stopPropagation()}
                              id="custom-scrollbar"
                              style={{
                                position: 'absolute',
                                top: '25px',
                                right: '0',
                                width: '100%',
                                zIndex: 1000,
                                maxHeight: '250px',
                                overflowY: 'scroll',
                                
                              }}
                              className={`mobile-list-container-card animate-popup-animation`}
                            >
                              {datesSuggestionsMetaData.map((suggestion) => {
                                return (
                                  <div onMouseDown={handleMouseDown}>
                                    <Button
                                      fullWidth
                                      className="suggestion-list-items"
                                      onClick={() => {
                                        setTripDate(suggestion);
                                        setIsDatesListOpen(false);
                                        setSearchEndLabel(SearchType.WHEN);
                                        setSearchTypeLebel("");
                                        handleFocus(SearchType.BUDGET);
                                      }}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        textTransform: "none",
                                      }}
                                    >
                                      <DateRangeOutlinedIcon
                                        style={{
                                          fontSize: "20px",
                                          marginRight: "5px",
                                        }}
                                      />

                                      <Typography className="mobile-suggestions-text">
                                        {suggestion}
                                      </Typography>
                                    </Button>
                                  </div>
                                );
                              })}
                            </Card>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div
                      className="mobile-input-field-container"
                      style={{ border: "none" }}
                    >
                      <div className="mobile-input-field-icons-wrapper">
                        <img
                          src={budget}
                          alt="img"
                          className="mobile-input-field-icons"
                        />
                      </div>
                      <div
                        className="mobile-input-field-text-wrapper"
                        ref={budgetswrapperRef}
                      >
                        <label for="budget" className="mobile-input-field-text">
                          BUDGET
                        </label>
                        <div style={{ position: "relative" }}>
                          <input
                            id="budget"
                            type="text"
                            placeholder="Select Your Budget"
                            value={tripBudget}
                            onBlur={handleBlur}
                            onClick={() => handleClickInput(SearchType.BUDGET)}
                            onChange={handleSearchBudgets}
                            className="mobile-input-field-search"
                            onFocus={() => handleFocus(SearchType.BUDGET)}
                            autoComplete="off"
                          />
                          {isBudgetsListOpen ? (
                            <Card
                              id="custom-scrollbar"
                              style={{
                                position: 'absolute',
                                top: '25px',
                                right: '0',
                                width: '100%',
                                zIndex: 1000,
                                maxHeight: '250px',
                                overflowY: 'scroll',
                                
                              }}
                              className={`mobile-list-container-card animate-popup-animation`}
                            >
                              {budgetSuggestionsMetaData?.map((suggestion) => {
                                return (
                                  <div onMouseDown={handleMouseDown}>
                                    <Button
                                      className="suggestion-list-items"
                                      fullWidth
                                      onClick={() => {
                                        setTripBudget(suggestion);
                                        setIsBudgetsListOpen(false);
                                        setSearchEndLabel(SearchType.BUDGET);
                                        setSearchTypeLebel("");
                                      }}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        textTransform: "none",
                                      }}
                                    >
                                      <MonetizationOnOutlinedIcon
                                        style={{
                                          fontSize: "20px",
                                          marginRight: "5px",
                                        }}
                                      />

                                      <Typography className="mobile-suggestions-text">
                                        {suggestion}
                                      </Typography>
                                    </Button>
                                  </div>
                                );
                              })}
                            </Card>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
            <div className="mobile-bottombar animate-slide-up">
              <h4
                onClick={handleClearAllInputFields}
                className="clear-all-text"
              >
                Clear All
              </h4>

              {tripDate || tripBudget || address ? (
                <NavLink
                  to={`/packages${getNavLinkQueryString(isGoogleSearched)}`}
                  style={{ textDecoration: "none" }}
                >
                  <PrimarySubmitButton
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <span style={{ fontSize: "20px", padding: "0px 20px" }}>
                      {" "}
                      Explore
                    </span>
                  </PrimarySubmitButton>
                </NavLink>
              ) : (
                <PrimaryDisabledButton>
                  {" "}
                  <span style={{ fontSize: "20px", padding: "0px 20px" }}>
                    {" "}
                    Explore
                  </span>
                </PrimaryDisabledButton>
              )}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  ) : (
    <div
      className={source == "nav_bar" ? "" : "mobile-searchbar-container"}
      onClick={handleOpenModal}
    >
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`${classes.modal} animate-popup-animation`}
        open={openSearchModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openSearchModal}>
          <div className={classes.paper} onClick={(e) => e.stopPropagation()}>
            <div className="mobile-modal-container">
              <div className="mobile-topbar animate-slide-down">
                {" "}
                <ClearIcon
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    padding: "5px",
                    border: "1px solid grey",
                  }}
                  onClick={handleCloseModal}
                />
                <img src={logo} height="100%" width="130" alt="logo" />
              </div>
              <div className="mobile-middleContainer animate-slide-down">
                <PlacesAutocomplete
                  value={address}
                  onChange={handleChange}
                  searchOptions={searchOptions}
                  shouldFetchSuggestions={true}
                  highlightFirstSuggestion={true}
                  onSelect={handleSelect}
                  onBlur={handleBlur}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div
                      className=""
                      style={{
                        width: "100%",

                        display: "flex",
                        flexDirection: "column",

                        boxShadow: "0 0 8px rgba(0, 0, 0, 0.4)",
                        gap: "10px",
                        alignItems: "center",

                        borderRadius: "10px",
                      }}
                    >
                      <div className="mobile-input-field-container">
                        <div className="mobile-input-field-icons-wrapper">
                          <img
                            src={place}
                            alt="img"
                            className="mobile-input-field-icons"
                          />
                        </div>
                        <div
                          className="mobile-input-field-text-wrapper"
                          ref={googlesearchwrapperRef}
                        >
                          <label
                            for="destinations"
                            className="mobile-input-field-text"
                          >
                            WHERE
                          </label>
                          <div style={{ position: "relative" }}>
                            <input
                              onClick={() => handleClickInput(SearchType.WHERE)}
                              id="destinations"
                              className="mobile-input-field-search"
                              type="text"
                              {...getInputProps({
                                placeholder: "Select Your Destination",
                              })}
                              onFocus={() => handleFocus(SearchType.WHERE)}
                              onBlur={handleBlur}
                            />
                            <div
                              className="autocomplete-dropdown-container"
                              style={{
                                position: "absolute",

                                right: "-10px",
                                // left:"20px",
                                width: "120%",
                                zIndex: "1",
                              }}
                            >
                              {isGoogleListOpen ? (
                                <Card
                                  onClick={(e) => e.stopPropagation()}
                                  id="custom-scrollbar"
                                  className={`${classes.cards} animate-popup-animation`}
                                >
                                  {suggestions.map((suggestion) => {
                                    return (
                                      <div
                                        {...getSuggestionItemProps(
                                          suggestion,
                                          {}
                                        )}
                                      >
                                        <div
                                          fullWidth
                                          className="suggestion-list-items"
                                          onClick={() =>
                                            handleFocus(SearchType.WHEN)
                                          }
                                        >
                                          <PlaceIcon className="mobile-suggestions-icons" />

                                          <Typography className="mobile-suggestions-text">
                                            {suggestion.description}
                                          </Typography>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </Card>
                              ) : isDestinationsListOpen ? (
                                <Card
                                  onClick={(e) => e.stopPropagation()}
                                  id="custom-scrollbar"
                                  className={`${classes.cards} animate-popup-animation`}
                                >
                                  {suggestionMetaData.map((suggestion) => {
                                    return (
                                      <div
                                        {...getSuggestionItemProps(
                                          suggestion,
                                          {}
                                        )}
                                      >
                                        <Button
                                          fullWidth
                                          style={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "start",
                                            cursor: "pointer",
                                            padding: "8px 5px",
                                          }}
                                          // className="suggestion-list-items"
                                          onClick={() =>
                                            handledestination(
                                              suggestion.description
                                            )
                                          }
                                        >
                                          <PlaceIcon className="mobile-suggestions-icons" />

                                          <Typography
                                            style={{ fontSize: "16px" }}
                                            className="mobile-suggestions-text"
                                          >
                                            {suggestion.description}
                                          </Typography>
                                        </Button>
                                      </div>
                                    );
                                  })}
                                </Card>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mobile-input-field-container">
                        {" "}
                        <div className="mobile-input-field-icons-wrapper">
                          <img
                            src={calender}
                            alt="img"
                            className="mobile-input-field-icons"
                          />
                        </div>
                        <div
                          className="mobile-input-field-text-wrapper"
                          ref={dateswrapperRef}
                        >
                          <label
                            for="months"
                            className="mobile-input-field-text"
                          >
                            WHEN
                          </label>
                          <div style={{ position: "relative" }}>
                            <input
                              id="months"
                              type="text"
                              value={tripDate}
                              onBlur={handleBlur}
                              onClick={() => handleClickInput(SearchType.WHEN)}
                              onChange={handleSearchDates}
                              onFocus={() => handleFocus(SearchType.WHEN)}
                              className="mobile-input-field-search"
                              placeholder="Select Your Month"
                              autoComplete="off"
                            />
                            {isDatesListOpen ? (
                              <Card
                                onClick={(e) => e.stopPropagation()}
                                id="custom-scrollbar"
                                // style={{backgroundColor:"red"}}
                                style={{
                                  position: 'absolute',
                                  top: '25px',
                                  right: '0',
                                  width: '100%',
                                  zIndex: 1000,
                                  maxHeight: '250px',
                                  overflowY: 'scroll',
                                  
                                }}
                                className={`mobile-list-container-card animate-popup-animation`}
                              >
                                {datesSuggestionsMetaData.map((suggestion) => {
                                  return (
                                    <div onMouseDown={handleMouseDown}>
                                      <Button
                                        fullWidth
                                        
                                        className="suggestion-list-items"
                                        onClick={() => {
                                          setTripDate(suggestion);
                                          setIsDatesListOpen(false);
                                          setSearchEndLabel(SearchType.WHEN);
                                          setSearchTypeLebel("");
                                          handleFocus(SearchType.BUDGET);
                                        }}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-start",
                                          textTransform: "none",
                                        }}
                                      >
                                        <DateRangeOutlinedIcon
                                          style={{
                                            fontSize: "20px",
                                            marginRight: "5px",
                                          }}
                                        />

                                        <Typography className="mobile-suggestions-text">
                                          {suggestion}
                                        </Typography>
                                      </Button>
                                    </div>
                                  );
                                })}
                              </Card>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div
                        className="mobile-input-field-container"
                        style={{ border: "none" }}
                      >
                        <div className="mobile-input-field-icons-wrapper">
                          <img
                            src={budget}
                            alt="img"
                            className="mobile-input-field-icons"
                          />
                        </div>
                        <div
                          className="mobile-input-field-text-wrapper"
                          ref={budgetswrapperRef}
                        >
                          <label
                            for="budget"
                            className="mobile-input-field-text"
                          >
                            BUDGET
                          </label>
                          <div style={{ position: "relative" }}>
                            <input
                              id="budget"
                              type="text"
                              placeholder="Select Your Budget"
                              value={tripBudget}
                              onBlur={handleBlur}
                              onClick={() =>
                                handleClickInput(SearchType.BUDGET)
                              }
                              onChange={handleSearchBudgets}
                              className="mobile-input-field-search"
                              onFocus={() => handleFocus(SearchType.BUDGET)}
                              autoComplete="off"
                            />
                            {isBudgetsListOpen ? (
                              <Card
                                id="custom-scrollbar"
                                style={{
                                  position: 'absolute',
                                  top: '25px',
                                  right: '0',
                                  width: '100%',
                                  zIndex: 1000,
                                  maxHeight: '250px',
                                  overflowY: 'scroll',
                                  
                                }}
                                className={`mobile-list-container-card animate-popup-animation`}
                              >
                                {budgetSuggestionsMetaData?.map(
                                  (suggestion) => {
                                    return (
                                      <div onMouseDown={handleMouseDown}>
                                        <Button
                                          className="suggestion-list-items"
                                          fullWidth
                                          onClick={() => {
                                            setTripBudget(suggestion);
                                            setIsBudgetsListOpen(false);
                                            setSearchEndLabel(
                                              SearchType.BUDGET
                                            );
                                            setSearchTypeLebel("");
                                          }}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            textTransform: "none",
                                          }}
                                        >
                                          <MonetizationOnOutlinedIcon
                                            style={{
                                              fontSize: "20px",
                                              marginRight: "5px",
                                            }}
                                          />

                                          <Typography className="mobile-suggestions-text">
                                            {suggestion}
                                          </Typography>
                                        </Button>
                                      </div>
                                    );
                                  }
                                )}
                              </Card>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              <div className="mobile-bottombar animate-slide-up">
                <h4
                  onClick={handleClearAllInputFields}
                  className="clear-all-text"
                >
                  Clear All
                </h4>
                {tripDate || tripBudget || address ? (
                  <NavLink
                    to={`/packages${getNavLinkQueryString(isGoogleSearched)}`}
                    style={{ textDecoration: "none" }}
                  >
                    <PrimarySubmitButton
                      onClick={() => {
                        getNavLinkQueryString(isGoogleSearched);
                        handleCompleteSearchEvent();
                      }}
                    >
                      <span style={{ fontSize: "20px", padding: "0px 20px" }}>
                        {" "}
                        Explore
                      </span>
                    </PrimarySubmitButton>
                  </NavLink>
                ) : (
                  <PrimaryDisabledButton>
                    {" "}
                    <span style={{ fontSize: "20px", padding: "0px 20px" }}>
                      {" "}
                      Explore
                    </span>
                  </PrimaryDisabledButton>
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      <img
        src={SearchIcon}
        style={{ display: source == "nav_bar" ? "none" : "" }}
        alt="search-image"
        className="search-icon"
      />

      <div
        style={{ width: "100%", display: source == "nav_bar" ? "none" : "" }}
      >
        <label for="mobile-searchbar" className="mobile-input-search-text">
          Where to ?
        </label>
        <input
          id="mobile-searchbar"
          onClick={() => handleClickInput("Where to ?")}
          className="mobile-input-field-search"
          type="text"
          style={{ fontSize: "12px" }}
          placeholder="Any Place, Any Time, Any Budget"
        />
      </div>

      <TuneIcon
        className="sttings-icon"
        style={{ display: source == "nav_bar" ? "none" : "" }}
      />
    </div>
  );
}

export default MobileSearchBar;
