import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants";
import { TrackableEvent } from "./trackableEvent";

export class customizeFormSubmitEvent extends TrackableEvent {
  constructor(where, when, budget) {
    let properties = {};

    properties[TrackerPropertyKey.Where] = where;
    properties[TrackerPropertyKey.When] = when;
    properties[TrackerPropertyKey.Budget] = budget;
    super(TrackingEventName.FormSubmitCustomize, properties);
  }
}
