import { TrackingEventName } from "../trackingConstants";
import { TrackableEvent } from "./trackableEvent";

export class FormSubmitPageEvent extends TrackableEvent {
  constructor() {
    let properties = {};

    super(TrackingEventName.FormSubmitPage, properties);
  }
}
