import React, { Component } from "react"
import { Formik } from "formik"
import { withStyles } from "@material-ui/core/styles"
import Form from "../../Banner/Call/form"
import * as Yup from "yup"
import { Typography, Grid, Container, IconButton, Dialog, DialogContent } from "@material-ui/core"
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined"
import Fogg from "../../../assets/images/fogg.png"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import Success from "../../Banner/SucessForm"
import { getRequestPackageAction } from "../../../store/actions/requestACall.action"
import { Tracker } from "../../../../tracking/tracker"
import { RequestCallFormOpenEvent } from "../../../../tracking/events/requestCallFormOpenEvent"
import { RequestCallFormSubmit } from "../../../../tracking/events/requestCallFormSubmit"

const useStyles = (theme) => ({
	text: {
		color: "#16222b",
		width: "100%",
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px`,
	},
	container: {
		maxWidth: 300,
	},
	dailogContent: {
		padding: theme.spacing(3),
	},
	closeIcon: {
		[theme.breakpoints.up("sm")]: {
			display: "none",
		},
	},
	image: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
})

const validationSchema = Yup.object({
	name: Yup.string("Enter a name")
		.required("Name is required")
		.matches(/^(\w+\s)*\w+$/, "enter valid name")
		.max(100, "not more than 100"),
	email: Yup.string("Enter your email").email("Enter a valid email").required("Email is required"),

	phone: Yup.string()
		.matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, "that's not your number")
		.min(10, "that's not your number")
		.max(15, "max is 15")
		.required("Phone number is required"),
})

class SubmitForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			open: false,
			name: "",
			email: "",
			phone: "",
		}
	}

	onEntered = () => {
		
		Tracker.getInstance().trackEvent(new RequestCallFormOpenEvent(this.props.origin, this.props.id))
	}

	handleClose = () => {
		this.props.onClose()
		this.setState({
			open: false,
		})
	
	}
	submit = (data) => {
		let name = data.name
		let email = data.email
		let phone = data.phone

		this.setState({
			open: true,
		})
		const { id, url, downloadId, pageNumber, package_url } = this.props

		const package_id = id

		this.props.getRequestPackageAction({
			name,
			email,
			phone,
			package_id,
			compareListUrl: url,
			page_number: pageNumber,
			package_url: package_url,
			Itinerary_pdf: !downloadId ? 0 : downloadId,
		})

		this.props.onRequestSubmit()

		Tracker.getInstance().trackEvent(new RequestCallFormSubmit(this.props.origin, this.props.id))
		this.props.history.push("/thanks")
	}

	render() {
		const { classes } = this.props
		const { open, onClose } = this.props

		const values = { name: "", email: "", phone: "", comment: "" }

		return (
			<React.Fragment>
				<Container>
					<Grid container>
						<Grid md={2} xs={12} item>
							{this.state.open === false ? (
								<Dialog open={open} onClose={onClose} fullScreen={window.innerWidth <= 576 ? true : false} TransitionProps={{ onEntered: this.onEntered }}>
									<Grid container justifyContent="flex-end" className={classes.closeIcon}>
										<IconButton justifyContent="flex-end" onClick={onClose} style={{ padding: 16, color: "#16222b" }}>
											<CloseOutlinedIcon />
										</IconButton>
									</Grid>
									<DialogContent width="100px" className={classes.dailogContent}>
										<Grid md={12} item container justifyContent="center" className={classes.image}>
											<img src={Fogg} alt="fogg" style={{ height: 200, width: 300 }}></img>
										</Grid>
										<Grid
											container
											justifyContent={window.innerWidth <= 576 ? "space-between" : ""}
											style={{
												display: window.innerWidth <= 576 ? "flex" : "",
											}}>
											<Grid md={12} xs={6} item container justifyContent="center">
												<Typography
													variant="body2"
													style={{
														color: "#bbc2c8",
														width: window.innerWidth <= 576 ? "108%" : "58%",
														textAlignLast: window.innerWidth <= 576 ? "start" : "center",
													}}>
													Just one more step to your perfect vacation
												</Typography>
											</Grid>
										</Grid>
										<div className={classes.container}>
											<Formik render={(props) => <Form {...props} />} initialValues={values} validationSchema={validationSchema} onSubmit={this.submit} />
										</div>
									</DialogContent>
								</Dialog>
							) : (
								<Success open={this.state.open} onClose={this.handleClose} />
							)}
						</Grid>
					</Grid>
				</Container>
			</React.Fragment>
		)
	}
}
const mapStateToProps = ({ getRequestPackageReducer }) => {
	return {
		_requestPackage: getRequestPackageReducer,
	}
}

export default withRouter(connect(mapStateToProps, { getRequestPackageAction })(withStyles(useStyles)(SubmitForm)))
