import React, { Component } from "react"
import { Grid, withStyles } from "@material-ui/core"
import Footer from "../footer/footer"
import Header from "../PackageDetails/PackageInformation/termsHeader"
import CommonUI from "./Model/model"
import WorkwithTsimga from "../../assets/images/workwithTsimga.png"
import { getMetaDetailAction } from "../../store/actions/getMeta.action"

import { Helmet } from "react-helmet"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { Tracker } from "../../../tracking/tracker"
import { PageViewEvent } from "../../../tracking/events/pageViewEvent"
import { TrackingPageName } from "../../../tracking/trackingConstants"
import { NavigationContext } from "../../navigation/navigationContext"

const style = (theme) => ({
	text: {
		marginTop: "4%",
		[theme.breakpoints.down("sm")]: {
			marginTop: "24%",
		},
	},
})
class About extends Component {
	static contextType = NavigationContext

	componentDidMount = () => {
		// Tracking Page View
		this.context.setBrowsingPage(TrackingPageName.WorkWithUs)

		this.props.getMetaDetailAction({ params: { page: "why_work_with_ts" } })
		window.scrollTo(0, 0)
	}
	render() {
		const { _metaDetail } = this.props
		const WhyWork = {
			mainHeading: "Why work with us?",
			perInfo: [
				{
					body: "1. Get more customers at no additional cost.",
				},
				{
					body: "2. Showcase your product to an entire new set of customers.",
				},
				{
					body: "3. High Conversion Rate, as customers have already searched & compared products.",
				},
				{
					body: "4. Leads not sold to multiple agents as they are Product specific.",
				},
				{
					body: "5. No limit on number of products uploaded.",
				},
				{
					body: "6. Creates Brand awareness.",
				},
			],
		}

		return (
			<Grid>
				<Helmet>
					<title>{_metaDetail.data ? _metaDetail.data.data.meta_title : ""}</title>
					<meta charset="UTF-8" />
					<meta name="description" content={_metaDetail.data ? _metaDetail.data.data.meta_description : ""} />
					<meta name="keywords" content={_metaDetail.data ? _metaDetail.data.data.meta_keyword : ""} />
					<meta name="author" content="Tripstore" />
					<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				</Helmet>
				<Header />
				<Grid
					md={12}
					xs={12}
					sm={12}
					item
					// container
					// justify="center"
					style={{ marginTop: "8%", display: "block" }}>
					<CommonUI Picture={WorkwithTsimga} completeData={WhyWork} page={0} />
					<Footer />
				</Grid>
			</Grid>
		)
	}
}

const mapStateToProps = ({ getMetaDetailReducer }) => {
	return {
		_metaDetail: getMetaDetailReducer,
	}
}
export default withRouter(
	connect(mapStateToProps, {
		getMetaDetailAction,
	})(withStyles(style)(About))
)
