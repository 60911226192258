import React, { useEffect } from "react"
import Banner from "../Banner/banner"
import { Tracker } from "../../../tracking/tracker"
import { PageViewEvent } from "../../../tracking/events/pageViewEvent"
import { TrackingPageName } from "../../../tracking/trackingConstants"

const Home = () => {
	// useEffect hook with an empty dependency array to mimic componentDidMount
	useEffect(() => {
		// Track the page view event here
		Tracker.getInstance().trackPageviewEvent(new PageViewEvent(TrackingPageName.Home))
	}, []) // Empty dependency array ensures this effect runs only once on mount

	return (
		<React.Fragment>
			<Banner />
			{/* <DestinationCard /> */}
		</React.Fragment>
	)
}

export default Home
