import { TrackerPropertyKey, TrackingEventName, TrackingEventValues } from "../trackingConstants"
import { TrackableEvent } from "./trackableEvent"

export class CustomiseFormOpenEvent extends TrackableEvent {
	constructor(origin) {
		let properties = {
			[TrackerPropertyKey.FormType]: TrackingEventValues.Customize,
			[TrackerPropertyKey.Origin]: origin,
		}
		super(TrackingEventName.CustomizeFormOpen, properties)
	}
}
