import { put, call } from "redux-saga/effects";
import { getOtherDestinationService } from "../services/getOtherDestination.service";

import { getOtherDestinationActionSuccess } from "../actions/getOtherDestination.action";
import * as types from "../action.types";
export function* getOtherDestinationSaga() {
    try {
        const response = yield call(getOtherDestinationService);
        yield put(getOtherDestinationActionSuccess(response.data));

    } catch (error) {
        yield put({ type: types.GET_OTHER_DESTINATION_ERROR, error });
    }
}
