import { put, call } from "redux-saga/effects";
import { getBannerService } from "../services/getBanner.service";

import { getBannerActionSuccess  } from "../actions/banner.action";
import * as types from "../action.types";
export function* getBannerSaga() {
    try {
        const response = yield call(getBannerService);
        yield put(getBannerActionSuccess(response.data));

    } catch (error) {
        yield put({ type: types.GET_BANNER_ERROR, error });
    }
}
