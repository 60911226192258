const SaleInfo = require("./saleInfo")

class AgentInfo {
	constructor({ id, name, rating }) {
		this.id = id
		this.name = name
		this.rating = rating
	}
}

class City {
	constructor({ name, nights }) {
		this.name = name
		this.nights = nights
	}

	get listingValue() {
		return `${this.name}(${this.nights}N)`
	}
}

class ItineraryInfo {
	constructor({ days_count, nights_count, cities_count, countries_count, cities }) {
		this.daysCount = days_count
		this.nightsCount = nights_count
		this.citiesCount = cities_count
		this.countriesCount = countries_count
		this.cities = cities.map((city) => new City(city))
	}

	get daysCountValue() {
		return this.daysCount === 1 ? `${this.daysCount} Day` : `${this.daysCount} Days`
	}

	get nightsCountValue() {
		return this.nightsCount === 1 ? `${this.nightsCount} Night` : `${this.nightsCount} Nights`
	}

	get citiesCountValue() {
		return this.citiesCount === 1 ? `${this.citiesCount} City` : `${this.citiesCount} Cities`
	}

	get countriesCountValue() {
		return this.countriesCount === 1 ? `${this.countriesCount} Country` : `${this.countriesCount} Countries`
	}

	get durationValue() {
		return `${this.nightsCountValue}  ${this.daysCountValue}`
	}
}

class Inclusion {
	constructor({ name, icon }) {
		this.name = name
		this.icon = icon
	}
}

class Highlights {
	constructor({ badge, color }) {
		this.badge = badge
		this.color = color
	}
}

class ListingPackage {
	constructor({ id, name, slug, agent_info, itinerary_info, inclusions, sale_info, images, highlights }) {
		this.id = id
		this.name = name
		this.slug = slug
		this.agentInfo = new AgentInfo(agent_info)
		this.itineraryInfo = new ItineraryInfo(itinerary_info)
		this.inclusions = inclusions.map((inclusion) => new Inclusion(inclusion))
		this.saleInfo = new SaleInfo(sale_info)
		this.images = images
		this.highlights = new Highlights(highlights)
	}
}

class ListingResponse {
	constructor(listingPackages, nextPage, totalCount) {
		this.listingPackages = listingPackages.map((listingPackage) => new ListingPackage(listingPackage))
		this.nextPage = nextPage
		this.totalCount = totalCount
	}
}

module.exports = ListingResponse
