
import { TrackableEvent } from "./trackableEvent"
import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants"

export class ListingFilterClearAllEvent extends TrackableEvent {
	constructor(filterCount) {
		let properties = {}
        properties[TrackerPropertyKey.FilterAppliedCount] = filterCount
		super(TrackingEventName.ListingFilterClearAllClick, properties)
	}
}
