import { TrackerPropertyKey } from "./trackingConstants"

export class GTMTracker {
	configure() {}

	/**
	 *
	 * @param TrackableEvent event
	 */
	trackEvent(event) {
		let properties = event.properties
		properties[TrackerPropertyKey.Event] = event.name
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push(properties)
	}

	trackPageviewEvent(event) {
		// No need to do anything here since page_views are being tracked by Google Tag. Check
		//https://tagmanager.google.com
	}
}
