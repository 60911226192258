import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Paper,
  Button,
  MenuItem,
  IconButton,
  Dialog,
  CircularProgress,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import Stepper from "../Banner/input";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SubmitForm from "../Banner/Call/userForm";
import { getInclusionAction } from "../../store/actions/getInclusion.action";
import { Tracker } from "../../../tracking/tracker";
import { CustomiseFormOpenEvent } from "../../../tracking/events/customiseFormOpenEvent";
import { getMonthAction } from "../../store/actions/getMonths";
import { getThemeAction } from "../../store/actions/getTheme.action";
import { getDestinationAction } from "../../store/actions/getDestination.action";

const style = (theme) => ({
  root: {
    paddingRight: theme.spacing(0),
  },
  Paper: {
    padding: theme.spacing(5),
    minWidth: window.innerWidth <= 672 ? 300 : 370,
  },
  textField: {
    // fontSize: "14px",
    // height: "14px",
    color: "#616e78",
    width: "100%",
  },
  input: {
    "&::placeholder": {
      color: "#bbc2c8",
    },
    fontSize: "14px",
    color: "#616e78",
    height: 50,
  },
  img: {
    cursor: "pointer",
  },
  Icon: {
    textTransform: "none",
    // paddingLeft: 4,
    paddingTop: 5,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  button: {
    "&:hover": {
      cursor: "pointer",
    },

    "&:focus": {
      transform: "translateY(8px)",
      transition: "0.3s",
    },
  },
  test: {
    cursor: "pointer",
    filter:
      "invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%)",
  },
  disableGutters: {
    textAlign: "left",
  },
  buttons: {
    justifyContent: "flex-start",
    textTransform: "none",
    "&:hover": {
      color: "#ff8362",
    },
  },
  loader: {
    color: "#ff6840",
    height: 40,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

class CustomizeForm extends Component {
  state = {
    setValue: 0,
    open: false,
    destinationName: "",
    monthId: "",
    minBudget: "",
    maxBudget: "",
    IncludesId: [],
    themeId: "",
    data: [],
    month: "",
    theme: "",
    inclusionsData: "",
    themesData: "",
    monthsData: "",
    iconid: [],
    page_number: 1,
    isLoading: true,
  };

  onEntered = () => {
    Tracker.getInstance().trackEvent(
      new CustomiseFormOpenEvent(this.props.origin)
    );
  };

  componentDidMount() {
    this.setState({
      CustomizeForm: this.props.openForm,
    });
  }

  handleClick = () => {
    this.setState({
      open: true,
    });
    const destination_name = this.state.destinationName;
    const departure_month_id = this.state.monthId;
    const min_budget = this.state.minBudget;
    const max_budget = this.state.maxBudget;
    const inclusion_id = this.state.IncludesId;
    const page_number = this.state.page_number;
    const theme_id = this.state.themeId;

    this.setState({
      data: {
        destination_name,
        departure_month_id,
        min_budget,
        max_budget,
        inclusion_id,
        theme_id,
        page_number,
      },
    });
    let data = this.state.inclusionsData;
    data.map((item) => {
      if (item.active === true) {
        item.active = item.active ? !item.active : false;
      }

      return item;
    });
    this.setState({
      destinationName: "",
      month: "",
      theme: "",
      iconid: [],
    });

    this.props.onClose();
  };
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  //for budget selection//
  handleChange = (value, min) => {
    this.setState({
      minBudget: value,
      maxBudget: min,
    });
  };
  //for theme id//
  handleIcons = (obj1) => {
    if (!obj1.active) {
      this.state.iconid.push(obj1.id);
    } else {
      const indexvalue = this.state.iconid.indexOf(obj1.id);
      this.state.iconid.splice(indexvalue, 1);
    }
    let data = this.state.inclusionsData;

    data.map((item) => {
      if (item.id === obj1.id) {
        item.active = item.active ? !item.active : true;
      }

      this.setState({
        inclusionsData: data,
        toggleButton: this.state.toggleButton,
        IncludesId: this.state.iconid,
      });
      return item;
    });
  };

  //for month selection//
  handleSubmit = (id) => {
    this.setState({
      month: id.month_name,
      monthId: id.id,
    });
  };
  //for category selection//
  handleCategory = (value) => {
    this.setState({
      themeId: value.id,
      theme: value.theme_name,
    });
  };
  handleOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.openForm !== this.props.openForm) {
      this.setState({
        CustomizeForm: this.props.openForm,
      });
    }
    if (
      prevState.CustomizeForm !== this.state.CustomizeForm &&
      this.state.CustomizeForm === true
    ) {
      this.props.getInclusionAction();
      this.props.getThemeAction();
      this.props.getMonthAction();
    }

    if (prevProps._inclusions !== this.props._inclusions) {
      this.setState({ inclusionsData: this.props._inclusions.data });
      this.setState({ isLoading: false });
    }

    if (prevProps._months !== this.props._months) {
      this.setState({ monthsData: this.props._months.data });
      this.setState({ isLoading: false });
    }

    if (prevProps._themes !== this.props._themes) {
      this.setState({ themesData: this.props._themes.data });
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { classes, openForm, onClose } = this.props;
    const { inclusionsData, themesData, monthsData, isLoading } = this.state;

    return (
      <Fragment>
        <Dialog
          open={openForm}
          fullScreen={window.innerWidth <= 576 ? true : false}
          onClose={onClose}
          TransitionProps={{ onEntered: this.onEntered }}
        >
          <Paper className={classes.Paper}>
            {isLoading ? (
              <div>
                <CircularProgress className={classes.loader}  />
              </div>
            ) : (
              <>
                {window.innerWidth <= 676 ? (
                  <div
			
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    {" "}
                    <CloseOutlinedIcon     	  onClick={this.handleClose}     />
                  </div>
                ) : null}
                <Grid md={12} item>
                  <Typography
                    variant="body2"
                    style={{ color: "#16222b", height: 12 }}
                  >
                    Where are you going?
                  </Typography>
                  <TextField
                    placeholder="Destination"
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: 24, marginTop: 9 }}
                    className={classes.textField}
                    onChange={(event) => {
                      const { value } = event.target;
                      this.setState({ destinationName: value });
                    }}
                    InputProps={{
                      className: classes.input,
                    }}
                  />

                  <Typography
                    variant="body2"
                    className={classes.textField}
                    style={{ color: "#16222b" }}
                  >
                    When will you be travelling?
                  </Typography>

                  <TextField
                    fullWidth
                    select
                    style={{ marginBottom: 24, marginTop: 5 }}
                    className={classes.TextField}
                    SelectProps={{
                      IconComponent: (props) => (
                        <i
                          {...props}
                          className={`material-icons ${props.className}`}
                        >
                          <DateRangeIcon color="primary" />
                        </i>
                      ),
                      displayEmpty: true,
                    }}
                    value={this.state.month}
                    onChange={() => this.handleSubmit}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  >
                    <MenuItem value="" disabled>
                      <Typography variant="body2" style={{ color: "#bbc2c8" }}>
                        Select Month
                      </Typography>
                    </MenuItem>
                    {monthsData &&
                      monthsData &&
                      monthsData.map((obj) => (
                        <MenuItem
                          value={obj.month_name}
                          key={obj.month_name}
                          onClick={() => this.handleSubmit(obj)}
                        >
                          <Button
                            size="small"
                            fullWidth
                            className={classes.buttons}
                            onClick={() => this.handleSubmit(obj)}
                            // style={{ textTransform: "none" }}
                            disableRipple={true}
                          >
                            <Typography
                              variant="body2"
                              style={{ color: "#16222b" }}
                            >
                              {obj.month_name}
                            </Typography>
                          </Button>
                        </MenuItem>
                      ))}
                  </TextField>

                  <Stepper data={(min, max) => this.handleChange(min, max)} />

                  <Typography
                    variant="body2"
                    style={{ color: "#16222b" }}
                    className={classes.textField}
                  >
                    Includes
                  </Typography>

                  <Grid item style={{ display: "flex", padding: 0 }}>
                    {(!inclusionsData
                      ? Array.from(new Array(6))
                      : inclusionsData && inclusionsData
                    ).map((obj, i) =>
                      obj ? (
                        <Grid md={12} xs={12} sm={12} container item>
                          <Grid key={i}>
                            {obj && obj.active ? (
                              <IconButton
                                key={i}
                                onClick={() => this.handleIcons(obj)}
                                className={classes.Icon}
                              >
                                <label>
                                  <img
                                    src={obj.image}
                                    alt="icons"
                                    className={classes.img}
                                  />
                                  <Grid>
                                    <Typography
                                      variant="overline"
                                      style={{
                                        textTransform: "none",
                                        color: "#616e78",
                                      }}
                                    >
                                      {obj.name}
                                    </Typography>
                                  </Grid>
                                </label>
                              </IconButton>
                            ) : (
                              <IconButton
                                key={i}
                                onClick={() => this.handleIcons(obj)}
                                className={classes.Icon}
                              >
                                <label>
                                  <img
                                    src={obj.unselected_image}
                                    alt="icons"
                                    className={classes.img}
                                  />
                                  <Grid>
                                    <Typography
                                      variant="overline"
                                      style={{
                                        textTransform: "none",
                                        color: "#616e78",
                                      }}
                                    >
                                      {obj.name}
                                    </Typography>
                                  </Grid>
                                </label>
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          container
                          justifyContent="space-between"
                          style={{ display: "block" }}
                        >
                          <Skeleton variant="circle" width={40} height={40} />
                          <Skeleton variant="text" width={40} height={20} />
                        </Grid>
                      )
                    )}
                  </Grid>
                  <Typography
                    variant="body2"
                    className={classes.textField}
                    style={{ color: "#16222b" }}
                  >
                    Themes
                  </Typography>
                  <TextField
                    fullWidth
                    select
                    style={{ marginBottom: 24, marginTop: 5 }}
                    placeholder="Destination"
                    value={this.state.theme}
                    onChange={() => this.handleCategory}
                    variant="outlined"
                    SelectProps={{
                      IconComponent: (props) => (
                        <i
                          {...props}
                          className={`material-icons ${props.className}`}
                        >
                          <ExpandMoreIcon color="primary" />
                        </i>
                      ),
                      displayEmpty: true,
                    }}
                    InputProps={{
                      className: classes.input,
                    }}
                  >
                    <MenuItem value="" disabled>
                      <Typography variant="body2" style={{ color: "#bbc2c8" }}>
                        Select One
                      </Typography>
                    </MenuItem>
                    {themesData &&
                      themesData &&
                      themesData.map((obj) => (
                        <MenuItem value={obj.theme_name}>
                          <Button
                            size="small"
                            fullWidth
                            className={classes.buttons}
                            disableRipple={true}
                            onClick={() => this.handleCategory(obj)}
                          >
                            <Typography
                              variant="body2"
                              style={{ color: "#16222b" }}
                            >
                              {obj.theme_name}
                            </Typography>
                          </Button>
                        </MenuItem>
                      ))}
                  </TextField>

                  <Grid xs={12} item>
                    {this.state.month &&
                    this.state.destinationName &&
                    this.state.maxBudget &&
                    this.state.minBudget &&
                    this.state.inclusionsData &&
                    this.state.theme ? (
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth={true}
                        onClick={this.handleClick}
                        style={{ textTransform: "none" }}
                      >
                        Request a Call
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ textTransform: "none" }}
                        disabled
                      >
                        Request a Call
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        </Dialog>

        <SubmitForm
          open={this.state.open}
          onClose={this.handleClose}
          Data={this.state.data}
          origin={this.props.origin}
        />
      </Fragment>
    );
  }
}
const mapStateToProps = ({
  getInclusionReducer,
  getThemeReducer,
  getMonthReducers,
}) => {
  return {
    _inclusions: getInclusionReducer,
    _themes: getThemeReducer,
    _months: getMonthReducers,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getInclusionAction,
    getMonthAction,
    getThemeAction,
    getDestinationAction,
  })(withStyles(style)(CustomizeForm))
);
