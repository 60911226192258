import { put, call } from "redux-saga/effects";
import { getRequestPackageService } from "../services/getPackageRequest.service";

import { getRequestPackageSuccessAction } from "../actions/requestACall.action";
import * as types from "../action.types";
export function* getRequestPackageSaga(data) {
  try {
        const response = yield call(getRequestPackageService,data);
        yield put(getRequestPackageSuccessAction(response));

    } catch (error) {
        yield put({ type: types.GET_REQUEST_PACKAGE_ERROR, error });
    }
}
