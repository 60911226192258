import * as types from "../action.types";

export const termsConditionAction = (data) => {
  return {
    type: types.GET_TERMS_CONDITION,
    data,
  };
};

export const termsConditionActionSuccess = (data) => {
  return {
    type: types.GET_TERMS_CONDITION_SUCCESS,
    data,
  };
};
export const getResponseNullAction = () => {
  return {
    type: types.GET_RESPONSE_NULL,
  };
};
