import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import Drawer from "@material-ui/core/Drawer";
import axios from "axios";
import "./filtersComponent.css";
// import { makeStyles } from '@material-ui/core/styles';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import { getFiltersList, getRegionList } from "../../Services/services";
import CloseIcon from "@material-ui/icons/Close";
import { Tracker } from "../../../../tracking/tracker";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ListingFilterOpenEvent } from "../../../../tracking/events/listingFilterOpenEvent";
import { ListingFilterClearAllEvent } from "../../../../tracking/events/listingFilterClearAllEvent";
import { ListingFilterApplyClickEvent } from "../../../../tracking/events/listingFilterApplyClickEvent";
import { ListingFilterCloseButtonClickEvent } from "../../../../tracking/events/listingFilterCloseButtonClickEvent";
import { ListingFilterCloseCancelClickEvent } from "../../../../tracking/events/listingFilterCloseCancelClickEvent";
import { ListingFilterCloseContinueClickEvent } from "../../../../tracking/events/listingFilterCloseContinueClickEvent";
import { ListingFilterItemClickEvent } from "../../../../tracking/events/listingFilterItemClickedEvent";
import { useField } from "formik";
const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
    borderRadius: "20px",
  },
  drawerPaper: {
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    height: "90%",
    overflow: "hidden",
  },
  fullList: {
    width: "auto",
    borderRadius: "20px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "5px",
  },
}));

const MobileFilterComponent = forwardRef(
  (
    {
      onFilterDataUpdate,
      openDialogue,
      handleOpenDialogue,
      handleCloseDialogue,
      bottom,
      // toggleDrawer,
      toggleDrawer2,
      onSelectedChipsChange,
      onChipDelete,
    },
    ref
  ) => {
    const classes = useStyles();
    const scrollRef = useRef(null);
    const [openSections, setOpenSections] = useState({});
    const [filterData, setFilterData] = useState([]);
    const [prevDestinationsLength, setPrevDestinationsLength] = useState(0);
    const sortedFilterData = filterData?.sort(
      (a, b) => a.display_order - b.display_order
    );

    const [searchTerms, setSearchTerms] = useState({});
    const [openFilter, setOpenFilter] = useState("");
    const [selectedFilters, setSelectedFilters] = useState({});
    const [localSelections, setLocalSelections] = useState({});
    const [loadingFilters, setLoadingFilters] = useState(false);
    const [controller, setController] = useState(null);

    const [cancelTokenSource, setCancelTokenSource] = useState(null);
    const getFilterData = async (filters) => {
      const source = axios.CancelToken.source();
      setCancelTokenSource(source);

      setLoadingFilters(true);

      try {
        const response = await getFiltersList(filters, source.token);

        if (response.data.response_code === 200) {
          setFilterData(response?.data?.data);
          onFilterDataUpdate(response?.data?.data);
        }
      } catch (e) {
      } finally {
        setLoadingFilters(false);
      }
    };

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const filters = [];

      urlParams.forEach((value, key) => {
        const decodedValue = decodeURIComponent(value);

        const processedValue = decodedValue.replace(/:/g, ",");

        let filterValues;
        if (key === "city" || key === "start_city" || key === "end_city") {
          filterValues = decodedValue.split(":").map((city) => city.trim());
        } else {
          filterValues = processedValue.split(/,(?!\d{3})/);
        }

        filters.push({
          type: key,
          values: filterValues,
        });
      });

      getFilterData(filters);
    }, []);
    useEffect(() => {
      const selectedChips = [];
      Object.keys(selectedFilters).forEach((section) => {
        Object.keys(selectedFilters[section]).forEach((option) => {
          if (selectedFilters[section][option]) {
            selectedChips.push({ section, option });
          }
        });
      });

      onSelectedChipsChange(selectedChips);
    }, [selectedFilters, onSelectedChipsChange]);

    const toggleSection = (section) => {
      setOpenFilter(section);
      const filters = Object.keys(localSelections)
        .map((key) => ({
          type: key,
          values: Object.keys(localSelections[key]),
        }))
        .filter((filter) => filter.values.length > 0);

      if (filters) {
        getFilterData(filters);
      }
    };
    const hasRunEffect = useRef(false);
    useEffect(() => {
      if (!hasRunEffect.current && sortedFilterData.length > 0) {
        setOpenFilter(sortedFilterData[0].name);
        hasRunEffect.current = true;
      }
    }, [sortedFilterData]);
    // useEffect(() => {
    //   toggleSection();
    // }, []);
    // useEffect(() => {
    //   const urlParams = new URLSearchParams(window.location.search);
    //   const filters = {};

    //   urlParams.forEach((value, key) => {
    //     const valuesArray = value.replace(/:/g, ",").split(/,(?!\d{3})/);
    //     filters[key] = valuesArray.reduce((acc, val) => {
    //       acc[val] = true;
    //       return acc;
    //     }, {});
    //   });

    //   setSelectedFilters(filters);
    // }, []);
    useEffect(() => {
      const filters = {};

      filterData.forEach((filterGroup) => {
        const { type, filters: filterItems } = filterGroup;
        filterItems.forEach((filterItem) => {
          const { name, is_selected } = filterItem;
          if (is_selected) {
            if (!filters[type]) {
              filters[type] = {};
            }
            filters[type][name] = true;
          }
        });
      });

      setSelectedFilters(filters);
    }, [filterData]);
    const [genatedUrl, setGenatedUrl] = useState("");

    const getRegion = async () => {
      const response = await getRegionList();
    };
    const [temporarySelectedValue, setTemporarySelectedValue] = useState({});

    const handleCheckboxChange = (filterName, optionName) => {
      setLocalSelections((prevSelections) => {
        const updatedSelections = { ...prevSelections };
        const filterOptions = updatedSelections[filterName] || {};
        const isSelected = !!filterOptions[optionName];

        setTemporarySelectedValue(filterOptions);
        if (isSelected) {
          delete filterOptions[optionName];
          if (Object.keys(filterOptions).length === 0) {
            delete updatedSelections[optionName];
          }
        } else {
          if (!updatedSelections[filterName]) {
            updatedSelections[filterName] = {};
          }
          updatedSelections[filterName][optionName] = true;
        }

        updateUrlParams(updatedSelections);

        Tracker.getInstance().trackEvent(
          new ListingFilterItemClickEvent(
            filterName,
            optionName,
            isSelected == false ? true : false
          )
        );

        return updatedSelections;
      });
    };

    const updateUrlParams = (selections) => {
      const params = new URLSearchParams(window.location.search);

      Object.keys(selections).forEach((filterName) => {
        const selectedOptions = Object.keys(selections[filterName]).filter(
          (optionName) => selections[filterName][optionName]
        );

        if (selectedOptions.length > 0) {
          params.set(filterName, selectedOptions.join(","));
        } else {
          params.delete(filterName);
        }
      });

      const encodedParams = params.toString().replace(/%2C(?!\d{3})/g, "%3A");

      setGenatedUrl(encodedParams);
    };

    const handleApplyFilters = () => {
      if (loadingFilters) {
        cancelTokenSource.cancel();
        setCancelTokenSource(null);
      } else {
        window.location.href = `/packages?${genatedUrl}`;
        Tracker.getInstance().trackEvent(new ListingFilterApplyClickEvent());
      }
    };
    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      const encodedParams = params.toString().replace(/%2C(?!\d{3})/g, "%3A");
      setGenatedUrl(encodedParams);
    }, []);
    const handleChipDelete = (section, option, filtersection) => {
      setSelectedFilters((prevState) => {
        const updatedSection = { ...prevState[section] } || {};
        delete updatedSection[option];

        let params = new URLSearchParams(window.location.search);
        if (params.has(filtersection.type)) {
          let existingValues = params
            .get(filtersection.type)
            .replace(/:/g, ",")
            .split(/,(?!\d{3})/);

          existingValues = existingValues.filter((value) => value !== option);

          if (existingValues.length > 0) {
            params.set(filtersection.type, existingValues.join(","));
          } else {
            params.delete(filtersection.type);
          }
        }
        const encodedParams = params.toString().replace(/%2C(?!\d{3})/g, "%3A");

        window.location.href = `/packages?${encodedParams}`;

        return {
          ...prevState,
          [section]: updatedSection,
        };
      });
    };
    useImperativeHandle(ref, () => ({
      handleChipDelete,
    }));

    const handleSearchChange = (section, event) => {
      const target = event?.target;
      if (target && "value" in target) {
        const searchValue = String(target.value);

        setSearchTerms((prevState) => ({
          ...prevState,
          [section]: searchValue,
        }));
      }
    };

    useEffect(() => {
      Object.keys(selectedFilters).forEach((section) => {
        Object.keys(selectedFilters[section]).forEach((option) => {
          if (!selectedFilters[section][option]) {
            onChipDelete(section, option);
          }
        });
      });
    }, [selectedFilters]);
    useEffect(() => {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);

      const selections = {};
      params.forEach((value, key) => {
        const options = value.replace(/:/g, ",").split(/,(?!\d{3})/);
        selections[key] = options.reduce((acc, option) => {
          acc[option] = true;
          return acc;
        }, {});
      });

      const initialSelections = { ...selections };

      sortedFilterData.forEach((filterSection) => {
        filterSection.filters.forEach((option) => {
          if (!initialSelections[filterSection.type]) {
            initialSelections[filterSection.type] = {};
          }
          if (option.is_selected) {
            initialSelections[filterSection.type][option.name] = true;
          }
        });
      });

      setLocalSelections(initialSelections);
    }, []);
    const totalFiltersApplied = Object.values(selectedFilters).reduce(
      (total, filterSection) => {
        return (
          total +
          Object.values(filterSection).filter((isSelected) => isSelected).length
        );
      },
      0
    );
    const handleClearAll = () => {
      window.location.href = `/packages`;
      Tracker.getInstance().trackEvent(
        new ListingFilterClearAllEvent(totalFiltersApplied)
      );
    };
    const handleCloseModalDialogue = () => {
      if (loadingFilters) {
        cancelTokenSource.cancel();
        setCancelTokenSource(null);
      } else {
        toggleDrawer2();
        handleCloseDialogue();

        setLocalSelections({});
        window.location.reload();
      }
    };

    return (
      <>
        {" "}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openDialogue}
          onClose={handleCloseDialogue}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openDialogue}>
            <div className={classes.paper}>
              <p className="dialogue-text">
                This action will undo your changes.
              </p>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <button
                  className="dialogue-cancel"
                  onClick={() => {
                    handleCloseDialogue();
                    Tracker.getInstance().trackEvent(
                      new ListingFilterCloseCancelClickEvent()
                    );
                  }}
                >
                  Cancel
                </button>{" "}
                <button
                  className="dialogue-continue"
                  onClick={() => {
                    handleCloseModalDialogue();
                    Tracker.getInstance().trackEvent(
                      new ListingFilterCloseContinueClickEvent()
                    );
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </Fade>
        </Modal>{" "}
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          anchor={"bottom"}
          open={bottom}
          onClose={
            Object.keys(temporarySelectedValue).length > 0
              ? handleOpenDialogue
              : toggleDrawer2
          }
          style={{ overscrollBehavior: "contain" }}
        >
          <div className="mobile-filter-header">
            {" "}
            <p
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                paddingLeft: "10px",
              }}
            >
              FILTERS
            </p>
            <CloseIcon
              style={{
                cursor: "pointer",
                paddingRight: "10px",
              }}
              onClick={() => {
                Object.keys(temporarySelectedValue).length > 0
                  ? handleOpenDialogue()
                  : toggleDrawer2();
                Tracker.getInstance().trackEvent(
                  new ListingFilterCloseButtonClickEvent()
                );
              }}
            />
          </div>

          <div className="mobile-filter-container">
            <div className="mobile-left-options-containers">
              {sortedFilterData.map((filterSection) => {
                const searchTerm = searchTerms[filterSection.name] || "";
                const selectedCount = Object.keys(
                  localSelections[filterSection.type] || {}
                ).length;

                const filteredAndSortedOptions = filterSection.filters
                  .filter((option) =>
                    option.name.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .sort((a, b) => {
                    const aSelected =
                      selectedFilters[filterSection.name]?.[a.name] || false;
                    const bSelected =
                      selectedFilters[filterSection.name]?.[b.name] || false;
                    return bSelected - aSelected;
                  });

                return (
                  <>
                    {" "}
                    <div
                      style={{
                        backgroundColor:
                          filterSection.name === openFilter
                            ? ""
                            : "var(--card-bg-color)",
                      }}
                    >
                      {" "}
                      <div
                        style={{ height: "40px" }}
                        className="mobile-filter-heading"
                        onClick={() => {
                          toggleSection(filterSection.name);
                          Tracker.getInstance().trackEvent(
                            new ListingFilterOpenEvent(filterSection.name)
                          );
                        }}
                      >
                        {filterSection.name}
                        {selectedCount > 0 && (
                          <div
                            style={{
                              color: "var(--text-secondary-color)",
                              marginLeft: "10px",

                              borderRadius: "50%",
                              height: "20px",
                              width: "20px",
                              textAlign: "center",
                              fontSize: "15px",

                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {selectedCount}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            <div className="mobile-right-option-containers">
              {sortedFilterData.map((filterSection) => {
                const searchTerm = searchTerms[filterSection.name] || "";
                const selectedCount = Object.keys(
                  localSelections[filterSection.type] || {}
                ).length;

                const filteredAndSortedOptions = filterSection.filters
                  .filter((option) =>
                    option.name.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .sort((a, b) => {
                    const aSelected =
                      selectedFilters[filterSection.name]?.[a.name] || false;
                    const bSelected =
                      selectedFilters[filterSection.name]?.[b.name] || false;
                    return bSelected - aSelected;
                  });

                return (
                  filterSection.name === openFilter &&
                  (loadingFilters ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <CircularProgress color="inherit" />
                    </div>
                  ) : (
                    <div className="mobile-right-containers">
                      {" "}
                      <div
                        style={{
                          marginLeft: "10px",
                          marginTop: "12px",
                        }}
                      >
                        <div>
                          <div className="filter-text-heading">
                            Choose from options below
                          </div>
                          {filterSection.filters.length >= 15 && (
                            <div
                              style={{
                                marginBottom: "10px",
                                marginTop: "10px",
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "10px",
                                outline: "none",
                                border: "none",
                              }}
                            >
                              <input
                                type="text"
                                placeholder={`Search ${filterSection.name}`}
                                value={searchTerm}
                                onChange={(event) =>
                                  handleSearchChange(filterSection.name, event)
                                }
                                style={{
                                  borderRadius: "10px",
                                  border: "1px solid var(--accent-color)",
                                  outline: "none",

                                  padding: "5px",
                                  width: "90%",
                                }}
                              />
                            </div>
                          )}
                          {filteredAndSortedOptions.length > 0 ? (
                            filteredAndSortedOptions.map((option) => (
                              <label
                                key={option.name}
                                style={{
                                  display: "block",
                                  padding: "5px 0",
                                  alignItems: "center",
                                  display: "flex",
                                  paddingRight: "5px",
                                }}
                              >
                                <input
                                  style={{ cursor: "pointer" }}
                                  type={
                                    filterSection.input_type === "multi_select"
                                      ? "checkbox"
                                      : "radio"
                                  }
                                  checked={
                                    localSelections[filterSection.type]?.[
                                      option.name
                                    ]
                                  }
                                  onChange={() =>
                                    handleCheckboxChange(
                                      filterSection.type,
                                      option.name,
                                      filterSection
                                    )
                                  }
                                  name={
                                    filterSection.input_type === "multi_select"
                                      ? undefined
                                      : filterSection.name
                                  }
                                />
                                {option.name}
                              </label>
                            ))
                          ) : (
                            <div style={{ fontSize: "12px" }}>
                              No results found!
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                );
              })}
            </div>
          </div>
          <div className="mobile-filters-bottom-bar">
            <button className="mobile-clear-button" onClick={handleClearAll}>
              Clear all
            </button>
            <button className="filter-button" onClick={handleApplyFilters}>
              Apply
            </button>
          </div>
        </Drawer>
      </>
    );
  }
);

export default MobileFilterComponent;
