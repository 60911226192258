import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants"
import { TrackableEvent } from "./trackableEvent"

export class WidgetScrollEvent extends TrackableEvent {
	constructor(widgetType, widgetIdentifier, pageNumber, totalPages,  impressions) {
		let properties = {}
        properties[TrackerPropertyKey.WidgetType] = widgetType
        properties[TrackerPropertyKey.PageNumber] = pageNumber
        properties[TrackerPropertyKey.TotalPages] = totalPages
        properties[TrackerPropertyKey.Impressions] = impressions
        properties[TrackerPropertyKey.widgetIdentifier] = widgetIdentifier

		super(TrackingEventName.WidegetScroll, properties)
	}
}

