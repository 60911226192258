import React, { Component } from "react";
import { Typography, Grid, Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getPackageListAction } from "../../../store/actions/packageList.action";
class Compare extends Component {
  handleClick = () => {
    // this.props.getPackageListAction();
    const previousPage = sessionStorage.getItem("previousPage");
    if (previousPage) {
      window.location.href = previousPage;
    }
  };
  render() {
    const defaultProps = {
      color: "#16222b",
      borderColor: "#c4c4c4",

      m: 1,
      border: 1,
    };

    return (
      <React.Fragment>
        <Box
          style={{
            borderStyle: "dashed",
            height: 210,
            width: 300,
         

          }}
          {...defaultProps}
        >
          <Grid
            container
            direction="row"
            justify="center"
            style={{ display:"flex",alignItems:"center",justifyContent:"center",height:"100%" }}
          >
            <div>  <Grid md={12} style={{ textAlign: "center" }}>
              <Typography
                variant="subtitle1"
                style={{ color: "#bbc2c8", width: "100%" }}
              >
                No Package Added
              </Typography>
            </Grid>
            <Grid md={12} container justify="center">
             
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    textTransform: "none",
                  }}
                  onClick={this.handleClick}
                >
                  <Typography variant="subtitle2"> Add More Package</Typography>
                </Button>
              
            </Grid></div>
          
          </Grid>
        </Box>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ getPackageListReducer }) => {
  return {
    _packageList: getPackageListReducer,
  };
};
export default withRouter(
  connect(mapStateToProps, { getPackageListAction })(Compare)
);
