import React from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";

const LoadingButton = ({ loading, children, ...props }) => {
  return (
    <FormControl margin="normal" fullWidth>
      <Button
        type="submit"
        color="primary"
        {...props}
        fullWidth
        style={{ textTransform: "none" }}
      >
        {children}
      </Button>
    </FormControl>
  );
};

export default LoadingButton;
