import { Grid, Typography, withStyles } from "@material-ui/core"
import React from "react"

const style = (theme) => ({
	text: {
		color: "#000",
		fontSize: 16,
		fontWeight: 300,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	firstText: {
		color: "#000",
		fontSize: 16,
		fontWeight: 300,
		paddingRight: theme.spacing(2),
	},
	pipeBox: {
		width: "2px",
		backgroundColor: "#959595",
	},
})

const ItineraryOverview = (props) => {
	const { classes, itineraryInfo } = props

	return (
		<React.Fragment>
			{itineraryInfo && (
				<Grid container item>
					<Typography className={classes.firstText}>{itineraryInfo.daysCountValue}</Typography>
					<div className={classes.pipeBox} />
					<Typography className={classes.text}>{itineraryInfo.citiesCountValue}</Typography>
					<div className={classes.pipeBox} />
					<Typography className={classes.text}>{itineraryInfo.countriesCountValue}</Typography>
				</Grid>
			)}
		</React.Fragment>
	)
}

export default withStyles(style)(ItineraryOverview)
