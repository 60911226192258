import React from "react";
import "./whytripstorecard.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function WhyTripStoreCard({ imageUrl, title, subtitle }) {
  const cardContent = (
    <div className="image-container">
    <img src={imageUrl} alt="Background" className="background-image" />
    <div className="text-overlay">
      <div className="text-content-overlay">
      <h1 style={{   whiteSpace: "normal"}} >{title}</h1>
      <p style={{   whiteSpace: "normal"}}>{subtitle}</p>
      </div>
   
    </div>
  </div>

  );
  return cardContent;
}

export default WhyTripStoreCard;
