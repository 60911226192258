import React, { useState } from "react";
import "./discountbar.css";
import ModalComponent from "../ModalComponent/ModalComponent";
import LoginUser from "../Auth/Login/LoginUser";
import { Tracker } from "../../../tracking/tracker";
import {
  PrimaryBorderedButton,
  SecondaryBorderedButton,
} from "../../../utils/Buttons";
import { loginButtonClickedEvent } from "../../../tracking/events/loginButtonClickedEvent";

function DiscountBarComponent({ color, fontStyle, fontWeight, origin,packageId}) {
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const isLoggedIn = localStorage.getItem("access_token");
  const [currentView, setCurrentView] = useState("");
  const handleOpenLoginModal = () => {
    setOpenLoginModal(true);

    Tracker.getInstance().trackEvent(new loginButtonClickedEvent(origin));
  };

  const handleCloseLoginModal = () => {
    setOpenLoginModal(false);
  };
  return (
    <>
      {" "}
      <ModalComponent
        openLoginModal={openLoginModal}
        handleCloseLoginModal={handleCloseLoginModal}
        children={<LoginUser packageId={packageId} />}
      />
      <div class="discount-text">
        <p
          style={{ color: color, fontWeight: fontWeight, fontStyle: fontStyle }}
        >
          Unlock up to ₹10,000 extra discount
        </p>{" "}
        {origin == "package-details" ? (
          <PrimaryBorderedButton
            style={{ height: "14px", marginLeft: "8px" }}
            onClick={handleOpenLoginModal}
          >
            Login
          </PrimaryBorderedButton>
        ) : (
          <SecondaryBorderedButton
            style={{ height: "12px", marginLeft: "8px" }}
            onClick={handleOpenLoginModal}
          >
            Login
          </SecondaryBorderedButton>
        )}
      </div>
    </>
  );
}

export default DiscountBarComponent;
