import { put, call } from "redux-saga/effects";
import { getInclusionService } from "../services/getInclusion.service";

import { getInclusionActionSucess } from "../actions/getInclusion.action";
import * as types from "../action.types";
export function* getInclusionSaga() {
    try {
        const response = yield call(getInclusionService);
        yield put(getInclusionActionSucess(response.data));

    } catch (error) {
        yield put({ type: types.GET_INCLUSION_ERROR, error });
    }
}