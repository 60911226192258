import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import "./signupuser.css";
import drawerLogo from "../../../assets/images/drawerLogo.png";
import { PrimarySubmitButton } from "../../../../utils/Buttons";
import { registerUser } from "../../Services/services";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Tracker } from "../../../../tracking/tracker";
import { loginUpdateProfileOpenEvent } from "../../../../tracking/events/loginUpdateProfileOpenEvent";
import { loginUpdateProfileClickEvent } from "../../../../tracking/events/loginUpdateProfileClickEvent";
import { loginEndEvent } from "../../../../tracking/events/loginEndEvent";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    outline: "none",
    border: "none",
    width: window.innerWidth <= 768 ? "350px" : "400px",
    borderRadius: "10px",
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
  },
}));

function SignUpUser({ openSignUpModal, handleCloseSignUpModal, accessToken }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nameError, setNameError] = useState("");
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRegisterUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (name) {
      const response = await registerUser(name, email, accessToken);
      if (response.status >= 200 && response.status < 300) {
        Tracker.getInstance().setUserProperties(name, email);
        setLoading(false);
        localStorage.setItem("access_token", accessToken);
        window.location.reload();
        handleClose();
        Tracker.getInstance().trackEvent(new loginUpdateProfileClickEvent());
        Tracker.getInstance().trackEvent(new loginEndEvent("update_profile"));
      }
    } else {
      setNameError("Please enter Full Name");
      setLoading(false);
    }
  };
  const handleChangeName = (e) => {
    const { value } = e.target;
    setName(value);
  };
  const handleChangeEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
  };
  useEffect(() => {
    Tracker.getInstance().trackEvent(new loginUpdateProfileOpenEvent());
  }, []);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} animate-slide-up`}
      open={true}
      BackdropProps={{ onClick: (e) => e.stopPropagation() }}
      BackdropComponent={Backdrop}
    >
      <Fade in={openSignUpModal}>
        <div className={classes.paper}>
          <div className="complete-profile-header">
            <div class="login-header">
              <img src={drawerLogo} alt="Trip Store Logo" class="login-logo" />
            </div>
          </div>
          <div class="signup-form-container">
            <h2>Complete your profile</h2>

            <form onSubmit={handleRegisterUser}>
              <label for="fullName">Full Name </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={name}
                onChange={handleChangeName}
                placeholder="Enter your full name"
                required
              />

              <label for="email">
                Email <span className="optional-text">(Optional)</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleChangeEmail}
                placeholder="Enter your email"
              />
              <PrimarySubmitButton
                type="submit"
                onClick={handleClose}
                className="login-button"
              >
                {loading ? (
                  <CircularProgress color="inherit" size={15} />
                ) : (
                  "Complete Signup"
                )}{" "}
              </PrimarySubmitButton>
            </form>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default SignUpUser;
