import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid, Button, Box, IconButton } from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import AddPackage from "./AddPackage";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Tracker } from "../../../../../tracking/tracker";
import { ListingCompareEvent } from "../../../../../tracking/events/listing/listingCompareEvent";
import { ListingRemoveFromCompareEvent } from "../../../../../tracking/events/listing/listingRemoveFromCompareEvent";
import { getFullUrlPath } from "../../../../../utils/utility";

const style = (theme) => ({
  packageName: {
    textTransform: "capitalize",
  },
});

class Compare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idArray: JSON.parse(localStorage.getItem("packageId")) || [],
      click: [],
      isLoading: true,
      scroll: "",
      showboxes: false,
    };
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
  };

  handleScroll = () => {
    this.setState({
      scroll: window.pageYOffset,
    });
  };
  _handleDelete = (obj) => {
    this.props.delete(obj.id);
    if (this.props.click.length === 0) {
      this.props.handleClose();
    }
    Tracker.getInstance().trackEvent(
      new ListingRemoveFromCompareEvent(obj.id, obj.name, obj.slug, obj.price)
    );
  };
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  getFullUrlPath = () => {
    const { location } = this.props;
    // return `${location.pathname}${location.search}${location.hash}`;
    return getFullUrlPath(location);
  };
  onClick = () => {
    let ids = [];
    let prices = [];
    let names = [];

    this.props.click.forEach((element) => {
      ids.push(element.id);
      prices.push(element.price);
      names.push(element.name);
    });
    sessionStorage.setItem("previousPage", this.getFullUrlPath());
    Tracker.getInstance().trackEvent(
      new ListingCompareEvent(ids, prices, names)
    );
  };
  handleMouseEnter = () => {
    this.setState({
      showboxes: true,
    });
  };

  render() {
    const { classes, click, handleClose } = this.props;

    let id = click.map((obj) => {
      return obj.id;
    });

    let id0 = id[0];
    let id1 = id[1];
    let id2;
    if (id[2]) {
      id2 = id[2];
    } else {
      id2 = null;
    }

    const defaultProps = {
      borderColor: "#e9eaeb",
      background: "#c4c4c4",
      marginTop: "12px",
      m: 1,
      border: 1,
      width: "100%",
      borderRadius: 4,
    };

    return (
      <React.Fragment>
        <Grid
          // container
          // justifyContent="flex-end"
          style={{
            width: "100%",

            position: "static",
            display: "block",
            top: window.innerWidth <= 768 ? "2%" : "20%",
          }}
        >
          <Box style={{ display: "flex" }}>
            {click != null
              ? click.slice(0, 3).map((obj, index) => (
                  <Grid
                    // container
                    justifyContent="center"
                    key={index}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Box
                      style={{
                        background: "#f3f5f6",
                        // border: " 2px solid #e9eaeb",
                        width: 200,
                        padding: 10,
                        height: 60,
                        position: "relative",
                        // backgroundColor:"red",
                        border:
                          window.innerWidth <= 768
                            ? "1px solid var(--text-secondary-color)"
                            : "",
                      }}
                      {...defaultProps}
                    >
                      <Grid
                        md={12}
                        xs={12}
                        item
                        container
                        justifyContent="flex-start"
                        style={{
                          minHeight: window.innerWidth <= 768 ? "100%" : 100,
                        }}
                      >
                        <div>
                          {window.innerWidth <= 1000 ? (
                            <Grid
                              md={1}
                              item
                              container
                              justifyContent="flex-end"
                            >
                              <IconButton
                                onClick={this._handleDelete.bind(this, obj)}
                                style={{ padding: 0 }}
                              >
                                <CloseOutlinedIcon
                                  fontSize="small"
                                  style={{
                                    top: -20,
                                    right: 2,
                                    position: "absolute",
                                    color: "#16222b",
                                    backgroundColor: "white",
                                    borderRadius: 50,
                                  }}
                                />
                              </IconButton>
                            </Grid>
                          ) : null}
                        </div>
                        <Grid
                          md={11}
                          item
                          style={{
                            height: 65,

                            // marginBottom: 5,
                            display: "flex",
                            flexDirection: "column",
                            // alignItems: "flex-start",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="body2"
                            // style={{
                            //   color: "#16222b",
                            // }}
                            style={{
                              textTransform: "capitalize",
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              color: "#16222b",
                            }}
                            className={`${classes.packageName} truncate-text`}
                          >
                            {obj.name.toLowerCase()}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              // lineHeight:"0px"
                            }}
                          >
                            {" "}
                            <Typography
                              variant="overline"
                              style={{ color: "#616e78" }}
                            >
                              {obj.duration}
                            </Typography>
                            <Typography
                              variant="body1"
                              style={{
                                color: "#0b3954",
                                fontWeight: "200px",
                                fontSize: "12px",
                              }}
                            >
                              Rs.{" "}
                              {Number(obj.price).toLocaleString("en-IN", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}
                              /-
                            </Typography>
                          </div>
                        </Grid>
                        {window.innerWidth <= 768 ? null : (
                          <Grid
                            md={1}
                            item
                            container
                            style={{
                              position: "absolute",
                              right: "-8px",
                              top: "-8px",
                              backgroundColor:"var(--accent-color)",
                              borderRadius:"50%",
                              width: "20px",
                              height: "20px",
                              padding:"2px"
                            }}
                            justifyContent="flex-end"
                          >
                            <IconButton
                              onClick={this._handleDelete.bind(this, obj)}
                              style={{ padding: 0 }}
                            >
                              <CloseOutlinedIcon
                                fontSize="small"
                                style={{
                                  fontSize: "15px",
                                  // top: 0,
                                  // right: 2,
                                  // position: "absolute",
                                  color: "#16222b",
                                }}
                              />
                            </IconButton>
                          </Grid>
                        )}
                        {/* <Grid style={{ position: "absolute", bottom: 6 }}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#0b3954",
                              fontWeight: "500px",
                            }}
                          >
                            Rs.{" "}
                            {Number(obj.price).toLocaleString("en-IN", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                            /-
                          </Typography>
                        </Grid> */}
                      </Grid>
                    </Box>
                  </Grid>
                ))
              : ""}
            {window.innerWidth <= 768 ? (
              click.length < 1 ? (
                <Grid>
                  <AddPackage />
                  <AddPackage />
                </Grid>
              ) : click.length < 2 ? (
                <Grid>
                  <AddPackage handleClose={this.props.handleClose} />
                </Grid>
              ) : (
                ""
              )
            ) : click.length < 1 ? (
              <Grid style={{ display: "flex",width: "100%", }}>
                <AddPackage />
                <AddPackage />
                <AddPackage />
              </Grid>
            ) : click.length < 2 ? (
              <Grid style={{ display: "flex", height: 60, }}>
                <AddPackage />
                <AddPackage />
              </Grid>
            ) : click.length < 3 ? (
              <Grid style={{ display: "flex", height: 60,}}>
                <AddPackage />
              </Grid>
            ) : (
              ""
            )}
            <div
              style={{
                width: "20%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
          
            
              }}
            >
              {click.length < 2 ? (
                <Button
                  variant="contained"
                  // component={Link}
                  disabled
                  style={{
                    width: "70%",
                    color: "var(--text-primary-color)",
                    cursor: "no-drop",
                    borderRadius: "4px",
                    backgroundColor: "#e9eaeb",
                    margin: 10,
                    textTransform: "none",
                    // height:50
                    // border: "1px solid var(--text-secondary-color)",
                  }}
                >
                  <Typography style={{ color: "#bbc2c8" }}>Compare</Typography>
                </Button>
              ) : (
                <Button
                  onMouseEnter={this.handleMouseEnter}
                  variant="contained"
                  // target="_blank"
                  href={`/comparelist?packages1=${id0}&packages2=${id1}&packages3=${id2}`}
                  style={{
                    width: "70%",
                    backgroundColor: "#0b3954",
                    color: "#fff",
                    borderRadius: "4px",
                    margin: 10,
                    textTransform: "none",
                   
                    
                  }}
                  onClick={this.onClick}
                >
                <Typography  style={{ color: "white" }} >Compare</Typography>
                </Button>
              )}
            </div>
          </Box>
          {/* {this.state.scroll < 350 ? (
            ""
          ) : (
            <Grid
              container
              justifyContent="flex-end"
              style={{
                position: "sticky",
                marginTop: "30%",
                paddingBottom: "5%",
              }}
            >
              <IconButton
                onClick={this.scrollToTop}
                style={{ background: "#ff8362" }}
              >
                <KeyboardArrowUpIcon style={{ color: "#fff" }} />
              </IconButton>
            </Grid>
          )} */}
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    state: state,
    cookies: ownProps.cookies,
  };
};

export default withRouter(
  connect(mapStateToProps)(withStyles(style, { withTheme: true })(Compare))
);
