import * as types from "../action.types";

export const getPackageDetailAction = data => {
  return {
    type: types.GET_PACKAGE_DETAILS,
    data
  };
};

export const getPackageDetailSuccessAction = data => {
  return {
    type: types.GET_PACKAGE_DETAILS_SUCCESS,
    data
  };
};
export const getResponseNullAction = () => {
  return {
    type: types.GET_RESPONSE_NULL
  };
};
