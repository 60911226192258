import React, { createContext, useState, useEffect } from "react";
import { getUserProfileDetails } from "../Services/services";
import { Tracker } from "../../../tracking/tracker";
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);
  const accesstoken = localStorage.getItem("access_token");

  useEffect(() => {
    if (accesstoken) {
      const fetchUserInfo = async () => {
        const response = await getUserProfileDetails();
        setUserInfo(response.data.data);
        if (response.data.data) {
          Tracker.getInstance().setUserIdentifier(`+${response.data.data.country_code}${response.data.data.phone_number}`)
          Tracker.getInstance().setUserProperties(
            response.data.data.name,
            response.data.data.email
          );
        }
      };

      fetchUserInfo();
    }
  }, []);

  return (
    <UserContext.Provider value={{ userInfo }}>{children}</UserContext.Provider>
  );
};
