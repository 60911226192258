import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getPackageListAction } from "../../store/actions/packageList.action";
import ValueLabel from "@material-ui/core/Slider/ValueLabel";

const style = (theme) => ({
  root: {
    width: 200 + theme.spacing(3) * 2,
  },
  text: {
    width: "100%",
  },
  margin: {
    height: theme.spacing(3),
    objectFit: "content",
  },
});
const StyledValueLabel = withStyles({
  circle: {
    //    transform: props => props.index === 0 ? "rotate(-90deg)" : "rotate(0deg)",
    //  width:"100%",
    //   height:50,
    background: "transparent",
  },

  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit",
    },
  },
})(ValueLabel);

class RangeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "Rs.5000 - Rs.500000",
      min: "",
      max: "",
      step: 500,
    };
  }
  handleChange = (min) => {
    let value = min;

    let minimum = value && value[0];
    let maximum = value && value[1];
    let total = `Rs.${minimum} - Rs.${maximum}`;
    this.setState({
      value: total,
    });

    //   this.props.getPackageListAction(
    //       {params: {
    //         min_budget:minimum,
    //         max_budget:maximum
    //   }},)

    this.props.data(minimum, maximum);
  };

  render() {
    const { classes } = this.props;
    const { min, max, step } = this.state;

    return (
      <React.Fragment>
        {/* <div classes={classes.root}> */}
        <Grid
          md={12}
          item
          container
          justifyContent="space-around"
          style={{ dispaly: "flex", marginTop: "4%", marginBottom: "2%" }}
        >
          <Grid md={6} xs={6} item>
            <Typography
              gutterBottom
              variant={window.innerWidth < 600 ? "caption" : "body2"}
              className={classes.text}
              style={{ marginBottom: 20, marginTop: 5, color: "#16222b" }}
            >
              Budget
            </Typography>
          </Grid>
          <Grid md={6} xs={6} item style={{ textAlign: "end" }}>
            <Typography variant="caption" style={{ color: "#16222b" }}>
              {this.state.value}
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Slider
            defaultValue={[min, max]}
            step={step}
            min={5000}
            max={1000000}
            onChange={(e, val) => this.handleChange(val)}
            // valueLabelDisplay="on"
            ValueLabelComponent={StyledValueLabel}
            style={{ marginBottom: 20 }}
          />
        </Grid>
        {/* </div> */}
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ getPackageListReducer }) => {
  return {
    _packageList: getPackageListReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, { getPackageListAction })(
    withStyles(style)(RangeSlider)
  )
);
