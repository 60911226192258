import React, { useEffect, useRef, useState } from "react";
import { PrimaryButton, PrimarySubmitButton } from "../../../../utils/Buttons";
import drawerLogo from "../../../assets/images/drawerLogo.png";
import SignUpUser from "../SignUp/SignUpUser";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import "./loginotp.css";
import ErrorIcon from "@material-ui/icons/Error";
import { resendOtp, verifyUserWithOtp } from "../../Services/services";
import { useHistory, useLocation } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomizeForm from "../../CustomizeForm/CustomizeForm";
import RequestCallForm from "../../RequestCall/RequestCallForm";
import { Tracker } from "../../../../tracking/tracker";
import { loginResendOtpClickEvent } from "../../../../tracking/events/loginResendOtpClickEvent";
import { loginValidateOtpClickEvent } from "../../../../tracking/events/loginValidateOtpClickEvent";
import { loginSuccessEvent } from "../../../../tracking/events/loginSuccessEvent";
import { loginEndEvent } from "../../../../tracking/events/loginEndEvent";
import { TrackingView } from "../../../../tracking/trackingConstants";
import FormSubmissionSuccess from "../../FormSubmissionSuccess/FormSubmissionSuccess";
import { getFullUrlPath } from "../../../../utils/utility";

function LoginOtp({
  setIsOtpView,
  countryCode,
  mobileNumber,
  handleCloseLoginModal,
  handleCloseCustomizeModal,
  shouldShowSuccessView,
  setCurrentView,
  leadId,
}) {
  const history = useHistory();
  const location = useLocation();
  const modalRef = useRef(null);
  const [timer, setTimer] = useState(30);
  const [resendOtpCount, setResendOtpCount] = useState(1);
  const [intervalId, setIntervalId] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState("");
  const [otpValues, setOtpValues] = useState(Array(6).fill(""));
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [openSignUpModal, setOpenSignUpModal] = useState(false);
  const [resendOtpMessage, setResendOtpMessage] = useState("");
  const [openSuccessView, setOpenSuccessView] = useState(false);
  const inputRef = useRef([]);
  const formRef = useRef(null);
  const fullUrlPath = getFullUrlPath(location);
  const handleVerifyOtp = async () => {
    setLoadingOtp(true);
    const response = await verifyUserWithOtp(
      countryCode,
      mobileNumber,
      otpValues.join(""),
      leadId
    );

    if (response.status >= 200 && response.status < 300) {
      Tracker.getInstance().setUserIdentifier(`+${countryCode}${mobileNumber}`);
      if (response.data.data.profile.name === null) {
        setLoadingOtp(false);
        setOpenSignUpModal(true);
        setError("");
        setAccessToken(response.data.data.access_token);
        handleClearInterval();
      } else {
        localStorage.setItem("access_token", response.data.data.access_token);
        if (shouldShowSuccessView) {
          history.push("/thanks");
          sessionStorage.setItem("previousPage", fullUrlPath);
          // setOpenSuccessView(true);
          handleClearInterval();
        } else {
          window.location.reload();
          handleCloseLoginModal();
          handleCloseCustomizeModal();
        }
        handleClearInterval();
        setError("");

        setLoadingOtp(false);

        if (!shouldShowSuccessView) {
          Tracker.getInstance().trackEvent(
            new loginEndEvent(TrackingView.LoginForm.VerifyOtp)
          );
          Tracker.getInstance().trackEvent(new loginSuccessEvent());
        }
      }
    } else {
      handleClearInterval();
      setLoadingOtp(false);
      setError("Sorry, we are not able to verify the code.");
    }
    Tracker.getInstance().trackEvent(new loginValidateOtpClickEvent());
  };
  const handleResendOtp = async () => {
    const response = await resendOtp(countryCode, mobileNumber);
    if (response.status >= 200 && response.status < 300) {
      setResendOtpMessage(
        `A new code has been sent to +${countryCode}${mobileNumber}`
      );
    }
    setResendOtpCount(resendOtpCount + 1);
    Tracker.getInstance().trackEvent(
      new loginResendOtpClickEvent(resendOtpCount)
    );
  };

  useEffect(() => {
    let interval = null;

    if (isActive) {
      const id = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          } else {
            clearInterval(id);
            setIsActive(false);
            return 0;
          }
        });
      }, 1000);
      setIntervalId(id);
    } else if (!isActive && timer !== 0) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isActive, timer]);

  const handleClearInterval = () => {
    clearInterval(intervalId);
    setIsActive(false);
  };
  useEffect(() => {
    startTimer(false);
  }, []);
  const startTimer = (shouldResendOtp) => {
    setTimer(30);
    setIsActive(true);
    setError("");
    if (shouldResendOtp) {
      handleResendOtp();
    }
  };

  const handleCloseSignUpModal = () => {
    setOpenSignUpModal(false);
  };
  const handleSubmitOtp = () => {
    if (otpValues.some((value) => value === "")) {
      setError("Please enter valid code.");
    } else {
      handleVerifyOtp();
    }
  };

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    if (value.length > 1) {
      return;
    }
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);
    if (value) {
      const nextIndex = inputRef.current.findIndex(
        (input) => input.value === ""
      );
      if (nextIndex !== -1) {
        inputRef.current[nextIndex].focus();
      }
    }
  };

  useEffect(() => {
    if (inputRef.current[0]) {
      inputRef.current[0].focus();
    }
  }, []);

  useEffect(() => {
    const otpLength = otpValues.join("").length;
    if (otpLength === 6) {
      handleSubmitOtp();
    }
  }, [otpValues]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setCurrentView(TrackingView.LoginForm.VerifyOtp);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div id="modal" className="animate-slide-up">
      {openSignUpModal ? (
        <SignUpUser
          openSignUpModal={openSignUpModal}
          handleCloseSignUpModal={handleCloseSignUpModal}
          accessToken={accessToken}
        />
      ) : openSuccessView ? (
        <FormSubmissionSuccess
          userInfo={null}
          handleCloseCustomizeModal={handleCloseCustomizeModal}
        />
      ) : (
        <div>
          <div className="login-close-button" ref={modalRef}>
            <div className="topbar-container">
              <ArrowBackIosIcon
                style={{
                  padding: "5px",
                  marginLeft: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsOtpView(false);
                  setError("");
                  setOtpValues(Array(6).fill(""));
                  setResendOtpMessage("");
                }}
              />
            </div>

            <div class="login-header">
              <img src={drawerLogo} alt="Trip Store Logo" class="login-logo" />
            </div>
            <div style={{ flex: "4" }}></div>
          </div>
          <div class="modal-content">
            <h2>Verify Phone Number</h2>
            <p>
              Enter the code we have sent via SMS to +{countryCode}
              {mobileNumber}
            </p>
            <div class="otp-inputs">
              <form ref={formRef} onSubmit={handleSubmitOtp}>
                <div className="otp-container">
                  {[...Array(6)].map((_, index) => (
                    <input
                      key={index}
                      type="number"
                      maxLength="1"
                      id="otp-input"
                      className="otp-input"
                      value={otpValues[index]}
                      ref={(el) => (inputRef.current[index] = el)}
                      onChange={(e) => handleInputChange(index, e)}
                      onKeyDown={(e) => {
                        if (
                          e.key === "Backspace" &&
                          !e.target.value &&
                          index !== 0
                        ) {
                          inputRef.current[index - 1].focus();
                        }
                      }}
                    />
                  ))}
                  {error && (
                    <span className="otp-error-text">
                      <ErrorIcon
                        style={{ fontSize: "12px", marginRight: "2px" }}
                      />{" "}
                      {error}
                    </span>
                  )}
                </div>
              </form>
            </div>
            <PrimarySubmitButton
              className="otp-login-button"
              onClick={handleSubmitOtp}
            >
              {loadingOtp ? (
                <CircularProgress color="inherit" size={12} />
              ) : (
                "Validate"
              )}
            </PrimarySubmitButton>
            {/* <div className="resend"> */}
            {isActive ? (
              <p
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                Resend Code in 00:
                {timer.toLocaleString()?.length == 1 ? `0${timer}` : timer}
              </p>
            ) : (
              <p
                onClick={() => {
                  startTimer(true);
                }}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                Resend Code
              </p>
            )}
            {/* </div> */}

            {resendOtpMessage ? (
              <span
                style={{
                  color: "var(--success-color)",
                  fontSize: "12px",
                  width: "100%",
                }}
              >
                {resendOtpMessage}
              </span>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginOtp;
