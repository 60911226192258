import React, { Component } from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import { Link } from "react-router-dom"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Grid, Typography, Box, Container } from "@material-ui/core"
import Footer from "../../footer/footer"
import Header from "./termsHeader"
import { termsConditionAction } from "../../../store/actions/termsCondition.action"
import { Helmet } from "react-helmet"
import { Tracker } from "../../../../tracking/tracker"
import { PageViewEvent } from "../../../../tracking/events/pageViewEvent"
import { TrackingPageName } from "../../../../tracking/trackingConstants"
import { NavigationContext } from "../../../navigation/navigationContext"

const style = (theme) => ({
	text: {
		marginTop: "4%",
		[theme.breakpoints.down("sm")]: {
			marginTop: "24%",
		},
	},
})
class TermsCondition extends Component {
	static contextType = NavigationContext
	
	componentDidMount = () => {
		// Tracking Page View
		this.context.setBrowsingPage(TrackingPageName.TermsAndConditions)

		const params = new URLSearchParams(window.location.search)
		const Myparam = params.get("packageId")
		this.props.termsConditionAction({ params: { package_id: Myparam } })
	}

	render() {
		const { _termsCondition, classes } = this.props
		const defaultProps = {
			border: "2px solid #e9eaeb",
			minHeight: "50vh",
		}
		return (
			<Grid>
				<Helmet>
					<title>Tripstore || Terms and condition</title>
					<meta charset="UTF-8" />
					<meta name="description" content="package detail description" />
					<meta name="keywords" content="package detail keywords" />
					<meta name="author" content="Tripstore" />
					<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				</Helmet>
				<Header />
				<Grid md={12} xs={12} sm={12} container justify="center" style={{ marginTop: "8%" }}>
					<Grid style={{ display: "block", padding: 16 }}>
						<Typography color="secondary" variant="h5" className={classes.text} style={{ color: "#16222b" }}>
							Terms and Conditions
						</Typography>
					</Grid>

					<Container>
						<Box {...defaultProps} style={{ padding: 40, marginTop: 24 }}>
							<Grid md={12} xs={12} sm={12} item container>
								<Typography style={{ color: "#616e78" }} dangerouslySetInnerHTML={{ __html: _termsCondition }} variant="subtitle1" />
							</Grid>
						</Box>
					</Container>
					<Grid md={12} sm={12} xs={12} container justify="center" style={{ marginTop: "4%", marginBottom: "4%" }}>
						<Link to="/" style={{ textDecoration: "none" }}>
							<Typography color="primary" variant="subtitle2">
								Go back to Tripstore
							</Typography>
						</Link>
					</Grid>
					<Footer />
				</Grid>
			</Grid>
		)
	}
}
const mapStateToProps = ({ termsConditionReducer }) => {
	return {
		_termsCondition: termsConditionReducer,
	}
}
export default withRouter(
	connect(mapStateToProps, {
		termsConditionAction,
	})(withStyles(style)(TermsCondition))
)
