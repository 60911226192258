//dev
const tripStoreBaseUrl =
  process.env.REACT_APP_BASE_URL ?? process.env.REACT_APP_PRODUCTION_BASE_URL;

const version = 1;

const apiUrl = {
  tripStoreBaseUrl: tripStoreBaseUrl,
  homePageBaseUrl: `${tripStoreBaseUrl}/api/v${version}`,
};

export default apiUrl;
