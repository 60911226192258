import axios from "axios"
export const getDestinationService = () => {
        return axios
                .get("/destination/Region")
                .then((response) => {
                        return response
                })
                .catch((err) => {
                        return err.response
                })
}
