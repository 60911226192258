import * as types from "../action.types";
export const termsConditionReducer = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.GET_TERMS_CONDITION_SUCCESS:
      return response;
    case types.GET_TERMS_CONDITION_ERROR:
      return state;
    case types.GET_RESPONSE_NULL:
      return { response: null };
    default:
      return state;
  }
};
