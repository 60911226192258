import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants"
import { TrackableEvent } from "./trackableEvent"

export class callButtonClickedWithOriginEvent extends TrackableEvent {
	constructor(origin) {
		let properties = {}
		properties[TrackerPropertyKey.Origin] = origin;
		super(TrackingEventName.NavbarCallButtonClickedWithOrigin, properties)
	}
}
    