import axios from "axios"
export const postRequestCallService = (request) => {
        return axios
                .get("/request/call", request.data)
                .then((response) => {
                        return response
                })
                .catch((err) => {
                        return err.response.data
                })
}
