import * as types from "../action.types";

export const getRequestPackageAction = data => {
  return {
    type: types.GET_REQUEST_PACKAGE,
    data
  };
};

export const getRequestPackageSuccessAction = data => {
  return {
    type: types.GET_REQUEST_PACKAGE_SUCCESS,
    data
  };
};
