import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants"
import { TrackableEvent } from "./trackableEvent"

export class HomeWidgetItemClickedEvent extends TrackableEvent {
	constructor(widgetType, widgetIdentifier, widgetItemTitle1, widgetItemTitle2,) {
		let properties = {}
		properties[TrackerPropertyKey.WidgetType] = widgetType
		properties[TrackerPropertyKey.widgetIdentifier] = widgetIdentifier
		
		if (widgetItemTitle1)
			properties[TrackerPropertyKey.widgetItemTitle1] = widgetItemTitle1
		
		if (widgetItemTitle2)
			properties[TrackerPropertyKey.widgetItemTitle2] = widgetItemTitle2

		super(TrackingEventName.HomeWidgetItemClick, properties)
	}
}