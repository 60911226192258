import { put, call } from "redux-saga/effects";
import { getPackageListService } from "../services/getPackageList.service";

import { getPackageListSuccessAction } from "../actions/packageList.action";
import * as types from "../action.types";
export function* getPackageListSaga(data) {
  try {
    const response = yield call(getPackageListService, data);

    yield put(getPackageListSuccessAction(response));
  } catch (error) {
    yield put({ type: types.GET_PACKAGE_LIST_ERROR, error });
  }
}
