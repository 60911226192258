import React, { Component } from "react"
import { Formik } from "formik"
import Form from "./form"
import * as Yup from "yup"
import { Typography, Grid, withStyles, Container, useMediaQuery, IconButton } from "@material-ui/core"
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import Fogg from "../../../assets/images/fogg.png"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { getRequestCallAction } from "../../../store/actions/requestCall.action"
import { Tracker } from "../../../../tracking/tracker"
import { RequestCallFormOpenEvent } from "../../../../tracking/events/requestCallFormOpenEvent"
import { RequestCallFormSubmit } from "../../../../tracking/events/requestCallFormSubmit"

const useStyles = (theme) => ({
	text: {
		width: "100%",
		// marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px`,
	},
	container: {
		maxWidth: 300,
	},
	dailogContent: {
		padding: theme.spacing(3),
	},
})

const validationSchema = Yup.object({
	name: Yup.string("Enter a name")
		.matches(/^(\w+\s)*\w+$/, "enter valid name")
		.max(100, "not more than 100")
		.required("Name is required"),
	// .nullable(),
	email: Yup.string("Enter your email").email("Enter a valid email").required("Email is required"),

	phone: Yup.string()
		.matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, "that's not your number")
		.min(10, "that's not your number")
		.max(15, "max is 15")
		.required("Phone number is required"),
})

class SubmitForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			open: false,
			form: {
				name: "",
				email: "",
				phone: "",
			},
		}
	}

	onEntered = () => {
		Tracker.getInstance().trackEvent(new RequestCallFormOpenEvent(this.props.origin))
	}

	handleClose = () => {
		this.props.onClose()
		this.setState({
			open: false,
		})
	}
	submit = (data) => {
		this.setState({
			open: true,
		})

		const { Data } = this.props

		let merged = { ...Data, ...data }

		this.props.getRequestCallAction({ params: merged })
		Tracker.getInstance().trackEvent(new RequestCallFormSubmit(this.props.origin))
		this.props.history.push("/thanks")
	}

	render() {
		const { classes } = this.props
		const { open, onClose } = this.props
		const values = { name: "", email: "", phone: "", comment: "" }

		return (
			<React.Fragment>
				<Container>
					<Grid container>
						<Grid md={2} xs={12} item>
							<Dialog fullScreen={window.innerWidth <= 576 ? true : false} open={open} onClose={onClose} TransitionProps={{ onEntered: this.onEntered }}>
								{window.innerWidth <= 576 ? (
									<Grid container justifyContent="flex-end">
										<IconButton justifyContent="flex-end" onClick={onClose} style={{ padding: 16, color: "#16222b" }}>
											<CloseOutlinedIcon />
										</IconButton>
									</Grid>
								) : (
									""
								)}
								<DialogContent width="100px" className={classes.dailogContent}>
									{window.innerWidth <= 576 ? (
										""
									) : (
										<Grid md={12} item container justifyContent="center">
											<img src={Fogg} alt="fogg" style={{ height: 200, width: 300 }}></img>
										</Grid>
									)}
									<Grid
										container
										justifyContent={window.innerWidth <= 576 ? "space-between" : ""}
										style={{
											display: window.innerWidth <= 576 ? "flex" : "",
										}}>
										<Grid md={12} xs={6} item container justifyContent={window.innerWidth <= 576 ? "flex-start" : "center"}>
											<Typography
												variant="body2"
												className={classes.text}
												style={{
													color: "#bbc2c8",
													width: window.innerWidth <= 576 ? "108%" : "58%",
													textAlignLast: window.innerWidth <= 576 ? "start" : "center",
												}}>
												Just one more step to your perfect vacation
											</Typography>
										</Grid>
									</Grid>

									<div className={classes.container}>
										<Formik render={(props) => <Form {...props} />} initialValues={values} validationSchema={validationSchema} onSubmit={this.submit} />
									</div>
								</DialogContent>
							</Dialog>
						</Grid>
					</Grid>
				</Container>
			</React.Fragment>
		)
	}
}
const mapStateToProps = ({ getRequestCallReducer }) => {
	return {
		_requestCall: getRequestCallReducer,
	}
}
export default withRouter(connect(mapStateToProps, { getRequestCallAction })(withStyles(useStyles, useMediaQuery)(SubmitForm)))
