import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import DiscountBadge from "./discountBadge";
import { Link } from "react-router-dom";
import HighlightBadge from "./highlightBadge";
import { hideTourOprator } from "../../../../utils/utility";

const style = (theme) => ({
  content: {
    paddingRight: theme.spacing(2),
  },
  packageName: {
    textTransform: "capitalize",
    color: "#000",
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: "1.2",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
  },
  agentName: {
    textTransform: "none",
    color: "#343434",
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  badge: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  rating: {
    marginLeft: "4px",
    marginRight: "4px",
  },
});

const PackageTitle = (props) => {
  const { packageData, classes } = props;
  const rating = packageData.agentInfo.rating;
  const badge = packageData.highlights.badge;
  const badgeColor = packageData.highlights.color ?? "#3582BD";
  const discountPercentage = packageData.saleInfo.discountPercentage;

  const isLoggedIn = localStorage.getItem("access_token");
  return (
    <React.Fragment>
      <Grid
        container
        item
        xs={10}
        md={discountPercentage && discountPercentage > 0 ? 8 : 9}
        direction="column"
        style={{ marginTop: "8px" }}
      >
        <Grid
          container
          item
          direction="column"
          alignItems="flex-start"
          className={classes.content}
        >
          <Button
            variant="text"
            component={Link}
            to={`/packagedetails/${packageData.slug}`}
            onClick={props.onPackageDetailClick}
            style={{ textTransform: "none", padding: 0 }}
          >
            <Typography variant="h6" className={classes.packageName}>
              {packageData.name.toLowerCase()}
            </Typography>
          </Button>
          <Grid container item alignItems="center" style={{ marginTop: "2px" }}>
            {(props.shouldShowOperatorName ?? true) && (
              <Typography className={classes.agentName}>
                {" "}
                {isLoggedIn
                  ? packageData.agentInfo.name
                  : "Operator: " + hideTourOprator(packageData.agentInfo.name)}
              </Typography>
            )}
            {rating && (
              <Rating
                className={classes.rating}
                name="read-only"
                value={packageData.agentInfo.rating}
                precision={0.25}
                readOnly
                size="small"
              />
            )}
            {rating && (
              <Typography className={classes.agentName}>
                {packageData.agentInfo.rating}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={2}
        md={discountPercentage && discountPercentage > 0 ? 4 : 3}
        justifyContent="flex-end"
      >
        {badge && (
          <div className={classes.badge}>
            <HighlightBadge text={badge} backgroundColor={badgeColor} />
          </div>
        )}
        {discountPercentage && discountPercentage > 0 ? (
          <div style={{ paddingRight: "10px", paddingLeft: "10px" }}>
            <DiscountBadge
              discount={packageData.saleInfo.discountPercentage}
              color={packageData.saleInfo.discountColor}
            />
          </div>
        ) : (
          <div style={{ paddingRight: "10px" }} />
        )}
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(style)(PackageTitle);
