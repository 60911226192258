import React, { useState } from "react";
import "./trusttestimonialone.css";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Rating from "@material-ui/lab/Rating";
import moment from "moment/moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import person from "../../assets/images/person-icon.png";

const useStyles = makeStyles({});

function TrustTestimonialOneCard({
  title,
  rating,
  time,
  imageUrl,
  subtitle,
  actions,
  onClick,
}) {
  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  }

  const [imageLoaded, setImageLoaded] = useState(true);

  return (
    <Grid item xs={12} sm={6} md={6} lg={6} className="grid-item-testimonial">
      <Card
        style={{
          padding: "0px",
          boxShadow: "none",
        }}
      >
        <div className="testimonial-main-container">
          <div className="testimonial-main-wrapper">
            <div className="testimonial-image-wrapper">
              {!imageLoaded ? (
                <img
                  src={person}
                  alt="placeholder"
                  className="testimonial-image"
                />
              ) : (
                <LazyLoadImage
                  src={imageUrl ? imageUrl : person}
                  alt="image 1"
                  effect="blur"
                  //  width="100%"
                  //  height="100px"
                  className="testimonial-image"
                  loading="lazy"
                  style={{ display: imageLoaded ? "block" : "none" }}
                  onLoad={() => {
                    setImageLoaded(true);
                  }}
                  onError={() => {
                    setImageLoaded(false);
                  }}
                />
              )}
            </div>
            <div className="testimonial-content-main">
              <div className="testimonial-heading-rating-container">
                <div className="testimonial-header-content1">
                  <div className="testimoniaal-name">{title}</div>

                  <Rating
                    name="read-only"
                    className="testimonial-ratings"
                    precision={0.5}
                    value={rating}
                    readOnly
                  />
                </div>
                {/* <div className="testimonial-header-content2">
                  {" "}
                  <div className="testimoniaal-data">
                    {moment(time).format("LL")}
                  </div>
                </div> */}
              </div>
              <div style={{ width: "100%", height: "100%" }}>
                <div className="testimonial-context-text1">
                  <p className="testimonial-text">
                    {truncateText(subtitle, 250)}
                  </p>
                </div>

                <div className="testimonial-reading">
                  {" "}
                  <a
                    href={
                      actions?.actions.length > 0
                        ? actions.actions[0].redirect_url
                        : null
                    }
                    className="testimonial-reading-text"
                    onClick={onClick}
                  >
                    {actions?.actions.length > 0
                      ? actions.actions[0].title
                      : null}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Grid>
  );
}

export default TrustTestimonialOneCard;
