import { Grid, Typography, withStyles } from "@material-ui/core"
import React from "react"

const style = (theme) => ({
	title: {
		color: "#343434",
		fontSize: 16,
		fontWeight: 400,
	},
	contentGrid: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	img: {
		width: 25,
		height: 25,
		objectFit: "cover",
	},
	inclusionText: {
		color: "#343434",
		fontSize: 12,
		fontWeight: 250,
	},
})

const InclusionList = (props) => {
	const { classes, inclusions } = props

	return (
		<React.Fragment>
			{inclusions && (
				<Grid container item direction="column">
					<Grid container item>
						<Typography className={classes.title}>Included:</Typography>
					</Grid>
					<Grid container item alignItems="center" justifyContent="flex-start" spacing={1}>
						{inclusions.map((obj, index) => (
							<Grid key={index} item direction="column" className={classes.contentGrid}>
								<img src={obj.icon} alt="icon" className={classes.img} />
								<Typography className={classes.inclusionText}>{obj.name}</Typography>
							</Grid>
						))}
					</Grid>
				</Grid>
			)}
		</React.Fragment>
	)
}

export default withStyles(style)(InclusionList)
