import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants"
import { TrackableEvent } from "./trackableEvent"

export class SearchIntentStartEvent extends TrackableEvent {
	constructor(searchTypeLabel) {
		let properties = {}
	
        properties[TrackerPropertyKey.SearchType] = searchTypeLabel
		super(TrackingEventName.SearchIntentStart, properties)
	}
}
          