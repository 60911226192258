import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import "./whytripstoregrid.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const useStyles = makeStyles({
  root: {
    width: "90%",
    height: "350px",
    borderRadius: "20px",
    position: "relative",
    marginTop:"15px",
    border: "1px solid var(--text-secondary-color)",
  },
});

function WhyTripStoreGrid({ title, imageUrl, subtitle }) {
  const classes = useStyles();

  const cardActionArea = (
    <CardActionArea className="desktop-image-container" disableRipple>
     
      <div className="desktop-text-overlay">
        <div className="desktop-text-content-overlay">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
      </div>
    </CardActionArea>
  );

  return (
    <Grid
      md={3}
      lg={3}
      style={{ width: "100%", display: "flex", justifyContent: "center" }}
    >
      <Card
        className={classes.root}
        style={{
          backgroundImage: imageUrl ? `url(${imageUrl})` : "",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
        }}
      >
        {cardActionArea}
      </Card>
    </Grid>
  );
}

export default WhyTripStoreGrid;
