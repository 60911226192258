import * as types from "../action.types";

export const getDestinationAction = () => {
    return {
        type: types.GET_DESTINATION
    };
};
export const getDestinationActionSuccess = values => {
    return {
        type: types.GET_DESTINATION_SUCCESS,
        values
    };
};
