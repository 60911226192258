import * as types from "../action.types";
export const getCompareListReducer = (state = {}, action) => {
  const response = action.data;
  switch (action.type) {
    case types.GET_COMPARE_LIST_SUCCESS:
      return response;
    case types.GET_COMPARE_LIST_ERROR:
      return state;
    case types.GET_RESPONSE_NULL:
      return { response: null };
    default:
      return state;
  }
};
