import { put, call } from "redux-saga/effects";
import { postRequestCallService } from "../services/postRequestCall.service";

import { getRequestSuccessAction } from "../actions/requestCall.action";
import * as types from "../action.types";
export function* postRequestCallSaga(data) {
  try {
        const response = yield call(postRequestCallService,data);
        yield put(getRequestSuccessAction(response));

    } catch (error) {
        yield put({ type: types.GET_REQUEST_CALL_ERROR, error });
    }
}
