import * as types from "../action.types";

export const getRequestCallReducer = (state = {}, action) => {
  const response = action.values;

  switch (action.types) {
    case types.GET_REQUEST_CALL_SUCCESS:
      return response;
    case types.GET_REQUEST_CALL_ERROR:
      return state;
    default:
      return state;
  }
};
