import { TrackerPropertyKey, TrackingEventName, TrackingPageName } from "../trackingConstants"
import { TrackableEvent } from "./trackableEvent"

export class PackageDetailPageViewEvent extends TrackableEvent {
	constructor(slug) {
		let properties = {}
		properties[TrackerPropertyKey.BrowsingPage] = TrackingPageName.PackageDetail
		properties[TrackerPropertyKey.PackageSlug] = slug

		super(TrackingEventName.PageView, properties)
	}
}
