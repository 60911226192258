
import { TrackableEvent } from "./trackableEvent"
import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants"

export class ListingFilterOpenEvent extends TrackableEvent {
	constructor(title) {
		let properties = {}
		properties[TrackerPropertyKey.FilterTitle] = title

		super(TrackingEventName.ListingFilterOpen, properties)
	}
}
