import { TrackerPropertyKey, TrackingEventName, TrackingEventValues } from "../trackingConstants"
import { TrackableEvent } from "./trackableEvent"

export class RequestCallFormOpenEvent extends TrackableEvent {
	constructor(origin, packageId) {
		let properties = {}
		properties[TrackerPropertyKey.FormType] = TrackingEventValues.RequestCall
		properties[TrackerPropertyKey.Origin] = origin
		if (packageId) {
			properties[TrackerPropertyKey.PackageId] = packageId
		}

		super(TrackingEventName.RequestCallFormOpen, properties)
	}
}
