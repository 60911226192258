import React, { Component } from "react"
import Toolbar from "@material-ui/core/Toolbar"
import AppBar from "@material-ui/core/AppBar"
import { Container, Grid } from "@material-ui/core/"
import { withRouter, NavLink } from "react-router-dom"
import { connect } from "react-redux"
import BecomeAnAgent from "../../newagent.js/agent"
import Logo from "../../../assets/images/tripLogo.png"
import withStyles from "@material-ui/core/styles/withStyles"
import { getMonthAction } from "../../../store/actions/getMonths"
import { getInclusionAction } from "../../../store/actions/getInclusion.action"
import { getThemeAction } from "../../../store/actions/getTheme.action"
import { getPackageListAction } from "../../../store/actions/packageList.action"

const style = (theme) => ({
	root: {
		backgoundColor: "ff8362",
		boxShadow: "none",
		flexGrow: 1,
	},
	headerLinks: {
		color: "#616e78",
		textDecoration: "none",
		padding: "0 1rem",

		"&:active": {
			color: "#ff8362",
		},
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	title: {
		listStyle: "none",
		display: "inline",
		margin: "0",
		padding: "0",
		width: "100%",
		color: "#ff8362",
		pressable: true,
		init: { scale: 1 },
		press: { scale: 0.8 },
	},
	spacer: {
		flex: 0.8,
	},
	toolbar: {
		top: 0,
		margin: 0,

		boxShadow: "none",
	},
	SiderDrawer: {
		trasition: "0.3s ease-out",
	},
	MenuIcon: {
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
		color: "#fff",
	},
	sideDrawerLinks: {
		listStyle: "none",
		display: "inline-block",
		textDecoration: "none",
		margin: "0",

		color: "#16222b",
	},
	filter: {
		minHeight: 80,
		alignItems: "flex-start",
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	customizeButton: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
})

class HeaderSection extends Component {
	state = {
		sideDrawer: false,
		monthData: "",
		themeData: [],
		inclusionData: [],
		monthName: [],
		budget: "",
		themeName: [],
		themestate: "",
		inclusionName: [],
		inclusionid: "",
		inclusionstate: "",
		open: false,
	}

	DrawerToggleHandler = () => {
		this.setState({
			sideDrawer: true,
		})
	}
	handleDrawerClose = () => {
		this.setState({
			sideDrawer: false,
		})
	}
	handleMonth = (data) => {
		this.setState({
			monthData: data,
		})
	}
	handleTheme = (data) => {
		this.setState({
			themeData: data,
			themestate: this.state.themeName,
		})
	}

	handleInclusion = (data) => {
		this.setState({
			inclusionData: data,
		})
	}
	handleClick = () => {
		this.setState({
			open: true,
		})
	}
	handleClose = () => {
		this.setState({
			open: false,
		})
	}
	render() {
		const { classes } = this.props

		return (
			<React.Fragment>
				<Grid md={12} item justify="flex-start">
					<AppBar
						position="fixed"
						style={{
							background: "#fff",
							boxShadow: this.state.visible ? "none" : " 2px 1px 7px 0px rgba(0,0,0,0.12)",
						}}>
						<Toolbar className={classes.toolbar}>
							<Container>
								<Grid container justify="space-between">
									<Grid xs={12} md={6} item>
										<div style={{ display: "flex" }}>
											<NavLink
												to="/"
												style={{
													textDecoration: "none",
													color: !this.state.visible ? "#ff8362" : "#fff",
												}}>
												<img src={Logo} height={30} width={132} alt="logo" style={{ objectFit: "contain" }} />
											</NavLink>
										</div>
									</Grid>
								</Grid>
							</Container>
						</Toolbar>
					</AppBar>
					<BecomeAnAgent open={this.state.open} onClose={this.handleClose} />
				</Grid>
			</React.Fragment>
		)
	}
}
// export default withStyles(style, { withTheme: true })(HeaderSection);
const mapStateToProps = ({
	getMonthReducers,

	getInclusionReducer,
	getThemeReducer,
	getPackageListReducer,
	postCompareListReducer,
}) => {
	return {
		_months: getMonthReducers,
		_inclusion: getInclusionReducer,
		_theme: getThemeReducer,
		_packageList: getPackageListReducer,
		_comparelist: postCompareListReducer,
	}
}
export default withRouter(
	connect(mapStateToProps, {
		getMonthAction,
		getInclusionAction,
		getThemeAction,
		getPackageListAction,
	})(withStyles(style)(HeaderSection))
)
