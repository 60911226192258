import * as types from "../action.types";

export const getMetaDetailAction = data => {
  return {
    type: types.GET_META,
    data
  };
};

export const getMetaDetailSuccessAction = data => {
  return {
    type: types.GET_META_SUCCESS,
    data
  };
};
export const getMetaResponseNullAction = () => {
  return {
    type: types.GET_RESPONSE_NULL
  };
};
