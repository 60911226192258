import * as types from "../action.types";

export const getRequestPackageReducer = (state = {}, action) => {
  const response = action.data;
  switch (action.type) {
    case types.GET_REQUEST_PACKAGE_SUCCESS:
      return response;
    case types.GET_REQUEST_PACKAGE_ERROR:
      return state;
    default:
      return state;
  }
};
