import { put, call } from "redux-saga/effects";
import { getPopulaPackageService } from "../services/getPopularPackage.service";

import { getPopularPackageActionSuccess } from "../actions/getpopularpackages.action";
import * as types from "../action.types";
export function* getPopularPackageSaga() {
    try {
        const response = yield call(getPopulaPackageService);
        
        yield put(getPopularPackageActionSuccess(response.data));

    } catch (error) {
        yield put({ type: types.GET_POPULAR_PACKAGES_ERROR, error });
    }
}
