import { put, call } from "redux-saga/effects";
import { agentService } from "../services/agent.service";
// import { resetErrorAction } from "../actions/agent.action";
import { agentActionSuccess } from "../actions/agent.action";
import * as types from "../action.types";
export function* agentSaga(data) {
  try {
    const response = yield call(agentService, data);

    if (response.status === 200) {
      yield put(agentActionSuccess(response.data));
    } else if (response.status === 401) {
      yield put(agentActionSuccess(response.data));
    }
  } catch (error) {
    yield put({ type: types.GET_BECOME_AGENT_ERROR, error });
  }
}
