import React, {
  useState,
  useEffect,
  lazy,
  Suspense,
  useContext,
  useRef,
  useMemo,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import WhatsAppIconMobile from "../assets/images/whatsapp (1).png";
import "./homepage.css";
import { homePageWidgetList } from "../components/Services/services";
import PageLoader from "../components/loader/PageLoader";
import { Tracker } from "../../tracking/tracker";
import { getTrackingPageName, TrackingPageName } from "../../tracking/trackingConstants";
import { TrackingOrigin } from "../../tracking/trackingConstants";
import CustomizeForm from "../components/header/customizeForm";
import { getHomepageMetaDta } from "../components/Services/metaTagsServices";
import TestimonialComponent from "../components/TestimonialComponent/TestimonialComponent";
import RegistrationFormMobile from "../components/Banner/RegistrationFormMobile";
import { Helmet } from "react-helmet";
import { NavigationContext } from "../navigation/navigationContext";
import { PageScrollEvent } from "../../tracking/events/pageScrollEvent";
import { HomeWidgetItemClickedEvent } from "../../tracking/events/homeWidgetItemClickedEvent";
import { WidgetScrollEvent } from "../../tracking/events/widgetScrollEvent";
import { HomeWidgetItemClickedNamedEvent } from "../../tracking/events/homeWidgetItemClickedNamedEvent";
import CarouselFive from "../components/CarouselFive/CarouselFive";
import SimpleDialogDemo from "../components/ModalComponent/ModalComponent";
import ModalComponent from "../components/ModalComponent/ModalComponent";
import LoginUser from "../components/Auth/Login/LoginUser";
import { convertUrlToPageName } from "../../utils/utility";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import HomePageLeadForm from "../components/HomepageLeadForm/HomePageLeadForm";
import { whatsappMessageUtil } from "../../utils/whatsappUtil";
import { whatsappButtonClickedEvent } from "../../tracking/events/whatsappButtonClickedEvent";
import { clickScrollToLeadFormEvent } from "../../tracking/events/clickScrollToLeadFormEvent";

const CarouselOne = lazy(() => import("../components/CarouselOne/CarouselOne"));
const Navbar = lazy(() => import("../components/navbar/Navbar"));
const Hero = lazy(() => import("../components/Hero/Hero"));
const SegmentOne = lazy(() => import("../components/SegmentOne/SegmentOne"));
const CarouselThree = lazy(() =>
  import("../components/CarouselThree/CarouselThree")
);
const CarouselFour = lazy(() =>
  import("../components/CarouselFour/CarouselFour")
);
const CarouselTwo = lazy(() => import("../components/CarouselTwo/CarouselTwo"));
const TrustOneComponent = lazy(() =>
  import("../components/TrustOneComponent/TrustOneComponent")
);
const Footer = lazy(() => import("../components/footer/footer"));

function HomePage() {
  const location = useLocation();
  const history = useHistory();

  const pageName = convertUrlToPageName(location.pathname)

  const [homepageData, setHomepageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCustomizeForm, setShowCustomizeForm] = useState(false);
  const [showMobileForm, setShowMobileForm] = useState(false);
  const [metaDataDetails, setMetaDataDetails] = useState({});
  const { browsingPage, setBrowsingPage } = useContext(NavigationContext);
  const [impressions, setImpressions] = useState([]);
  const [lastWidgetType, setLastWidgetType] = useState("");
  const [lastWidgetIdentifier, setLastWidgetIdentifier] = useState("");
  const [lastWidgetIndex, setLastWidgetIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const impressionsRef = useRef(impressions); // Create a ref for impressions
  const lastWidgetIdentifierRef = useRef(lastWidgetIdentifier);
  const lastWidgetIndexRef = useRef(lastWidgetIndex);
  const lastWidgetTypeRef = useRef(lastWidgetType);
  const totalPagesRef = useRef(totalPages);

  let lastCallTimestamp = Date.now(); // Initialize with the current time

  // Ref to store the timeout ID
  const scrollEndTimeoutRef = useRef(null);

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const homePageLeadFormRef = useRef(null);

  const scrollToLeadForm = (homePageLeadFormRef) => {
    if (homePageLeadFormRef.current) {
      const elementTop =
        homePageLeadFormRef.current.getBoundingClientRect().top;
      const offset =
        window.innerHeight / 2 - homePageLeadFormRef.current.offsetHeight / 2;
      window.scrollTo({
        top: window.pageYOffset + elementTop - offset,
        behavior: "smooth",
      });
    }
    Tracker.getInstance().trackEvent(new clickScrollToLeadFormEvent());
  };
  const getHomePageData = async (pageName) => {
    setLoading(true);
    const response = await homePageWidgetList(pageName);
    if (response.status === 200) {
      setLoading(false);
      setHomepageData(response.data.data.widgets);
      setMetaDataDetails(response.data.data.meta);
    }
    if (response.status === 404) {
      history.push("/");
      window.location.reload();
    }
  };

  const handleScroll = () => {
    // Clear the existing timeout to reset the timer
    if (scrollEndTimeoutRef.current) {
      clearTimeout(scrollEndTimeoutRef.current);
    }

    // Set a new timeout
    scrollEndTimeoutRef.current = setTimeout(() => {
      if (impressionsRef.current.length > 0) {
        sendHomePageScrollEvent();
        setImpressions([]);
      }
    }, 150); // 150ms after the user stops scrolling, it is considered as scroll end
  };

  const addToImpressions = (widgetIdentifier, type, index, totalPage) => {
    setImpressions((prevImpressions) => [...prevImpressions, widgetIdentifier]);

    if ((widgetIdentifier, type, index, totalPage)) {
      setLastWidgetIdentifier(widgetIdentifier);
      setLastWidgetType(type);
      setLastWidgetIndex(index);
      setTotalPages(totalPage);
    }
  };

  useEffect(() => {
    setBrowsingPage(getTrackingPageName(location.pathname));
  }, []);

  useEffect(() => {
    if (pageName) {
      getHomePageData(pageName);
    }
  }, []);

  useEffect(() => {
    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener and clear timeout when the component unmounts
    return () => {
      // console.log("Scrolling listener stopped");
      window.removeEventListener("scroll", handleScroll);
      if (scrollEndTimeoutRef.current) {
        clearTimeout(scrollEndTimeoutRef.current);
      }
    };
  }, []); // Empty dependency array means this effect runs only once on mount

  // Update the ref whenever impressions changes
  useEffect(() => {
    impressionsRef.current = impressions;
  }, [impressions]);
  useEffect(() => {
    lastWidgetIdentifierRef.current = lastWidgetIdentifier;
    lastWidgetIndexRef.current = lastWidgetIndex;
    lastWidgetTypeRef.current = lastWidgetType;
    totalPagesRef.current = totalPages;
    return () => {
      lastWidgetIdentifierRef.current = null;
      lastWidgetIndexRef.current = 0;
      lastWidgetTypeRef.current = null;
      totalPagesRef.current = 0;
    };
  }, [lastWidgetIdentifier, lastWidgetIndex, lastWidgetType, totalPages]);

  // arranging api data into acending order------
  const sortedSections = homepageData.sort(
    (a, b) => a.display_order - b.display_order
  );

  const handleHomePageWidgetClick = (
    widgetType,
    widgetIdentifier,
    widgetItemTitle1,
    widgetItemTitle2
  ) => {
    Tracker.getInstance().trackEvent(
      new HomeWidgetItemClickedEvent(
        widgetType,
        widgetIdentifier,
        widgetItemTitle1,
        widgetItemTitle2
      )
    );

    Tracker.getInstance().trackEvent(
      new HomeWidgetItemClickedNamedEvent(
        widgetType,
        widgetIdentifier,
        widgetItemTitle1,
        widgetItemTitle2
      )
    );
  };
  // const totalPageSizeRef=useRef(sortedSections)

  const sendHomePageScrollEvent = () => {
    // Impressions
    const impressions = impressionsRef.current;
    const widgetsType = lastWidgetTypeRef.current;
    const widgetIdentifier = lastWidgetIdentifierRef.current;
    const widgetIndex = lastWidgetIndexRef.current;
    const totalPages = totalPagesRef.current;
    const currentTime = Date.now();

    const timeSpent = currentTime - lastCallTimestamp;
    // console.log(`Time spent since last call: ${timeSpent} ms`);
    Tracker.getInstance().trackEvent(
      new PageScrollEvent(
        widgetsType,
        widgetIdentifier,
        widgetIndex + 1,
        totalPages,
        impressions,
        timeSpent
      )
    );

    lastCallTimestamp = currentTime;
  };

  const handleWidgetScrollEvent = (
    tracking_identifier,
    type,
    pagenumber,
    totalpage,
    sliderImpressions
  ) => {
    Tracker.getInstance().trackEvent(
      new WidgetScrollEvent(
        type,
        tracking_identifier,
        pagenumber,
        totalpage,
        sliderImpressions
      )
    );
  };

  const renderSection = (section, index) => {
    switch (section.type) {
      case "banner_1":
        return (
          <Hero
            data={section.data}
            onVisibile={() => {
              addToImpressions(
                section.tracking_identifier,
                section.type,
                index,
                sortedSections.length
              );
            }}
          />
        );
      case "carousel_1":
        return (
          <CarouselOne
            data={section.data}
            onVisibile={() => {
              addToImpressions(
                section.tracking_identifier,
                section.type,
                index,
                sortedSections.length
              );
            }}
            onItemClick={(itemTitle) => {
              handleHomePageWidgetClick(
                section.type,
                section.tracking_identifier,
                itemTitle
              );
            }}
            onWidgetButtonClick={(lastindex, sliderImpressions) => {
              handleWidgetScrollEvent(
                section.tracking_identifier,
                section.type,
                lastindex + 1,
                section.data?.list?.items.length,
                sliderImpressions
              );
            }}
          />
        );
      case "segment_1":
        return (
          <SegmentOne
            data={section.data}
            onItemClick={(segmentTitle, itemTitle) => {
              handleHomePageWidgetClick(
                section.type,
                section.tracking_identifier,
                segmentTitle,
                itemTitle
              );
            }}
            onVisibile={() => {
              addToImpressions(
                section.tracking_identifier,
                section.type,
                index,
                sortedSections.length
              );
            }}
          />
        );
      case "carousel_3":
        return (
          <CarouselThree
            data={section.data}
            onItemClick={(widgetItemTitle) => {
              handleHomePageWidgetClick(
                section.type,
                section.tracking_identifier,
                widgetItemTitle
              );
            }}
            onVisibile={() => {
              addToImpressions(
                section.tracking_identifier,
                section.type,
                index,
                sortedSections.length
              );
            }}
          />
        );
      case "carousel_4":
        return (
          <CarouselFour
            data={section.data}
            onItemClick={(itemTitle) => {
              handleHomePageWidgetClick(
                section.type,
                section.tracking_identifier,
                itemTitle
              );
            }}
            onVisibile={() => {
              addToImpressions(
                section.tracking_identifier,
                section.type,
                index,
                sortedSections.length
              );
            }}
          />
        );
      case "carousel_2":
        return (
          <CarouselTwo
            data={section.data}
            onVisibile={() => {
              addToImpressions(
                section.tracking_identifier,
                section.type,
                index,
                sortedSections.length
              );
            }}
          />
        );
      case "trust_1":
        return (
          <TrustOneComponent
            data={section.data}
            onVisibile={() => {
              addToImpressions(
                section.tracking_identifier,
                section.type,
                index,
                sortedSections.length
              );
            }}
          />
        );
      case "trust_2":
        return (
          <TestimonialComponent
            data={section.data}
            onItemClick={(itemTitle) => {
              handleHomePageWidgetClick(
                section.type,
                section.tracking_identifier,
                itemTitle
              );
            }}
            onVisibile={() => {
              addToImpressions(
                section.tracking_identifier,
                section.type,
                index,
                sortedSections.length
              );
            }}
          />
        );
      case "carousel_5":
        return (
          <CarouselFive
            data={section.data}
            onItemClick={(itemTitle) => {
              handleHomePageWidgetClick(
                section.type,
                section.tracking_identifier,
                itemTitle
              );
            }}
          />
        );
      case "lead_1":
        return (
          <HomePageLeadForm
            ref={homePageLeadFormRef}
            data={section.data}
            onItemClick={(itemTitle) => {
              handleHomePageWidgetClick(
                section.type,
                section.tracking_identifier,
                itemTitle
              );
            }}
            pageName={pageName}
          />
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleWhatsAppClick = () => {
    whatsappMessageUtil();
    Tracker.getInstance().trackEvent(
      new whatsappButtonClickedEvent("floating")
    );
  };
  return (
    <>
      <Helmet>
        <title>{metaDataDetails ? metaDataDetails.title : ""}</title>
        <meta charset="UTF-8" />
        <meta
          name="title"
          content={metaDataDetails ? metaDataDetails.title : null}
        />
        <meta
          name="description"
          content={metaDataDetails ? metaDataDetails.description : ""}
        />
        {/* <meta
          name="keywords"
          content={metaDataDetails ? metaDataDetails.meta_keyword : ""}
        /> */}
        <meta name="author" content="Tripstore" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <CustomizeForm
        openForm={showCustomizeForm}
        onClose={() => setShowCustomizeForm(false)}
        origin={TrackingOrigin.LeadForm.HomePage}
      />
      <RegistrationFormMobile
        open={showMobileForm}
        onClose={() => setShowMobileForm(false)}
        origin={TrackingOrigin.LeadForm.PackageList}
      />

      <div className="home-container">
        <Suspense fallback={<PageLoader />}>
          {loading ? (
            <PageLoader />
          ) : (
            <>
              {" "}
              <Navbar
                onCustomizeFormClick={() => setShowCustomizeForm(true)}
                onMobileCustomizeFormClick={() => setShowMobileForm(true)}
                shouldHideOnScroll={true}
                backgroundColor={"transparent"}
              />
              {sortedSections.map((section, index) => {
                const sectionComponent = renderSection(section, index);
                return sectionComponent ? (
                  <div
                    className="widgets-container"
                    key={section.tracking_identifier}
                    id={section.tracking_identifier}
                  >
                    {sectionComponent}
                  </div>
                ) : (
                  ""
                );
              })}
              <Footer />
            </>
          )}
        </Suspense>
        { homePageLeadFormRef.current ? (<button
          onClick={() => {
            scrollToLeadForm(homePageLeadFormRef);
          }}
          className="scroll-to-lead-form"
        >
          <ExpandLessIcon />
        </button>) : null}
        <button
          onClick={handleWhatsAppClick}
          className="sticky-whatsapp-button"
        >
          <img
            src={WhatsAppIconMobile}
            alt="whatsapp-image"
            className="homepage-whatsapp-call"
          />
        </button>
      </div>
    </>
  );
}

export default HomePage;
