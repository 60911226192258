import apiUrl from "../urls/apiUrls";

export const getScreenSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};
export const LeadSource = {
  ON_PAGE: "on_page",
  CUSTOMISE: "customise",
  PACKAGE_ENQUIRY: "package_enquiry",
};

export const getSavingAmount = (retailPrice, listingPrice) => {
  return parseFloat(retailPrice) - parseFloat(listingPrice);
};

export const capitalizeSentences = (text) => {
  if (text) {
    const textWithSpaces = text.replace(/_/g, " ");
    const sentences = textWithSpaces?.match(/[^.!?]+[.!?]*\s*/g);

    const capitalizedSentences = sentences.map((sentence) =>
      sentence
        .trim()
        .toLowerCase()
        .replace(/(?:^|\s+)(\w)/g, function (match, group1) {
          return match.replace(group1, group1.toUpperCase());
        })
    );

    return capitalizedSentences.join(" ");
  }
};

export const hideTourOprator = (str) => {
  return str
    .split(" ")
    .map((word) => {
      if (word.length <= 1) {
        return word; // If the word is 1 character or less, return it as is
      }

      let visiblePart = word.substring(0, 1); // Get the first character
      let maskedPart = "x".repeat(4); // Create a string of four crosses

      return visiblePart + maskedPart; // Combine the visible and masked parts
    })
    .join(" ");
};

export const convertUrlToPageName = (pathName) => {
  if (pathName === "" || pathName === "/") {
    return "home";
  } else {
    const match = pathName.match(/^\/([a-zA-Z]{1,3})\/(.*)$/);

    if (match) {
      const components = pathName
        .split("/")
        .filter((component) => component !== "");
      const convertedString = components.join("_");

      return convertedString;
    }
  }
  return null;
};

export const getFullUrlPath = (location) => {
  const fullPath = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;
  return fullPath;
};

export const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};
export const bookingIntentObj = {
  status: "Ok",
  message: "Booking Intent",
  data: {
    package: {
      id: 2479,
      name: "EUROPEAN DISCOVERER",
      slug: "european-discoverer",
      agent_info: {
        id: 226,
        name: "Cost Saver",
        rating: 4.3,
      },
      itinerary_info: {
        days_count: 29,
        nights_count: 28,
        cities_count: 17,
        countries_count: 9,
        cities: [
          {
            name: "Amsterdam",
            nights: 2,
          },
          {
            name: "Rhineland",
            nights: 1,
          },
          {
            name: "Lucerne",
            nights: 2,
          },
          {
            name: "Venice",
            nights: 2,
          },
          {
            name: "Plitvica Selo",
            nights: 1,
          },
          {
            name: "Dubrovnik",
            nights: 2,
          },
          {
            name: "Tirana",
            nights: 2,
          },
          {
            name: "Kalabaka",
            nights: 1,
          },
          {
            name: "Athens",
            nights: 4,
          },
          {
            name: "Rome",
            nights: 2,
          },
          {
            name: "Florence",
            nights: 1,
          },
          {
            name: "Nice",
            nights: 1,
          },
          {
            name: "French Riviera",
            nights: 1,
          },
          {
            name: "Barcelona",
            nights: 2,
          },
          {
            name: "Pamplona",
            nights: 1,
          },
          {
            name: "Bordeaux",
            nights: 1,
          },
          {
            name: "Paris",
            nights: 2,
          },
        ],
      },
      inclusions: [
        {
          name: "Hotel",
          icon: "https://static.tripstoreholidays.com/package-list-icons/Icon Hotel.svg",
        },
        {
          name: "Meal",
          icon: "https://static.tripstoreholidays.com/package-list-icons/Icon_Meal.svg",
        },
        {
          name: "Sightseeing",
          icon: "https://static.tripstoreholidays.com/package-list-icons/IconSightseeing.svg",
        },
        {
          name: "Transport",
          icon: "https://static.tripstoreholidays.com/package-list-icons/Icon_Transport.svg",
        },
      ],
      sale_info: {
        retail_price: {
          amount: 509575,
          currency: "INR",
        },
        list_price: {
          amount: 436730,
          currency: "INR",
        },
        discountPercentage: 14,
        discountColor: "#DD3110",
      },
      images: [
        "https://images.tripstoreholidays.com/package-images/480/6576da2e95c9a.webp",
        "https://images.tripstoreholidays.com/package-images/480/6576da2ea54e3.webp",
        "https://images.tripstoreholidays.com/package-images/480/6576da2eb45b1.webp",
        "https://images.tripstoreholidays.com/package-images/480/6576da2ec3590.webp",
        "https://images.tripstoreholidays.com/package-images/480/6576da2ed3c77.webp",
        "https://images.tripstoreholidays.com/package-images/480/6576da2ee4648.webp",
      ],
      highlights: {
        badge: "Value",
        color: "#e6744e",
      },
    },
    availability: {
      id: 1,
      date: "2025-11-25",
    },
    profile: {
      id: 66,
      name: "Somebody else",
      email: "some@tripstoreholidays.com",
      country_code: "91",
      phone_number: "7506886030",
      status: "verified",
      is_whatsapp_enabled: true,
    },
    adults_count: 2,
  },
};
