import axios from "axios"
export const getMonthService = () => {
        return axios
                .get("/get/Months")
                .then((response) => {
                        return response
                })
                .catch((err) => {
                        return err.response
                })
}
