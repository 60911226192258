import { FilterRequestItem } from "./filterRequestItem"

export class FilterRequest {
	constructor(filters, fetchAll = false) {
		this.filters = filters
		this.all = fetchAll
	}

	static fromURLSearchParams = (urlParams) => {
		const filters = []
		// Iterate through the keys of the URLSearchParams object
		urlParams.forEach((value, key) => {
			// Create a new FilterRequestItem instance and add it to the filters array
			filters.push(new FilterRequestItem(key, value.split(":")))
		})

		const all_query_param = "all"
		const all_query_value = urlParams.get(all_query_param) || false;

		return new FilterRequest(filters, all_query_value)
	}

	queryParams = () => {
		return { filters: this.filters, all: this.all }
	}

	buildTypeValuesMap = () => {
		const typeValuesMap = {}

		// Iterate through the array of FilterRequestItem instances
		this.filters.forEach((filter) => {
			if (filter instanceof FilterRequestItem) {
				// Check if the filter has a type and values
				if (filter.type && filter.values) {
					// Add an entry to typeValuesMap
					if (!typeValuesMap[filter.type]) {
						typeValuesMap[filter.type] = []
					}
					typeValuesMap[filter.type].push(...filter.values)
				}
			}
		})

		return typeValuesMap
	}
}
