import axios from "axios"
export const getOtherDestinationService = () => {
        return axios
                .get("/destination/Region/Others")
                .then((response) => {
                        return response
                })
                .catch((err) => {
                        return err.response
                })
}
