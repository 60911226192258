// import React from "react";
// import styled from "styled-components";
// import Skeleton from "react-loading-skeleton";
// import { Fade } from "react-slideshow-image";
// import BannerImage from "../../assets/images/newBanner.png";
// const Banner = styled.div`
//   background: linear-gradient(rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42)),
//     ${(props) => `url(${props.backgroundImg}) no-repeat right center `};
//   height: 580px;
//   @media (max-width: 900px) {
//     height: 60vh;
//     background: linear-gradient(rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42)),
//       ${(props) =>
//         `url(${props.backgroundImg}) no-repeat  right -30px  center `};
//     background-size: cover;
//   }
//   background-size: cover;
//   width: "100%";
//   position: relative;
// `;

// class DemoAutoPlay extends React.Component {
//   state = {
//     index: 0,
//   };

//   handleChangeIndex = (index) => {
//     this.setState({
//       index,
//     });
//   };

//   render() {
//     const { image } = this.props;
//     const data = image && image.data;
//     if (!data) {
//       return <Skeleton height="60vh" />;
//     }
//     const properties = {
//       duration: 5000,
//       transitionDuration: 600,
//       infinite: true,
//       arrows: false,

//       // onChange: (oldIndex, newIndex) => {},
//     };

//     return (
//       // <Fade {...properties}>
//       //   {data && data.length !== undefined
//       //     ? data &&
//       //       data.map((obj, i) => (
//       //         <div>
//       //           <Banner
//       //             key={i}
//       //             // backgroundImg={obj.image_name}
//       //             backgroundImg={BannerImage}
//       //           />
//       //         </div>
//       //       ))
//       //     : ""}

//       // </Fade>
//       <div>
//         <Banner
//           // key={i}
//           // backgroundImg={obj.image_name}
//           backgroundImg={BannerImage}
//         />
//         {/* <img src={BannerImage} /> */}
//       </div>
//     );
//   }
// }

// export default DemoAutoPlay;
import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import { Fade } from "react-slideshow-image";

const Banner = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.52)),
    ${(props) => `url(${props.backgroundImg}) no-repeat top center`};
  height: 580px;
  @media (max-width: 900px) {
    height: 500px;
  }
  background-size: cover;
  width: "100%";
  position: relative;
`;

class DemoAutoPlay extends React.Component {
  state = {
    index: 0,
  };

  handleChangeIndex = (index) => {
    this.setState({
      index,
    });
  };

  render() {
    const { image } = this.props;
    const data = image && image.data;
    if (!data) {
      return <Skeleton height="60vh" />;
    }
    const properties = {
      duration: 5000,
      transitionDuration: 600,
      infinite: true,
      arrows: false,

      // onChange: (oldIndex, newIndex) => {},
    };

    return (
      <Fade {...properties}>
        {data && data.length !== undefined
          ? data &&
            data.map((obj, i) => (
              <div>
                <Banner key={i} backgroundImg={obj.image_name} />
              </div>
            ))
          : ""}
      </Fade>
    );
  }
}

export default DemoAutoPlay;
