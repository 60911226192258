import mixpanel from "mixpanel-browser"

export class MixpanelTracker {
	configure() {
		// Initialize Mixpanel
		const is_debug = !(process.env.REACT_APP_NODE_ENV === "production")
		mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: is_debug, persistence: "localStorage" })
	}

	setUserIdentifier(phoneNumber) {
		mixpanel.identify(phoneNumber)
		mixpanel.people.set({ $phone: phoneNumber})
	}

	setUserProperties(name, email) {
		mixpanel.people.set({ $name: name, $email: email })
	}

	logout() {
		mixpanel.reset()
	}

	/**
	 *
	 * @param TrackableEvent event
	 */
	trackEvent(event) {
		mixpanel.track(event.name, event.properties)
	}

	/**
	 *
	 * @param TrackableEvent event
	 */
	trackPageviewEvent(event) {
		mixpanel.track_pageview(event.properties)
	}
}
