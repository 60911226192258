import { TrackerPropertyKey, TrackingEventName } from "../../trackingConstants"
import { TrackableEvent } from "../trackableEvent"

export class ListingAddToCompareEvent extends TrackableEvent {
	constructor(id, title, position, slug, price) {
		let properties = {}
		properties[TrackerPropertyKey.PackageId] = id
		properties[TrackerPropertyKey.PackageTitle] = title
		properties[TrackerPropertyKey.PackagePosition] = position
		properties[TrackerPropertyKey.PackageSlug] = slug
		properties[TrackerPropertyKey.PackagePrice] = price

		super(TrackingEventName.ListingAddToCompareClick, properties)
	}
}
