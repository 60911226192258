import axios from "axios"

export const getPackageListingService = (request) => {
	return axios
		.get("/package/listing", {
			params: request.data,
		})
		.then((response) => {
			return response.data
		})
		.catch((err) => {
			return err.response.data
		})
}
