export class WhatsappUtil {
  openWhatsappForPackage = (name, price, url) => {
    const message = `Hi Trip Store Team,\nI would like to know more about this package:\n\nName: ${name}\nPrice: ${price}\nURL: ${url}`;
    const waUrl = `https://wa.me/919209200041?text=${encodeURIComponent(
      message
    )}`;
    window.open(waUrl, "_blank");
  };
}

export const whatsappMessageUtil = () => {
  const message = `Hi Trip Store Holidays,\n\nI’m planning my next vacation. I would like to inquire about a tour with you.`;
  const waUrl = `https://wa.me/919209200041?text=${encodeURIComponent(message)}`;
  window.open(waUrl, "_blank");
};
