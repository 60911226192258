import * as types from "../action.types";

export const getMonthAction = () => {
    return {
        type: types.GET_MONTHS
    };
};

export const getMonthActionSucess = values => {
    return {
        type: types.GET_MONTHS_SUCCESS,
        values
    }
}