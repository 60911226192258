import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "./input.css";
const UseStyles = makeStyles((theme) => ({
  input: {
    "&::placeholder": {
      color: "#bbc2c8",
    },
    fontSize: "14px",
    color: "#616e78",
    // height: 50
  },
}));

const InputField = ({
  field,
  form: { touched, errors, ...form },
  ...props
}) => {
  const error = errors[field.name];
  const classes = UseStyles();
  return (
    <TextField
      variant="outlined"
      margin="dense"
      error={touched && error ? true : false}
      helperText={touched && error ? error : null}
      fullWidth
      {...field}
      {...props}
      InputProps={{
        className: classes.input,
      }}
    />
  );
};

export default InputField;
