import React, { useState, useEffect, useRef } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { Typography, Card, Icon, Button } from "@material-ui/core";
import "./searchbar.css";
import PlaceIcon from "@material-ui/icons/Place";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { Tracker } from "../../../tracking/tracker";
import { SearchIntentStartEvent } from "../../../tracking/events/searchIntentStartEvent";
import { SearchIntentEndEvent } from "../../../tracking/events/searchIntentEndEvent";
import { SearchCompleteEvent } from "../../../tracking/events/searchCompleteEvent";
import { makeStyles } from "@material-ui/core/styles";
import {
  PrimaryButton,
  PrimaryDisabledButton,
  PrimarySubmitButton,
} from "../../../utils/Buttons";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import place from "../../assets/images/placeholder.png";
import calender from "../../assets/images/calendar.png";
import budget from "../../assets/images/money-bag.png";
import MobileSearchBar from "./MobileSearchBar";

const style = makeStyles((theme) => ({
  textField: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: window.innerWidth < 768 ? ".8rem" : "1rem",
    },
  },
  input: {
    marginBottom: 10,
    outline: "none",
  },
  button: {},
  search: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 10,
    "&:hover": {
      background: "none",
    },
  },
  cards: {
    width: "100%",
    vertical: "top",
    horizontal: "left",
    top: "10px",
    zIndex: 5,
    maxHeight: "280px",
    position: "relative",

    overflowY: "scroll",
    borderRadius: "10px",
    transition: "all 0.8s ",
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "100%",
    height: "100%",
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1, 3, 1),
    backgroundColor: "white",
  },
}));
var searchOptions = {
  types: ["(regions)"],
};

// Enum for search types
export const SearchType = {
  WHERE: "where",
  WHEN: "when",
  BUDGET: "budget",
};

function SearchBar({
  openSearchModalNav,
  handleOpenModalNav,
  handleCloseModalNav,
  destinationsList,
  monthsList,
  budgetsList,
  source
}) {
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [suggestionMetaData, setSuggestionsMetaData] = useState([]);
  const [budgetSuggestionsMetaData, setBudgetSuggestionsMetaData] = useState(
    []
  );
  const [datesSuggestionsMetaData, setDatesSuggestionsMetaData] = useState([]);
  const [isDatesListOpen, setIsDatesListOpen] = useState(false);
  const [isBudgetsListOpen, setIsBudgetsListOpen] = useState(false);
  const [tripDate, setTripDate] = useState("");
  const [tripBudget, setTripBudget] = useState("");
  const [isGoogleSearched, setIsGoogleSearched] = useState(false);
  const [cityName, setCityName] = useState("");
  const [countryName, setCountryName] = useState("");
  const [randomText, setRandomText] = useState("");
  const [address, setAddress] = useState("");
  const [isGoogleListOpen, setIsGoogleListOpen] = useState(false);
  const [isDestinationsListOpen, setIsDestinationsListOpen] = useState(false);
  const [isWhereAutoSuggestionOpen, setIsWhereAutoSuggestionOpen] =
    useState(false);
  const [isGoogleAutoSuggestionOpen, setIsGoogleAutoSuggestionOpen] =
    useState(false);
  const [searchTypeLebel, setSearchTypeLebel] = useState("");
  const [userQueryString, setUserQueryString] = useState("");
  const [searchEndLabel, setSearchEndLabel] = useState("");

  useEffect(() => {
    if (destinationsList) {
      const transformedArray = destinationsList.map((item) => ({
        description: item,
      }));
      setSuggestionsMetaData(transformedArray);
    }
  }, [destinationsList]);

  useEffect(() => {
    if (budgetsList) {
      setBudgetSuggestionsMetaData(budgetsList);
    }
  }, [budgetsList]);

  useEffect(() => {
    if (monthsList) setDatesSuggestionsMetaData(monthsList);
  }, [monthsList]);

  const handleSelect = async (value) => {
    setAddress(value);
    setRandomText(value);
    try {
      const results = await geocodeByAddress(value);
      let values = results[0].address_components;

      let city = "";
      let country = "";
      let locality = "";
      if (values) {
        for (let i = 0; i < values.length; i++) {
          if (values[i].types[0]) {
            const type = values[i].types[0];
            if (type === "administrative_area_level_2") {
              city = values[i].long_name;
            } else if (type === "country") {
              country = values[i].long_name;
            } else if (type === "locality") {
              locality = values[i].long_name;
            }
          }
        }
      }

      setCityName(locality ? locality : city);
      setCountryName(country);

      setIsDestinationsListOpen(false);
      setIsGoogleListOpen(false);
    } catch (err) {}
  };

  const handledestination = (selectedDestination) => {
    setIsWhereAutoSuggestionOpen(true);
    setAddress(selectedDestination);
    handleFocus(SearchType.WHEN);
  };

  const handleClickInput = (clickedLabel) => {
    setSearchTypeLebel(clickedLabel);
    switch (clickedLabel) {
      case SearchType.WHERE:
        Tracker.getInstance().trackEvent(
          new SearchIntentStartEvent(clickedLabel)
        );
        break;
      case SearchType.WHEN:
        Tracker.getInstance().trackEvent(
          new SearchIntentStartEvent(clickedLabel)
        );

        break;
      case SearchType.BUDGET:
        Tracker.getInstance().trackEvent(
          new SearchIntentStartEvent(clickedLabel)
        );

        break;
      default:
        Tracker.getInstance().trackEvent(
          new SearchIntentStartEvent(clickedLabel)
        );
        break;
    }
  };

  const getNavLinkQueryString = (searchQuery) => {
    const queryParams = [];
    const addParam = (key, value) =>
      value && queryParams.push(`${key}=${encodeURIComponent(value)}`);

    if (searchQuery) {
      const location =
        cityName && countryName
          ? `${cityName}, ${countryName}`
          : cityName || countryName;
      addParam("city", location);
      addParam("country", countryName);
      addParam("month", tripDate);
      addParam("price", tripBudget);
      if (!cityName || !countryName) addParam("query", randomText);
    } else {
      addParam("month", tripDate);
      addParam("destination", address);
      addParam("price", tripBudget);
    }

    return queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
  };

  const handleFocus = (searchType) => {
    setIsDestinationsListOpen(false);
    setIsGoogleListOpen(false);
    setIsDatesListOpen(false);
    setIsBudgetsListOpen(false);

    switch (searchType) {
      case SearchType.WHERE:
        setIsDestinationsListOpen(true);

        break;
      case SearchType.WHEN:
        setIsDatesListOpen(true);

        break;
      case SearchType.BUDGET:
        setIsBudgetsListOpen(true);

        break;
      default:
        break;
    }
  };

  const handleBlur = () => {
    setIsDestinationsListOpen(false);
    setIsGoogleListOpen(false);

    Tracker.getInstance().trackEvent(
      new SearchIntentEndEvent(
        searchTypeLebel ? searchTypeLebel : searchEndLabel,
        userQueryString,
        address,
        tripDate,
        tripBudget,
        isWhereAutoSuggestionOpen ? "YES" : null,
        isGoogleAutoSuggestionOpen ? "YES" : null
      )
    );
  };

  const handleOpenModal = (e) => {
 
    setOpenSearchModal(true);
    e.target.blur();
  };

  const handleCloseModal = () => {
    handleClearAllInputFields();
    setOpenSearchModal(false);
    handleBlur();
    handleCloseModalNav()
  };

  const classes = style();
  const handleChange = (address) => {
    setIsGoogleSearched(true);
    setAddress(address);
    setUserQueryString(address);
    if (address.length === 0) {
      setIsDestinationsListOpen(true);
      setIsGoogleListOpen(false);
    } else {
      setIsDestinationsListOpen(false);
      setIsGoogleListOpen(true);
      setIsGoogleAutoSuggestionOpen(true);
    }
    setRandomText(address);
  };
  const handleSearchBudgets = (e) => {
    const value = e.target.value;
    setTripBudget(value);
    setIsBudgetsListOpen(true);

    if (value.length > 0) {
      const suggestions = budgetSuggestionsMetaData.filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setBudgetSuggestionsMetaData(suggestions);
    } else {
      setBudgetSuggestionsMetaData(budgetsList);
    }
  };

  const handleSearchDates = (e) => {
    const value = e.target.value;
    setTripDate(value);
    setIsDatesListOpen(true);

    if (value.length > 0) {
      const suggestions = datesSuggestionsMetaData.filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setDatesSuggestionsMetaData(suggestions);
    } else {
      setDatesSuggestionsMetaData(monthsList);
    }
  };

  const dateswrapperRef = useRef(null);
  const budgetswrapperRef = useRef(null);
  const googlesearchwrapperRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dateswrapperRef.current &&
        !dateswrapperRef.current.contains(event.target)
      ) {
        setIsDatesListOpen(false);
      }
      if (
        budgetswrapperRef.current &&
        !budgetswrapperRef.current.contains(event.target)
      ) {
        setIsBudgetsListOpen(false);
      }
      if (
        googlesearchwrapperRef.current &&
        !googlesearchwrapperRef.current.contains(event.target)
      ) {
        setIsDestinationsListOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [budgetswrapperRef, dateswrapperRef, googlesearchwrapperRef]);

  const handleClearAllInputFields = () => {
    setAddress("");
    setTripDate("");
    setTripBudget("");
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  };
  const handleCompleteSearchEvent = () => {
    Tracker.getInstance().trackEvent(
      new SearchCompleteEvent(
        searchTypeLebel,
        userQueryString,
        address,
        tripDate,
        tripBudget,
        isWhereAutoSuggestionOpen ? "YES" : null,
        isGoogleAutoSuggestionOpen ? "YES" : null
      )
    );
    // window.location.reload()
  };
  return window.innerWidth <= 1000  ? (
    <MobileSearchBar
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
      openSearchModal={openSearchModal?openSearchModal:openSearchModalNav}
      handleChange={handleChange}
      handleSelect={handleSelect}
      address={address}
      isGoogleListOpen={isGoogleListOpen}
      handleFocus={handleFocus}
      handleBlur={handleBlur}
      isDestinationsListOpen={isDestinationsListOpen}
      isDatesListOpen={isDatesListOpen}
      setIsDatesListOpen={setIsDatesListOpen}
      setTripDate={setTripDate}
      isBudgetsListOpen={isBudgetsListOpen}
      setIsBudgetsListOpen={setIsBudgetsListOpen}
      suggestionMetaData={suggestionMetaData}
      handledestination={handledestination}
      datesSuggestionsMetaData={datesSuggestionsMetaData}
      handleSearchDates={handleSearchDates}
      tripDate={tripDate}
      getNavLinkQueryString={getNavLinkQueryString}
      isGoogleSearched={isGoogleSearched}
      budgetSuggestionsMetaData={budgetSuggestionsMetaData}
      handleSearchBudgets={handleSearchBudgets}
      tripBudget={tripBudget}
      setTripBudget={setTripBudget}
      handleClearAllInputFields={handleClearAllInputFields}
      dateswrapperRef={dateswrapperRef}
      budgetswrapperRef={budgetswrapperRef}
      googlesearchwrapperRef={googlesearchwrapperRef}
      handleCompleteSearchEvent={handleCompleteSearchEvent}
      handleClickInput={handleClickInput}
      handleMouseDown={handleMouseDown}
      setSearchEndLabel={setSearchEndLabel}
      setSearchTypeLebel={setSearchTypeLebel}
      source={source}
    />
  ) : source==="" ?(
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      searchOptions={searchOptions}
      shouldFetchSuggestions={true}
      highlightFirstSuggestion={true}
      onSelect={handleSelect}
      onBlur={handleBlur}
    >
      {({ getInputProps, value, suggestions, getSuggestionItemProps }) => (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            alignItems: "center",

            borderRadius: "10px",
          }}
        >
          <div className="input-field-container">
            <div className="input-field-icons-wrapper">
              <img src={place} alt="img" className="input-field-icons" />
            </div>
            <div
              className="input-field-text-wrapper"
              ref={googlesearchwrapperRef}
            >
              <label for="destinations" className="input-field-text">
                WHERE
              </label>
              <div style={{ position: "relative" }}>
                <input
                  onClick={() => handleClickInput(SearchType.WHERE)}
                  id="destinations"
                  className="input-field-search"
                  type="text"
                  {...getInputProps({
                    placeholder: "Select Your Destination",
                  })}
                  onFocus={() => handleFocus(SearchType.WHERE)}
                  onBlur={handleBlur}
                />
                <div
                  className="autocomplete-dropdown-container"
                  style={{
                    position: "absolute",

                    right: "15",
                    width: "130%",
                    zIndex: "1",
                  }}
                >
                  {isGoogleListOpen ? (
                    <Card
                      id="custom-scrollbar"
                      className={classes.cards}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {suggestions.map((suggestion) => {
                        return (
                          <div {...getSuggestionItemProps(suggestion, {})}>
                            <div
                              fullWidth
                              className="suggestion-list-items"
                              onClick={() => handleFocus(SearchType.WHEN)}
                            >
                              <PlaceIcon
                                style={{ fontSize: "16px", color: "#ff6840" }}
                              />

                              <Typography
                                style={{ color: "#16222b", fontSize: "14px" }}
                              >
                                {suggestion.description}
                              </Typography>
                            </div>
                          </div>
                        );
                      })}
                    </Card>
                  ) : isDestinationsListOpen ? (
                    <Card
                      id="custom-scrollbar"
                      className={`${classes.cards} animate-popup-animation`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {suggestionMetaData.map((suggestion) => {
                        return (
                          <div {...getSuggestionItemProps(suggestion, {})}>
                            <Button
                              fullWidth
                              style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                                cursor: 'pointer',
                                padding: '8px 5px',
                                textTransform: 'none',
                                // color: 'red',
                                // backgroundColor: '#16222b',
                              }}
                              // className="suggestion-list-items"
                             
                              onClick={() => {
                                handledestination(suggestion.description);
                              }}
                            >
                              <PlaceIcon
                                style={{ fontSize: "18px", color: "#ff6840" }}
                              />

                              <Typography
                                oncl
                                style={{ color: "#16222b", fontSize: "14px" }}
                              >
                                {suggestion.description}
                              </Typography>
                            </Button>
                          </div>
                        );
                      })}
                    </Card>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="input-field-container">
            {" "}
            <div className="input-field-icons-wrapper">
              <img src={calender} alt="img" className="input-field-icons" />
            </div>
            <div className="input-field-text-wrapper" ref={dateswrapperRef}>
              <label for="months" className="input-field-text">
                WHEN
              </label>
              <div style={{ position: "relative" }}>
                <input
                  id="months"
                  type="text"
                  value={tripDate}
                  onBlur={handleBlur}
                  onClick={() => handleClickInput(SearchType.WHEN)}
                  onChange={handleSearchDates}
                  onFocus={() => handleFocus(SearchType.WHEN)}
                  className="input-field-search"
                  placeholder="Select Your Month"
                  autoComplete="off"
                />
                {isDatesListOpen ? (
                  <Card
                    onClick={(e) => e.stopPropagation()}
                    id="custom-scrollbar"
                    style={{
                      position: 'absolute',
                      maxHeight: '280px',
                      top: '30px',
                      right: '0',
                      width: '100%',
                      zIndex: 1,
                      overflowY: 'scroll',
                    }}
                    className={`list-container-card animate-popup-animation`}
                    // style={{
                    //   position: "absolute",
                    //   top: "18",
                    //   right: "0",
                    //   width: "100%",
                    //   zIndex: "1",
                    // }}
                  >
                    {datesSuggestionsMetaData.map((suggestion) => {
                      return (
                        <div onMouseDown={handleMouseDown}>
                          <Button
                            fullWidth
                            className="suggestion-list-items"
                            onClick={() => {
                              setTripDate(suggestion);
                              setSearchEndLabel(SearchType.WHEN);
                              setSearchTypeLebel("");
                              // setIsDatesListOpen(false);
                              handleFocus(SearchType.BUDGET);
                            }}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              textTransform: "none",
                            }}
                          >
                            <DateRangeOutlinedIcon
                              style={{ fontSize: "14px", marginRight: "5px" }}
                            />

                            <Typography
                              style={{ color: "#16222b", fontSize: "12px" }}
                            >
                              {suggestion}
                            </Typography>
                          </Button>
                        </div>
                      );
                    })}
                  </Card>
                ) : null}
              </div>
            </div>
          </div>
          <div className="input-field-container">
            <div className="input-field-icons-wrapper">
              <img src={budget} alt="img" className="input-field-icons" />
            </div>
            <div className="input-field-text-wrapper" ref={budgetswrapperRef}>
              <label for="budget" className="input-field-text">
                BUDGET
              </label>
              <div style={{ position: "relative" }}>
                <input
                  id="budget"
                  type="text"
                  onBlur={handleBlur}
                  placeholder="Select Your Budget"
                  value={tripBudget}
                  onClick={() => handleClickInput(SearchType.BUDGET)}
                  onChange={handleSearchBudgets}
                  className="input-field-search"
                  onFocus={() => handleFocus(SearchType.BUDGET)}
                  autoComplete="off"
                />
                {isBudgetsListOpen ? (
                  <Card
                    onClick={(e) => e.stopPropagation()}
                    id="custom-scrollbar"
                    style={{
                      position: 'absolute',
                      maxHeight: '280px',
                      top: '30px',
                      right: '0',
                      width: '100%',
                      zIndex: 1,
                      overflowY: 'scroll',
                    }}
                    className="list-container-card animate-popup-animation"
                  >
                    {budgetSuggestionsMetaData?.map((suggestion) => {
                      return (
                        <div onMouseDown={handleMouseDown}>
                          <Button
                            className="suggestion-list-items"
                            fullWidth
                            onClick={() => {
                              setTripBudget(suggestion);
                              setIsBudgetsListOpen(false);
                              setSearchEndLabel(SearchType.BUDGET);
                              setSearchTypeLebel("");
                            }}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              textTransform: "none",
                            }}
                          >
                            <img
                              src={budget}
                              alt="img"
                              width="14px"
                              height="14px"
                              style={{ marginRight: "5px" }}
                            />

                            <Typography
                              style={{ color: "#16222b", fontSize: "12px" }}
                            >
                              {suggestion}
                            </Typography>
                          </Button>
                        </div>
                      );
                    })}
                  </Card>
                ) : null}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100px",
            }}
          >
            {tripDate || tripBudget || address ? (
              <NavLink
                to={`/packages${getNavLinkQueryString(isGoogleSearched)}`}
                style={{ textDecoration: "none" }}
              >
                <PrimarySubmitButton
                  onClick={() => {
                    getNavLinkQueryString(isGoogleSearched);
                    handleCompleteSearchEvent();
                  }}
                >
                  Explore
                </PrimarySubmitButton>
              </NavLink>
            ) : (
              <PrimaryDisabledButton>Explore</PrimaryDisabledButton>
            )}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  ):null;
}

export default SearchBar;
