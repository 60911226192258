import React from "react";
import ClearIcon from "@material-ui/icons/Clear";
import drawerLogo from "../../assets/images/drawerLogo.png";
import successImage from "../../assets/images/success.svg";
import { PrimarySubmitButton } from "../../../utils/Buttons";
import "./formsubmission.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function FormSubmissionSuccess({ userInfo, handleCloseCustomizeModal }) {

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userInfo) {
      handleCloseCustomizeModal();
    } else {
      handleCloseCustomizeModal();

      window.location.reload();
    }
  };

  return (
    <div className="animate-slide-up">
      <div className="customize-close-button">
        <div className="customize-cross-container">
          <ClearIcon
            style={{
              padding: "10px",

              cursor: "pointer",
            }}
            onClick={handleSubmit}
          />
        </div>

        <div class="customize-logo-header">
          <img src={drawerLogo} alt="Trip Store Logo" class="login-logo" />
        </div>
        <div style={{ flex: "4" }}></div>
      </div>
      <form className="request-call-form">
        <div class="success-image-container">
          <LazyLoadImage
            src={successImage}
            effect="blur"
            width="100%"
            loading="lazy"
            alt="success-image"
            class="success-image"
          />
        </div>
        <div className="success-content-container">
          <h4 className="success-heading-text">Always be trip-ing!!</h4>
          <p className="success-heading-sub-text">
            Thank you for sharing your details. Our team will reach out to you
            shortly.
          </p>
          <div style={{ padding: "10px 0px" }}>
            <PrimarySubmitButton onClick={handleSubmit}>
              Close
            </PrimarySubmitButton>
          </div>
        </div>
      </form>
    </div>
  );
}

export default FormSubmissionSuccess;
