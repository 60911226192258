import { TrackingEventName } from "../../trackingConstants"
import { TrackableEvent } from "../trackableEvent"

export class FilterAppliedEvent extends TrackableEvent {
	constructor(filters) {
                let properties = {}
                filters.forEach(element => {
                        properties[element.type] = element.values
                });
		super(TrackingEventName.FilterApplied, properties)
	}
}
