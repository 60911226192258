class NavigationStore {
  constructor() {
    this.originPage = null;
    this.browsingPage = null;
  }

  setOriginPage(page) {
    console.log('Setting origin page to', page);
    this.originPage = page;
  }

  setBrowsingPage(page) {
    console.log('Setting browsing page to', page);
    this.browsingPage = page;
  }

  getOriginPage() {
    return this.originPage;
  }

  getBrowsingPage() {
    return this.browsingPage;
  }
}

// Create a singleton instance
export const navigationStore = new NavigationStore();