import * as types from "../action.types";

export const getMonthReducers = (state = {}, action) => {
  const response = action.values;
  switch (action.type) {
    case types.GET_MONTHS_SUCCESS:
      return response;
    case types.GET_MONTHS_ERROR:
      return state;
    default:
      return state;
  }
};
