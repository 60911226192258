import { put, call } from "redux-saga/effects";
import { getPackageDetailService } from "../services/getPackageDetail.service";

import { getPackageDetailSuccessAction } from "../actions/getPackageDetail.action";
import * as types from "../action.types";
export function* getPackageDetailSaga(data) {
  try {
    const response = yield call(getPackageDetailService, data);
    
    if (response.status === 200) {
      yield put(getPackageDetailSuccessAction(response));
    }
  } catch (error) {
    yield put({ type: types.GET_PACKAGE_DETAILS_ERROR, error });
  }
}
