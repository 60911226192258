import * as types from "../action.types";

export const getOtherDestinationAction = () => {
    return {
        type: types.GET_OTHER_DESTINATION
    };
};
export const getOtherDestinationActionSuccess = values => {
    return {
        type: types.GET_OTHER_DESTINATION_SUCCESS,
        values
    };
};
