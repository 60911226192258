import { Grid, Typography, withStyles } from "@material-ui/core"
import React from "react"

const style = (theme) => ({
	text: {
		color: "#343434",
		fontSize: 12,
		fontWeight: 300,
		[theme.breakpoints.down("md")]: {
			fontSize: 14,
		},
	},
	circleBox: {
		width: "5px",
		height: "5px",
		marginLeft: theme.spacing(0.5),
		marginRight: theme.spacing(0.5),
		backgroundColor: "#959595",
		borderRadius: "50%",
	},
})

const CityList = (props) => {
	const { classes, cities } = props

	return (
		<React.Fragment>
			{cities && (
				<Grid container item>
					<Grid container item alignItems="center">
						{cities.map((obj, index) => (
							<React.Fragment key={index}>
								<Typography className={classes.text}>{obj.listingValue}</Typography>
								{index < cities.length - 1 ? <div className={classes.circleBox} /> : ""}
							</React.Fragment>
						))}
					</Grid>
				</Grid>
			)}
		</React.Fragment>
	)
}

export default withStyles(style)(CityList)
