import { TrackableEvent } from "./trackableEvent";
import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants";

export class ListingFilterChipClickEvent extends TrackableEvent {
  constructor(title, item_title,) {
    let properties = {};
    properties[TrackerPropertyKey.FilterTitle] = title;
    properties[TrackerPropertyKey.ItemTitle] = item_title;

    super(TrackingEventName.ListingFilterChipClick, properties);
  }
}
