import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import ProfileIcon from "../../../utils/ProfileIcon";
import { logoutUser } from "../Services/services";
import { UserContext } from "../hooks/UserContext";
import ChooseUs from "../../assets/images/help.png";
import AboutUs from "../../assets/images/i.png";
import "./profiledropdown.css";
import logout from "../../assets/images/logout.png";
import { logoutClickEvent } from "../../../tracking/events/logoutClickEvent";
import { Tracker } from "../../../tracking/tracker";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0",
    margin: "0px",
  },
  paper: {
    padding: "0px",
  },
}));

export default function ProfileDropdown({
  source,
  setOpenUserDropDown,
  openUserDropDown,
  handleToggleDropDown,
  handleCloseDropDown,
  
}) {

  const { userInfo } = useContext(UserContext);
  const classes = useStyles();
  const anchorRef = React.useRef(null);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenUserDropDown((prevState) => ({
        ...prevState,
        openUserDropDown: false,
      }));
    }
  }

  const prevOpen = React.useRef(openUserDropDown);
  React.useEffect(() => {
    if (prevOpen.current === true && openUserDropDown === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openUserDropDown;
  }, [openUserDropDown]);
  const handleLogoutUser = async () => {
    const response = await logoutUser();
    Tracker.getInstance().trackEvent(new logoutClickEvent());
 
  };
  return (
    <div className={classes.root}>
      <Button
        style={{ minWidth: "0px", height: "0px", padding: "0px" }}
        ref={anchorRef}
        aria-controls={openUserDropDown ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggleDropDown}
      >
        <ProfileIcon userName={userInfo?.name} source={source} />
      </Button>
      
      <Popper
        open={openUserDropDown}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{
          width: "200px",
          marginTop: "20px",
          marginLeft: "180px",
          height: "180px",
          zIndex: "1000",
        }}
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
              width: "100%",
              height: "100%",
            }}
          >
            <Paper style={{ width: "100%" }}>
              <ClickAwayListener
                onClickAway={handleCloseDropDown}
                style={{ width: "100%" }}
              >
                <MenuList
                  style={{
                    padding: "0px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  autoFocusItem={openUserDropDown}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    style={{
                      color: "white",
                      width: "100%",
                      fontSize: "14px",
                      backgroundColor: "var(--secondary-button-color)",
                      borderBottom: "1px solid rgb(211, 208, 208)",
                      height: "50px",
                    }}
                  >
                    Hi, {userInfo?.name}
                  </MenuItem>
                  <div className="desktop-navigation-container">
                    <div className="desktop-drawer-items-list">
                      <img
                        src={ChooseUs}
                        alt="customize-image"
                        width="15px"
                        height="15px"
                      />{" "}
                      <a
                        href="/bookingTs"
                        className="desktop-drawer-list-items-text"
                      >
                        Why Choose Us
                      </a>
                    </div>

                    <div className="desktop-drawer-items-list">
                      <img
                        src={AboutUs}
                        alt="customize-image"
                        width="15px"
                        height="15px"
                      />{" "}
                      <a
                        href="/About"
                        className="desktop-drawer-list-items-text"
                      >
                        About Us
                      </a>
                    </div>

                    {userInfo ? (
                      <div
                        className="desktop-drawer-items-list"
                        style={{ borderTop: "1px solid rgb(211, 208, 208)" }}
                        onClick={() => {
                          handleLogoutUser();
                        }}
                      >
                        <img
                          src={logout}
                          width="15px"
                          height="15px"
                          alt="logout"
                        />
                        <span className="desktop-drawer-list-items-text">
                          Logout
                        </span>
                      </div>
                    ) : null}
                  </div>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
