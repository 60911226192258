export class MetaPixelTracker {
	configure() {
		// Configuration for Meta Pixel is implemented in index.html
	}

	setUserIdentifier(phoneNumber) {
	}

	setUserProperties(name, email) {
	}

	logout() {
	}

	/**
	 *
	 * @param TrackableEvent event
	 */
	trackEvent(event) {
		if (window.fbq) {
			console.log("Tracking event with Meta Pixel")
			window.fbq('track', event.name, event.properties)
		}
	}

	trackPageviewEvent(event) {
		if (window.fbq) {
			console.log("Tracking page view event with Meta Pixel")
			window.fbq('track', 'PageView', event.properties)
		}
	}
}
