export const PrivacyData = {
  mainHeading: "Privacy Policy",
  perInfo: [
    {
      body: `TRIP STORE HOLIDAYS Travel (“The company” or “we” or “us”), offers this website available at 
      https://tripstoreholidays.com/ 
      
      (“Website”) and any service availed by users (hereinafter referred to as “you”, “your” or “User”) through the Website, conditioned upon your acceptance of the terms and conditions contained in TRIP STORE HOLIDAYS’s Terms of Use as available on its Website https://tripstoreholidays.com/privacy/ , this privacy policy (“Privacy Policy”)`,
    },
    {
      mainText: "Purpose and Scope:",
      body: `We are strongly committed to respecting your online privacy and recognizes the need for appropriate protection and management of any Personal Information collected and/or collated by us.
    The purpose of this Privacy Policy is to ensure that there is an intact charter to collect, use and protect any personal and/or sensitive data collected by us. This Policy defines our policies and procedure for collection, usage, processing, disclosure, and protection of any information obtained by us through the Website.
    By using the Website and availing the services, and voluntarily providing us with information, you are consenting to our use of it in accordance with this Privacy Policy. This Privacy Policy does not apply to third-party links, applications or websites that are connected via links to the Website or displayed on the Website.
    This Privacy Policy has been drafted and published in accordance with the Information Technology Act, 2000, the Information Technology (Amendment) Act, 2008, and the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011. This Privacy Policy constitutes a legal agreement between You, as a user of the Website and us, as the owner of the Website. You must be a natural person who is at least 18 years of age. If you are under the age of 18, it is mandatory that your parent or guardian has read and accepted this Privacy Policy on your behalf, and in such a case this Privacy Policy shall be deemed to be a contract between TRIP STORE HOLIDAYS and your legal guardian or parent and to the extent permissible under applicable laws, enforceable against you.
    `,
    },
    {
      mainText: "Disclaimer:",
      body:
        "Please be advised that in any Sensitive Information (as defined herein below) procured by us, shall be:",
      Listings: [
        {
          subpoints: [
            { desc: "processed fairly and lawfully" },
            {
              desc: `obtained only for specified and lawful purposes, and not be used in any manner which is against the law or policy in force in India.`,
            },
            {
              desc: `adequate, relevant, and not excessive in relation to the purpose for which it is required.`,
            },
            {
              desc: `able to be reviewed by the user, from time to time and updated-if need arises; and`,
            },
            {
              desc: `not kept longer than for the time which it is required or the purpose for which it is required.`,
            },
          ],
        },
      ],
    },
    {
      mainText: "Your consent:",
      body: `Please note that by providing the information (as enumerated upon herein below) you provide your consent and authorize us to collect, use or disclose such information for the business and research purposes and as stated in this Privacy Policy, the Terms of Use, and as permitted or required by law.
        Moreover, you understand and hereby consent that this information may be transferred to any third party for the purpose of services provided through the Website or to any third party providers for any jointly developed or marketed services, payment processing, order fulfilment, customer services, data analysis, IT services and such other services which enable us to provide you services through the Website.
        This Privacy Policy shall be enforceable against you in the same manner as any other written agreement.
        By visiting or accessing the Website and voluntarily providing us with information (including Personal Information), you are consenting to our use of it in accordance with this Privacy Policy. If you do not agree with this Privacy Policy, you may refuse or withdraw your consent any time, or alternatively to not provide us with any Personal Information.
        Such an intimation to withdraw your consent can be provided in letter, phone or e-mail to the communication details provided below:`,
      Listings: [
        {
          Address: [
            {
              head: "Trip Store Hoildays LLP",

              desc:
                "OFFICE JNO. 1/4 SHARAYU BUILDING, ARTI NAGARI, GANDHARE, THANE, Maharashtra - 421301",
            },
            {
              desc: "Phone: +917666915959 or +91 9209200041",
            },
          ],
        },
      ],
    },
    {
      mainText: "Types of Information collected by TRIP STORE HOLIDAYS:",

      Listings: [
        {
          title: "Personal Information",
          subpoints: [
            {
              head: "Personal Information",
              desc: `means and includes any information that relates to a
            natural person through which an individual is identified, such as the name,
            contact details, email address, gender, age or any other relevant material
            provided by a User when they register for an account on the Website or use
            the services made available through the Website; including but not limited to
            information gathered through a purchase of a service, survey, e-mail
            updates. This may also include information regarding the family member,
            relative, partner, friend or any person related to the User in any way as
            provided by the User through the Website. This also includes information
            pertaining to the racial or ethnic origin, religion or other beliefs, health etc. of
            a User, either as provided by the User through the Website.`,
            },
            {
              head: "Sensitive Information",
              desc: `means and includes any information of a person as
            specified under Information Technology (Reasonable security practices and
            procedures and sensitive personal data or information) Rules, 2011, such as
            password; financial information such as bank account or credit card or debit
            card or any other payment information, physical, physiological and mental
            health information; sexual orientation; medical records and history;
            biometric information; and any other information provided to TRIP STORE
            HOLIDAYS for rendering service or for processing, storing under a lawful
            contract or otherwise.`,
            },
            {
              head: "Technical Information",
              desc: `means and includes any information gathered
            through various technologies that may employ cookies, web beacons, or
            similar technologies to automatically record certain information from your
            device through which you use the Website. This technical information may
            include your Internet Protocol (IP) address, device or browser type,
            advertising ID, Internet service provider (ISP), referring or exit pages,
            clickstream data, operating system. This data includes usage information and
            user statistics.`,
            },
            {
              head: "Integrated Service Information",
              desc: `means and includes any information
            obtained through access to any other social media platform (Facebook,
            Google, etc.) integrated with TRIP STORE HOLIDAYS (if applicable). TRIP
            STORE HOLIDAYS may collect this information already provided in your
            integrated service account.`,
            },
            {
              head: "Information from your parents/guardian",
              desc: `means and includes any
            information which is provided in a case where a child is less than 18 years of
            age and the data provided by their parent and/or guardian.`,
            },
            {
              head: "Locational Information",
              desc: `shall mean and include the information obtained
            through GPS or other means, such as the geographical location of the user.`,
            },
          ],
        },
        {
          title: "Non-Personal Information",
          subpoints: [
            {
              head: "Information through use of our service",
              desc: `means and includes information  which is procured through the use of our service, including but not limited to
            the kind of services availed, the feedback of the services provided by us and
            the kind of services viewed and availed by you.`,
            },
            {
              head: "Non-Personal Information",
              desc: `means and includes any information that does not reveal your specific identity, such as, browser information, information
            collected through Cookies (as defined below), pixel tags and other
            technologies, demographic information, etc. As is true with most websites,
            our Website gathers some information automatically when you visit the URL
            of the Website and stores it in log files. When You use the Website, we may
            collect certain information about your computer or system to facilitate,
            evaluate and verify your use of the Website. For example, we may store
            environmental variables, such as browser type, operating system, speed of
            the central processing unit (CPU), referring or exit web pages, click patterns
            and the internet protocol (IP) address of your computer. This information is
            generally collected in aggregate form, without identifying any user
            individually.`,
            },
            {
              head: "Technical Information",
              desc: `means and includes any information gathered through various technologies that may employ cookies, web beacons, or
            similar technologies to automatically record certain information from your
            device through which you use the Website. This technical information may
            include your Internet Protocol (IP) address, device or browser type,
            advertising ID, Internet service provider (ISP), referring or exit pages,
            clickstream data, operating system. This data includes usage information and
            user statistics.`,
            },
            {
              head: "Integrated Service Information",
              desc: `means and includes any information obtained through access to any other social media platform (Facebook,
            Google, etc.) integrated with TRIP STORE HOLIDAYS (if applicable). TRIP
            STORE HOLIDAYS may collect this information already provided in your
            integrated service account.`,
            },
            {
              head: "Information from your parents/guardian",
              desc: `means and includes any information which is provided in a case where a child is less than 18 years of
            age and the data provided by their parent and/or guardian.`,
            },
            {
              head: "Locational Information",
              desc: `shall mean and include the information obtained through GPS or other means, such as the geographical location of the user.`,
            },
          ],
        },
      ],
    },

    {
      mainText: "Purpose of Collection and Usage of this Information:",

      Listings: [
        {
          title: "Support regular TRIP STORE HOLIDAYS usage",
          subpoints: [
            {
              head: "Identity verification:",
              desc: `This information is particularly pertinent for identity
                verification and to authenticate the submissions made on the Website.`,
            },
            {
              head: "Internal Record Keeping:",
              desc: ` We use the information (as enumerated
                  hereinabove), save and except Sensitive Information, to ascertain the types
                  of service more relevant to your interests and to suit your needs. It helps in
                  building a statistical database to analyse the characteristics, behaviour and
                  demographics of our Users. This information is used solely to promote our
                  business activities.`,
            },
            {
              head: "Communications with TRIP STORE HOLIDAYS:",
              desc: `We have imbibed a feature on
                the Website to provide our Users to have a conversation with TRIP STORE
                HOLIDAYS personnel for providing smooth and efficient services to our Users.
                However, when you provide us your Personal Information, we may use this
                information to communicate with you in the future through e-mail, phone or
                any such information gathered by us through Website.`,
            },
            {
              head: "Ease of use of our services: ",
              desc: `The information is used to ensure continued service to a User and not to interrupt the medical assistance because of any such concern (logistical or otherwise) and depending on the specifics of each user.
                `,
            },
          ],
        },
        {
          title: "Improving your experience",
          subpoints: [
            {
              head: "Increase output:",
              desc: `The information collated through use of our service, save and except Personal Information, Information from your parent/guardian and Sensitive Information, is used to enhance our relationship with you and provide travel assistance to User. It is TRIP STORE HOLIDAYS’s endeavor to grow interest in a User by providing services which are relevant to a particular User. Moreover, TRIP STORE HOLIDAYS uses all this information to analyze the usage trends, learning behaviors and preferences of our Users, to improve the way these services work and look with respect to you, including updating, enhancing the operations and functionality of these features
                `,
            },
            {
              head: "Business or Research Purposes: ",
              desc: `The information (as enumerated above), save and except Personal Information and Sensitive Information, is used for business or research purposes, including improving and customizing the Website for ease of use and the products and services offered by us. We may archive this information to use it for future communications for providing updates and/or surveys.
                `,
            },
            {
              head: "Improve functionality: ",
              desc: `All information, save and except Personal Information and Sensitive Information, is used to provide a personalized learning and high-quality experience for our Users by increasing site functionality and the services offered by TRIP STORE HOLIDAYS.
                `,
            },
            {
              head: "Aggregating Information / Anonymized data:",
              desc: `We may aggregate information and analyze it in a manner to further accentuate the level of the services that we offer to our customers. This information includes average number of users of the Website, the average clicks of the services offered by us, the features used, the response rate, etc. and other such statistics regarding groups or individuals. In doing so, we shall not be making disclosures of any Sensitive Information as defined above.`,
            },
          ],
        },
      ],
    },
    {
      mainText: "Disclosure and Sharing of Information:",
      body: `We do not rent, sell or disclose or share any information that we collect from you, with third
          parties, save and except in order to provide you services in accordance with this Privacy
          Policy, and as per the procedure prescribed by law and in compliance with our legal
          obligations. We may share your information in circumstances and for the purposes as
          specified hereunder:`,
      Listings: [
        {
          subpoints: [
            {
              head: "When compelled by law:",
              desc: `We may disclose any information provided by you on the Website as may be deemed to be necessary or appropriate: (a) under applicable law, including laws outside your country of residence; (b) to comply with legal process; (c) to respond to requests from public and government authorities including public and government authorities outside your country of residence; (d) to enforce our Terms of Use; (e) to protect our operations or those of any of our affiliates; (f) to protect our rights, privacy, safety or property, and/or that of our affiliates, you or others; and (g) to allow us to pursue available remedies or limit the damages that We may sustain.
                `,
            },
            {
              head: "Merger or Acquisition:",
              desc: ` We may share information upon merger or acquisition of TRIP STORE HOLIDAYS with another company. We shall transmit and transfer the information upon acquisition or merger of TRIP STORE HOLIDAYS with another company.
                `,
            },
            {
              head: "With our service providers::",
              desc: `We may share information with other service providers on a need to know basis, subject to obligations of confidentiality, in order to provide services. We hereby clarify that TRIP STORE HOLIDAYS works with vendors, partners, business partners, advertisers, and other service providers, including but not limited to those who provide products or services such as contact information verification, payment processing, website hosting, data analysis, infrastructure provision, IT services, auditing services, travel services, accommodation services and other similar services, in different industries and categories of business by virtue of lawful contracts instituted between such third parties and TRIP STORE HOLIDAYS to improve our product and services. Accordingly, we may share your information with such service provider to provide you our services.
                `,
            },
            {
              head: "On your authorization:",
              desc: `We may share information with social networking sites: Some of our Users choose to connect their TRIP STORE HOLIDAYS account with social networks. In such a case and upon your express authorization, we may share your Personal Information with such networks.

                `,
            },
          ],
        },
      ],
      bodyBottom: `Please be advised that the information shall only be shared, without your consent, with government agencies as mandated under the law. You can be assured that no Sensitive Information shall ever be published on the Website. However, TRIP STORE HOLIDAYS shall share such Sensitive Information with its parent, subsidiaries and affiliates, for the purpose of research and to accentuate the services provided by us through Website.
          `,
    },
    {
      mainText: "Transfer of Information:",
      body: `Any information provided by you including Sensitive Information will only be transferred to any other entity or person in India, or located in another country, including but not limited to the parent, subsidiaries, affiliates, business partners, vendors and agents of TRIP STORE HOLIDAYS, that ensures the industrial level of data protection. Such a transfer is subject to a lawful contract between TRIP STORE HOLIDAYS and the third party or in a case where there is an express authorization for such a transfer to be effectuated`,
    },
    {
      mainText: "Your Rights:",
      body:
        "You retain several rights in relation to your data as provided under applicable law. These may include the rights to:",

      Listings: [
        {
          subpoints: [
            {
              desc: "Access, confirm, and review data you may have provided",
            },
            {
              desc: `Correct data that may be inaccurate or irrelevant.`,
            },
            {
              desc: `Receive data we hold about you in a portable format. `,
            },
            {
              desc: `Object to or restrict any form of processing you may not be comfortable with.
                  `,
            },
          ],
        },
      ],

      bodyBottom: `To exercise these rights, please contact us on the email address provided above.
          To update, correct, review, or amend the information that has been previously provided by you, you may make the changes on your account profile page by signing in and saving the said changes/ modifications. Any change/ modification made by you will be reflected on the Website. Alternatively, you may write us at the above-mentioned contact details.
          `,
    },
    {
      mainText: "Control the collection or use of the Information:",
      body: `If users have any reservations, constraints or apprehensions regarding the access to, collection, storage, or any other use of the information they have provided to TRIP STORE HOLIDAYS, they may withdraw their consent in the manner indicated above.
          `,
    },
    {
      mainText: "Retention of Data:",
      body: `All information provided by you, save and except upon withdrawal or termination, shall be retained in locations outside the direct control of TRIP STORE HOLIDAYS (for instance, on servers or databases co-locates with hosting providers), as long as your account is created and for use in operational purposes only. We will delete information based on a request received from you within a reasonable period and latest within 90 (ninety) days of receiving a deletion request. However, we may retain such portion of information and for such periods as may be required under applicable law.

          `,
    },
    {
      mainText: "Cookies and other tracking Technologies:",
      body: `Our Website may, irrespective of whether or not you are a registered member, utilize "cookies" and other Technical Information. "Cookies" are a small text file constituting of alphanumeric numbers used to collect the information about Website activity. The Technical Information helps us analyse web traffic and help you by customizing the Website to your preferences. A Cookie in no way gives us access to your computer or mobile device. In relation to Cookies, you can deny access to the installation of the Cookies by modifying the settings on your web browser, however this may prevent you from taking full advantage of the Website.
          Our use of Cookies and Technical Information allows us to improve our Website and your experience of our Website and services. We may also analyse Technical Information that does not contain Personal Information for trends and statistics.
         `,
    },
    {
      mainText: "Third Party services :",
      body: `TRIP STORE HOLIDAYS will not sell your information to third parties unless TRIP STORE HOLIDAYS has your permission or is required by law to do so. TRIP STORE HOLIDAYS may use your information to send you promotional information about third parties which TRIP STORE HOLIDAYS think you may find interesting if you tell us that you wish this to happen. In the event you choose to avail any third-party services, your use of such services shall be subject to the terms of use and privacy policy of such third parties. We assume no liability for any information disclosed by you to any third parties either through the Website or otherwise. We shall not be responsible for any disclosure of information due to unauthorized third party access other acts of third parties or acts or omissions beyond our reasonable control and you agree that you will not hold us responsible for any breach of security unless such breach has been caused as s direct result of our negligence or wilful default.
          EXCEPTION: TRIP STORE HOLIDAYS will provide a URL on the Website which will re-direct Users to a blog owned by TRIP STORE HOLIDAYS, which will contain blogs related to TRIP STORE HOLIDAYS, information about destination, recommendations sourced from publicly available data, travel essentials and assistances by TRIP STORE HOLIDAYS etc., and the said blog will be governed by the Terms and Conditions of TRIP STORE HOLIDAYS and this Privacy Policy.
           `,
    },
    {
      mainText: "Data Security:",
      body: `To protect your privacy and security, TRIP STORE HOLIDAYS takes steps to verify your identity before granting you account access or making corrections to your information. You should create and maintain a strong password to help ensure the security of your account.
          We use certain physical, managerial, technical or operational safeguards as per industry standards and established best practices to protect the information we collect. We use reasonable security practices and procedures and use secure servers as mandated under applicable laws for protection of your information. We review our information collection, storage and processing practices, including physical security measures to guard against unauthorized access to systems. However, as effective as these measures are, no security system is impenetrable. We cannot guarantee the security of our database, nor can we guarantee that the information you supply will not be intercepted while being transmitted to us over the internet. You accept the inherent security implications of data transmission over the internet and the world wide web cannot always be guaranteed as complete secure. Therefore, your use of the Website will be at your own risk. If you have any concerns please feel free to contact the Grievance Officer at the details given herein below.
          `,
    },
    {
      mainText: "Changes and updates to Policy:",
      body: `TRIP STORE HOLIDAYS may modify or revise this Privacy Policy from time to time and shall accordingly notify you of any changes to this Privacy Policy by posting the revised Privacy Policy with an updated date of revision. TRIP STORE HOLIDAYS shall endeavor to review, revise, update, modify, amend or correct this Policy on a regular and routine basis, especially whenever a significant update is made to the technology employed by TRIP STORE HOLIDAYS for its services. You must periodically review the Privacy Policy for the latest information on our privacy practices. In the event you continue to use the Website and our services after any update in the Privacy Policy, your use of the Website shall be subject to such update privacy policy.`,
    },
    {
      mainText: "Discrepancies and Grievances with TRIP STORE HOLIDAYS:",
      body: `In case of any discrepancy or grievance with respect to all or any information shared with TRIP STORE HOLIDAYS, please feel free to contact at support@tripstoreholidays.com
          We assure you that we shall ensure implementation of this Privacy Policy and shall make the Policy available to individuals and redress the grievances of the user expeditiously within one (1) month from the date of receipt of grievance.
          `,
    },
    {
      mainText: "Contacting TRIP STORE HOLIDAYS:",
      body: `Please feel free to reach out to us by e-mail at or mail at OFFICE JNO. 1/4 SHARAYU BUILDING, ARTI NAGARI, GANDHARE, THANE, Maharashtra - 421301, India in case of any concerns, grievances, or questions relating to our privacy or data related practices.
    `,
    },
    {
      mainText: "Miscellaneous:",
      body: `The invalidity or unenforceability of any part of this Privacy Policy shall not prejudice or affect the validity or enforceability of the remainder of this Privacy Policy. This Privacy Policy does not apply to any information other than the information collected by us through the Website. This Privacy Policy shall be inapplicable to any unsolicited information you provide us through the Website or through any other means. All unsolicited information shall be deemed to be non-confidential and we shall be free to use and/ or disclose such unsolicited information without any limitations. The rights and remedies available under this Policy may be exercised as often as necessary and are cumulative and not exclusive of rights or remedies provided by law. It may be waived only in writing. Delay in exercising or non-exercise of any such right or remedy does not constitute a waiver of that right or remedy, or any other right or remedy.
    `,
    },
    {
      mainText: "Governing Laws and Jurisdiction:",
      body: `This Privacy Policy, our services and the use of it is governed by the laws of India and the courts in Thane shall have exclusive jurisdiction over any disputes connected to our Website or the services.`,
    },
  ],
};
