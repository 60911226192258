import * as types from "../action.types";

export const agentAction = data => {
  return {
    type: types.GET_BECOME_AGENT,
    data
  };
};

export const agentActionSuccess = data => {
  return {
    type: types.GET_BECOME_AGENT_SUCCESS,
    data
  };
};
export const resetErrorAction = data => {
  return {
    type: types.GET_RESPONSE_NULL
  };
};
