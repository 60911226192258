import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Tracker } from "../../../tracking/tracker";
import { loginEndEvent } from "../../../tracking/events/loginEndEvent";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    outline: "none",
    border: "none",
    boxShadow: theme.shadows[1],
  },
}));

function ModalComponent({
  packageDetails,
  openLoginModal,
  handleCloseLoginModal,
  children,
  origin,
}) {
  const classes = useStyles();

  const isLoggedIn = localStorage.getItem("access_token");
  const [currentView, setCurrentView] = useState("");
  const closeModal = () => {
    if (!isLoggedIn) {
      Tracker.getInstance().trackEvent(new loginEndEvent(currentView));
    }

    handleCloseLoginModal();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} animate-slide-up`}
      open={openLoginModal}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openLoginModal}>
        <div className={classes.paper}>
          {" "}
          {React.cloneElement(children, {
            packageDetails,
            handleCloseLoginModal,
            setCurrentView,
            origin,
          })}
        </div>
      </Fade>
    </Modal>
  );
}

export default ModalComponent;
