// import axios from "axios";

// export const termsConditionService = (request) => {
//   let id = request && request.data;

import axios from "axios"

export const termsConditionService = (request) => {
        return axios
                .get("/agent-terms", request.data)
                .then((response) => {
                        return response.data
                })
                .catch((err) => {
                        return err.response.data
                })
}
