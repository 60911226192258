import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants";
import { TrackableEvent } from "./trackableEvent";

export class loginEndEvent extends TrackableEvent {
  constructor(endScreen) {
    let properties = {};
    properties[TrackerPropertyKey.EndScreen] = endScreen
    super(TrackingEventName.LoginEnd, properties);
  }
}
