import { createTheme } from "@material-ui/core/styles";


const theme = createTheme({
  palette: {
    primary: {
      main: "#ff6840",
      contrastText: "#fff",
    },
    secondary: {
      main: "#0B3954",
      contrastText: "#fff",
    },
    text: {
      primary: "#0ain00",
      secondary: "#fff",
    },

    background: {},
  },
  typography: {
    useNextVariants: true,
    fontFamily: ["Aileron", "sans-serif"].join(","),

    //display1
    h4: {
      fontSize: "28px",

      fontWeight: "normal !important",
    },

    //headline
    h5: {
      fontSize: "24px !important",

      fontWeight: "normal!important",
    },
    //title
    h6: {
      fontSize: "20px",
      fontWeight: 500,
    },
    //for p1 normal
    body1: {
      fontSize: "18px",
      fonteWight: "normal !important",
    },
    //for p2 normal
    subtitle2: {
      fontSize: "14px",
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: "16px",

      fontWeight: "normal !important",
    },
    //for p4 normal
    body2: {
      fontSize: "14px",

      fontWeight: "normal !important",
    },
    //for p1 normal
    caption: {
      fontSize: "12px",

      fontWeight: "normal important",
    },
    //for p2 normal
    overline: {
      fontSize: "10px",
      fontWeight: "normal !important",
    },
  },
});
export default theme;

// h1: {
//   fontSize: "5rem",
//   [breakpoints.down("xs")]: {
//     fontSize: "3rem"
//   }


