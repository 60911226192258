import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import "./filtersComponent.css";
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import { getFiltersList } from "../../Services/services";
import FilterListIcon from "@material-ui/icons/FilterList";
import Skeleton from "@material-ui/lab/Skeleton";
import Chip from "@material-ui/core/Chip";
import { ListingFilterClearAllEvent } from "../../../../tracking/events/listingFilterClearAllEvent";
import { Tracker } from "../../../../tracking/tracker";
import { ListingFilterItemClickEvent } from "../../../../tracking/events/listingFilterItemClickedEvent";
import { ListingFilterOpenEvent } from "../../../../tracking/events/listingFilterOpenEvent";
const FiltersComponent = forwardRef(
  ({ chipsToShow, onSelectedChipsChange, onChipDelete }, ref) => {
    const urlParams = new URLSearchParams(window.location.search);
    const [isPreviousSelected, setIsPreviousSelected] = useState(false);

    const [filterOptionClicked, setFilterOptionClicked] = useState("");
    const [openSections, setOpenSections] = useState({});

    const [filterData, setFilterData] = useState([]);
    const [searchTerms, setSearchTerms] = useState({});
    const sortedFilterData = filterData?.sort(
      (a, b) => a.display_order - b.display_order
    );
    const [selectedFilters, setSelectedFilters] = useState({});
   

    const getFilterData = async (filters) => {
      const response = await getFiltersList(filters);
      if (response.data.response_code === 200) {
        setFilterData(response?.data?.data);
      }
    };

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const filters = [];

      urlParams.forEach((value, key) => {
        const decodedValue = decodeURIComponent(value);

        const processedValue = decodedValue.replace(/:/g, ",");

        let filterValues;
        if (key === "city" || key === "start_city" || key === "end_city") {
          filterValues = decodedValue.split(":").map((city) => city.trim());
        } else {
          filterValues = processedValue.split(/,(?!\d{3})/);
        }

        filters.push({
          type: key,
          values: filterValues,
        });
      });
      getFilterData(filters);
    }, []);

    useEffect(() => {
      const selectedChips = [];

      Object.keys(selectedFilters).forEach((section) => {
        Object.keys(selectedFilters[section]).forEach((option) => {
          if (selectedFilters[section][option]) {
            selectedChips.push({ section, option });
          }
        });
      });

      onSelectedChipsChange(selectedChips);
    }, [selectedFilters, onSelectedChipsChange]);

    useEffect(() => {
      const initialOpenSections = {};
      sortedFilterData.forEach((filterSection) => {
        const hasSelectedOptions =
          Object.keys(selectedFilters[filterSection.type] || {}).length > 0;
        if (hasSelectedOptions) {
        initialOpenSections[filterSection.name] = true;
        }
      });
      setOpenSections(initialOpenSections);
    }, [sortedFilterData, selectedFilters]);

    const toggleSection = (section) => {
      setFilterOptionClicked(section);

      setOpenSections((prevOpenSections) => {
        const isOpen = !!prevOpenSections[section];
        const newOpenSections = { ...prevOpenSections, [section]: !isOpen };

        if (!isOpen) {
          Tracker.getInstance().trackEvent(new ListingFilterOpenEvent(section));
        }

        return newOpenSections;
      });
    };
    useEffect(() => {
      const filters = {};
 
    

      filterData.forEach((filterGroup) => {
        const { type, filters: filterItems } = filterGroup;
        filterItems.forEach((filterItem) => {
          const { name, is_selected } = filterItem;
          if (is_selected) {
            if (!filters[type]) {
              filters[type] = {};
            }
            filters[type][name] = true;
          }
        });
      });
    
      setSelectedFilters(filters);
    }, [filterData]);
    // useEffect(() => {
    //   const urlParams = new URLSearchParams(window.location.search);
    //   const filters = {};
    

    //   urlParams.forEach((value, key) => {
    //     const valuesArray = value.replace(/:/g, ",").split(/,(?!\d{3})/);
    //     filters[key] = valuesArray.reduce((acc, val) => {
    //       acc[val] = true;
    //       return acc;
    //     }, {});
    //   });

    //   setSelectedFilters(filters);
    // }, []);
    // useEffect(() => {
    //   const urlParams = new URLSearchParams(window.location.search);
    //   const filters = {};
    //   console.log("the test----",filterData)
    
    //   // Assuming filtersData is an array of objects with a 'type' property
    //   const filterTypes = filterData.map((filter) => filter.type);
    
    //   urlParams.forEach((value, key) => {
    //     console.log("the test----",key,value)
    //     if (filterTypes.includes(key)) {
    //       const valuesArray = value.replace(/:/g, ",").split(/,(?!\d{3})/);
    //       filters[key] = valuesArray.reduce((acc, val) => {
    //         acc[val] = true;
    //         return acc;
    //       }, {});
    //     }
    //   });
    
    //   setSelectedFilters(filters);
    // }, [filterData]);

    const handleCheckboxChange = (section, option, filtersection) => {
      setSelectedFilters((prevState) => {
        const updatedSection = { ...prevState[section] } || {};

        let params = new URLSearchParams(window.location.search);

        if (params.has(filtersection.type)) {
          let existingValues = params.get(filtersection.type).split(":");

          if (existingValues.includes(option)) {
            Tracker.getInstance().trackEvent(
              new ListingFilterItemClickEvent(
                section,
                option,
                !existingValues.includes(option)
              )
            );

            existingValues = existingValues.filter((value) => value !== option);

            delete updatedSection[option];
          } else {
            existingValues.push(option);
            updatedSection[option] = true;
          }

          if (existingValues.length > 0) {
            params.set(filtersection.type, existingValues.join(":"));
         
          } else {
            params.delete(filtersection.type);
          }
        } else {
          params.set(filtersection.type, option);
          updatedSection[option] = true;
          // Tracker.getInstance().trackEvent(
          //   new ListingFilterItemClickEvent(section, option, true)
          // );
        }

        const encodedParams = params.toString();

        window.location.href = `/packages?${encodedParams}`;

        return {
          ...prevState,
          [section]: updatedSection,
        };
      });
      // onChipDelete(section, option);
    };
    const handleChipDelete = (section, option, filtersection) => {
      setSelectedFilters((prevState) => {
        const updatedSection = { ...prevState[section] } || {};
        delete updatedSection[option];

        let params = new URLSearchParams(window.location.search);
        if (params.has(filtersection.type)) {
          let existingValues = params
            .get(filtersection.type).split(":");
            // .replace(/:/g, ",")
            // .split(/,(?!\d{3})/);

          existingValues = existingValues.filter((value) => value !== option);

          if (existingValues.length > 0) {
            params.set(filtersection.type, existingValues.join(":"));
          } else {
            params.delete(filtersection.type);
          }
        }

        const encodedParams = params.toString();
        window.location.href = `/packages?${encodedParams}`;

        return {
          ...prevState,
          [section]: updatedSection,
        };
      });
    };
    useImperativeHandle(ref, () => ({
      handleChipDelete,
    }));

    const handleSearchChange = (section, event) => {
      const target = event?.target;
      if (target && "value" in target) {
        const searchValue = String(target.value);

        setSearchTerms((prevState) => ({
          ...prevState,
          [section]: searchValue,
        }));
      }
    };

    useEffect(() => {
      Object.keys(selectedFilters).forEach((section) => {
        Object.keys(selectedFilters[section]).forEach((option) => {
          if (!selectedFilters[section][option]) {
            onChipDelete(section, option);
          }
        });
      });
    }, [selectedFilters]);

    const totalFiltersApplied = Object.values(selectedFilters).reduce(
      (total, filterSection) => {
        return (
          total +
          Object.values(filterSection).filter((isSelected) => isSelected).length
        );
      },
      0
    );
    const handleClearAll = () => {
      setSelectedFilters({});
      window.location.href = `/packages`;
      Tracker.getInstance().trackEvent(
        new ListingFilterClearAllEvent(totalFiltersApplied)
      );
    };

    return (
      <div className="filter-container">
        <div className="filters-top-heading">
          {" "}
          <p
            style={{
              display: "flex",
              alignItems: "center",
              lineHeight: "0px",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {" "}
            <FilterListIcon /> FILTERS
          </p>
          {urlParams.size === 0 ? null : (
            <p className="clear-all" onClick={handleClearAll}>
              Clear all
            </p>
          )}
        </div>

        {filterData.length > 0 ? (
          sortedFilterData.map((filterSection) => {
            const searchTerm = searchTerms[filterSection.name] || "";

            const filteredAndSortedOptions = filterSection.filters
              .filter((option) =>
                option.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .sort((a, b) => {
                const aSelected =
                  selectedFilters[filterSection.name]?.[a.name] || false;
                const bSelected =
                  selectedFilters[filterSection.name]?.[b.name] || false;
                return bSelected - aSelected;
              });

            return (
              <div key={filterSection.type} className="filter-section">
                <div
                  className="filter-heading"
                  onClick={() => toggleSection(filterSection.name)}
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    padding: "10px 0",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {filterSection.name}
                  <span>
                    {openSections[filterSection.name] ? (
                      <ExpandLessOutlinedIcon
                        style={{ color: "var(--accent-color)" }}
                      />
                    ) : (
                      <ExpandMoreOutlinedIcon
                        style={{ color: "var(--accent-color)" }}
                      />
                    )}
                  </span>
                </div>
                {openSections[filterSection.name] && (
                  <div
                    className="filter-options"
                    style={{
                      height:
                        filterSection.filters.length >= 15 ? "500px" : "auto",
                      overflowY:
                        filterSection.filters.length >= 15 ? "auto" : "visible",
                    }}
                  >
                    {filterSection.filters.length >= 15 && (
                      <div
                        style={{
                          position: "relative",
                          marginBottom: "10px",
                          marginTop: "0px",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "10px",
                          outline: "none",
                          border: "none",
                        }}
                      >
                        <input
                          type="text"
                          placeholder={`Search ${filterSection.name}`}
                          value={searchTerm}
                          onChange={(event) =>
                            handleSearchChange(filterSection.name, event)
                          }
                          style={{
                            borderRadius: "10px",
                            border: "1px solid var(--accent-color)",
                            outline: "none",

                            padding: "5px",
                            width: "96%",
                          }}
                        />
                        <SearchOutlinedIcon
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            pointerEvents: "none",
                            color: "#888",
                          }}
                        />
                      </div>
                    )}
                    {filteredAndSortedOptions.length > 0 ? (
                      filteredAndSortedOptions.map((option) => (
                        <>
                          <label
                            key={option.name}
                            className="filter-option"
                            style={{ display: "block", padding: "5px 0" }}
                          >
                            <input
                              style={{ cursor: "pointer" }}
                              type={
                                filterSection.input_type === "multi_select"
                                  ? "checkbox"
                                  : "radio"
                              }
                              checked={option.is_selected}
                              onChange={() =>
                                handleCheckboxChange(
                                  filterSection.name,
                                  option.name,
                                  filterSection
                                )
                              }
                              name={
                                filterSection.input_type === "multi_select"
                                  ? undefined
                                  : filterSection.name
                              }
                            />

                            {option.name}
                          </label>
                        </>
                      ))
                    ) : (
                      <div style={{ fontSize: "12px" }}>No results found!</div>
                    )}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <>
            <Skeleton style={{ height: "50px" }} className="filters-skelton" />
            <Skeleton style={{ height: "50px" }} className="filters-skelton" />
            <Skeleton style={{ height: "50px" }} className="filters-skelton" />
            <Skeleton style={{ height: "50px" }} className="filters-skelton" />
            <Skeleton style={{ height: "50px" }} className="filters-skelton" />
          </>
        )}
      </div>
    );
  }
);

export default FiltersComponent;
