import React, { lazy, Suspense } from "react"
import Grid from "@material-ui/core/Grid"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import Scroll from "./scrollBanner"
import Themes from "../Themes/themes"
import "./banner.css"
import { getDestinationAction } from "../../store/actions/getDestination.action"
import { getThemeAction } from "../../store/actions/getTheme.action"
import { getSeasonAction } from "../../store/actions/getSeason.action"
import { getMonthAction } from "../../store/actions/getMonths"
import { getPopularPackageAction } from "../../store/actions/getpopularpackages.action"
import { getBannerAction } from "../../store/actions/banner.action"
import { getPackageListAction } from "../../store/actions/packageList.action"
import { getPackageDetailAction } from "../../store/actions/getPackageDetail.action"
import { getMetaDetailAction } from "../../store/actions/getMeta.action"
import { getEuropeDestinationAction } from "../../store/actions/getEuropeDestination.action"
import { getOtherDestinationAction } from "../../store/actions/getOtherDestination.action"
import { Helmet } from "react-helmet"
import { Tracker } from "../../../tracking/tracker"
import { HomeWidgetItemClickEvent, HomeWidgetType } from "../../../tracking/events/homeWidgetItemClickEvent"
import GoogleReviewsWidget from "../Reviews/googleReviewsWidget"
import { TrackingPageName } from "../../../tracking/trackingConstants"
const HomeSearchWidget = lazy(() => import("./homeSearchWidget"))
const HeaderSection = lazy(() => import("../header/header"))
const Destination = lazy(() => import("../Destinations/destination"))
const ThreeImage = lazy(() => import("../ThreeImageSection/threeImage"))
const Season = lazy(() => import("../Seasons/seasons"))
const PopularPackage = lazy(() => import("../PopularPackage/popularpackage"))
const BottomBanner = lazy(() => import("../../components/Banner/BottomBanner"))
const Footer = lazy(() => import("../../components/footer/footer"))

class HomeBanner extends React.Component {
	state = {
		visible: true,
		showGoogleReviewWidget: false,
	}

	componentDidMount = () => {
		this.props.getDestinationAction()
		this.props.getThemeAction()
		this.props.getSeasonAction()
		this.props.getMonthAction()
		this.props.getPopularPackageAction()
		this.props.getBannerAction()
		this.props.getEuropeDestinationAction()
		this.props.getOtherDestinationAction()
		this.props.getMetaDetailAction({ params: { page: "home" } })

		this.setState({
			visible: false,
		})
		window.scrollTo(0, 0)
	}

	onDestinationClick = (widgetType, itemTitle) => {
		this.trackWidgetClick(widgetType, itemTitle)
	}

	onSeasonClick = (itemTitle) => {
		this.trackWidgetClick(HomeWidgetType.SEASON, itemTitle)
	}

	onThemeClick = (itemTitle) => {
		this.trackWidgetClick(HomeWidgetType.THEME, itemTitle)
	}

	onPopularPackageClick = (itemTitle) => {
		this.trackWidgetClick(HomeWidgetType.POPULAR_PACKAGE, itemTitle)
	}

	trackWidgetClick = (widgetType, itemTitle) => {
		Tracker.getInstance().trackEvent(new HomeWidgetItemClickEvent(widgetType, itemTitle))
	}

	onGoogleReviewWidgetLoad = () => {
		this.setState({
			showGoogleReviewWidget: true,
		})
	}

	render() {
		const { _destination, _theme, _season, _months, _popularPackage, _banner, _metaDetail, _europeDestination, _otherDestination } = this.props
		return (
			<React.Fragment>
				<Helmet>
					<title>{_metaDetail.data ? _metaDetail.data.data.meta_title : ""}</title>
					<meta charset="UTF-8" />
					<meta name="title" content={_metaDetail.data ? _metaDetail.data.data.meta_title : null} />
					<meta name="description" content={_metaDetail.data ? _metaDetail.data.data.meta_description : ""} />
					<meta name="keywords" content={_metaDetail.data ? _metaDetail.data.data.meta_keyword : ""} />
					<meta name="author" content="Tripstore" />
					<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				</Helmet>
				{/* ================== navbar sections starts here======================= */}
				<Suspense fallback={<h6 style={{ color: "transparent" }}>loading...</h6>}>
					<HeaderSection
						destinationScroll={this.destination}
						themeScroll={this.theme}
						seasonScroll={this.Season}
						popularScroll={this.popular}
						destinationNAme={_destination}
						month={_months}
						theme={_theme}
					/>
				</Suspense>
				{/* ===================================ends===================================== */}
				{/* ===================  background carousel started====================== */}
				<Grid className="image-box">
					<Grid item md={12} sm={12} xs={12} className="image-box__background" /*style={{ height: "30vh" }}*/>
						<Scroll image={_banner} />
					</Grid>
					<Grid className="image-box__overlay"></Grid>
					<Grid item className="image-box__content">
						<Suspense fallback={<h6 style={{ color: "transparent" }}>loading...</h6>}>
							<HomeSearchWidget destinationNAme={_destination} />  
							{/* search component */}
						</Suspense>
					</Grid>
				</Grid>
				{/* ======Ends here==================================== */}
				{/*=================== EUROPE sections started============================ */}
				{_europeDestination.data && _europeDestination.data.length > 0 ? (
					<section
						ref={(section) => {
							this.destination = section
						}}>
						<Suspense fallback={<h6 style={{ color: "transparent" }}>loading...</h6>}>
							<Destination
								data={_europeDestination}
								title="Explore Europe"
								visible={this.state.visible}
								onClick={(selectedItemTitle) => {
									this.onDestinationClick(HomeWidgetType.EUROPE_DESTINATION, selectedItemTitle)
								}}
							/>
						</Suspense>
					</section>
				) : (
					""
				)}
				{/*=================ended here======================================== */}
				{/* ======================Themes sections started===================================== */}
				<section
					ref={(section) => {
						this.theme = section
					}}>
					<Suspense fallback={<h6 style={{ color: "transparent" }}>loading...</h6>}>
						<Themes
							data={_theme}
							onClick={(selectedItemTitle) => {
								this.onThemeClick(selectedItemTitle)
							}}
						/>
					</Suspense>
				</section>
				{/* =====================ends here=================================================== */}
				{_otherDestination.data && _otherDestination.data.length > 0 ? (
					<section>
						<Suspense fallback={<h6 style={{ color: "transparent" }}>loading...</h6>}>
							<Destination
								data={_otherDestination}
								title="Explore Other Destinations"
								visible={this.state.visible}
								onClick={(selectedItemTitle) => {
									this.onDestinationClick(HomeWidgetType.OTHER_DESTINATION, selectedItemTitle)
								}}
							/>
						</Suspense>
					</section>
				) : (
					""
				)}
				<section
					ref={(section) => {
						this.Season = section
					}}>
					<Suspense fallback={<h6 style={{ color: "transparent" }}>loading...</h6>}>
						<Season
							data={_season}
							month={_months}
							onClick={(selectedItemTitle) => {
								this.onSeasonClick(selectedItemTitle)
							}}
						/>
					</Suspense>
				</section>
				<section
					ref={(section) => {
						this.popular = section
					}}>
					<Suspense fallback={<h6 style={{ color: "transparent" }}>loading...</h6>}>
						<PopularPackage
							data={_popularPackage}
							visible={this.state.visible}
							onClick={(selectedItemTitle) => {
								this.onPopularPackageClick(selectedItemTitle)
							}}
						/>
					</Suspense>
				</section>
				<Suspense fallback={<h6 style={{ color: "transparent" }}>loading...</h6>}>
					<ThreeImage destinationScroll={this.destination} />
				</Suspense>
				<Suspense fallback={<h6 style={{ color: "transparent" }}>loading...</h6>}>
					<Footer />
				</Suspense>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({
	getDestinationReducer,
	getThemeReducer,
	getSeasonReducer,
	getMonthReducers,
	getPopularPackageReducer,
	getBannerReducer,
	getPackageListReducer,
	getPackageDetailReducer,
	getMetaDetailReducer,
	getEuropeDestinationReducer,
	getOtherDestinationReducer,
}) => {
	return {
		_destination: getDestinationReducer,
		_theme: getThemeReducer,
		_season: getSeasonReducer,
		_months: getMonthReducers,
		_popularPackage: getPopularPackageReducer,
		_banner: getBannerReducer,
		_packageList: getPackageListReducer,
		_packageDetails: getPackageDetailReducer,
		_metaDetail: getMetaDetailReducer,
		_europeDestination: getEuropeDestinationReducer,
		_otherDestination: getOtherDestinationReducer,
	}
}

export default withRouter(
	connect(mapStateToProps, {
		getDestinationAction,
		getThemeAction,
		getSeasonAction,
		getMonthAction,
		getPopularPackageAction,
		getBannerAction,
		getMetaDetailAction,
		getPackageListAction,
		getPackageDetailAction,
		getEuropeDestinationAction,
		getOtherDestinationAction,
		// getInclusionAction
	})(HomeBanner)
)
