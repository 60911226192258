import { TrackerPropertyKey, TrackingEventName } from "../../trackingConstants"
import { TrackableEvent } from "../trackableEvent"

export class ListingPaginationEvent extends TrackableEvent {
	constructor(pageNumber) {
		let properties = {}
		properties[TrackerPropertyKey.PageNumber] = pageNumber

		super(TrackingEventName.ListingPagination, properties)
	}
}
