import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "../home/home";
// import Banner from "../Banner/banner";
import PackageList from "../PackagesList/packagelist";
import CompareList from "../PackagesList/CompareList/comparelist";
import PackageDetails from "../PackageDetails/PackageDetails";
import TermsCondition from "../PackageDetails/PackageInformation/termsCondition";
import About from "../FooterFiles/About";
import BookigTs from "../FooterFiles/bookingTS";
import WorkTs from "../FooterFiles/workwithTs";
import PrivacyPolicy from "../FooterFiles/Model/privacypolicy";
import TermsFooter from "../FooterFiles/TermsANdConditionfooter";
import SuccessPage from "../Banner/SucessForm";
import BookingPage from "../Booking/BookingPage";
import HomePage from "../../pages/HomePage";
import NoInternetConnection from "../NointernetConnection/NoInternetConnection";
import SuccessBooking from "../Booking/SuccessBooking";

const landing = () => {
  return (
    <div>
      <NoInternetConnection>
        <BrowserRouter>
          <Switch>
            
            <Route exact path="/">
              {/* <Home /> */}
              <HomePage />
            </Route>
            <Route
              path="/packagedetails/:id"
              component={PackageDetails}
            ></Route>
               <Route path="/booking/:intentId" component={BookingPage}></Route>
               <Route path="/booking-success/:bookingId" component={SuccessBooking}></Route>
            <Route path="/:path/:subpath?/:value">
              <HomePage />
            </Route>
            <Route path="/packages" component={PackageList} />
            <Route path="/comparelist" component={CompareList} />
            <Route path="/termsCondition" component={TermsCondition}></Route>
            <Route path="/Conditions" component={TermsFooter}></Route>
            <Route path="/About" component={About}></Route>
            <Route path="/policy" component={PrivacyPolicy}></Route>
            <Route path="/bookingTs" component={BookigTs}></Route>
            <Route path="/workTs" component={WorkTs}></Route>
            <Route path="/thanks" component={SuccessPage}></Route>
         
          
            {/* <Redirect from="*" to="/" /> */}
          </Switch>
        </BrowserRouter>
      </NoInternetConnection>
    </div>
  );
};

export default landing;
