import React, { Component, createRef } from "react";
import {
  CardMedia,
  Grid,
  Typography,
  IconButton,
  Button,
  Container,
} from "@material-ui/core";
import { throttle } from "lodash";
import "./comparelist.css";
import ShareIcon from "@material-ui/icons/Share";
import SharePopup from "./shareIcon/shareicon";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Addpackage from "./addpackage";
import DumyImage from "../../../assets/images/DestinationAfrica.jpg";
import Header from "./header";
import Footer from "../../footer/footer";
import Registration from "../RequestForPackage/PackageCall";
import { getResponseNullAction } from "../../../store/actions/getComparelist.action";
import { getCompareListAction } from "../../../store/actions/getComparelist.action";
import { getPackageListAction } from "../../../store/actions/packageList.action";
import { getMetaDetailAction } from "../../../store/actions/getMeta.action";
import { getPackageDetailAction } from "../../../store/actions/getPackageDetail.action";
import { Helmet } from "react-helmet";
import { TrackingOrigin } from "../../../../tracking/trackingConstants";
import { PageViewEvent } from "../../../../tracking/events/pageViewEvent";
import { TrackingPageName } from "../../../../tracking/trackingConstants";
import { Tracker } from "../../../../tracking/tracker";
import { NavigationContext } from "../../../navigation/navigationContext";
import ModalComponent from "../../ModalComponent/ModalComponent";
import LoginUser from "../../Auth/Login/LoginUser";
import FormModal from "../../FormModal/FormModal";
import RequestCallForm from "../../RequestCall/RequestCallForm";
import SaleInfo from "../../../models/listing/saleInfo";
import { loginEndEvent } from "../../../../tracking/events/loginEndEvent";
import { PrimaryButton } from "../../../../utils/Buttons";
import { capitalizeSentences } from "../../../../utils/utility";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: "ce",
    color: theme.palette.text.secondary,
  },
  title: {
    color: "#616e78",
  },
  content: {
    color: "#16222b",
  },
  borders: {
    borderLeft: "1px solid #e9eaeb",
    borderTop: "1px solid #e9eaeb",
    borderRight: "1px solid #e9eaeb",
    marginBottom: 15,
  },
  packageName: {
    textTransform: "capitalize",
  },
});

class CompareList extends Component {
  static contextType = NavigationContext;
  constructor(props) {
    super(props);
    this.state = {
      comparelist: JSON.parse(localStorage.getItem("compare")) || [],
      idArray: JSON.parse(localStorage.getItem("packageId")) || [],
      open: false,
      requestId: [],
      url: "",
      pageNumber: 3,
      popularPackage: JSON.parse(localStorage.getItem("popularPackage")),
      shareBox: false,
      idParams: [],
      requestCallclickOne: false,
      requestCallclicktwo: false,
      requestCallclickthree: false,
      openCallRequestModal0: false,
      openCallRequestModal1: false,
      openCallRequestModal2: false,
      isrequestCallButtonClicked: "",
      openLoginModal: false,
      currentView: "",
      showImageRow: true,
      lastScrollTop: 0,
    };
    this.tableContainerRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleRequestCallClick = (index) => {
    const stateKeys = [
      "requestCallclickOne",
      "requestCallclicktwo",
      "requestCallclickthree",
    ];
    const modalHandlers = [
      this.handleOpenRequestCallModal0,
      this.handleOpenRequestCallModal1,
      this.handleOpenRequestCallModal2,
    ];

    this.setState({
      [stateKeys[index]]: true,
    });

    modalHandlers[index]();
  };
  handleOpenLoginModal = (requestCallButtonClicked) => {
    this.setState({
      isrequestCallButtonClicked: requestCallButtonClicked,
    });
    this.setState({
      openLoginModal: true,
    });
  };
  isLoggedIn = localStorage.getItem("access_token");
  setCurrentView = (newData) => {
    this.setState({ currentView: newData });
  };
  handleCloseLoginModal = () => {
    this.setState({
      openLoginModal: false,
    });
    if (!this.isLoggedIn) {
      Tracker.getInstance().trackEvent(
        new loginEndEvent(this.state.currentView)
      );
    }
  };
  handleCloseRequestModal0 = () => {
    this.setState({
      openCallRequestModal0: false,
    });
    // if (!this.isLoggedIn) {
    //   Tracker.getInstance().trackEvent(new loginEndEvent(this.state.currentView));
    // }
  };
  handleCloseRequestModal1 = () => {
    this.setState({
      openCallRequestModal1: false,
    });
    // if (!this.isLoggedIn) {
    //   Tracker.getInstance().trackEvent(new loginEndEvent(this.state.currentView));
    // }
  };
  handleCloseRequestModal2 = () => {
    this.setState({
      openCallRequestModal2: false,
    });
    // if (!this.isLoggedIn) {
    //   Tracker.getInstance().trackEvent(new loginEndEvent(this.state.currentView));
    // }
  };
  handleOpenRequestCallModal0 = () => {
    this.setState({
      openCallRequestModal0: true,
    });
  };
  handleOpenRequestCallModal1 = () => {
    this.setState({
      openCallRequestModal1: true,
    });
  };
  handleOpenRequestCallModal2 = () => {
    this.setState({
      openCallRequestModal2: true,
    });
  };
  componentDidMount = () => {
    // Tracking
    this.context.setBrowsingPage(TrackingPageName.CompareList);

    let params1 = new URLSearchParams(window.location.search).get("packages1");
    let params2 = new URLSearchParams(window.location.search).get("packages2");
    let params3 = new URLSearchParams(window.location.search).get("packages3");
    let AllParams = [];

    if (params1 !== "null") {
      AllParams.push(params1);
    }
    if (params2 !== "null") {
      AllParams.push(params2);
    }
    if (params3 !== "null") {
      AllParams.push(params3);
    }

    this.setState({ idParams: AllParams });
    this.props.getCompareListAction({ params: { packages: AllParams } });

    this.props.getMetaDetailAction({ params: { page: "compare" } });

    this.setState({
      url: window.location.href,
    });
  };

  componentWillUnmount = () => {
    this.props.getResponseNullAction();
  };
  handleCompare = (id) => {
    this.setState({
      open: true,
      requestId: id,
    });
  };
  handleDetail = (id) => {
    this.setState({
      popularPackage: id,
    });
    this.props.getPackageDetailAction({ params: { package_id: id } });
    localStorage.setItem("popularPackage", JSON.stringify(id));
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleClick = () => {
    this.setState({
      open: true,
    });
  };

  delete = (id) => {
    const afterdelete = this.state.idParams.filter(
      (el) => el !== JSON.stringify(id)
    );
    this.props.history.push({
      pathName: "/comparelist",
      search: `?packages1=${afterdelete[0]}&packages2=${
        afterdelete[1] ? afterdelete[1] : "null"
      }&packages3=${afterdelete[2] ? afterdelete[2] : "null"}`,
    });

    window.location.reload();
  };
  share = () => {
    this.setState({
      shareBox: true,
    });
  };
  closeShare = () => {
    this.setState({
      shareBox: false,
    });
  };
  onRequestSubmit = () => {};

 
  componentDidMount() {
    const tableContainer = document.querySelector(".table-container");
    if (tableContainer) {
      tableContainer.addEventListener("scroll", this.handleScroll);
    }
  }

  componentWillUnmount() {
    const tableContainer = document.querySelector(".table-container");
    if (tableContainer) {
      tableContainer.removeEventListener("scroll", this.handleScroll);
    }
  }
  handleScroll(event) {
    const tableContainer = document.querySelector(".table-container");
      if (tableContainer) {
        const scrollTop = tableContainer.scrollTop;
        this.setState({
            showImageRow: (scrollTop <= 0)
        })
      }
  }
  // ==================================
  render() {
    const previousPage= sessionStorage.getItem("previousPage");
    const { showImageRow } = this.state;

    const { classes, _metaDetail } = this.props;
    const { _comparelist } = this.props;
    const data =
      _comparelist &&
      _comparelist.data &&
      _comparelist.data.map((obj) => {
        return obj;
      });

    const package0 = data && data[0];

    const id0 = package0 && package0.id;
    const tourSlug0 = package0 && package0.tour_slug;
    const saleInfo = data && new SaleInfo(data[0]?.sale_info);

   

    const shouldShowRetailPrice0 =
      (saleInfo && saleInfo.retailPrice && saleInfo.shouldShowRetailPrice) ??
      false;
    const retailPrice0 = saleInfo && saleInfo.retailPrice;
    const salePrice0 = saleInfo && saleInfo.listPrice;
    const discountPercentage0 = saleInfo && saleInfo.discountPercentage;

    const packageName0 =
      package0 && package0.tour_name && package0.tour_name.toLowerCase();
    const packageImage0 = package0 && package0.package_images;
    const packagePrice0 = package0 && package0.price;
    const packageDuration0 = package0 && package0.duration;
    const destination0 = package0 && package0.destinations;
    const Accomodation0 = package0 && package0.package_accomodation;
    const inclusion0 = package0 && package0.package_inclusion;

    const sightSeeing0 = package0 && package0.sightseeing;

    const tableHtml0 =
      inclusion0 && inclusion0.inclusions
        ? inclusion0.inclusions.replace(
            "<table",
            '<table  style={{width: "100%"}}'
          )
        : "";

    //  1data
    const package1 = data && data[1];
    const id1 = package1 && package1.id;
    const packageName1 =
      package1 && package1.tour_name && package1.tour_name.toLowerCase();
    const packageImage1 = package1 && package1.package_images;
    const packagePrice1 = package1 && package1.price;
    const packageDuration1 = package1 && package1.duration;
    const destination1 = package1 && package1.destinations;
    const Accomodation1 = package1 && package1.package_accomodation;
    const sightSeeing1 = package1 && package1.sightseeing;
    const inclusion1 = package1 && package1.package_inclusion;
    const tourSlug1 = package1 && package1.tour_slug;

    const saleInfo1 = data && new SaleInfo(package1?.sale_info);
    const shouldShowRetailPrice1 =
      (saleInfo1 && saleInfo1.retailPrice && saleInfo1.shouldShowRetailPrice) ??
      false;
    const retailPrice1 = saleInfo1 && saleInfo1.retailPrice;
    const salePrice1 = saleInfo1 && saleInfo1.listPrice;
    const discountPercentage1 = saleInfo1 && saleInfo1.discountPercentage;
    const tableHtml1 =
      inclusion1 && inclusion1.inclusions
        ? inclusion1.inclusions.replace(
            "<table",
            '<table  style={{width: "100%"}}'
          )
        : "";

    //  2data
    const package2 = data && data[2];
    const id2 = package2 && package2.id;
    const packageName2 =
      package2 && package2.tour_name && package2.tour_name.toLowerCase();
    const packageImage2 = package2 && package2.package_images;
    const packagePrice2 = package2 && package2.price;
    const packageDuration2 = package2 && package2.duration;
    const destination2 = package2 && package2.destinations;
    const Accomodation2 = package2 && package2.package_accomodation;
    const inclusion2 = package2 && package2.package_inclusion;
    const sightSeeing2 = package2 && package2.sightseeing;
    const tourSlug2 = package2 && package2.tour_slug;

    const saleInfo2 = package2 && new SaleInfo(package2?.sale_info);

    const shouldShowRetailPrice2 =
      (saleInfo2 && saleInfo2.retailPrice && saleInfo2.shouldShowRetailPrice) ??
      false;
    const retailPrice2 = saleInfo2 && saleInfo2.retailPrice;
    const salePrice2 = saleInfo2 && saleInfo2.listPrice;
    const discountPercentage2 = saleInfo2 && saleInfo2.discountPercentage;

    const packageDetails0 = {
      packageName: packageName0,
      packageImage: packageImage0,
      packageListPrice: salePrice0,
      packageRetailPrice: retailPrice0,
      packageId: id0,
      shouldShowRetailPrice: shouldShowRetailPrice0,
      slug: tourSlug0,
      discount: saleInfo?.discountPercentage,
    };
    const packageDetails1 = {
      packageName: packageName1,
      packageImage: packageImage1,
      packageListPrice: salePrice1,
      packageRetailPrice: retailPrice1,
      packageId: id1,
      shouldShowRetailPrice: shouldShowRetailPrice1,
      slug: tourSlug1,
      discount: saleInfo1?.discountPercentage,
    };
    const packageDetails2 = {
      packageName: packageName2,
      packageImage: packageImage2,
      packageListPrice: salePrice2,
      packageRetailPrice: retailPrice2,
      packageId: id2,
      shouldShowRetailPrice: shouldShowRetailPrice2,
      slug: tourSlug2,
      discount: saleInfo2?.discountPercentage2,
    };

    const isMobile = window.innerWidth <= 768;
    const gridClass = `${
      isMobile && this.state.isVisibleNav
        ? "grid-container-hide"
        : "grid-container-show"
    }`;

    return (
      <React.Fragment>
        <FormModal
          packageDetails={packageDetails0}
          openCustomizeModal={this.state.openCallRequestModal0}
          handleCloseCustomizeModal={this.handleCloseRequestModal0}
          children={<RequestCallForm />}
          origin={TrackingOrigin.LeadForm.CompareList}
          // setCurrentView={this.setCurrentView}
        />
        <FormModal
          packageDetails={packageDetails1}
          openCustomizeModal={this.state.openCallRequestModal1}
          handleCloseCustomizeModal={this.handleCloseRequestModal1}
          children={<RequestCallForm />}
          origin={TrackingOrigin.LeadForm.CompareList}
          // setCurrentView={this.setCurrentView}
        />
        <FormModal
          packageDetails={packageDetails2}
          openCustomizeModal={this.state.openCallRequestModal2}
          handleCloseCustomizeModal={this.handleCloseRequestModal2}
          children={<RequestCallForm />}
          origin={TrackingOrigin.LeadForm.CompareList}
          // setCurrentView={this.setCurrentView}
        />
        <Helmet>
          <title>
            {_metaDetail.data ? _metaDetail.data.data.meta_title : ""}
          </title>
          <meta charset="UTF-8" />
          <meta
            name="description"
            content={
              _metaDetail.data ? _metaDetail.data.data.meta_description : ""
            }
          />
          <meta
            name="keywords"
            content={_metaDetail.data ? _metaDetail.data.data.meta_keyword : ""}
          />
          <meta name="author" content="Tripstore" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
        {window.innerWidth <= 768 ? (
          <>
            {" "}
            <Container>
              <Header />
              <div
                className="table-container"
                onScroll={this.handleScroll}
                style={{ marginTop: "65px" }}
              >
                {
                  <table className="comparison-table">
                    <thead
                      className="fixed-header"
                      style={{
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                      }}
                    >
                      <tr
                        style={{
                          borderRight: "0px solid white",
                          borderBottom: "0px solid white",

                          backgroundColor: "var(--card-background-color2)",
                          borderTopLeftRadius: "0px",
                        }}
                      >
                        <th
                          id="fixed-rows-left"
                        
                          style={{
                          textAlign: "center",
                          }}
                          // className="attribute-column"
                        >
                          {" "}
                          {capitalizeSentences(
                            packageName0 ? packageName0 : ""
                          )}
                        </th>

                        <th
                          id="fixed-rows-right"
                          style={{
                            textAlign: "center",
                            }}
                          className="attribute-column"
                        >
                          {capitalizeSentences(
                            packageName1 ? packageName1 : ""
                          )}
                        </th>
                      </tr>
                      {showImageRow && (
                        <tr>
                          <td id="fixed-rows-left">
                            <img
                              src={packageImage0}
                              alt={packageName0}
                              className="product-image"
                            />
                          </td>

                          <td id="fixed-rows-right">
                            <img
                              src={packageImage1}
                              alt={packageName1}
                              className="product-image"
                            />
                          </td>
                        </tr>
                      )}

                      <tr
                        id="fixed-rows-left"
                        // className="fixed-row"
                        style={{
                          borderRight: "0px solid white",
                          borderBottom: "0px solid white",
                          border: "0px solid white",
                          backgroundColor: "var(--card-background-color2)",
                        }}
                      >
                        <td className="left-align" id="fixed-rows-left">
                          <Typography
                            className={classes.content}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            ₹
                            {Number(packagePrice0).toLocaleString("en-IN", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                            <p style={{ fontSize: "10px", marginLeft: "5px" }}>
                              per person
                            </p>
                          </Typography>{" "}
                        </td>

                        <td className="right-align" id="fixed-rows-right">
                          <Typography
                            className={classes.content}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            ₹
                            {Number(packagePrice1).toLocaleString("en-IN", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                            <p style={{ fontSize: "10px", marginLeft: "5px" }}>
                              per person
                            </p>
                          </Typography>
                        </td>
                      </tr>
                      <tr
                        style={{
                          borderRight: "0px solid white",
                          borderBottom: "0px solid white",
                          border: "0px solid white",
                          backgroundColor: "var(--card-background-color2)",
                        }}
                        className="fixed-row"
                      >
                        <td className="left-align" id="fixed-rows-left">
                          {" "}
                          <Typography
                            variant="subtitle1"
                            className={classes.content}
                          >
                            {packageDuration0}
                          </Typography>
                        </td>

                        <td style={{textAlign:"right"}} id="fixed-rows-right">
                          {" "}
                          <Typography
                            variant="subtitle1"
                            className={classes.content}
                          >
                            {packageDuration1}
                          </Typography>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="attribute-row">
                        <td className="attribute-heading">
                          Destinations
                        </td>
                      </tr>
                      <tr>
                        <td className="left-align">
                          {destination0 &&
                            destination0.map((item) => (
                              <Typography
                                // className={classes.content}
                                variant="subtitle1"
                              >
                                {item.city_name}({item.number_of_night})
                              </Typography>
                            ))}
                        </td>

                        <td className="left-align" >
                          {" "}
                          {destination1 &&
                            destination1.map((item) => (
                              <Typography
                                className={classes.content}
                                variant="subtitle1"
                              >
                                {item.city_name}({item.number_of_night})
                              </Typography>
                            ))}
                        </td>
                      </tr>
                      <tr className="attribute-row">
                        <td className="attribute-heading">
                          Accommodation
                        </td>
                      </tr>

                      <tr>
                        <td className="left-align">
                          {" "}
                          {Accomodation0 &&
                            Accomodation0.map((item) => (
                              <Grid
                                style={{ marginTop: "2%" }}
                                className={classes.content}
                              >
                                {item.itenirary.itenirary_location.city_name ? (
                                  <Typography variant="overline">
                                    {
                                      item.itenirary.itenirary_location
                                        .city_name
                                    }
                                  </Typography>
                                ) : (
                                  <Typography variant="overline">
                                    {
                                      item.itenirary.itenirary_location
                                        .state_name
                                    }
                                  </Typography>
                                )}
                                <Typography
                                  variant="subtitle1"
                                  style={{ lineHeight: "normal" }}
                                >
                                  {item.hotel_name}
                                </Typography>
                              </Grid>
                            ))}
                        </td>

                        <td className="left-align">
                          {" "}
                          {Accomodation1 &&
                            Accomodation1.map((item) => (
                              <Grid
                                style={{ marginTop: "2%" }}
                                className={classes.content}
                              >
                                {item.itenirary.itenirary_location.city_name ? (
                                  <Typography variant="overline">
                                    {
                                      item.itenirary.itenirary_location
                                        .city_name
                                    }
                                  </Typography>
                                ) : (
                                  <Typography variant="overline">
                                    {
                                      item.itenirary.itenirary_location
                                        .state_name
                                    }
                                  </Typography>
                                )}
                                <Typography
                                  variant="subtitle1"
                                  style={{ lineHeight: "normal" }}
                                >
                                  {item.hotel_name}
                                </Typography>
                              </Grid>
                            ))}
                        </td>
                      </tr>
                      <tr className="attribute-row">
                        <td className="attribute-heading">
                          Sightseeing
                        </td>
                      </tr>
                      <tr>
                        <td className="left-align">
                          {" "}
                          {sightSeeing0 &&
                            sightSeeing0.map((item) => (
                              <Grid
                                style={{ marginTop: "2%" }}
                                className={classes.content}
                              >
                                <Typography variant="overline">
                                  {item.city_name}
                                </Typography>
                                {Array.isArray(item.sight_title) &&
                                  item.sight_title.map((obj) => (
                                    <Grid style={{ display: "flex" }}>
                                      <FiberManualRecordIcon
                                        fontSize="small"
                                        style={{
                                          height: 5,
                                          width: 7,
                                          margin: 5,
                                        }}
                                      />

                                      <Typography
                                        variant="subtitle1"
                                        style={{
                                          color: "#16222b",
                                          lineHeight: "normal",
                                        }}
                                      >
                                        {obj}
                                      </Typography>
                                    </Grid>
                                  ))}

                                <Grid></Grid>
                              </Grid>
                            ))}
                        </td>

                        <td className="left-align">
                          {" "}
                          {sightSeeing1 &&
                            sightSeeing1.map((item) => (
                              <Grid
                                style={{ marginTop: "2%" }}
                                className={classes.content}
                              >
                                <Typography variant="overline">
                                  {item.city_name}
                                </Typography>
                                {Array.isArray(item.sight_title) &&
                                  item.sight_title.map((obj) => (
                                    <Grid style={{ display: "flex" }}>
                                      <FiberManualRecordIcon
                                        fontSize="small"
                                        style={{
                                          height: 5,
                                          width: 7,
                                          margin: 5,
                                        }}
                                      />

                                      <Typography
                                        variant="subtitle1"
                                        style={{
                                          color: "#16222b",
                                          lineHeight: "normal",
                                        }}
                                      >
                                        {obj}
                                      </Typography>
                                    </Grid>
                                  ))}

                                <Grid></Grid>
                              </Grid>
                            ))}
                        </td>
                      </tr>
                      <tr className="attribute-row">
                        <td className="attribute-heading">
                          Inclusions
                        </td>
                      </tr>
                      <tr>
                        <td className="left-align">
                          {" "}
                          {inclusion0 !== null ? (
                            <Typography
                              dangerouslySetInnerHTML={{
                                __html: tableHtml0,
                              }}
                              variant="body2"
                              style={{ color: "#16222b" }}
                            />
                          ) : (
                            ""
                          )}
                        </td>

                        <td className="left-align">
                          {inclusion1 !== null ? (
                            <Typography
                              dangerouslySetInnerHTML={{
                                __html: tableHtml1,
                              }}
                              variant="body2"
                              style={{ color: "#16222b" }}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              textTransform: "none",
                              fontSize: "14px",
                            }}
                            // onClick={() => this.handleCompare(id0)}
                            onClick={() => {
                              this.handleRequestCallClick(0);
                            }}
                          >
                            Request a call
                          </Button>
                        </td>

                        <td>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              textTransform: "none",
                              fontSize: "14px",
                            }}
                            // onClick={() => this.handleCompare(id0)}
                            onClick={() => {
                              this.handleRequestCallClick(1);
                            }}
                          >
                            Request a call
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
            </Container>{" "}
          </>
        ) : (
          <>
            {" "}
            <Container>
              <Header />
              {!_comparelist.data ? (
                <div>
                  <Skeleton width={400} height={800} count={3} />
                </div>
              ) : (
                <Grid container className={`divToPrint ${gridClass}`}>
                  <Grid
                    item
                    md={12}
                    style={{
                      top: 100,
                      marginBottom: "5%",
                      position: "relative",
                      display: "grid",
                      // height: "85vh",
                    }}
                  >
                    <Grid md={12} xs={12} item justify="space-around">
                      <Grid container spacing={3} xs>
                        {package0 ? (
                          <Grid
                            item
                            xs
                            sm={4}
                            gutter={8}
                            className={classes.borders}
                            style={{ display: "flex" }}
                          >
                            <Grid md={12} container>
                              <Grid md={10} item justify="flex-start">
                                <Button
                                  variant="text"
                                  style={{ textTransform: "none" }}
                                  onClick={() => this.handleDetail(id0)}
                                  component={Link}
                                  to={`/packagedetails/${tourSlug0}`}
                                >
                                  <Typography
                                    variant="h6"
                                    style={{
                                      left: 5,
                                      color: "#16222b",
                                      lineHeight: "normal",
                                    }}
                                    className={classes.packageName}
                                  >
                                    {packageName0}
                                  </Typography>
                                </Button>
                              </Grid>
                              {this.state.idArray.length < 2 ? (
                                ""
                              ) : (
                                <Grid md={2} item justify="flex-end">
                                  <IconButton
                                    onClick={this.delete.bind(this, id0)}
                                    size="small"
                                    edge="end"
                                    style={{ left: 20 }}
                                  >
                                    <CloseOutlinedIcon
                                      style={{ color: "#16222b" }}
                                    />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        ) : (
                          ""
                        )}

                        {package1 ? (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            className={classes.borders}
                            style={{ display: "flex", paddingRight: 0 }}
                          >
                            <Grid md={12} container>
                              <Grid md={10} item justify="flex-start">
                                <Button
                                  variant="text"
                                  style={{ textTransform: "none" }}
                                  onClick={() => this.handleDetail(id1)}
                                  component={Link}
                                  to={`/packagedetails/${tourSlug1}`}
                                >
                                  <Typography
                                    variant="h6"
                                    style={{
                                      left: 5,
                                      color: "#16222b",
                                      lineHeight: "normal",
                                    }}
                                    className={classes.packageName}
                                  >
                                    {packageName1}
                                  </Typography>
                                </Button>
                              </Grid>
                              <Grid md={2} item justify="flex-end">
                                <IconButton
                                  onClick={this.delete.bind(this, id1)}
                                  size="small"
                                  edge="end"
                                  style={{ left: 20 }}
                                >
                                  <CloseOutlinedIcon
                                    style={{ color: "#16222b" }}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* <Grid md={1} xs/> */}
                        {package2 ? (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            className={classes.borders}
                            style={{ display: "flex", paddingRight: 0 }}
                          >
                            <Grid md={12} container>
                              <Grid md={10} item justify="flex-start">
                                <Button
                                  variant="text"
                                  style={{ textTransform: "none" }}
                                  onClick={() => this.handleDetail(id2)}
                                  component={Link}
                                  to={`/packagedetails/${tourSlug2}`}
                                >
                                  <Typography
                                    variant="h6"
                                    style={{
                                      color: "#16222b",
                                      lineHeight: "normal",
                                    }}
                                    className={classes.packageName}
                                  >
                                    {packageName2}
                                  </Typography>
                                </Button>
                              </Grid>
                              <Grid md={2} item justify="flex-end">
                                <IconButton
                                  onClick={this.delete.bind(this, id2)}
                                  size="small"
                                  edge="end"
                                  style={{ left: 20, color: "#16222b" }}
                                >
                                  <CloseOutlinedIcon
                                    style={{ color: "#16222b" }}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>

                      <Grid container spacing={3} xs={12}>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{
                            borderLeft: "1px solid #e9eaeb",
                            borderRight: "1px solid #e9eaeb",
                            borderSpacing: "1em .5em",
                          }}
                        >
                          <CardMedia
                            style={{
                              borderRadius: "4px",
                              height: 250,
                              objectFit: "cover",
                            }}
                            ref="html"
                            component="img"
                            image={packageImage0}
                          />
                          {/* <img src={packageImage0} src="image0" style={{ height:200,width:200}}></img> */}
                        </Grid>
                        {package1 ? (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{
                              borderLeft: "1px solid #e9eaeb",
                              borderRight: "1px solid #e9eaeb",
                            }}
                          >
                            <CardMedia
                              style={{
                                borderRadius: "4px",
                                height: 250,
                                objectFit: "cover",
                              }}
                              component="img"
                              image={!packageImage1 ? DumyImage : packageImage1}
                            />
                          </Grid>
                        ) : (
                          ""
                        )}
                        {package2 ? (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{
                              borderLeft: "1px solid #e9eaeb",
                              borderRight: "1px solid #e9eaeb",
                            }}
                          >
                            <CardMedia
                              style={{
                                borderRadius: "4px",
                                height: 250,
                                objectFit: "cover",
                              }}
                              component="img"
                              image={packageImage2}
                            />
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>

                      <Grid container spacing={3} xs={12}>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{
                            borderLeft: "1px solid #e9eaeb",
                            borderRight: "1px solid #e9eaeb",
                            marginBottom: 15,
                          }}
                        >
                          <Typography className={classes.title} variant="body2">
                            Price:
                          </Typography>
                          <Typography className={classes.content} variant="h6"   style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}>
                          ₹
                            {Number(packagePrice0).toLocaleString("en-IN", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                             <p style={{ fontSize: "10px", marginLeft: "5px" }}>
                              per person
                            </p>
                          </Typography>
                        </Grid>

                        {package1 ? (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{
                              borderLeft: "1px solid #e9eaeb",
                              borderRight: "1px solid #e9eaeb",
                              marginBottom: 15,
                            }}
                          >
                            <Typography
                              className={classes.title}
                              variant="body2"
                            >
                              Price:
                            </Typography>
                            <Typography
                              className={classes.content}
                              variant="h6"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                          ₹
                              {Number(packagePrice1).toLocaleString("en-IN", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}
                               <p style={{ fontSize: "10px", marginLeft: "5px" }}>
                              per person
                            </p>
                            </Typography>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {package2 ? (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{
                              borderLeft: "1px solid #e9eaeb",
                              borderRight: "1px solid #e9eaeb",
                              marginBottom: 15,
                            }}
                          >
                            <Typography
                              className={classes.title}
                              variant="body2"
                            >
                              Price:
                            </Typography>
                            <Typography
                              className={classes.content}
                              variant="h6"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                           ₹
                              {Number(packagePrice2).toLocaleString("en-IN", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}
                                <p style={{ fontSize: "10px", marginLeft: "5px" }}>
                              per person
                            </p>
                            </Typography>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>

                      <Grid container spacing={3} xs={12}>
                        <Grid item xs={12} sm={4} className={classes.borders}>
                          <Typography className={classes.title} variant="body2">
                            Duration:
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            className={classes.content}
                          >
                            {packageDuration0}
                          </Typography>
                        </Grid>
                        {package1 ? (
                          <Grid item xs={12} sm={4} className={classes.borders}>
                            <Typography
                              className={classes.title}
                              variant="body2"
                            >
                              Duration:
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              className={classes.content}
                            >
                              {packageDuration1}
                            </Typography>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {package2 ? (
                          <Grid item xs={12} sm={4} className={classes.borders}>
                            <Typography
                              className={classes.title}
                              variant="body2"
                            >
                              Duration:{" "}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              className={classes.content}
                            >
                              {packageDuration2}
                            </Typography>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>

                      {/* <hr/> */}

                      {/*************Destinations********************************** ************* */}
                      <Grid container spacing={3} xs={12}>
                        <Grid item xs={12} sm={4} className={classes.borders}>
                          <Typography variant="body2" className={classes.title}>
                            Destinations:
                          </Typography>
                          {destination0 &&
                            destination0.map((item) => (
                              <Typography
                                className={classes.content}
                                variant="subtitle1"
                              >
                                {item.city_name}({item.number_of_night})
                              </Typography>
                            ))}
                        </Grid>
                        {package1 ? (
                          <Grid item xs={12} sm={4} className={classes.borders}>
                            <Typography
                              variant="body2"
                              className={classes.title}
                            >
                              Destinations:
                            </Typography>
                            {destination1 &&
                              destination1.map((item) => (
                                <Typography
                                  className={classes.content}
                                  variant="subtitle1"
                                >
                                  {item.city_name}({item.number_of_night})
                                </Typography>
                              ))}
                          </Grid>
                        ) : (
                          ""
                        )}
                        {package2 ? (
                          <Grid item xs={12} sm={4} className={classes.borders}>
                            <Typography
                              variant="body2"
                              className={classes.title}
                            >
                              Destinations:
                            </Typography>
                            {destination2 &&
                              destination2.map((item) => (
                                <Typography
                                  className={classes.content}
                                  variant="subtitle1"
                                >
                                  {item.city_name}({item.number_of_night})
                                </Typography>
                              ))}
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                      {/* **************************************** */}
                      {/* <hr/> */}
                      <Grid container spacing={3} xs={12}>
                        <Grid item xs={12} sm={4} className={classes.borders}>
                          <Typography variant="body2" className={classes.title}>
                            Accomodations:
                          </Typography>
                          {Accomodation0 &&
                            Accomodation0.map((item) => (
                              <Grid
                                style={{ marginTop: "2%" }}
                                className={classes.content}
                              >
                                {item.itenirary.itenirary_location.city_name ? (
                                  <Typography variant="overline">
                                    {
                                      item.itenirary.itenirary_location
                                        .city_name
                                    }
                                  </Typography>
                                ) : (
                                  <Typography variant="overline">
                                    {
                                      item.itenirary.itenirary_location
                                        .state_name
                                    }
                                  </Typography>
                                )}
                                <Typography
                                  variant="subtitle1"
                                  style={{ lineHeight: "normal" }}
                                >
                                  {item.hotel_name}
                                </Typography>
                              </Grid>
                            ))}
                        </Grid>
                        {package1 ? (
                          <Grid item xs={12} sm={4} className={classes.borders}>
                            <Typography
                              variant="body2"
                              className={classes.title}
                            >
                              Accomodations:
                            </Typography>
                            {Accomodation1 &&
                              Accomodation1.map((item) => (
                                <Grid
                                  style={{ marginTop: "2%" }}
                                  className={classes.content}
                                >
                                  {item.itenirary.itenirary_location
                                    .city_name ? (
                                    <Typography variant="overline">
                                      {
                                        item.itenirary.itenirary_location
                                          .city_name
                                      }
                                    </Typography>
                                  ) : (
                                    <Typography variant="overline">
                                      {
                                        item.itenirary.itenirary_location
                                          .state_name
                                      }
                                    </Typography>
                                  )}
                                  <Typography
                                    variant="subtitle1"
                                    style={{ lineHeight: "normal" }}
                                  >
                                    {item.hotel_name}
                                  </Typography>
                                </Grid>
                              ))}
                          </Grid>
                        ) : (
                          ""
                        )}
                        {package2 ? (
                          <Grid item xs={12} sm={4} className={classes.borders}>
                            <Typography
                              variant="body2"
                              className={classes.title}
                            >
                              Accomodations:
                            </Typography>
                            {Accomodation2 &&
                              Accomodation2.map((item) => (
                                <Grid
                                  style={{ marginTop: "2%" }}
                                  className={classes.content}
                                >
                                  {item.itenirary.itenirary_location
                                    .city_name ? (
                                    <Typography variant="overline">
                                      {
                                        item.itenirary.itenirary_location
                                          .city_name
                                      }
                                    </Typography>
                                  ) : (
                                    <Typography variant="overline">
                                      {
                                        item.itenirary.itenirary_location
                                          .state_name
                                      }
                                    </Typography>
                                  )}
                                  <Typography
                                    variant="subtitle1"
                                    style={{ lineHeight: "normal" }}
                                  >
                                    {item.hotel_name}
                                  </Typography>
                                </Grid>
                              ))}
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid container spacing={3} xs={12}>
                        <Grid item xs={12} sm={4} className={classes.borders}>
                          <Typography variant="body2" className={classes.title}>
                            Sightseeing:
                          </Typography>
                          {sightSeeing0 &&
                            sightSeeing0.map((item) => (
                              <Grid
                                style={{ marginTop: "2%" }}
                                className={classes.content}
                              >
                                <Typography variant="overline">
                                  {item.city_name}
                                </Typography>
                                {Array.isArray(item.sight_title) &&
                                  item.sight_title.map((obj) => (
                                    <Grid style={{ display: "flex" }}>
                                      <FiberManualRecordIcon
                                        fontSize="small"
                                        style={{
                                          height: 5,
                                          width: 7,
                                          margin: 5,
                                        }}
                                      />

                                      <Typography
                                        variant="subtitle1"
                                        style={{
                                          color: "#16222b",
                                          lineHeight: "normal",
                                        }}
                                      >
                                        {obj}
                                      </Typography>
                                    </Grid>
                                  ))}

                                <Grid></Grid>
                              </Grid>
                            ))}
                        </Grid>
                        {package1 ? (
                          <Grid item xs={12} sm={4} className={classes.borders}>
                            <Typography
                              variant="body2"
                              className={classes.title}
                            >
                              Sightseeing:
                            </Typography>
                            {sightSeeing1 &&
                              sightSeeing1.map((item) => (
                                <Grid>
                                  <Typography
                                    className={classes.content}
                                    variant="overline"
                                  >
                                    {item.city_name}
                                  </Typography>

                                  {Array.isArray(item.sight_title) &&
                                    item.sight_title.map((obj) => (
                                      <Grid style={{ display: "flex" }}>
                                        <FiberManualRecordIcon
                                          fontSize="small"
                                          style={{
                                            height: 5,
                                            width: 7,
                                            margin: 5,
                                            // arginLeft: "8px"
                                          }}
                                        />
                                        <Typography
                                          variant="subtitle1"
                                          style={{
                                            color: "#16222b",
                                            lineHeight: "normal",
                                          }}
                                        >
                                          {obj}
                                        </Typography>
                                      </Grid>
                                    ))}
                                </Grid>
                              ))}
                          </Grid>
                        ) : (
                          ""
                        )}
                        {package2 ? (
                          <Grid item xs={12} sm={4} className={classes.borders}>
                            <Typography
                              variant="body2"
                              className={classes.title}
                            >
                              Sightseeing:
                            </Typography>
                            {sightSeeing2 &&
                              sightSeeing2.map((item) => (
                                <Grid>
                                  <Typography
                                    className={classes.content}
                                    variant="overline"
                                  >
                                    {item.city_name}
                                  </Typography>
                                  {Array.isArray(item.sight_title) &&
                                    item.sight_title.map((obj) => (
                                      <Grid style={{ display: "flex" }}>
                                        <FiberManualRecordIcon
                                          fontSize="small"
                                          style={{
                                            height: 5,
                                            width: 7,
                                            margin: 5,
                                            // marginTop: 16,
                                            // arginLeft: "8px"
                                          }}
                                        />
                                        <Typography
                                          variant="subtitle1"
                                          style={{
                                            color: "#16222b",
                                            lineHeight: "normal",
                                          }}
                                        >
                                          {obj}
                                        </Typography>
                                      </Grid>
                                    ))}

                                  <Grid></Grid>
                                </Grid>
                              ))}
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>

                      {/* inclusions  */}
                      <Grid container spacing={3} xs={12}>
                        <Grid item xs={12} sm={4} className={classes.borders}>
                          <Typography variant="body2" className={classes.title}>
                            Inclusions:
                          </Typography>
                          <Grid>
                            {inclusion0 !== null ? (
                              <Typography
                                dangerouslySetInnerHTML={{
                                  __html: inclusion0 && inclusion0.inclusions,
                                }}
                                variant="body2"
                                style={{ marginLeft: "2%", color: "#16222b" }}
                              />
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Grid>
                        {package1 ? (
                          <Grid item xs={12} sm={4} className={classes.borders}>
                            <Typography
                              variant="body2"
                              className={classes.title}
                            >
                              Inclusions:
                            </Typography>
                            {inclusion1 !== null ? (
                              <Typography
                                dangerouslySetInnerHTML={{
                                  __html: inclusion1 && inclusion1.inclusions,
                                }}
                                variant="body2"
                                style={{ marginLeft: "2%", color: "#16222b" }}
                              />
                            ) : (
                              ""
                            )}
                          </Grid>
                        ) : (
                          ""
                        )}
                        {package2 ? (
                          <Grid item xs={12} sm={4} className={classes.borders}>
                            <Typography
                              variant="body2"
                              className={classes.title}
                            >
                              Inclusions:
                            </Typography>

                            <Grid>
                              {inclusion2 !== null ? (
                                <Typography
                                  dangerouslySetInnerHTML={{
                                    __html: inclusion2 && inclusion2.inclusions,
                                  }}
                                  variant="body2"
                                  style={{ marginLeft: "2%", color: "#16222b" }}
                                />
                              ) : (
                                ""
                              )}
                            </Grid>

                            {/* ))} */}
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>

                      <Grid container spacing={3} xs={12}>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{
                            borderLeft: "1px solid #e9eaeb",
                            borderBottom: "1px solid #e9eaeb",
                            borderRight: "1px solid #e9eaeb",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ width: "100%", textTransform: "none" }}
                            // onClick={() => this.handleCompare(id0)}
                            onClick={() => {
                              this.handleRequestCallClick(0);
                            }}
                          >
                            Request a call
                          </Button>
                        </Grid>
                        {package1 ? (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{
                              borderLeft: "1px solid #e9eaeb",
                              borderBottom: "1px solid #e9eaeb",
                              borderRight: "1px solid #e9eaeb",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ width: "100%", textTransform: "none" }}
                              // onClick={() => this.handleCompare(id1)}
                              onClick={() => {
                                this.handleRequestCallClick(1);
                              }}
                            >
                              Request a call
                            </Button>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {package2 ? (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{
                              borderLeft: "1px solid #e9eaeb",
                              borderBottom: "1px solid #e9eaeb",
                              borderRight: "1px solid #e9eaeb",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ width: "100%", textTransform: "none" }}
                              // onClick={() => this.handleCompare(id2)}
                              onClick={() => {
                                this.handleRequestCallClick(2);
                              }}
                            >
                              Request a call
                            </Button>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Registration
                    open={this.state.open}
                    id={this.state.requestId}
                    onClose={this.handleClose}
                    url={this.state.url}
                    pageNumber={this.state.pageNumber}
                    origin={TrackingOrigin.LeadForm.CompareList}
                    onRequestSubmit={this.onRequestSubmit}
                  />
                  <SharePopup
                    open={this.state.shareBox}
                    url={this.state.url}
                    onClose={this.closeShare}
                  />
                </Grid>
              )}
            </Container>
            <Grid
              style={{
                display: "contents",
                position: "relative",
               
              }}
            >
              {_comparelist.data && _comparelist.data.length < 3 ? (
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "fixed",
                    top: 80,
                    right: 100,
                   
                  }}
                  justify="flex-end"
                >
                  {!package1 ? (
                    <Grid container md={12} xs={12}>
                      <Addpackage previousPage={previousPage} />
                    </Grid>
                  ) : (
                    ""
                  )}
                  {!package2 ? (
                    <Grid container md={12} xs={12}>
                      <Addpackage previousPage={previousPage}  />
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              ) : (
                ""
              )}

              <Grid container justify="flex-end">
                <IconButton
                  style={{
                    background: "#ff6840",
                    position: "sticky",
                    right: 50,
                    bottom: 20,
                  }}
                  onClick={this.share}
                >
                  <ShareIcon style={{ color: "#fff" }} />
                </IconButton>
              </Grid>
            </Grid>
          </>
        )}

        <Grid style={{ marginTop: "4%" }}>
          <Footer />
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({
  getCompareListReducer,
  getPackageListReducer,
  getPackageDetailReducer,
  getMetaDetailReducer,
}) => {
  return {
    _comparelist: getCompareListReducer,
    _packageList: getPackageListReducer,
    _packageDetails: getPackageDetailReducer,
    _metaDetail: getMetaDetailReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getCompareListAction,
    getPackageListAction,
    getPackageDetailAction,
    getResponseNullAction,
    getMetaDetailAction,
  })(withStyles(styles)(CompareList))
);
