import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./twoitemslider.css";

function TwoItemsSlider({ divs }) {
  const settings = {
    dots: true,
    dotsClass: "slick-dots custom-dots",
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    lazyLoad: "progressive",
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        zIndex: "1000",
      }}
    >
      <Slider {...settings}>{divs}</Slider>
    </div>
  );
}

export default TwoItemsSlider;
