import axios from "axios"

export const getPackageListService = (request) => {
        return axios
                .get("/package/list", request.data)
                .then((response) => {
                        return response.data
                })
                .catch((err) => {
                        return err.response.data
                })
}
