import React, { Component } from "react"
import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"
import { Button, MenuItem, IconButton, Container, Dialog, Slide, DialogContent } from "@material-ui/core"
import DateRangeIcon from "@material-ui/icons/DateRange"
import Typography from "@material-ui/core/Typography"
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined"
import TextField from "@material-ui/core/TextField"
import Stepper from "./input"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
// import Month from "./monthforform";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import SubmitForm from "../Banner/Call/userForm"
import { getInclusionAction } from "../../store/actions/getInclusion.action"
import { TrackingOrigin } from "../../../tracking/trackingConstants"
import { Tracker } from "../../../tracking/tracker"
import { CustomiseFormOpenEvent } from "../../../tracking/events/customiseFormOpenEvent"
import { getMonthAction } from "../../store/actions/getMonths"
import { getThemeAction } from "../../store/actions/getTheme.action"

const style = (theme) => ({
	root: {
		padding: theme.spacing(5),
	},
	textField: {
		color: "#16222b",
		width: "100%",
	},
	input: {
		"&::placeholder": {
			color: "#bbc2c8",
		},
		fontSize: "14px",
		color: "#616e78",
		height: 50,
	},
	img: {
		cursor: "pointer",
	},
	Icon: {
		textTransform: "none",
		padding: 8,
		"&:hover": {
			backgroundColor: "transparent",
		},
	},

	button: {
		"&:hover": {
			cursor: "pointer",
		},

		"&:focus": {
			transform: "translateY(8px)",
			transition: "0.3s",
		},
	},
	test: {
		cursor: "pointer",
		filter: "invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%)",
	},
	disableGutters: {
		textAlign: "left",
	},
	buttons: {
		justifyContent: "flex-start",
		textTransform: "none",
		"&:hover": {
			color: "#ff8362",
		},
	},
})
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})
class Registration extends Component {
	constructor(props) {
		super(props)

		this.state = {
			setValue: 0,
			open: false,
			destinationName: "",
			monthId: "",
			minBudget: "",
			maxBudget: "",
			IncludesId: [],
			themeId: "",
			data: [],
			month: "",
			theme: "",
			inclusionsData: "",
			themesData: "",
			monthsData: "",
			iconid: [],
			page_number: 1,
			isLoading: true
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	onEntered = () => {
		Tracker.getInstance().trackEvent(new CustomiseFormOpenEvent(this.props.origin))
	}

	handleClick = () => {
		this.setState({
			open: true,
		})
		const destination_name = this.state.destinationName
		const departure_month_id = this.state.monthId
		const min_budget = this.state.minBudget
		const max_budget = this.state.maxBudget
		const inclusion_id = this.state.IncludesId
		const page_number = this.state.page_number
		const theme_id = this.state.themeId

		this.setState({
			data: {
				destination_name,
				departure_month_id,
				min_budget,
				max_budget,
				inclusion_id,
				theme_id,
				page_number,
			},
		})
		let data = this.state.inclusionsData
		data.map((item) => {
			if (item.active === true) {
				item.active = item.active ? !item.active : false
			}

			return item
		})
		this.setState({
			destinationName: "",
			month: "",
			theme: "",
			iconid: [],
		})
		this.props.onClose()
	}
	handleClose = () => {
		this.setState({
			open: false,
		})
	}

	//for budget selection//
	handleChange = (value, min) => {
		this.setState({
			minBudget: value,
			maxBudget: min,
		})
	}
	//for theme id//
	handleIcons = (obj1) => {
		if (this.state.iconid !== null) {
			this.state.iconid.push(obj1.id)
		}
		let data = this.state.inclusionsData

		data.map((item) => {
			if (item.id === obj1.id) {
				item.active = item.active ? !item.active : true
			}

			this.setState({
				inclusionsData: data,
				toggleButton: !this.state.toggleButton,
				IncludesId: this.state.iconid,
			})
			return item
		})
	}

	//for month selection//
	handleSubmit = (id) => {
		this.setState({
			month: id.month_name,
			monthId: id.id,
		})
	}
	//for category selection//
	handleCategory = (value) => {
		this.setState({
			themeId: value.id,
			theme: value.theme_name,
		})
	}
	handleOpen = (event) => {
		//    event.persist()

		this.setState({
			anchorEl: event.currentTarget,
		})
	}

	componentWillReceiveProps(nextprops) {
		if (nextprops._inclusion && nextprops._inclusion.data) {
			this.setState({
				inclusionsData: nextprops._inclusion.data,
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.open !== this.props.open && this.props.open === true) {
			this.props.getInclusionAction()
			this.props.getThemeAction()
			this.props.getMonthAction()
		}

		if(prevProps._inclusions !== this.props._inclusions) {
			this.setState({inclusionsData: this.props._inclusions.data})
			this.setState({isLoading: false})
		}

		if(prevProps._months !== this.props._months) {
			this.setState({monthsData: this.props._months.data})
			this.setState({isLoading: false})
		}

		if(prevProps._themes !== this.props._themes) {
			this.setState({themesData: this.props._themes.data})
			this.setState({isLoading: false})
		}
	}

	render() {
		const { classes, open, onClose } = this.props
		const {inclusionsData, themesData, monthsData, isLoading} = this.state

		return (
			<React.Fragment>
				<Container>
					{this.state.open === false ? (
						<Dialog fullScreen open={open} onClose={onClose} transition={Transition} TransitionProps={{ onEntered: this.onEntered }}>
							<Grid container>
								{/* <Paper > */}
								<Grid container justifyContent="flex-end">
									<IconButton justifyContent="flex-end" onClick={onClose} style={{ padding: 16, color: "#16222b" }}>
										<CloseOutlinedIcon />
									</IconButton>
								</Grid>
								<DialogContent width="100px" style={{ padding: 24 }}>
									<Grid md={2} item>
										{/* <FormControl fullwidth> */}
										<Typography variant="caption" style={{ color: "#16222b", height: 12 }}>
											Where would like to Travel
										</Typography>
										<TextField
											fullWidth
											placeholder="Destination"
											variant="outlined"
											// autoFocus={true}
											// margin="dense"
											style={{ marginBottom: 15, marginTop: 5 }}
											className={classes.textField}
											onChange={(event) => {
												const { value } = event.target
												this.setState({ destinationName: value })
											}}
											InputProps={{
												className: classes.input,
											}}
										/>

										<Typography variant="caption" className={classes.textField} style={{ color: "#16222b" }}>
											When are you planning
										</Typography>

										<TextField
											select
											fullWidth
											style={{ marginBottom: 12, marginTop: 5 }}
											className={classes.TextField}
											SelectProps={{
												IconComponent: (props) => (
													<i {...props} className={`material-icons ${props.className}`}>
														<DateRangeIcon color="primary" />
													</i>
												),
												displayEmpty: true,
											}}
											value={this.state.month}
											onChange={() => this.handleSubmit}
											variant="outlined"
											InputProps={{
												className: classes.input,
											}}>
											<MenuItem value="" disabled>
												<Typography variant={window.innerWidth < 600 ? "body2" : "subtitle1"} style={{ color: "#bbc2c8" }}>
													Select Month
												</Typography>
											</MenuItem>
											{monthsData &&
												monthsData &&
												monthsData.map((obj) => (
													<MenuItem value={obj.month_name} key={obj.month_name} onClick={() => this.handleSubmit(obj)}>
														<Button
															size="small"
															fullWidth
															className={classes.buttons}
															onClick={() => this.handleSubmit(obj)}
															// style={{ textTransform: "none" }}
															disableRipple={true}>
															<Typography
																variant="caption"
																// variant="body2"
																style={{ color: "#16222b" }}>
																{obj.month_name}
															</Typography>
														</Button>
													</MenuItem>
												))}
										</TextField>

										{/* </Grid> */}

										<Stepper data={(min, max) => this.handleChange(min, max)} />

										<Typography variant="caption" style={{ color: "#16222b" }} className={classes.textField}>
											Includes
										</Typography>
										<Grid
											item
											style={{
												display: "flex",
												padding: 0,
												marginBottom: 12,
												marginTop: 5,
											}}>
											{inclusionsData &&
												inclusionsData.map((obj, i) => (
													<Grid key={i}>
														{obj && obj.active ? (
															<IconButton key={i} onClick={() => this.handleIcons(obj)} className={classes.Icon}>
																<label>
																	<img src={obj.image} alt="icons" className={classes.img}></img>
																	<Grid>
																		<Typography
																			variant="overline"
																			style={{
																				textTransform: "none",
																				color: "#616e78",
																			}}>
																			{obj.name}
																		</Typography>
																	</Grid>
																</label>
															</IconButton>
														) : (
															<IconButton key={i} onClick={() => this.handleIcons(obj)} className={classes.Icon}>
																<label>
																	<img src={obj.unselected_image} alt="icons" className={classes.img}></img>
																	<Grid>
																		<Typography
																			variant="overline"
																			style={{
																				textTransform: "none",
																				color: "#616e78",
																			}}>
																			{obj.name}
																		</Typography>
																	</Grid>
																</label>
															</IconButton>
														)}
													</Grid>
												))}
										</Grid>
										<Typography variant="caption" className={classes.textField} style={{ color: "#16222b" }}>
											Category
										</Typography>
										<TextField
											margin="dense"
											select
											fullWidth
											style={{ marginBottom: 8, marginTop: 5 }}
											placeholder="Destination"
											value={this.state.theme}
											onChange={() => this.handleCategory}
											variant="outlined"
											SelectProps={{
												IconComponent: (props) => (
													<i {...props} className={`material-icons ${props.className}`}>
														<ExpandMoreIcon color="primary" />
													</i>
												),
												displayEmpty: true,
											}}
											InputProps={{ className: classes.input }}>
											<MenuItem value="" disabled>
												<Typography variant={window.innerWidth < 600 ? "body2" : "subtitle1"} style={{ color: "#bbc2c8" }}>
													Select One
												</Typography>
											</MenuItem>
											{themesData &&
												themesData &&
												themesData.map((obj, i) => (
													<MenuItem value={obj.theme_name} key={i}>
														<Button size="small" fullWidth className={classes.buttons} disableRipple={true} onClick={() => this.handleCategory(obj)}>
															<Typography variant="caption" style={{ color: "#16222b" }}>
																{obj.theme_name}
															</Typography>
														</Button>
													</MenuItem>
												))}
										</TextField>

										<Grid xs={12} item style={{ marginTop: "26px" }}>
											{this.state.month && this.state.destinationName && this.state.maxBudget && this.state.minBudget && this.state.inclusionsData && this.state.theme ? (
												<Button color="primary" variant="contained" fullWidth={true} onClick={this.handleClick} style={{ textTransform: "none" }}>
													Request a Call
												</Button>
											) : (
												<Button variant="contained" color="primary" fullWidth style={{ textTransform: "none" }} disabled>
													Request a Call
												</Button>
											)}
										</Grid>
										{/* </FormControl> */}
									</Grid>
									{/* </Paper> */}
								</DialogContent>
							</Grid>
						</Dialog>
					) : (
						<SubmitForm open={this.state.open} onClose={this.handleClose} Data={this.state.data} origin={TrackingOrigin.LeadForm.HomePage} />
					)}
				</Container>
			</React.Fragment>
		)
	}
}
const mapStateToProps = ({ 
	getInclusionReducer,
	getThemeReducer,
	getMonthReducers
 }) => {
	return {
		_inclusions: getInclusionReducer,
		_themes: getThemeReducer,
		_months: getMonthReducers,
	}
}

export default withRouter(
	connect(mapStateToProps, { 
		getInclusionAction,
		getMonthAction,
		getThemeAction,
	})(withStyles(style)(Registration)))
