import { navigationStore } from "../../app/navigation/navigationStore"
import { TrackerPropertyKey } from "../trackingConstants"

// Define an interface-like object
export class TrackableEvent {
	constructor(name, properties) {
		this.name = name

		// Adding the browsingPage property here so that it is available to all events
		// Not removing the existing TrackingPageName properties from other events
		// so that they can be removed in a separate PR
		const defaultProperties = {
			[TrackerPropertyKey.BrowsingPage]: navigationStore.getBrowsingPage(),
			[TrackerPropertyKey.OriginPage]: navigationStore.getOriginPage(),
		}

		this.properties = { ...defaultProperties, ...properties }
	}
}
