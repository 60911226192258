import { MetaTrackingEventName, TrackerPropertyKey } from "../trackingConstants"
import { TrackableEvent } from "./trackableEvent"

export class MetaLeadEvent extends TrackableEvent {
        constructor(countryCode, phoneNumber) {
                let properties = {}
                properties[TrackerPropertyKey.EventId] = `Lead_+${countryCode}${phoneNumber}`

                super(MetaTrackingEventName.Lead, properties)
        }
}