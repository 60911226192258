import React, { useState, useEffect } from 'react';
import { NavigationContext } from './navigationContext';
import { Tracker } from '../../tracking/tracker';
import { navigationStore } from './navigationStore';
import { PageViewEvent } from '../../tracking/events/pageViewEvent';

export const NavigationProvider = ({ children }) => {
  const [browsingPage, setBrowsingPage] = useState(null);

  const setPrevBrowsingPageRef = (browsingPage) => {
    sessionStorage.setItem('prevBrowsingPageRef', browsingPage)
  }

  const getPrevBrowsingPageRef = () => {
    return sessionStorage.getItem('prevBrowsingPageRef')
  }

  useEffect(() => {
    const prevBrowsingPage = getPrevBrowsingPageRef()
    if (browsingPage !== null) {
      navigationStore.setBrowsingPage(browsingPage);
      
      // Then update the originPage
      navigationStore.setOriginPage(prevBrowsingPage)
      if (browsingPage) {
        // Send page view event with originPage as the referrer
        Tracker.getInstance().trackPageviewEvent(new PageViewEvent(browsingPage))
      }

      setPrevBrowsingPageRef(browsingPage);
    }
  }, [browsingPage]);

  return (
    <NavigationContext.Provider value={{ browsingPage, setBrowsingPage }}>
      {children}
    </NavigationContext.Provider>
  );
};