import React, { Component } from "react";

import { Grid, withStyles } from "@material-ui/core";
import Footer from "../footer/footer";
import Header from "../PackageDetails/PackageInformation/termsHeader";
import CommonUI from "./Model/model";
import WhyTsImage from "../../assets/images/whyTs.svg";
import { Helmet } from "react-helmet";
import { getMetaDetailAction } from "../../store/actions/getMeta.action";
import { connect } from "react-redux";
import { withRouter } from "react-router";
const style = (theme) => ({
  text: {
    marginTop: "4%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "24%",
    },
  },
});
class About extends Component {
  componentDidMount = () => {
    this.props.getMetaDetailAction({ params: { page: 'why_ts' } });
    window.scrollTo(0, 0);
  };
  render() {
    const { _metaDetail } = this.props;
    const WhyTsData = {
      mainHeading: "Why Trip Store",
      perInfo: [
        {
          mainText: "1. Power of Choice:",
          body:
            "Trip store gives you a huge array to select from for any and every destination you would look up for.",
        },
        {
          mainText: "2. Compare:",
          body:
            "You can select up to three packages as per your requirement and compare the same on our system to narrow down to the right package for you.",
        },
        {
          mainText: "3. Multiple Tour Operators:",
          body:
            "We believe in providing the best to our clients, so we carefully partner with local and global tour operators to bring you the best tour packages that offer great experience.",
        },
        {
          mainText: "4. All the above in one place:",
          body:
            "To achieve all the above on your own, you will have to visit multiple websites and spend your time studying and then comparing the different products. At Trip Store, you will just have to select the package as per your requirement and the rest will follow.",
        },
        {
          mainText: "5. Best Price Guaranteed:",
          body:
            "If you find the same tour with the same departure date for a lower price elsewhere, we'll match the price. We deal directly with each tour operator, which means we can offer you the best price, guaranteed.",
        },
        {
          mainText: "6. Secured Payments:",
          body:
            "Your Money is safe with us:We do not pass on your full payment to the Tour Operator unless you have verified your tour booking vouchers. Thus keeping your payment well secured with us and making sure of quality service.",
        },
      ],
    };
    return (
      <Grid>
        <Helmet>
          <title>
          {_metaDetail.data?_metaDetail.data.data.meta_title:''}
          </title>
          <meta charset="UTF-8" />
          <meta name="description" content={_metaDetail.data?_metaDetail.data.data.meta_description:''} />
          <meta name="keywords" content={_metaDetail.data?_metaDetail.data.data.meta_keyword:''} />
          <meta name="author" content="Tripstore" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>
        <Header />
        <Grid
          md={12}
          xs={12}
          sm={12}
          item
          style={{ marginTop: "8%", display: "block" }}
        >
          <CommonUI Picture={WhyTsImage} completeData={WhyTsData} page={1} />

          <Footer />
        </Grid>
      </Grid>
    );
  }
}


const mapStateToProps = ({
  getMetaDetailReducer,
}) => {
  return {
    _metaDetail: getMetaDetailReducer,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    getMetaDetailAction,
  })(withStyles(style)(About))
);