import * as types from "../action.types"

export const getListingAction = (data) => {
    return {
        type: types.GET_LISTING,
        data,
    }
}

export const getListingActionSuccess = (data) => {
    return {
        type: types.GET_LISTING_SUCCESS,
        data,
    }
}

export const getResponseNullAction = () => {
    return {
        type: types.GET_RESPONSE_NULL,
    }
}
