import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import aboutFootball from "../../../assets/images/aboutFootball.svg";
import Girl from "../../../assets/images/girl.svg";
import mountains from "../../../assets/images/mountains.svg";
import rocket from "../../../assets/images/rocket.svg";
const useStyle = makeStyles((theme) => ({
  root: {
    height: "100%",
    background: "#F3F5F6",
    position: "relative",
    padding: 16,
    [theme.breakpoints.down("sm")]: {
      marginTop: 24,
    },
  },
  bodyContent: {
    textAlign: "center",
    fontSize: 16,
    color: "#616E78",
    marginTop: 16,
  },
  Title: {
    fontSize: 24,
    color: "#FF8362",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  subtitle: {
    fontSize: 16,
    color: "#FF8362",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  subBody: {
    fontSize: 14,
    color: "#616E78",
    marginTop: 6,
  },
  RightImageGrid: {
    height: 200,
    width: "100%",
  },
  footballImage: {
    maxWidth: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: 0,
      right: 0,
      height: 100,
    },
  },
  Icons: {
    height: 60,
    width: 60,
  },
  GirlImage: {
    position: "absolute",
    bottom: 16,
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      height: 100,
    },
    [theme.breakpoints.only("sm")]: {
      height: 150,
    },
  },
  GrilImageGrid: {
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  missonvissionGrid: {
    marginTop: "60px",
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      margin: "20px 20px",
    },
  },
}));

const AboutModel = () => {
  const classes = useStyle();
  return (
    <Grid container>
      <Grid md={12} xs={12} sm={12} container item className={classes.root}>
        <Grid
          md={3}
          xs={2}
          sm={3}
          container
          item
          className={classes.GrilImageGrid}
        >
          <Grid md={8} xs={10}>
            <img src={Girl} alt="girl" className={classes.GirlImage} />
          </Grid>
        </Grid>
        <Grid
          md={6}
          xs={8}
          sm={6}
          container
          justify="center"
          item
          style={{ display: "block", marginTop: 80, marginBottom: 80 }}
        >
          <Grid md={11} xs={11} sm={11} container justify="center" item>
            <Typography className={classes.Title}>ABOUT TRIP STORE</Typography>
            <Typography className={classes.bodyContent}>
              We are a bunch of young travel – tech experts, with over a decade
              of experience, trying to simplify travel. The plethora of
              multi-day tour options, each presented differently got us
              thinking, what if all of them are under one roof! Travellers can
              easily: find, compare, and select the options that suit their
              style and budget. Trip Store Holidays is a marketplace with the
              endeavour to connect travellers to the trusted Indian &
              International tour operators and empower them to choose the best.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          md={3}
          xs={2}
          sm={3}
          container
          justify="flex-end"
          item
          className={classes.RightImageGrid}
        >
          <img
            src={aboutFootball}
            className={classes.footballImage}
            alt="pic"
          />
        </Grid>
      </Grid>
      <Grid md={12} xs={12} sm={12} container item style={{ height: 200 }}>
        <Grid md={1} />
        <Grid
          md={10}
          xs={12}
          sm={12}
          container
          spacing={3}
          justify="space-between"
          item
          className={classes.missonvissionGrid}
        >
          <Grid md={5} xs={12} sm={12} item style={{ display: "flex" }}>
            <img src={mountains} alt="mountain" className={classes.Icons} />
            <div style={{ display: "block", marginLeft: 16 }}>
              <Typography className={classes.subtitle}>Our Vision</Typography>
              <Typography className={classes.subBody}>
                To guide every traveller, finding their dream tour.
              </Typography>
            </div>
          </Grid>
          <Grid md={5} xs={12} sm={12} item style={{ display: "flex" }}>
            <img src={rocket} alt="mountain" className={classes.Icons} />
            <Grid md={10} style={{ display: "block", marginLeft: 16 }}>
              <Typography className={classes.subtitle}>Our Mission</Typography>
              <Typography className={classes.subBody}>
                Empowering travellers to compare and book from varied multi-day
                tours at best prices from trusted Tour Operators.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid md={1} />
      </Grid>
    </Grid>
  );
};

export default AboutModel;
