import React, { useEffect } from "react";
import TrustTestimonialOneCard from "../TrustTestimonialOne/TrustTestimonialOneCard";
import "./testimonialcomponent.css";
import GridComponent from "../GridComponent/GridComponent";
import { IntersectionObservers } from "../IntersectionObservers";

function TestimonialComponent({ data, onVisibile, onItemClick}) {

  const testimonialData = {
    widget: "testimonial",
  };
  const { isVisible, refValue } = IntersectionObservers({ threshold: 0.1 });
  useEffect(() => {
    if (isVisible) {
      onVisibile();
    }
  }, [isVisible]);
  const testimonialDivs = data?.list?.items.map((item, index) => {
    return (
      <TrustTestimonialOneCard
      key={index}
        title={item.title}
        rating={item.rating}
        time={item.timestamp}
        imageUrl={item.image}
        subtitle={item.subtitle}
        actions={item}
        onClick={() => {
          onItemClick(item.title)
        }}
      />
    );
  });

  return (
    <div className="testimonial-component-container" ref={refValue}>
      <div className="testimonial-component-heading-wrapper">
      <div className="global-widget-headings">{data.title}</div>
      </div>

      <GridComponent divs={testimonialDivs} testimonialData={testimonialData} />
    </div>
  );
}

export default TestimonialComponent;
