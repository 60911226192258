import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants"
import { TrackableEvent } from "./trackableEvent"

export const WhatsappOpenEventOrigin = Object.freeze({
	LISTING_PAGE: "listing_page",
	DETAIL_PAGE: "detail_page",
})

export class PackageOpenWhatsappEvent extends TrackableEvent {
	/**
	 *@var WhatsappOpenEventOrigin origin
	 */
	constructor(origin, packageSlug) {
		let properties = {}
		properties[TrackerPropertyKey.Origin] = origin
		properties[TrackerPropertyKey.PackageSlug] = packageSlug

		super(TrackingEventName.PackageOpenWhatsapp, properties)
	}
}
