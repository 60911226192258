// import * as types from "../action.types";

import * as types from "../action.types"

export const listingReducer = (state = {}, action) => {
    const response = action.data

    switch (action.type) {
        case types.GET_LISTING_SUCCESS:
            return response
        case types.GET_LISTING_ERROR:
            return state
        case types.GET_RESPONSE_NULL:
            return { response: null }
        default:
            return state
    }
}
