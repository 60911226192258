import React, { Component } from "react"
import { Grid } from "@material-ui/core"
import Footer from "../footer/footer"
import Model from "./Model/AboutModel"
import Header from "../PackageDetails/PackageInformation/termsHeader"
import { Helmet } from "react-helmet"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { getMetaDetailAction } from "../../store/actions/getMeta.action"
import { TrackingPageName } from "../../../tracking/trackingConstants"
import { NavigationContext } from "../../navigation/navigationContext"

class About extends Component {
	static contextType = NavigationContext

	componentDidMount = () => {
		// Tracking Page View
		this.context.setBrowsingPage(TrackingPageName.About)

		this.props.getMetaDetailAction({ params: { page: "about" } })
		window.scrollTo(0, 0)
	}
	render() {
		const { _metaDetail } = this.props
		return (
			<Grid>
				<Helmet>
					<title>{_metaDetail.data ? _metaDetail.data.data.meta_title : ""}</title>
					<meta charset="UTF-8" />
					<meta name="description" content={_metaDetail.data ? _metaDetail.data.data.meta_description : ""} />
					<meta name="keywords" content={_metaDetail.data ? _metaDetail.data.data.meta_keyword : ""} />
					<meta name="author" content="Tripstore" />
					<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				</Helmet>
				<Header />
				<Grid md={12} xs={12} sm={12} item style={{ marginTop: "4%", display: "block" }}>
					<Model />
					<footer style={{ marginTop: 64 }}>
						<Footer />
					</footer>
				</Grid>
			</Grid>
		)
	}
}

const mapStateToProps = ({ getMetaDetailReducer }) => {
	return {
		_metaDetail: getMetaDetailReducer,
	}
}
export default withRouter(
	connect(mapStateToProps, {
		getMetaDetailAction,
	})(About)
)
