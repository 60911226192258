import { CircularProgress, withStyles } from "@material-ui/core"
import React from "react"

const style = (theme) => ({
	loader: {
		color: "#ff6840",
		height: 40,
		width: 40,
	},
})

const LoadMoreWidget = (props) => {
	const { classes } = props

	return (
		<div>
			<CircularProgress className={classes.loader} />
		</div>
	)
}

export default withStyles(style)(LoadMoreWidget)
