import React from "react"
import { Container, Grid, Typography, useMediaQuery, withStyles } from "@material-ui/core"

const style = (theme) => ({
	root: {
		flexGrow: 10,
		[theme.breakpoints.down("xs")]: {
			paddingRight: 0,
		},
	},
	title: {
		color: "#16222b",
		//  padding:theme.spacing(3)
		margin: "4% 0% 2% 0%",
		[theme.breakpoints.down("xs")]: {
			margin: "8% 0% 2% 0%",
			fontWeight: "500",
		},
	},
	iFrame: {
		width: "100%",
		border: "none",
		height: "450px",
	},
})

const GoogleReviewsWidget = (props) => {
	const { iFrameUrl, classes } = props
	const matches1 = useMediaQuery((theme) => theme.breakpoints.down("xs"))

	return (
		<React.Fragment>
			<Container className={classes.root}>
				<Grid item>
					<Typography variant={matches1 ? "h6" : "h5"} className={classes.title}>
						Travelers love us
					</Typography>
				</Grid>
				<Grid item>
					<iframe src={iFrameUrl ?? "https://a2babcdda39c4e0a86e004ac1057ab6</Typography>f.elf.site"} title="Google Reviews" className={classes.iFrame} onLoad={props.onWidgetLoad}></iframe>
				</Grid>
			</Container>
		</React.Fragment>
	)
}

export default withStyles(style)(GoogleReviewsWidget)
