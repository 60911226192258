export const TermsData = {
  mainHeading: `General Terms and Conditions`,
  perInfo: [
    {
      mainText: "Our Contract",
      body: `TTHE FOLLOWING TERMS AND CONDITIONS (THE "TERMS AND CONDITIONS") APPLY TO ALL
      VISITORS TO OR USERS OF THIS WEBSITE. BY ACCESSING THIS WEBSITE, THE USER
      ACKNOWLEDGES ACCEPTANCE OF THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE
      TO THESE TERMS AND CONDITION, YOU MAY NOT USE THE WEBSITE. YOU ACKNOWLEDGE
      AND AGREE THAT BY MERE USE OF THE WEBSITE, YOU SHALL BE CONTRACTING WITH TRIP
      STORE HOLIDAYS ETRAVEL AND ENTERTAINMENT PRIVATE LIMITED AND THESE TERMS
      AND CONDITION CONSTITUTE YOUR BINDING OBLIGATIONS WITH TSH. THESE TERMS OF
      USE SHALL BE ENFORCEABLE AGAINST YOU IN THE SAME MANNER AS ANY OTHER WRITTEN
      AGREEMENT
      "IN THE EVENT YOU ON BEHALF OF ANY PERSON OR GROUP OPT FOR THE SERVICES OF THE
      HOST OR ANY OTHER SERVICES THROUGH OUR WEBSITE, YOU ACKNOWLEDGE AND AGREE
      THAT ALL THE INDIVIDUALS, WHETHER AS A GROUP OR OTHERWISE, ON BEHALF OF WHOM
      YOU ARE VISITING OUR WEBSITE AND BOOKING THE SERVICES, HAVE READ AND ACCEPTED
      THE TERMS AND CONDITIONS, BOOKING AND CANCELLATION POLICY AND PRIVACY POLICY
      OF THE WEBSITE. YOU AGREE TO INDEMNIFY TRIP STORE HOLIDAYS AND ITS DIRECTORS,
      PARTNERS, EMPLOYEES, AFFILIATES, REPRESENTATIVE, HOST OR BUSINESS PARTNERS
      AGAINST ANY LIABILITY WHICH MAY ARISE DUE TO, OR FROM, SUCH INDIVIDUAL. IN CASE,
      THE INDIVIDUAL/S DO NOT AGREE WITH THE TERMS AND CONDITIONS, BOOKING AND
      CANCELLATION POLICY AND PRIVACY POLICY OF THE WEBSITE, THEN SUCH INDIVIDUAL
      MAY OPT OUT FROM THE SERVICES BEFORE ANY BOOKING AND ACCORDINGLY MAY
      REFRAIN FROM USING OUR WEBSITE OR THE SERVICES PROVIDED THEREUNDER."
      IN THE CASE OF ANY VIOLATION OF THESE TERMS AND CONDITIONS, TRIP STORE HOLIDAYS
      LLP AND ITS SUBSIDIARIES (COLLECTIVELY,“TRIP STORE HOLIDAYS”,
      “TSH”, “WE” AND “US”.), RESERVE THE RIGHT TO SEEK ALL REMEDIES AVAILABLE BY LAW
      AND IN EQUITY FOR SUCH VIOLATIONS. THESE ARE LEGALLY BINDING TERMS AND
      CONDITIONS FOR YOUR USE OF THIS WEBSITE AND LOGIN. IN THESE TERMS AND
      CONDITIONS, THE USER MAY BE REFERRED TO AS “USER” “YOU”, “YOUR”, “MY”.
      THESE TERMS AND CONDITIONS MAY CHANGE PERIODICALLY AT THE DISCRETION OF TSH.
      IT IS YOUR RESPONSIBILITY TO REVIEW THESE TERMS AND CONDITIONS PERIODICALLY FOR
      ANY UPDATES / CHANGES. YOUR CONTINUED USE OF THE WEBSITE SUBSEQUENT TO SUCH
      CHANGES WILL INDICATE AND IMPLY YOUR ACCEPTANCE TO SUCH REVISIONS.`,
    },
    {
      mainText: "About Us:",
      body: `TSH’s website provides you with information about destinations and lists trips which are
      organized by various associations or organization or TSH and also allow you to select and make
      bookings of the trip including flight booking and visa assistance (“Services”) from the third-
      party service providers, or partners ("Host”).
    `,
    },

    {
      mainText: "Account Registration and Use of Services:",
      body: `You may access the Website either by (a) signing in as a guest; or (b) registering to create an
      account (“Account”) and become a member (“Member”); or (c) You can also register to join by
      logging into your account with certain third party social networking sites (“SNS”) including, but
      not limited to, Facebook or Google); each such account, a “Third Party Account”, via our
      Services. As part of the functionality of the Account login information to Us through the
      
      Services; or allowing us to access your Third Party Account, as is permitted under the applicable
      terms and conditions that govern Your use of each Third Party Account.
      You represent that you are entitled to disclose your Third Party Account login information to us
      and/or grant Us access to your Third Party Account (including, but not limited to, for use for the
      purposes described herein), without breach by you of any of the terms and conditions that
      govern Your use of the applicable Third Party Account and without obligating Us to pay any fees
      or making Us subject to any usage limitations imposed by such Host.
      By granting Us access to any Third Party Accounts, You understand that We will access, make
      available and store (if applicable) any content or information that you have provided to and
      stored in Your Third Party Account (“SNS Content”) so that it is available on and through the
      Services via Your Account.
      Unless otherwise specified in these Terms and Conditions, all SNS Content, if any, will be
      considered to be your content for all purposes of these Terms.
      Depending on the Third Party Accounts you choose and subject to the privacy settings that you
      have set in such Third Party Accounts, personally identifiable information that you post to your
      Third Party Accounts will be available on and through your Account.
      Please note that if a Third-Party Account or associated service becomes unavailable or our
      access to such Third-Party Account is terminated by the Host, then SNS Content will no longer
      be available on and through the Services.
      We will create Your Account for your use of the Services based upon the personal information
      You provide to Us or that We obtain via an SNS as described above.
      You agree to provide accurate, current, and complete information during the registration
      process and to update such information to keep it accurate, current, and complete.
      We reserve the right to suspend or terminate Your Account and your access to the Services if
      any information provided during the registration process or thereafter proves to be inaccurate,
      not current, or incomplete.
      You are responsible for safeguarding your password. You agree that you will not disclose your
      password to any third party and that you will take sole responsibility for any activities or
      actions under Your Account, whether or not you have authorized such activities or actions. You
      will immediately notify us of any unauthorized use of Your Account.`,
    },

    {
      mainText: "Services Listings:",
      body: `A Host may list their Host Services on the Platform for you to choose and book the Host Services
      ("List" or "Listing"). To create a Listing, the Host may be asked to provide information to Us
      including but not limited to the location, itinerary, guides, transportation, transfer services, visa
      assistances, information about meals, availability of the such Host Services, pricing and related
      rules and financial terms (“Listing Content”). Any and all Accommodations desired to be listed
      must have valid physical addresses. Listings will be made publicly available the Platform. You
      shall book the Host Services via the Platform based on the information provided in the Listing.`,
      Listings: [
        {
          subpoints: [
            {
              desc: `We assume no responsibility for a Host’s compliance with any agreements with or
              duties towards third parties, applicable laws, rules, and regulations. We reserve the
              right, at any time, without prior notice, to remove or disable access to any Listing for any
              
              reason, in violation of these terms or our current policies or otherwise harmful to the
              Website or our Services.`,
            },
            {
              desc: `The Users understands that We do not act as an insurer or as the contracting agent for
              the Host. When You book and avail the Host Services, any agreement the Host enters
              with You is between the Host and You and that we are not a party to it. We shall
              accordingly not be responsible for any breach of the terms of such Agreement.`,
            },
            {
              desc: `The Website makes certain tools available to the User to help the User make informed
              decisions about the confirmation or pre-approval of booking the Host Services. The Host
              and User respectively agree and acknowledge to be responsible for their own the acts,
              decisions and omissions, and also in the event of any Host, the acts, decisions and
              omissions of any User who may avail the Host Services.`,
            },
            {
              desc: `We may offer the Host the option of having photographers take photographs of their
              Accommodations and Host Services. If the Host chooses to have a photographer do this,
              the photographs taken will be made available to the Host to be included in their Listing
              with a watermark or tag bearing the words "Website Verified Photo" or similar wording
              ("Verified Images"). The Host agrees that the Host alone is responsible for ensuring that
              Listing is accurately represented in the Verified Images. The Host alone is responsible
              for using the Verified Images and warrants that it will cease to use the Verified Images
              or any other images if such images cease to accurately represent the Listing of such
              Host. All images, materials and content created by these photographers, including
              Verified Images, constitute our Content, regardless of whether the Host includes them in
              their Listing. The Host agrees that we may use the Verified Images for advertising,
              marketing, commercial and other business purposes in any media or platform, whether
              in relation to their Listing or otherwise, without further notice or compensation.
`,
            },
            {
              desc: `As clarified above, all the Host Services are provided by the Hosts and We do not make
            any representation or warranties with respect to the Host Services listed on the
            Platform. We shall not be responsible or liable for any inaccuracy in information
            provided by the Host or any other delay or deficiency in performance of the Host
            Services by the Host.`,
            },
            {
              desc: `In the event Users has any queries, concerns or grievances related to the Host Services,
              including any concerns related to delay or deficiency in performance of the Host Service,
              the User shall directly reach out to the relevant Host. We shall not be responsible for any
              resolution of any disputes between the Host and the relevant Users.`,
            },
          ],
        },
      ],
      bodyBottom: `However, in the event any User has reasons to believe that the information provided by the Host
      is incorrect/insufficient or there has been any incident of fraud or misrepresentation by the
      Host, the User may reach out to us at our customer care number or email ID and We agree to
      exercise commercially reasonable efforts to resolve such concerns of the User. Notwithstanding
      the above, in no event shall TSH be responsible for any acts or omissions of the Host in relation
      to the Host Services.
          `,
    },
    {
      mainText: "No Endorsement",
      body: `We do not endorse any particular any services provided by Host whether or not it is displayed
      on the website. The images displayed on the website are only indicative of a photographic
      representation of the Service at the time when any such photograph was taken. Images are
      therefore not an endorsement by Us of any User or any Service. We do not make any
      representations about, confirm, or endorse any User or the User's purported identity or
      background.`,
    },
    {
      mainText: "Booking and Financial Terms",
      body: `All prices listed on the website for the booking of Services is, to the best of the knowledge of
        TSH, correct at the time of publication and TSH shall have the right to alter the prices at any
        time before Your booking of the Services. While we take great care to keep them up to date, the
        final price charged to You by Us may change at the time of finally booking the relevant third-
        party service. We reserve the right to alter the price of the booking for Services on the website
        and to delete and remove from listing any Host and their Listing, if any.
        In the event where Host informs Us immediately after you book a Host Service, about the
        change in charges for such booking, We will try to make every possible effort to inform You
        about the price difference and You can choose to opt-out of the booking at that time. In such an
        event, the payments made by You will be refunded by Us in accordance with the terms and
        conditions contained hereunder.`,
    },
    {
      mainText: "Booking and Financial Terms for the User",
      body: `The Host, and not We, are solely responsible for honouring any confirmed bookings and making
      available any Services reserved through the website. If you book any Services then you agree to
      enter into an agreement with the Host and further agree to accept any terms, conditions, rules,
      and restrictions associated with such services imposed by the Host.
      You acknowledge and agree that you and not us, will be responsible for performing the
      obligations of any such agreements that We are not a party to such agreements, and that, with
      the exception of our payment obligations hereunder, We disclaim all liability arising from or
      related to any such agreements.
      You acknowledge and agree that We act as the Host’s payment agent for the limited purpose of
      accepting payments from the User on behalf of the Host. Upon the User’s payment of amounts to
      us, which are due to the Host, your payment obligation to the Host for such amounts is
      completed, and we are responsible for remitting such amounts, to the Host. The User shall not,
      under any circumstances whatsoever, make any payment directly to the Host.
      The Total Fees payable will be displayed to You before You send a booking request to Us.
      You agree to pay Us for the Total Fees for any booking requested in connection with Your
      Account if such requested bookings are confirmed by the applicable Host. In order to establish a
      booking pending the applicable Host's confirmation of your requested booking, you understand
      and agree that we, on behalf of the Host, reserve the right, in Our sole discretion, to (i) obtain a
      pre-authorization via your credit card for the Total Fees or (ii) charge your credit card a
      nominal amount, not to exceed, or a similar sum in the currency in which you are transacting to
      verify your credit card. As a general rule, we will collect the Total Fees due once we receive
      confirmation of your booking from the applicable Host; if necessary, Total Fees may instead be
      collected at a later point. Please note that we cannot control any fees that may be charged to you
      by your bank related to our collection of the Total Fees, and we disclaim all liability in this
      regard.
      In connection with Your requested booking, you will be asked to provide customary billing
      information such as name, billing address and credit card information either to Us or our
      Payment Processor(s). You agree to pay Us for any confirmed bookings made in connection with
      Your Account in accordance with these Terms by one of the methods described on the Platform.
      You hereby authorize the collection of such amounts by charging the credit card provided as
      part of requesting the booking in accordance with the terms and conditions contained
      hereunder. You also authorize Us to deduct the charges from Your credit card, in the event of
      damage caused at an Accommodation as contemplated under "Damage to Accommodations"
      
      below, if applicable. If you are directed to TSH Payments third-party payment processor(s), you
      may be subject to terms and conditions governing use of that third party's service and that third
      party's personal information collection practices. All payments made by You for the Services
      shall be considered as a consideration for the Services received by You and You agree to make
      all payments are done as per the terms and conditions of the Website and the policies of the
      Website including but not limited to refund and cancellation policy, shall be applicable to such
      payments.`,
    },
    {
      mainText: "Damage to Accommodations",
      body: `You are responsible for taking care of the Accommodations and leaving them in a state in which
      it was received by You. You agree and acknowledge the responsibility from your acts or
      omissions and also the acts and omissions of any individuals who any such User may invite to,
      or otherwise provide access to, the Accommodation. In event of any claim of damage to the
      Accommodation by the Host that the Host proves through evidence of damage to the
      Accommodation by the User, such User shall be responsible for either paying the damages for or
      replacing such goods as are damaged or both depending upon the condition of damage. After
      being notified of the claim and given forty-eight (48) hours to respond, the payment will be
      charged to the User. User undertakes to pay to the Host any such payment as claimed by the
      Host for any damage to the Accommodation.`,
    },
    {
      mainText: "Taxes",
      body: `Tax regulations may require us to collect appropriate tax information from you. Therefore, you
      agree to pay any taxes or regulatory charges which any party to these Terms is required to pay.`,
    },
    {
      mainText: "Service Fees",
      body: `In consideration of our Services provided to You, we may charge service fees, or any other fees
      as specified by Us on the Website (“Service Charges”). We shall deduct such Service Charges
      from the Host Services Fees before remitting the balance to the Host and any such Service
      Charges shall be included in the Host Services Fees charged to the Users as defined in the
      clauses above.
      You acknowledge and agree that all the payments on the Website are handled and processed by
      third-party payment processors appointed by Us (“Payment Processors”). All payments and
      refunds made through the Website shall be subject to the terms and conditions of the Payment
      Processor appointed by Us. You further acknowledge and agree that We shall not be responsible
      for any delay or deficiency in processing the payments by the relevant Payment Processors.
      While making the payments You agree to accept the terms and conditions of the relevant
      Payment Processor and all information provided by You to the Payment Processor shall be
      subject to the privacy policy of the relevant Payment Processor.
      Irrespective of cancelling a booking before or after the booking confirmation is received, it is
      agreed that the User fee and Service Charge shall be non-refundable. If the User cancels a
      booking before the booking confirmation is received from the Host, no charges would be
      chargeable. Further, if any such cancellation is made after the receipt of booking confirmation,
      then the cancellation policy of the Host will apply to such cancellation.
      Notwithstanding anything under this clause, we reserve the right to cancel any booking,
      whether confirmed or not, made via the Website for any reasons which in our discretion
      validate such cancellation. The User shall be returned the Service Charge in event of any such
      cancellation.`,
    },
    {
      mainText: "General Terms of Use",
      body: `Only individuals who are 18 years of age or older may use the Website. If You are under 18
      years of age and You wish to download, install, access, or use the Website, your parents or legal
      guardian must acknowledge and agree to the Terms of Use and Privacy Policy. Should Your
      parents or legal guardian fail to agree or acknowledge the Terms of Use and Privacy Policy, you
      shall immediately discontinue its use.
      If You choose to use Website, it shall be Your responsibility to treat Your user identification
      code, password and any other piece of information that We may provide as part of Our security
      procedures as confidential and not disclose the same to any person or entity other than us
      (“Third Party”). We shall at times and at Our sole discretion reserve the right to disable any user
      identification code or password if You have failed to comply with any of the provisions of these
      Terms of Use.
      Your use of the Website shall be solely for Your personal use and You shall not authorize others
      to use Your Account, including Your profile or email address and that You are solely responsible
      for all content published or displayed through Your Account, including any email messages, and
      Your interactions with other users and You shall abide by all applicable local, state, national and
      foreign laws, treaties and regulations, including those related to data privacy, international
      communications and the transmission of technical or personal data.
      You agree that TSH will only assist and help You with the airlines tickets, visa application which
      shall include providing You with visa letter, checklist of documentation required, proof of
      accommodation, hotel voucher and itinerary detail, and other ancillary services. However, the
      responsibility of the validity and accuracy of the document submitted by You shall not be of
      TSH. You acknowledge and agree that services like approval of visa application and airlines
      tickets or any delay or cancellation of scheduled flight and other such events which are not in
      control of TSH, TSH shall not be held liable for such event at any time and You hereby waive off
      any right or liability which may arise due to such event.
      You will not submit, post, upload, distribute, or otherwise make available or transmit any
      content that; (b) is bigoted, hateful, or racially or otherwise offensive; (c) is violent, vulgar,
      obscene, pornographic or otherwise sexually explicit; (d) is illegal or encourages or advocates
      illegal activity or the discussion of illegal activities with the intent to commit them.
      All necessary licenses, consents, permissions and rights are owned by You and there is no need
      for any payment or permission or authorization required from any other party or entity to use,
      distribute or otherwise exploit in all manners permitted by these Terms of Use and Privacy
      Policy, all trademarks, copyrights, patents, trade secrets, privacy and publicity rights and / or
      other proprietary rights contained in any content that You submit, post, upload, distribute or
      otherwise transmit or make available
      You will not use Website in any way that is unlawful, or harms Us or any other person or entity,
      as determined in Our sole discretion.
      You will not post, submit, upload, distribute, or otherwise transmit or make available any
      software or other computer files that contain a virus or other harmful component, or otherwise
      impair or damage the Website and/or or any connected network, or otherwise interfere with
      any person or entity’s use or enjoyment of the Services. You will not use another person’s username, password or other account information, or another
      person’s name, likeness, voice, image or photograph or impersonate any person or entity or
      misrepresent your identity or affiliation with any person or entity.
      You will not engage in any form of antisocial, disrupting, or destructive acts, including “flaming,”
      “spamming,” “flooding,” “trolling,” and “griefing” as those terms are commonly understood and
      used on the Internet
      You will not delete or modify any content of the Website, including but not limited to, legal
      notices, disclaimers or proprietary notices such as copyright or trademark symbols, logos, that
      You do not own or have express permission to modify.
      We may alter or vary the Terms and Conditions at any time without notice to you.
      The Terms of Use together with the other policies, any order form and payment instructions
      constitute the entire agreement between You and Us. No other terms whether expressed or
      implied shall form part of this Agreement. In the event of any conflict between these Terms of
      Use and any other term or provision on the Website, these Terms of Use shall prevail.
      Try to get in touch with any parties to these Terms for any purpose except as envisaged under
      these Terms.
      The Website may be used only for lawful purposes. TSH specifically prohibits any use of the
      Website for, and You agree not to use the Website for, any of the following purposes:`,
      Listings: [
        {
          subpoints: [
            {
              desc: `Copying, modifying, publishing, transmitting, distributing, displaying, performing,
              participating in the transfer or sale of, create derivative works on, or in any way
              exploiting, any of the copyrighted material contained on the Website, in whole or in part,
              without Our prior written consent; or`,
            },
            {
              desc: `Subleasing, leasing, selling, assigning, transferring, distributing, renting, permitting
              concurrent use of, or granting other rights in any material provided on the Website to
              any third party.`,
            },
            {
              desc: `Download any content unless You see a ‘download’ or similar link displayed on the
              Website by reference; or`,
            },
          ],
        },
      ],
    },
    {
      mainSubHeading: "Communications and Content",
      mainText: "Communication",
      body: `TSH, through the Website, provides for various communication channels including but not
      limited to chat boxes, comments sections, social media channels, e-mails, etc. between various
      Users, TSH and/or any person otherwise authorized to access the Website. During Your use of
      any communication channel on the Website or in any event of sharing of information or
      documents, You specifically agree and undertake that You shall not upload, post, transmit,
      transfer, disseminate, distribute, or facilitate distribution (collectively referred to as “Posting”)
      of any content, including text, images, video, sound, data, information, files or software,
      (“Content”) to any User or part of the Website, including Your Profile, the Posting of Your
      offering of the Content, the Posting of the services You want, or the Posting of any opinions or
      reviews in connection with the Website or any other User of the Platform, that:`,
      Listings: [
        {
          subpoints: [
            {
              desc: `misrepresents the source of anything You post, including impersonation of another
              individual or entity or any false or inaccurate biographical information for any User.`,
            },
            {
              desc: `provides or create links to external sites that violate the Terms of Use.`,
            },
            {
              desc: `is intended to harm or exploit any individual under the age of majority in any
              jurisdiction.`,
            },
            {
              desc: `is designed to solicit or collect personally identifiable information of any individual
              under the age of majority in any jurisdiction, including, but not limited to, name, email
              address, home address, phone number.`,
            },
            {
              desc: `encourages conduct that would be considered a criminal offense, give rise to
              civil liability or violate any law including any abetment, conspiracy or collusion
              to commit a crime or undertake illegal monetary transactions including money
              laundering, corrupt practices;`,
            },
            {
              desc: `is pornographic, pedophilic, blasphemous, harassing, hateful, illegal, obscene,
              defamatory, libelous, slanderous, threatening, discriminatory, racially,
              culturally or ethnically offensive; incites, advocates, or expresses
              pornography, obscenity, vulgarity, profanity, hatred, bigotry, racism, or
              gratuitous violence; promotes racism, hatred or physical harm of any kind
              against any group or individual; contains nudity, violence or any other
              inappropriate subject matter; or is otherwise inappropriate;`,
            },
            {
              desc: `threatens the unity, integrity, defense, security or sovereignty of India,
              its friendly relations with foreign states, or public order or causes incitement to
              the commission of any cognizable offence or prevents investigation of any
              offence or is insulting to any other nation;`,
            },
            {
              desc: `invades anyone’s privacy by attempting to harvest, collect, store, or
              publish private or personally identifiable information, such as names, email
              addresses, phone numbers, passwords, account information, credit card
              numbers, home addresses, or other contact information without their
              knowledge and willing consent;`,
            },
            {
              desc: `contains falsehoods or misrepresentations that could damage reputation of
              TSH or any third party;`,
            },
            {
              desc: `is intended to threaten, stalk, defame, defraud, degrade, victimize, or
              intimidate an individual or group of individuals for any reason on the basis of
              age, gender, disability, ethnicity, sexual orientation, race, or religion; or to
              incite or encourage anyone else to do so;`,
            },
            {
              desc: `intends to harm or disrupt another User’s computer or would allow others to
              illegally access software or bypass security on websites or servers, including
              but not limited, to spamming, phishing, hacking or the use of trojan horses;`,
            },
            {
              desc: `impersonates, uses the identity of, or attempts to impersonate TSH’s
              employee, agent, manager, host, another User, or any other person through
              any means;`,
            },
            {
              desc: `advertises or solicits a business not related to or appropriate for the
              Website (as determined by the TSH in its sole discretion);`,
            },
            {
              desc: `contains advertising for fraud schemes, discount cards, credit
              counseling, online surveys or online contests;`,
            },
            {
              desc: `distributes or contains viruses or any other technologies that may harm
              TSH, or the interests or property of TSH or Users or the integrity of the
              
              Platform including attempts to decipher, decompile, disassemble or reverse
              engineer any part of TSH;
              `,
            },
            {
              desc: `contains links to commercial services or websites, except as allowed
            pursuant to the Terms of Use; and/or`,
            },
          ],
        },
      ],
    },
    {
      body: `Subject to the other terms of these Terms of Use, You will be able to view or share
      any Content on the Website, provided that You represent and adhere to the
      following:`,
      Listings: [
        {
          subpoints: [
            {
              desc: `if You are sharing any Content, whether on the Website or on any other third-
              party website which is linked to the Website, then You are either the owner of
              such Content or are a valid licensee with a right to share such Content;`,
            },
            {
              desc: `if You are viewing the Content, then You have been authorized to view the
              Content specifically by TSH on the Website, whether by subscription to such
              service or otherwise, or by a User on the Website.`,
            },
          ],
        },
      ],
      bodyBottom: `You further represent that You shall be solely responsible for Your own Content and
      the consequences of Posting or publishing it. In connection with such Content, You
      affirm, represent, and/or warrant that: (a) You own or have the necessary licenses,
      rights, consents, and permissions to use and authorize TSH to use/disseminate all
      patent, trademark, trade secret, copyright or other proprietary rights in and to any
      and all Content provided by You to enable inclusion and use of the such Content in
      the manner contemplated by the Website and these Terms of Use; and (b) You have
      the written consent, release, and/or permission of each and every identifiable
      individual person in the Content to use the name or likeness of each and every such
      identifiable individual person to enable inclusion and use of the Content in the
      manner contemplated by the Website and these Terms of Use. You agree to pay for
      all royalties, fees, and any other monies owing any person by reason of any Content
      posted by You to or through the Website.`,
    },
    {
      mainText: "Prohibited Postings",
      body: `Notwithstanding the generality of the foregoing, You will not make Postings or send
      messages to other Users containing:`,
      Listings: [
        {
          subpoints: [
            {
              desc: `unsolicited advertising or marketing of a service not offered on the Website or
              an external website; or`,
            },
            {
              desc: `Discriminatory Postings. Indian laws prohibit any preference, limitation or
              discrimination based on race, color, religion, sex, national origin, age,
              handicap or other protected class. TSH will not knowingly accept any Posting
              which is in violation of the law. TSH has the right, in its sole discretion and
              without prior notice to You; to immediately remove any Posting that
              discriminates or is in any way violation of any law.`,
            },
          ],
        },
      ],
    },
    {
      mainText: "Report Abuse:",
      body: `As a participant in the Website, You agree to use careful, prudent, and good
      judgment when Posting any feedback for another User (“Feedback”). The following
      actions constitute inappropriate uses of Feedback:
          `,
      Listings: [
        {
          subpoints: [
            {
              desc: `threatening to leave negative or impartial Feedback for another User unless
              that User provides services not included in the original Posting or not agreed
              to as part of the service to be provided;`,
            },
            {
              desc: `leaving Feedback in order to make the User appear better than he or she
              actually is or was, as may be reported by any other User of the Website; and
             `,
            },
            {
              desc: `including conditions that restrict a User from leaving Feedback.`,
            },
          ],
        },
      ],
    },
    {
      body: `If You violate any of the above-referenced rules in connection with leaving
      Feedback, TSH, in its sole discretion, may take any of the following actions, with or
      without cause and with or without notice, for any reason or no reason, or for any
      action that TSH determines is inappropriate or disruptive to this Website or to any
      other User of this Website. The list provided hereunder is illustrative and not
      exhaustive:`,
      Listings: [
        {
          subpoints: [
            {
              desc: `cancel or delete Your Feedback or any of Your Postings;`,
            },
            {
              desc: `limit Your Account privileges;
             `,
            },
            {
              desc: `suspend Your Account; and/or`,
            },
            {
              desc: `report to law enforcement authorities any actions that may be illegal, and any
            reports it receives of such conduct. When legally required or at TSH’s
            discretion, TSH will cooperate with law enforcement agencies in any
            investigation of alleged illegal activity on this Website or on the internet.`,
            },
          ],
        },
      ],
      bodyBottom: `In the event of any inappropriate use of the Feedback provisions provided
      hereunder, You may contact TSH regarding any such inappropriate use of Feedback
      via-email at`,
      url: `support@tripstoreholidays.com`,
      link: `support@tripstoreholidays.com`,
      completeText: `In the event of any dispute between Users concerning Feedback, TSH shall be the final arbiter of
      such dispute. Further, in the event of any dispute between Users of TSH concerning Feedback,
      TSH has the right, in its sole and absolute discretion, to remove such Feedback or take any
      action it deems reasonable without incurring any liability therefrom.
      Disclaimer: TSH does not and cannot review every Posting posted to the Platform. These
      prohibitions do not require TSH to monitor, police or remove any Postings or other information
      submitted by You or any other User.`,
    },
    {
      mainText: "Indemnity",
      body: `You agree to indemnify, defend and hold TSH, its parents, subsidiaries, affiliates,
      successors, assigns and licensors, any of their respective officers, directors,
      employees, agents, vendors, licensors, representatives, advertisers, service
      providers and suppliers harmless from and against any and all claims, actions,
      losses, expenses, damages and costs (including reasonable attorneys fees or any
      incidental or consequential damages), resulting from any personal injuries or
      damages to You while availing the Host Services, wilful misconduct by You,
      negligence by You or any breach or violation of Terms of Use by You, or due to Your
      activities related to the Website. Notwithstanding the generality of the foregoing, you
      
      agree to indemnify, defend and hold TSH harmless from any claim made by a third
      party against TSH for any reasons attributable to You.
    `,
    },
    {
      mainText: "Limitation of Liability",
      body: `Great care has been taken to ensure that the information available on the Website is correct and
      error free. We apologize for any errors or omissions that may have occurred. We cannot
      warrant that use of the Website will be error free or fit for purpose, timely, that defects will be
      corrected, or that the site or the server that makes it available are free of viruses or bugs or
      represents the full functionality, accuracy, reliability of the Website and We do not make any
      warranty whatsoever, whether express or implied, relating to fitness for purpose, or accuracy.
      By accepting these Terms of Use You agree to relieve Us from any liability whatsoever arising
      from Your use of information from any third party, or Your use of any third party website, or
      Your booking of any Host Services on the Website.
      We have taken all reasonable steps to prevent internet fraud and ensure any data collected from
      You is stored as securely and safely as possible. However, we cannot be held liable in the
      extremely unlikely event of a breach in Our secure computer servers or those of third parties.
      You acknowledge and agree that, to the maximum extent permitted by law, the entire risk
      arising out of Your access to and use of the Website and collective content, your listing or
      booking of any Host Services via the Website, Your participation in the any program, and any
      contact You have with other Users whether in person or online remains with You. Neither TSH
      nor any other party involved in creating, producing, or delivering the collective content or any
      program will be liable for any incidental, special, exemplary or consequential damages,
      including lost profits, loss of data or loss of goodwill, service interruption, computer damage or
      system failure or the cost of substitute products or services, or for any damages for personal or
      bodily injury or emotional distress arising out of or in connection with these terms, from the use
      of or inability to use the Website or collective content, from any communications, interactions
      
      or meetings with other users of the Website or other persons with whom You communicate or
      interact as a result of Your use of the Website or your participation in the any program or from
      Your booking of any Host Services via the Website, whether based on warranty, contract, tort
      (including negligence), product liability or any other legal theory, and whether or not TSH has
      been informed of the possibility of such damage, even if a limited remedy set forth herein is
      found to have failed of its essential purpose.
      Except for Our obligations to pay amounts to applicable Hosts pursuant to an approved
      payment request, in no event will our aggregate liability arising out of or in connection with
      these terms and Host’s use of the Website including, but not limited to, from booking of any
      accommodation via the Website, or from the use of or inability to use the Website, or collective
      content or Host participation in the any program and in connection with any Host Services or
      interactions with any other members, exceed the amounts you have paid or owe for bookings
      via the Website as a User in the twelve (12) month period prior to the event giving rise to the
      liability or one thousand Indian rupees only (INR 1000), if no such payments have been made.
      TSH merely provides intermediary services in order to facilitate highest quality services to you.
      TSH is not the last-mile service provider to you and therefore it shall not be or deemed to be
      responsible for any lack or deficiency of services provided by any person, Host (airline,
      travel/tour operator or similar agency), you shall engage or hire or appoint pursuant to or
      resulting from the material available in this Website. Neither TSH nor any of its directors,
      employees, agents, vendors or suppliers will be liable for any direct, indirect, special, punitive,
      consequential or incidental damages including, without limitation, lost profits or revenues, costs
      of replacement goods, loss or damage to data arising out of the use or inability to use this
      Website or any TSH product, or damages from the use of or reliance on the information present
      on this Website, even if TSH has been advised of the possibility of such damages. Cancellation,
      No Show and Refunds will be as per Host’s policies. Further TSH shall not be liable for any event
      which is not in direct control of TSH.
    `,
    },
    {
      mainText: "Personal Information and Privacy",
      body: `User understands and acknowledges that by choosing Website for booking an
accommodation You have allowed TSH to access and use Your personal
information. The collection, storage and use of Your personal information and any
other information provided by You on the Platform shall be subject to the Our Privacy
Policy available at `,
      url: `privacy policy`,
      link: `/policy`,
    },
    {
      mainText: "General Use Provisions; and Copyright",
      body: `All materials provided on this Website, including but not limited to all text, logos,
      designs, graphics, images, sounds, information, software, documents, products and
      services (collectively, the “Materials”), and the selection, arrangement and display
      thereof, are the copyrighted works of TSH and/or its vendors or suppliers. Any use of
      TSH's name, logo or any Intellectual Property without TSH's prior written consent
      shall amount to a material breach and TSH reserves the right to terminate. You
      acknowledge that TSH is only an intermediary with respect to all content featured on
      the Website. However, on receiving written notification of alleged infringement of
      third party intellectual property rights by any content, TSH may, at its own discretion,
      remove the allegedly infringing Content from the Website with or without prior notice
      to You. If Website does not take down the same, You agree that Your only recourse
      will be to terminate Your Account with the Website. You hereby waive any legal or
      equitable rights or remedies You have or may have against TSH with respect
      thereto. `,
    },
    {
      mainText: "License and Use of Content submitted by You",
      body: `You retain all of Your ownership rights in the Content submitted by You. However, by
      submitting such Content to TSH for Posting on the Platform, You hereby grant, and You
      represent and warrant that You have the right to grant, to TSH a perpetual, worldwide, non-
      exclusive, royalty-free, sub-licensable and transferable license to link to, use, reproduce,
      distribute, reformat, translate, prepare derivative works of, display, and perform such Content
      in connection with the Website and TSH's (and its assigns or successor’s) business operations,
      including without limitation, for the promotion and redistribution of any part or all of Website,
      and any derivative works thereof, in any media formats and through any media channels. You
      also hereby grant each User of the Website a non-exclusive license to access the Content
      submitted by You through the Website, and to use, reproduce, distribute, prepare derivative
      works of, display and perform such Content as permitted through the functionality of the
      Website and under these Terms of Use.
      You may remove such Content submitted on the Website at any time. However, TSH may
      continue to retain or store such Content submitted by You in its or third-party servers or
      systems and use such Content in the manner provided herein above.
      However, you will not submit, upload, share or Post, or provide as Feedback, anything on or
      through the Website as Your Content, which:`,
      Listings: [
        {
          subpoints: [
            {
              desc: `is copyrighted, protected by trade secret or otherwise subject to third-party proprietary
              rights, including privacy and publicity rights, unless You are the owner of such rights or
              have permission from the rightful owner to post the material and to grant TSH all of the
              license rights granted herein; or`,
            },
            {
              desc: `contains or promotes an illegal or unauthorized copy of another person’s copyrighted
              work, such as pirated computer programs or links to them, information to circumvent
              manufacture installed copy-protection devices, pirated audio or video or links to pirated
              audio or video files, works of art, articles or any other item the copy, display, use,
              performance, or distribution of which infringes on another’s copyright, intellectual
              property right, or any other proprietary right.
             `,
            },
          ],
        },
      ],
    },
    {
      mainText: "Devices",
      body: `You agree that you will not use any robot, spider, other automatic device, or manual process to
      monitor or copy our Web pages or the content contained herein without our prior expressed
      written permission. You agree that you will not use any device, software, or routine to interfere
      or attempt to interfere with the proper working of the TSH site or any listing, offer or
      transaction being conducted on our site. You agree that you will not take any action that
      imposes an unreasonable or disproportionately large load on our infrastructure. `,
    },
    {
      mainText: "No Unlawful or Prohibited Use",
      body: `As a condition of your use of this Web site, you warrant to TSH that you will comply with all
      applicable laws, statutes, ordinances, and regulations regarding your use of our service and any
      related activities. In addition, you warrant that you will not use this Web site in any way
      prohibited by these terms, conditions, and notices.`,
    },
    {
      mainText: "Consolidation",
      body: `TSH does not authorize any of its agents and franchises to consolidate or resell TSH products to
      sub-agents and/or other agents and franchises. If found, all such bookings shall be treated as
      fraudulent and TSH has the right to cancel such bookings at its own discretion with no liability
      
      for any consequential loss or damage. Any consolidation or reselling of TSH products and
      services by TSH agents and franchises requires prior approval and permission of TSH.`,
    },
    {
      mainText: "Links to Third-Party Web Sites",
      body: `This Web site may contain links to Web sites operated by parties other than TSH. Such
      hyperlinks are provided for reference only. TSH does not control such Web sites and is not
      responsible for their contents. TSH's inclusion of hyperlinks to such Web sites does not imply
      any endorsement of the material on such Web sites or any association with their operators. If
      you decide to access any of the third-party sites linked to this Web site, you do so entirely at
      your own risk.`,
    },
    {
      mainText: "Usage of Mobile Number of The User By The Company",
      body: `The Company agrees to send booking confirmation & itinerary information & update the USER
      on the booking status and any further itinerary information via SMS (short messaging service)
      or WhatsApp, on the mobile number given by the USER at the time of booking. The USER hereby
      unconditionally consents such intimation via SMS by the Company in accordance with the 'Do
      not Disturb' guidelines of Telecom Regulatory Authority of India (TRAI) or such other authority
      in India and abroad.`,
    },
    {
      mainText: "General Disclaimer",
      body: `Although TSH has attempted to provide accurate information on the Web site, TSH assumes no
      responsibility for the accuracy of the information (including fares displayed). All information
      provided on this Website is provided “as is” with all faults without warranty of any kind, either
      express or implied. TSH hereby disclaims all warranties, express or implied, including, without
      limitation, those of merchantability, fitness for a particular purpose, title and non-infringe
      mentor arising from a course of dealing, usage or trade practice, except to the extent such
      disclaimers are held to be legally invalid.`,
    },
    {
      mainText: "Modification of the Web Site",
      body: `TSH (and/or its suppliers) reserves the right in its sole discretion to improve, modify or remove
      any information or content appearing on the Web site. TSH may discontinue or revise any or all
      aspects of the Web site in its sole discretion and without prior notice.`,
    },
    {
      mainText: "Modification of These Terms of Use",
      body: `TSH reserves the right to change the terms, conditions, and notices under which this Web site is
      offered at any time. Modification of this contract will be deemed effective upon publication on
      the Web site with respect to any actions, communications or transactions occurring after said
      date. It is your responsibility to check terms and conditions of this Agreement at the time of
      each use.`,
    },
    {
      mainText: "Severability",
      body: `If any part of this agreement is determined to be invalid or unenforceable pursuant to
      applicable law including, but not limited to, the warranty disclaimers and liability limitations set
      forth above, then the invalid or unenforceable provision will be deemed superseded by a valid
      enforceable provision that most closely matches the intent of the original provision and the
      remainder of the agreement shall continue in effect.`,
    },
    {
      mainText: "Assignment",
      body: `You cannot assign or otherwise transfer this Terms and Conditions, or any rights granted
      hereunder or any obligations, to any third party and any such assignment or transfer or
      purported assignment or transfer shall be void ab initio. Our rights and/or obligations under the
      Terms of Use are freely assignable or otherwise transferable by Us to any third parties without
      the requirement of seeking Your prior consent. We may inform You of such assignment or
      transfer in accordance with the notice requirements under the Terms and Conditions. We shall
      have right to transfer Your Account and information to a third party who purchases Our
      business.`,
    },
    {
      mainText: "Force Majeure",
      body: `If performance of any service or obligation under this Terms and Conditions by any of the
      parties is prevented, restricted, delayed or interfered with by reason of labour disputes, strikes,
      acts of God, floods, lightning, severe weather, shortages of materials, rationing, utility or
      communication failures, earthquakes, war, revolution, acts of terrorism, civil commotion, acts of
      public enemies, blockade, embargo or any law, order, proclamation, regulation, ordinance,
      demand or requirement having legal effect of any government or any judicial authority or
      representative of any such government, or any other act whatsoever, whether similar or
      dissimilar to those referred to in this clause, which are beyond the reasonable control of such
      party or its third parties performing such services and could not have been prevented by
      reasonable precautions (each, a "Force Majeure Event"), then such party shall be excused from
      such performance to the extent of and during the period of such Force Majeure Event.`,
    },
    {
      mainText: "Waiver",
      body: `Any failure or delay by a party to enforce or exercise any provision of the Terms and Conditions,
      or any related right, shall not constitute a waiver by such party of that provision or right. The
      exercise of one or more of a party’s rights hereunder shall not be a waiver of, or preclude the
      exercise of, any rights or remedies available to such party under this Terms and Conditions or in
      law or at equity. Any waiver by a party shall only be made in writing and executed by a duly
      authorized officer of such party.`,
    },
    {
      mainText: "General",
      body: `This agreement is governed by the laws of India, without regard to its conflicts of laws
      principles. You hereby consent to the exclusive jurisdiction and venue of courts in India, in all
      disputes arising out of or relating to the use of this Web site. Use of this Web site is
      unauthorized in any jurisdiction that does not give effect to all provisions of these terms and
      conditions including without limitation this paragraph. Disputes, if any shall be subject to the
      jurisdiction of the courts of Thane, India.`,
    },
  ],
};
