import { TrackerPropertyKey, TrackingEventName, TrackingEventValues } from "../trackingConstants"
import { TrackableEvent } from "./trackableEvent"

export class RequestCallFormEndEvent extends TrackableEvent {
	constructor(filledInfo) {
		let properties = {}

		properties[TrackerPropertyKey.InfoFilled] = filledInfo
	

		super(TrackingEventName.FormEndRequestCall, properties)
	}
}