import * as types from "../action.types";

export const getBannerAction = () => {
    return {
        type: types.GET_BANNER
    };
};
export const getBannerActionSuccess = values => {
    return {
        type: types.GET_BANNER_SUCCESS,
        values
    };
};
