import React from "react"
import { withStyles } from "@material-ui/core/styles"
import discountBannerIcon from "../../../assets/images/discount_banner.svg"

const style = (theme) => ({
	img: {
		width: 50,
		height: 65,
		objectFit: "cover",
	},
	discountValueText: {
		color: "#fff",
		fontSize: 16,
		fontWeight: 600,
	},
	discountText: {
		color: "#fff",
		fontSize: 10,
	},
})

const DiscountBadge = (props) => {
	const { discount, color, classes } = props

	return (
		<React.Fragment>
			<div style={{ position: "relative", display: "inline-block" }}>
				<img src={discountBannerIcon} alt="discount" className={classes.img} />
				<div
					style={{
						position: "absolute",
						top: "30%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						textAlign: "center",
					}}>
					<div className={classes.discountValueText}>{discount}%</div>
					<div className={classes.discountText}>Discount</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default withStyles(style)(DiscountBadge)
