import * as types from "../action.types";

export const getRequestCallAction = data => {
  return {
    type: types.GET_REQUEST_CALL,
    data
  };
};

export const getRequestSuccessAction = data => {
  return {
    type: types.GET_REQUEST_CALL_SUCCESS,
    data
  };
};
