import { put, call } from "redux-saga/effects";
import { getDestinationService } from "../services/getDestination.service";

import { getDestinationActionSuccess } from "../actions/getDestination.action";
import * as types from "../action.types";
export function* getDestinationSaga() {
    try {
        const response = yield call(getDestinationService);
        yield put(getDestinationActionSuccess(response.data));

    } catch (error) {
        yield put({ type: types.GET_DESTINATION_ERROR, error });
    }
}
