import axios from "axios"

export const getThemeService = () => {
        return axios
                .get("/get/theme")
                .then((response) => {
                        return response
                })
                .catch((err) => {
                        return err.response
                })
}
