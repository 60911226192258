import React, { Component } from "react";
import { Typography, withStyles } from "@material-ui/core";
import { Dialog, IconButton } from "@material-ui/core";
// import styled from "styled-components";
import Logo from "../../assets/images/tripLogo.png";
import Success from "../../assets/images/DestinationEurope.jpg";
import Form from "./form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { agentAction } from "../../store/actions/agent.action";
import { validation } from "./validation";
import { Grid } from "@material-ui/core";
import "./agent.css";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
const useStyles = (theme) => ({
  paper: {
    position: "absolute",
    left: 100,
    right: 80,
    // top: 100,
    bottom: -60,
    [theme.breakpoints.down("sm")]: {
      left: 0,
      right: 0,
      top: 0,
    },
  },
  JoinText: {
    fontSize: 24,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  root: {
    height: 650,
    width: "100%",
    position: "absolute",
    top: 100,
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  ImageContents: {
    position: "absolute",
    bottom: 55,
    left: 32,
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      left: 16,
      bottom: 30,
    },
  },
  content: {
    paddingTop: 0,
    overflow: "hidden",
    // width: 700,
    display: "flex",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      overflow: "auto",
      display: "block",
    },
  },
  ImageGrid: {
    [theme.breakpoints.down("sm")]: {
      // display: "none",
      // height: 700,
    },
  },
  TextBody: {
    width: "auto",
    lineHeight: "normal",
    color: "#fff",
    paddingRight: 16,
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      paddingRight: 16,
    },
  },
  FormGrid: {
    padding: 32,
    // height: "100%",
    // overflow: "scroll",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
});
// const Image = styled.div`
//   background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
//     ${`url(${Success}) no-repeat top center`};
//   height: 100%;
//   width: 100%;
//   background-size: contain;
// `;

class BecomeAnAgent extends Component {
  state = {
    code: "",
  };
  componentWillReceiveProps = (nextprops) => {
    let agentData =
      nextprops &&
      nextprops._agent &&
      nextprops._agent.data &&
      nextprops._agent.data.message;
    this.setState({
      code: agentData,
    });
  };
  handleSubmit = (values) => {
    this.props.onClose();

    this.props.agentAction(values);
  };

  render() {
    const { open, onClose, classes } = this.props;

    return (
      <React.Fragment>
        <Grid
          md={12}
          xs={12}
          sm={12}
          item
          container
          justifyContent="center"
          style={{ position: "relative" }}
        >
          <Dialog
            open={open}
            onClose={onClose}
            className={classes.root}
            fullScreen={window.innerWidth <= 576 ? true : false}
            PaperProps={{
              className: classes.paper,
            }}
          >
            {/* <DialogContent > */}
            <Grid md={12} xs={12} sm={12} item className={classes.content}>
              <Grid
                md={6}
                sm={12}
                xs={12}
                container
                item
                justifyContent="flex-start"
                style={{ position: "absolute", zIndex: 1 }}
              >
                <img
                  src={Logo}
                  height={30}
                  width={132}
                  alt="logo"
                  style={{ objectFit: "contain", padding: 16 }}
                />
              </Grid>
              <Grid
                md={6}
                xs={12}
                sm={12}
                item
                style={{ position: "relative", height: 600 }}
              >
                {window.innerWidth <= 900 ? (
                  <Grid
                    container
                    justifyContent="flex-end"
                    style={{ position: "absolute", zIndex: 1 }}
                  >
                    <IconButton
                      justifyContent="flex-end"
                      onClick={onClose}
                      style={{ padding: 16, color: "#000" }}
                    >
                      <CloseOutlinedIcon />
                    </IconButton>
                  </Grid>
                ) : (
                  ""
                )}
                <Grid
                  md={12}
                  sm={12}
                  xs={12}
                  item
                  className="img-gradient"
                  style={{ height: "100%" }}
                >
                  <img
                    src={Success}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    alt="pic"
                  />
                </Grid>

                {/* <Image /> */}
                <Grid
                  md={10}
                  sm={12}
                  xs={12}
                  item
                  className={classes.ImageContents}
                >
                  <Typography className={classes.JoinText}>Join Us</Typography>
                  <Typography className={classes.TextBody} variant="subtitle1">
                    Trip Store is a online platform for customers to search,
                    compare and book tour packages. Trip Store provides
                    continous support to tour operators — we drive you real
                    bookings, handle the payments and provide marketing support
                    to help your brand and tours reach more customers.
                  </Typography>
                </Grid>
              </Grid>
              <Grid md={7} xs={12} item className={classes.FormGrid}>
                <Form
                  initialValues={{
                    name: "",
                  }}
                  onSubmit={this.handleSubmit}
                  validationSchema={validation}
                  onClose={this.props.onClose}
                />
              </Grid>
            </Grid>
            {/* </DialogContent> */}
          </Dialog>
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ agentReducer }) => {
  return {
    _agent: agentReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    agentAction,
  })(withStyles(useStyles)(BecomeAnAgent))
);
