import React from "react";
import "./pageloader.css";

function Skeleton() {
  return (
    <div className="flex-center">
      <div className="wrapper">
        <div className="card">
          <div className="shimmer"></div>
          <div className="card-content"></div>
          
        </div>
      </div>
    </div>
  );
}

export default Skeleton;
