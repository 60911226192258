import * as types from "../action.types";
export const getMetaDetailReducer = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.GET_META_SUCCESS:
      return response;
    case types.GET_META_ERROR:
      return state;
    case types.GET_RESPONSE_NULL:
      return { state: null };
    default:
      return state;
  }
};
