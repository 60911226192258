import React, { Component, lazy, Suspense } from "react";
import { Link, withRouter } from "react-router-dom";
import { Grid, Typography, Container, Button, Box } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import HighlightBadge from "../PackagesList/packageCard/highlightBadge";
import { getCompareListAction } from "../../store/actions/getComparelist.action";
import { getPackageListAction } from "../../store/actions/packageList.action";
import { getPackageDetailAction } from "../../store/actions/getPackageDetail.action";
import { getResponseNullAction } from "../../store/actions/getPackageDetail.action";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Helmet } from "react-helmet";
import {
  TrackingOrigin,
  TrackingPageName,
} from "../../../tracking/trackingConstants";
import { Tracker } from "../../../tracking/tracker";
import { useLocation, useHistory } from "react-router-dom";
import { PackageDetailPageViewEvent } from "../../../tracking/events/packageDetailPageViewEvent";
import SaleInfo from "../../models/listing/saleInfo";
import DiscountBadge from "../PackagesList/packageCard/discountBadge";
import icon_whatsapp from "../../../app/assets/images/icon_whatsapp.svg";
import "./packagedetails.css";
import { WhatsappUtil } from "../../../utils/whatsappUtil";
import {
  PackageOpenWhatsappEvent,
  WhatsappOpenEventOrigin,
} from "../../../tracking/events/packageOpenWhatsappEvent";
import { NavigationContext } from "../../navigation/navigationContext";
import FormModal from "../FormModal/FormModal";
import RequestCallForm from "../RequestCall/RequestCallForm";
import { loginEndEvent } from "../../../tracking/events/loginEndEvent";
import { convertUrlToPageName, hideTourOprator } from "../../../utils/utility";
import DiscountBarComponent from "../DiscountBarComponent/DiscountBarComponent";
import CalendarComponent from "../Calendar/CalendarComponent";
import ModalComponent from "../ModalComponent/ModalComponent";
import LoginUser from "../Auth/Login/LoginUser";
import {
  getBookingAvailibilty,
  getpackageDetails,
  getPackagePdf,
  getPackagePdfDownloadUrl,
  getRelatedpackageDetails,
} from "../Services/services";
import { departureDateButtonClickEvent } from "../../../tracking/events/departureDateButtonClickEvent";
import { bookPackageButtonClickEvent } from "../../../tracking/events/bookPackageButtonClickEvent";
import HomePageLeadForm from "../HomepageLeadForm/HomePageLeadForm";
import { DownloadPdfClickEvent } from "../../../tracking/events/downloadPdfClickEvent";
const Navbar = lazy(() => import("./header"));
const DailogBox = lazy(() => import("./images"));
const CompareDailog = lazy(() => import("./compareDailog/CompareDailog"));
const Footer = lazy(() => import("../footer/footer"));
const Facility = lazy(() => import("./packageFacilities"));
const Itenary = lazy(() => import("./Itenary/DayChange"));
const RelatedPackage = lazy(() => import("./RelationPackage/related"));
const AccomodationSightseeing = lazy(() =>
  import("./Accommodation/newAccomodation")
);
const Inclusion = lazy(() => import("./inclusion&exclusion/inclusion"));
const Registration = lazy(() =>
  import("../PackagesList/RequestForPackage/PackageCall")
);

const style = (theme) => ({
  root: {
    flexGrow: 1,
  },
  tags: {
    padding: "0px 16px 16px 0px",
    "& > *": {
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  images: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      overflowX: "scroll",
      marginTop: theme.spacing(10),
    },
  },

  ButtonSeeCompare: {
    color: "#ff8362",
    textTransform: "none",
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  perperson: {
    color: "#616e78",
    textAlign: "end",
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      textAlign: "start",
    },
  },

  price: {
    color: "#16222b",
    fontSize: 28,

    textAlign: "end",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
      fontSize: 20,
      width: "max-content",
      // textAlign: "start"
    },
  },
  rowdata: {
    marginTop: theme.spacing(3),

    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  img: {
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      width: 370,
      paddingRight: theme.spacing(1),
    },
  },

  gridImage1: {
    height: "80vh",
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      height: "40vh",
    },
  },
  tourName: {
    textTransform: "capitalize",
    color: "#16222b",
    fontSize: 28,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  duration: {
    color: "#616E78",
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  gridImage2: {
    height: "40vh",
  },
  gridImage3: {
    height: "40vh",
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(0),
    },
  },
  gridImage4: {
    height: "40vh",

    paddingTop: theme.spacing(1),
  },
  commonclass: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  firstRow: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  RequestCall: {
    width: "100%",
    textAlign: "center",
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },

  completeImageGrid: {
    display: "flex",
    overflowX: "hidden",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      overflowX: "scroll",
    },
  },

  secondGrid: {
    flexWrap: "wrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  secondcol: {
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },

  rightCol: {
    justifyContent: "flex-end",

    width: "100%",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      marginTop: 8,
    },
  },
  departureText: {
    color: "#16222B",
    padding: "16px 16px 16px 0px ",
    fontSize: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  agentName: {
    color: "#224E6D",
    fontSize: 16,
    fontWeight: "bold",
    paddingTop: 8,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  retailPrice: {
    color: "#000",
    fontSize: 24,
    marginRight: "8px",
    textDecoration: "line-through",
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  salePrice: {
    color: "#ff6840",
    fontSize: 28,
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
    },
  },
  pricingGrid: {
    justifyContent: "flex-end",
    display: "flex-end",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  pricingDiscount: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  titleDiscount: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  whatsappButton: {
    backgroundColor: "transparent",
    hover: "none",
    paddingLeft: 0,
    margin: 0,
    minWidth: "auto", // Add this line to make the button size as per the image
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
    },
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.spacing(1),
    },
  },
  whatsappImage: {
    width: "32px",
    height: "32px",
  },
});
class PackageDetails extends Component {
  static contextType = NavigationContext;

  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      BoxOpen: false,
      images: [],
      open: false,
      compareOpen: false,
      requestId: "",
      isClick: false,
      url: "",
      pageNumber: 4,
      count: 4,
      destId: JSON.parse(localStorage.getItem("destId")),
      themeId: JSON.parse(localStorage.getItem("themeId")) || [],
      themeName: JSON.parse(localStorage.getItem("themeName")) || [],
      compareData: JSON.parse(localStorage.getItem("compare")) || [],
      idArray: JSON.parse(localStorage.getItem("packageId")) || [],
      popularPackage: JSON.parse(localStorage.getItem("popularPackage")),
      title_tags: "",
      description_tags: "",
      openCallRequestModal: false,
      isrequestCallButtonClicked: "",
      openLoginModal: false,
      currentView: "",
      isVisibleNav: false,
      openCalendarModal: false,
      clickedButton: "",
      bookingAvailibiltyData: [],
      loadingBookingButton: false,
      packageDetailsData: {},
      sightseeingData: [],
      relatedPackageDetailsData: [],
      loadingPdf: false,
      packagePdffUrl: "",
    };

    this.closeBox = this.closeBox.bind(this);
  }
  //  locationssss = useLocation();
  //  history = useHistory();
  getPackagePdfUrl = async () => {
    this.setState({
      loadingPdf: true,
    });
    try {
      const urlResponse = await getPackagePdfDownloadUrl(
        this.state.packageDetailsData?.id
      );
      if (urlResponse.status === 200) {
        this.downloadPackagePdf(urlResponse?.data.data.url);
        this.setState({
          loadingPdf: false,
        });
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
      this.setState({
        loadingPdf: false,
      });
    }
  };

  downloadPackagePdf = (packageUrl) => {
    const link = document.createElement("a");
    link.href = packageUrl;
    link.setAttribute("download", `${this.state.packageDetailsData?.slug}.pdf`);

    link.click();
  };

  accommodations = [];
  sightseeingList = [];
  groupedByCity = this.sightseeingList.reduce((group, activity) => {
    const city = activity.location.city;
    if (!group[city]) {
      group[city] = [];
    }
    group[city].push(activity.title);
    return group;
  }, {});

  getPackagedetailsInfo = async (packageSlug) => {
    const response = await getpackageDetails(packageSlug);
    if (response.status === 200) {
      this.setState({
        packageDetailsData: response.data.data,
      });

      response.data.data.itinerary_info.forEach((item) => {
        if (item.accommodation) {
          const accommodationWithLocation = {
            ...item.accommodation,
            location: item.location || {},
          };
          this.accommodations.push(accommodationWithLocation);
        }

        if (item.sightseeing && item.sightseeing.length > 0) {
          const sightseeingWithLocation = item.sightseeing.map((sight) => ({
            ...sight,
            location: item.location || {},
          }));
          this.sightseeingList.push(...sightseeingWithLocation);
        }
        const groupedByCity = new Map();

        this.sightseeingList.forEach((sight) => {
          const city = sight.location.city || "Unknown";
          if (!groupedByCity.has(city)) {
            groupedByCity.set(city, []);
          }
          groupedByCity.get(city).push(sight.title);
        });
        const groupedArray = Array.from(groupedByCity, ([city, titles]) => ({
          city,
          titles,
        }));
        this.setState({
          sightseeingData: groupedArray,
        });
      });
    }
  };

  getRelatedPackage = async (packageSlug) => {
    const response = await getRelatedpackageDetails(packageSlug);
    if (response.status === 200) {
      this.setState({
        relatedPackageDetailsData: response.data.data,
      });
    }
  };

  handleRequestCallClick = () => {
    this.handleOpenRequestCallModal();
  };
  handleOpenLoginModal = () => {
    this.setState({
      openLoginModal: true,
    });
  };
  isLoggedIn = localStorage.getItem("access_token");
  setCurrentView = (newData) => {
    this.setState({ currentView: newData });
  };
  handleCloseLoginModal = () => {
    this.setState({
      openLoginModal: false,
    });
  };
  handleCloseRequestModal = () => {
    this.setState({
      openCallRequestModal: false,
    });
  };
  handleOpenRequestCallModal = () => {
    this.setState({
      openCallRequestModal: true,
    });
  };
  handleCloseCalendarModal = () => {
    this.setState({
      openCalendarModal: false,
    });
  };
  handleOpenCalendarModal = (value) => {
    this.getBookingAvailabilityList();

    this.setState({
      clickedButton: value,
    });
    this.setState({
      openCalendarModal: true,
    });
  };

  componentDidMount = () => {
    const getLastItem = (thePath) =>
      thePath.substring(thePath.lastIndexOf("/") + 1);
    let lastpath = getLastItem(window.location.pathname);

    this.getPackagedetailsInfo(
      window.location.pathname.split("/").slice(2).join("/")
    );
    this.getRelatedPackage(
      window.location.pathname.split("/").slice(2).join("/")
    );
    // Tracking Page View
    this.context.setBrowsingPage(TrackingPageName.PackageDetail);

    this.setState({
      url: window.location.href,
    });
    window.scrollTo(0, 0);

    // this.props.getPackageDetailAction({
    //   params: { package_id: lastpath },
    // });
  };
  componentWillUnmount = () => {
    this.props.getResponseNullAction();
  };

  // componentWillReceiveProps = (nextProps) => {
  //   console.log("rtehe",nextProps)
  //   let tags =
  //     nextProps &&
  //     nextProps._packageDetails &&
  //     nextProps._packageDetails.data &&
  //     nextProps._packageDetails.data.data &&
  //     nextProps._packageDetails.data.data.meta_tag;
  //   if (tags) {
  //     this.setState({
  //       title_tags: tags.title,
  //       description_tags: tags.description,
  //     });
  //   }
  // };
  handleStorageDailog = (value) => {
    this.handleCompareOpen();

    this.setState(
      {
        compareData: this.state.compareData.concat(value),
        idArray: this.state.idArray.concat(value.id),
      },
      () => {
        localStorage.setItem("compare", JSON.stringify(this.state.compareData));
        localStorage.setItem("packageId", JSON.stringify(this.state.idArray));
      }
    );
    this.props.getPackageListAction();
  };
  handlerelatedPackage = (id) => {
    window.location.href = `/packagedetails/${id}`;
  };

  delete = (id) => {
    const deleteFrmLocal = JSON.parse(localStorage.getItem("compare"));
    const afterdelete = deleteFrmLocal.filter((el) => el.id !== id);
    localStorage.setItem("compare", JSON.stringify(afterdelete));
    this.setState({
      compareData: afterdelete,
    });
    const deleteId = JSON.parse(localStorage.getItem("packageId"));
    const iddeleted = deleteId.filter((el) => el !== id);

    localStorage.setItem("packageId", JSON.stringify(iddeleted));
    this.setState({
      idArray: iddeleted,
    });
  };
  handleCompareOpen = () => {
    this.setState({
      compareOpen: true,
    });
  };
  handleCompareClose = () => {
    if (this.state.compareDate === null) {
      this.setState({
        compareOpen: false,
      });
    }
    this.setState({
      compareOpen: false,
    });
  };
  handleCompare = (id) => {
    this.setState({
      open: true,
      requestId: id,
    });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  openBox = (obj) => {
    this.setState({
      BoxOpen: true,
      images: obj,
    });
  };
  closeBox = () => {
    this.setState({
      BoxOpen: false,
    });
  };

  handleTags = (item) => {
    if (item.theme_name) {
      this.setState({
        themeId: this.state.themeId.includes(item.id)
          ? ""
          : this.state.themeId.push(item.id),
        themeName: this.state.themeName.includes(item.theme_name)
          ? ""
          : this.state.themeName.push(item.theme_name),
      });
    }

    if (item.region_name) {
      this.setState({
        destId: item.id,
      });
    }
    this.props.getPackageListAction({
      params: { theme_id: [item.id] },
    });
    this.props.getPackageListAction({ params: { destination_id: item.id } });
    localStorage.setItem("destId", JSON.stringify(this.state.destId));
    localStorage.setItem("themeId", JSON.stringify(this.state.themeId));
    localStorage.setItem("themeName", JSON.stringify(this.state.themeName));
  };

  onWhatsAppClick = () => {
    const whatsappUtil = new WhatsappUtil();
    const packageData = this.state.packageDetailsData;
    const name = this.state.packageDetailsData.name;
    const saleData =
      this.state.packageDetailsData && this.state.packageDetailsData.sale_info;
    const saleInfo = saleData && new SaleInfo(saleData);

    // const saleInfo =
    //   packageData &&
    //   packageData.sale_info &&
    //   new SaleInfo(packageData.sale_info);
    const price = saleInfo
      ? saleInfo.listPrice.displayValue
      : "Price not available";
    const pageUrl = window.location.toString();
    Tracker.getInstance().trackEvent(
      new PackageOpenWhatsappEvent(
        WhatsappOpenEventOrigin.DETAIL_PAGE,
        packageData.slug
      )
    );

    whatsappUtil.openWhatsappForPackage(name, price, pageUrl);
  };
  onRequestSubmit = () => {};
  handleVisibilityChange = (isVisible) => {
    this.setState({ isVisibleNav: isVisible });
  };

  getBookingAvailabilityList = async () => {
    this.setState({
      loadingBookingButton: true,
    });
    const response = await getBookingAvailibilty(
      this.state.packageDetailsData.id
    );
    if (response.status === 200) {
      this.setState({ bookingAvailibiltyData: response.data.data });
      this.setState({
        loadingBookingButton: false,
      });
    }
    if (response.status === 400) {
      this.setState({
        loadingBookingButton: false,
      });
    }
  };

  render() {
    //  const pageName = convertUrlToPageName(this.locationssss.pathname)
    //     console.log("the page name---",pageName)
    const previousPage = sessionStorage.getItem("previousPage");
    const { packageDetailsData } = this.state;

    const { _packageDetails, classes } = this.props;
    const { title_tags, description_tags } = this.state;
    const saleData = packageDetailsData && packageDetailsData.sale_info;
    const data2 = _packageDetails.data;
    let data = data2 && data2.data;
    let id = data && data.id;
    const PdfFile = data && data.itinerary_pdf;
    const image1 = packageDetailsData && packageDetailsData.images?.[0];
    const image2 = packageDetailsData && packageDetailsData.images?.[1];
    const image3 = packageDetailsData && packageDetailsData.images?.[2];
    const image4 = packageDetailsData && packageDetailsData.images?.[3];
    const images =
      packageDetailsData &&
      packageDetailsData.images?.map((item) => {
        return item;
      });

    const saleInfo = saleData && new SaleInfo(saleData);

    const shouldShowRetailPrice =
      (saleInfo && saleInfo.retailPrice && saleInfo.shouldShowRetailPrice) ??
      false;
    const retailPrice = saleInfo && saleInfo.retailPrice;
    const salePrice = saleInfo && saleInfo.listPrice;
    const discountPercentage = saleInfo && saleInfo.discountPercentage;
    const packageDetails = {
      packageName: packageDetailsData?.name,
      packageImage: packageDetailsData?.images?.[0],
      packageListPrice: salePrice,
      packageRetailPrice: retailPrice,
      packageId: packageDetailsData.id,
      shouldShowRetailPrice: shouldShowRetailPrice,
      slug: packageDetailsData.slug,
      discount: packageDetailsData?.sale_info?.discount_percentage,
    };

    const isMobile = window.innerWidth <= 768;
    const gridClass = `${
      isMobile && this.state.isVisibleNav
        ? "grid-container-hide"
        : "grid-container-show"
    }`;
    const widgetData = {
      title: "Get the Best Holiday Planned by Experts!",
    };
    return (
      <React.Fragment>
        <CalendarComponent
          handleCloseCalendarModal={this.handleCloseCalendarModal}
          handleOpenCalendarModal={this.handleOpenCalendarModal}
          openCalendarModal={this.state.openCalendarModal}
          clickedButton={this.state.clickedButton}
          bookingAvailibiltyData={this.state.bookingAvailibiltyData}
          loadingBookingButton={this.state.loadingBookingButton}
        />
        <FormModal
          packageDetails={packageDetails}
          openCustomizeModal={this.state.openCallRequestModal}
          handleCloseCustomizeModal={this.handleCloseRequestModal}
          children={<RequestCallForm />}
          origin={TrackingOrigin.LeadForm.PackageDetail}
        />
        <ModalComponent
          openLoginModal={this.state.openLoginModal}
          handleCloseLoginModal={this.handleCloseLoginModal}
          children={<LoginUser />}
        />
        <Helmet>
          <title>{packageDetailsData?.meta?.title}</title>
          <meta
            data-react-helmet={true}
            name="description"
            content={packageDetailsData?.meta?.description}
          />
          <meta name="keywords" content="packageDetailPage" />
        </Helmet>

        <Suspense
          fallback={<h6 style={{ color: "transparent" }}>loading...</h6>}
        >
          <Navbar
            style={{ background: "#16222b" }}
            parentPageName={TrackingPageName.PackageDetail}
            onVisibilityChange={this.handleVisibilityChange}
          />
        </Suspense>
        {Object.keys(packageDetailsData).length === 0 ? (
          <Skeleton
            height="100vh"
            width="100%"
            style={{ color: "primary" }}
          ></Skeleton>
        ) : (
          <Grid
            container
            justifyContent="space-around"
            direction="column"
            className={gridClass}
          >
            <Grid
              md={12}
              xs={12}
              sm={12}
              item
              className={classes.completeImageGrid}
            >
              <Grid md={6} sm={6} item className={classes.gridImage1}>
                <img
                  src={image1}
                  alt="image1"
                  width="100%"
                  height="100%"
                  style={{ objectFit: "cover" }}
                  onClick={() => this.openBox(image1)}
                />
              </Grid>
              <Grid md={6} sm={6} xs={2} item className={classes.commonclass}>
                <Grid md={12} sm={12} className={classes.gridImage2}>
                  <img
                    src={image2}
                    alt="image2"
                    width="100%"
                    height="100%"
                    className={classes.img}
                    onClick={() => this.openBox(image2)}
                  />
                </Grid>

                <Grid container direction="column">
                  <Grid
                    md={12}
                    justifyContent="space-between"
                    item
                    style={{ display: "flex" }}
                  >
                    <Grid md={6} sm={6} item className={classes.gridImage3}>
                      <img
                        src={image3}
                        className={classes.img}
                        alt="image3"
                        width="100%"
                        height="100%"
                        onClick={() => this.openBox(image3)}
                      />
                    </Grid>
                    <Grid md={6} sm={6} item className={classes.gridImage4}>
                      <img
                        src={image4}
                        alt="image4"
                        className={classes.img}
                        width="100%"
                        height="100%"
                        onClick={() => this.openBox(image4)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Suspense
              fallback={<h6 style={{ color: "transparent" }}>loading...</h6>}
            >
              <DailogBox
                open={this.state.BoxOpen}
                images={images}
                currimagesentImage={this.state.images}
                close={this.closeBox}
              />
            </Suspense>
          </Grid>
        )}
        {Object.keys(packageDetailsData).length > 0 ? (
          <Grid>
            <Container className={classes.root}>
              <Grid
                md={12}
                xs={12}
                sm={12}
                container
                justifyContent="space-between"
                direction="row"
                style={{ marginTop: 24 }}
              >
                <Grid xs={12} sm={7} item container>
                  <Grid container item xs={10} style={{ display: "block" }}>
                    <Typography className={classes.tourName}>
                      {packageDetailsData?.name.toLowerCase()}
                    </Typography>
                    <Grid container item alignItems="center" spacing={1}>
                      {packageDetailsData?.highlight && (
                        <Grid item>
                          <HighlightBadge
                            text={packageDetailsData?.highlight.badge}
                            backgroundColor={
                              packageDetailsData?.highlight.color
                            }
                          />
                        </Grid>
                      )}
                      <Grid item>
                        <Typography className={classes.duration}>
                          {packageDetailsData?.nights} Nights{" "}
                          {packageDetailsData?.days} Days
                        </Typography>
                      </Grid>
                    </Grid>

                    <Typography className={classes.agentName}>
                      Tour Operator:
                      {this.isLoggedIn
                        ? packageDetailsData?.tour_operator.name
                        : hideTourOprator(
                            packageDetailsData?.tour_operator.name
                          )}
                    </Typography>
                    {window.innerWidth <= 768 ? null : packageDetailsData
                        ?.departure_dates?.length > 0 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.handleOpenCalendarModal("departureDates");
                          Tracker.getInstance().trackEvent(
                            new departureDateButtonClickEvent()
                          );
                        }}
                        // onClick={() => this.handleCompare(data.id)}
                        style={{
                          height: "36px",
                          width: "153px",
                          textTransform: "none",
                          backgroundColor: "var(--secondary-button-color)",
                          borderRadius: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <Typography
                          className={classes.RequestCall}
                          variant="subtitle1"
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Departure Dates
                        </Typography>
                      </Button>
                    ) : null}
                  </Grid>
                  {packageDetailsData?.sale_info.discount_percentage > 0 ? (
                    <Grid container item xs={2}>
                      <div
                        className={classes.titleDiscount}
                        style={{ paddingLeft: "10px" }}
                      >
                        <DiscountBadge
                          discount={
                            packageDetailsData?.sale_info.discount_percentage
                          }
                          color={packageDetailsData?.sale_info.discount_color}
                        />
                      </div>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid
                  xs={12}
                  sm={5}
                  item
                  container
                  className={classes.rightCol}
                >
                  <Grid
                    xs={6}
                    sm={12}
                    item
                    className={classes.secondcol}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent:
                        window.innerWidth <= 768 ? "flex-start" : "flex-end",
                    }}
                  >
                    <Grid
                      container
                      item
                      className={classes.pricingGrid}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:
                          window.innerWidth <= 768 ? "flex-start" : "flex-end",
                      }}
                    >
                      {shouldShowRetailPrice && (
                        <Typography className={classes.retailPrice}>
                          {retailPrice.displayValue}
                        </Typography>
                      )}
                      <Grid
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent:
                            window.innerWidth <= 768 ? "flex-start" : "center",
                        }}
                      >
                        <Typography className={classes.salePrice}>
                          {salePrice.displayValue}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className={classes.perperson}
                          style={{ marginLeft: "5px" }}
                        >
                          Per Person
                        </Typography>
                      </Grid>
                      {packageDetailsData?.sale_info.discount_percentage > 0 ? (
                        <div
                          className={classes.pricingDiscount}
                          style={{ paddingLeft: "10px" }}
                        >
                          <DiscountBadge
                            discount={
                              packageDetailsData?.sale_info.discount_percentage
                            }
                            color={packageDetailsData?.sale_info.discount_color}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  {this.isLoggedIn ? null : (
                    <div class="packageDetails-discount-bar">
                      <DiscountBarComponent
                        color="var(--primary-black)"
                        fontStyle="italic"
                        fontWeight=""
                        origin="package-details"
                        packageId={packageDetailsData?.id}
                      />
                    </div>
                  )}
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {window.innerWidth <= 768 ? null : this.state.compareData
                        .length === 3 ||
                      this.state.idArray.includes(packageDetailsData?.id) ? (
                      <div
                        style={{
                          color: "#ff8362",
                          textTransform: "none",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "auto",
                          whiteSpace: "normal",
                          cursor: "pointer",
                          padding: "0px 0px",
                          marginRight: "5px",
                        }}
                        className={classes.ButtonSeeCompare}
                        onClick={() => this.handleCompareOpen()}
                      >
                        <h5
                          color="secondary"
                          style={{
                            width: "100%",
                            whiteSpace: "normal",
                            color: "#ff8362",
                          }}
                        >
                          Show Compare List
                        </h5>
                      </div>
                    ) : (
                      <div
                        variant="text"
                        style={{
                          color: this.state.isClick ? "green" : "#ff8362",
                          textTransform: "none",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "auto",
                          whiteSpace: "normal",
                          cursor: "pointer",
                          padding: "0px 0px",
                          marginRight: "5px",
                        }}
                        onClick={() =>
                          this.handleStorageDailog({
                            id: packageDetailsData?.id,
                            name: packageDetailsData?.name,
                            price:
                              packageDetailsData?.sale_info.list_price.amount,
                            duration: `${packageDetailsData?.nights} Nights ${packageDetailsData?.days} Days`,
                          })
                        }
                      >
                        <PlaylistAddIcon
                          style={{ color: "#224e6d", marginRight: 1 }}
                        />
                        <h5
                          color="secondary"
                          style={{
                            width: "100%",
                            whiteSpace: "normal",
                            color: "var(--secondary-color)",
                          }}
                        >
                          ADD TO COMPARE
                        </h5>
                      </div>
                    )}

                    {window.innerWidth > 600 ? (
                      <Grid direction="row">
                        <Button
                          className={classes.whatsappButton}
                          onClick={this.onWhatsAppClick}
                        >
                          <img
                            src={icon_whatsapp}
                            alt="WhatsApp"
                            className={classes.whatsappImage}
                          />
                        </Button>
                      </Grid>
                    ) : (
                      ""
                    )}
                    {window.innerWidth <= 768 ? (
                      packageDetailsData?.departure_dates?.length > 0 ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            this.handleOpenCalendarModal("departureDates");
                            Tracker.getInstance().trackEvent(
                              new departureDateButtonClickEvent()
                            );
                          }}
                          style={{
                            height: "36px",
                            width: "153px",
                            textTransform: "none",
                            backgroundColor: "var(--secondary-button-color)",
                            borderRadius: "10px",
                          }}
                        >
                          <Typography
                            className={classes.RequestCall}
                            variant="subtitle1"
                            style={{ width: "100%", textAlign: "center" }}
                          >
                            Departure Dates
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            this.handleRequestCallClick();
                          }}
                          style={{
                            height: "36px",
                            width: "153px",
                            textTransform: "none",
                            borderRadius: "10px",
                            borderWidth: "1px",
                            marginRight: "10px",
                          }}
                        >
                          <Typography
                            className={classes.RequestCall}
                            variant="subtitle1"
                            style={{ width: "100%", textAlign: "center" }}
                          >
                            Request a Call
                          </Typography>
                        </Button>
                      )
                    ) : (
                      <>
                        {" "}
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            this.handleRequestCallClick();
                          }}
                          // onClick={() => this.handleCompare(data.id)}
                          style={{
                            height: "36px",
                            width: "153px",
                            textTransform: "none",
                            borderRadius: "10px",
                            borderWidth: "1px",
                            marginRight: "10px",
                          }}
                        >
                          <Typography
                            className={classes.RequestCall}
                            variant="subtitle1"
                            style={{ width: "100%", textAlign: "center" }}
                          >
                            Request a Call
                          </Typography>
                        </Button>
                        {packageDetailsData?.departure_dates?.length > 0 ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              this.isLoggedIn
                                ? this.handleOpenCalendarModal("booknow")
                                : this.handleOpenLoginModal();
                              Tracker.getInstance().trackEvent(
                                new bookPackageButtonClickEvent()
                              );
                            }}
                            style={{
                              height: "36px",
                              width: "153px",
                              textTransform: "none",
                              borderRadius: "10px",
                            }}
                          >
                            <Typography
                              className={classes.RequestCall}
                              variant="subtitle1"
                              style={{ width: "100%", textAlign: "center" }}
                            >
                              {this.state.loadingBookingButton ? (
                                <CircularProgress color="inherit" size={12} />
                              ) : (
                                "Book at ₹0"
                              )}
                            </Typography>
                          </Button>
                        ) : null}
                      </>
                    )}

                    {window.innerWidth <= 600 ? (
                      <Grid direction="row">
                        <Button
                          className={classes.whatsappButton}
                          onClick={this.onWhatsAppClick}
                        >
                          <img
                            src={icon_whatsapp}
                            alt="WhatsApp"
                            className={classes.whatsappImage}
                          />
                        </Button>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {window.innerWidth <= 768 &&
              packageDetailsData?.departure_dates?.length > 0 ? (
                <div className="packagedeatails-floating-bottom-bar">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      this.handleRequestCallClick();
                    }}
                    style={{
                      height: "36px",
                      width: "153px",
                      textTransform: "none",
                      backgroundColor: "white",
                      borderRadius: "10px",
                      borderWidth: "2px",
                    }}
                  >
                    <Typography
                      className={classes.RequestCall}
                      variant="subtitle1"
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      Request a Call
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.isLoggedIn
                        ? this.handleOpenCalendarModal("booknow")
                        : this.handleOpenLoginModal();
                      Tracker.getInstance().trackEvent(
                        new bookPackageButtonClickEvent()
                      );
                    }}
                    style={{
                      height: "36px",
                      width: "153px",
                      textTransform: "none",
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      className={classes.RequestCall}
                      variant="subtitle1"
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      {this.state.loadingBookingButton ? (
                        <CircularProgress color="primary" size={12} />
                      ) : (
                        "Book at ₹0"
                      )}
                    </Typography>
                  </Button>
                </div>
              ) : null}
              <div>
                {window.innerWidth <= 768 ? (
                  this.state.compareData.length > 1 ||
                  this.state.idArray.includes(packageDetailsData?.id) ? (
                    <Button
                      variant="text"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",

                        padding: "5px 0px",
                      }}
                      className={classes.ButtonSeeCompare}
                      onClick={() => this.handleCompareOpen()}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{ fontSize: "16px", marginLeft: "3px" }}
                      >
                        Show Compare List
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      variant="text"
                      style={{
                        color: this.state.isClick ? "green" : "#ff8362",
                        textTransform: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",

                        padding: "5px 0px",
                      }}
                      onClick={() =>
                        this.handleStorageDailog({
                          id: packageDetailsData?.id,
                          name: packageDetailsData?.name,
                          price:
                            packageDetailsData?.sale_info.list_price.amount,
                          duration: `${packageDetailsData?.nights} Nights ${packageDetailsData?.days} Days`,
                        })
                      }
                    >
                      <PlaylistAddIcon
                        style={{ color: "#224e6d", fontSize: "22px" }}
                      />
                      <Typography
                        variant="subtitle1"
                        style={{ fontSize: "14px", marginLeft: "3px" }}
                        color="secondary"
                      >
                        ADD TO COMPARE
                      </Typography>
                    </Button>
                  )
                ) : null}
              </div>
              <Suspense
                fallback={<h6 style={{ color: "transparent" }}>loading...</h6>}
              >
                <Facility
                  highlights={packageDetailsData?.inclusion_highlights}
                  tags={packageDetailsData?.tags}
                  departureCities={packageDetailsData?.departure_cities}
                  FilterByTags={(val) => this.handleTags(val)}
                />
              </Suspense>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <HomePageLeadForm
                  data={widgetData}
                  // onItemClick={(itemTitle) => {
                  //   handleHomePageWidgetClick(
                  //     "gdgdgd",
                  //     "ection.tracking_identifier",
                  //     "itemTitle"
                  //   );
                  // }}
                  packageId={packageDetailsData?.id}
                  pageName="packagedetails"
                />
              </div>
              <Grid
                style={{
                  marginTop: window.innerWidth <= 768 ? "6%" : "2%",
                  height: window.innerWidth <= 768 ? "100%" : "",
                }}
              >
                {packageDetailsData ? (
                  <Suspense
                    fallback={
                      <h6 style={{ color: "transparent" }}>loading...</h6>
                    }
                  >
                    <Itenary
                      itenirary={packageDetailsData?.itinerary_info}
                      Pdf={PdfFile}
                      packageId={packageDetailsData?.id}
                      handleOpenLoginModal={this.handleOpenLoginModal}
                      // downloadPackagePdf={this.downloadPackagePdf}
                      downloadPackagePdf={this.getPackagePdfUrl}
                      isBrochureAvailable={packageDetailsData?.has_brochure}
                      loadingPdf={this.state.loadingPdf}
                    />
                  </Suspense>
                ) : (
                  <Skeleton width={20} height={100} />
                )}
              </Grid>
              <Grid style={{ marginTop: "2%" }}>
                <Suspense
                  fallback={
                    <h6 style={{ color: "transparent" }}>loading...</h6>
                  }
                >
                  <AccomodationSightseeing
                    accomodation={this.accommodations}
                    // sightseeing={data.sightseeing}
                    sightseeing={this.state.sightseeingData}
                  />
                </Suspense>
              </Grid>

              <Grid style={{ marginTop: "6%" }}>
                <Suspense
                  fallback={
                    <h6 style={{ color: "transparent" }}>loading...</h6>
                  }
                >
                  <Inclusion
                    inclusion={packageDetailsData?.inclusion_details}
                    exclusion={packageDetailsData?.exclusion_details}
                  />
                </Suspense>
              </Grid>
              {data && data.agent_terms === 1 ? (
                <Grid
                  md={12}
                  sm={12}
                  xs={12}
                  item
                  container
                  justifyContent="center"
                  style={{ marginTop: "6%" }}
                >
                  <Link
                    to={`/termsCondition?packageId=${packageDetailsData?.id}`}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography color="primary" variant="subtitle1">
                      TERMS {"&"} CONDITIONS
                    </Typography>
                  </Link>
                </Grid>
              ) : (
                ""
              )}
              <Grid
                style={{
                  marginTop: data && data.agent_terms === 1 ? "2%" : "7%",
                }}
              >
                <Suspense
                  fallback={
                    <h6 style={{ color: "transparent" }}>loading...</h6>
                  }
                >
                  <RelatedPackage
                    data={this.state.relatedPackageDetailsData}
                    id={(id) => this.handlerelatedPackage(id)}
                  />
                </Suspense>
              </Grid>
            </Container>
            <Suspense
              fallback={<h6 style={{ color: "transparent" }}>loading...</h6>}
            >
              <Footer />
            </Suspense>
          </Grid>
        ) : (
          ""
        )}
        <Suspense
          fallback={<h6 style={{ color: "transparent" }}>loading...</h6>}
        >
          <Registration
            open={this.state.open}
            id={this.state.requestId}
            onClose={this.handleClose}
            pageNumber={this.state.pageNumber}
            package_url={this.state.url}
            origin={TrackingOrigin.LeadForm.PackageDetail}
            onRequestSubmit={this.onRequestSubmit}
          />
        </Suspense>
        <Suspense
          fallback={<h6 style={{ color: "transparent" }}>loading...</h6>}
        >
          <CompareDailog
            previousPage={previousPage}
            open={this.state.compareOpen}
            click={this.state.compareData}
            deleteId={this.delete.bind(this)}
            onClose={this.handleCompareClose}
          />
          {/* compareData={this.handleLink}
                      click={this.state.compareData}
                      id={this.state.idArray}
                      paramId={this.state.paramId}
                      delete={this.delete.bind(this)} */}
        </Suspense>
        {/* <FilterModel
          open={this.state.filterModel}
          close={this.modelClose}
          data={data}
        /> */}
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({
  getPackageDetailReducer,
  getCompareListReducer,
  getPackageListReducer,
}) => {
  return {
    _comparelist: getCompareListReducer,
    _packageDetails: getPackageDetailReducer,
    _packageList: getPackageListReducer,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    getCompareListAction,
    getPackageListAction,
    getPackageDetailAction,
    getResponseNullAction,
  })(withStyles(style)(PackageDetails))
);
