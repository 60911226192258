import React, { Component } from "react";
import { Popover } from "@material-ui/core";
import "./share.css";
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  PocketShareButton,
  InstapaperShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
  PocketIcon,
  InstapaperIcon,
} from "react-share";
class Share extends Component {
  render() {
    const { open, onClose, url } = this.props;
    const shareUrl = url;
    const title = "comparePackages";

    return (
      <Popover
        open={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <div className="Demo__container">
          <div className="Demo__some-network">
            <FacebookShareButton
              url={shareUrl}
              quote={title}
              className="Demo__some-network__share-button"
              onClick={onClose}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>

          <div className="Demo__some-network">
            <FacebookMessengerShareButton
              url={shareUrl}
              appId="521270401588372"
              className="Demo__some-network__share-button"
              onClick={onClose}
            >
              <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>
          </div>

          <div className="Demo__some-network">
            <TwitterShareButton
              url={shareUrl}
              title={title}
              className="Demo__some-network__share-button"
              onClick={onClose}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>

          <div className="Demo__some-network">
            <TelegramShareButton
              url={shareUrl}
              title={title}
              className="Demo__some-network__share-button"
              onClick={onClose}
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>
          </div>

          <div className="Demo__some-network">
            <WhatsappShareButton
              url={shareUrl}
              title={title}
              separator=":: "
              className="Demo__some-network__share-button"
              onClick={onClose}
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>

          <div className="Demo__some-network">
            <EmailShareButton
              url={shareUrl}
              subject={title}
              body="body"
              className="Demo__some-network__share-button"
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>

          <div className="Demo__some-network">
            <PocketShareButton
              url={shareUrl}
              title={title}
              className="Demo__some-network__share-button"
            >
              <PocketIcon size={32} round />
            </PocketShareButton>
          </div>

          <div className="Demo__some-network">
            <InstapaperShareButton
              url={shareUrl}
              title={title}
              className="Demo__some-network__share-button"
            >
              <InstapaperIcon size={32} round />
            </InstapaperShareButton>
          </div>
        </div>
      </Popover>
    );
  }
}
export default Share;
