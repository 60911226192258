import { Box, Typography, withStyles } from "@material-ui/core"
import React from "react"

const style = (theme) => ({
	badgeBox: {
		borderRadius: "4px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: 28,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	badgeText: {
		textTransform: "none",
		color: "#fff",
		fontSize: "1rem",
		fontWeight: 400,
	},
})

const HighlightBadge = (props) => {
	const { classes, text, backgroundColor } = props

	const badge = text ?? ""
	const badgeColor = backgroundColor ?? "#3582BD"

	return (
		<React.Fragment>
			<Box className={classes.badgeBox} style={{ backgroundColor: badgeColor }}>
				<Typography variant="caption" className={classes.badgeText}>
					{badge}
				</Typography>
			</Box>
		</React.Fragment>
	)
}

export default withStyles(style)(HighlightBadge)
