import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants"
import { TrackableEvent } from "./trackableEvent"

export class loginResendOtpClickEvent extends TrackableEvent {
	constructor(noOfTimesResendOtpClicked) {
		let properties = {}
	
        properties[TrackerPropertyKey.ResendCount] = noOfTimesResendOtpClicked
		super(TrackingEventName.LoginResendOtpClick, properties)
	}
}