import axios from "axios"

export const getCompareListService = (request) => {
        return axios
                .get("/compare/packages", request.data)
                .then((response) => {
                        return response.data
                })
                .catch((err) => {
                        return err.response.data
                })
}
