import React from "react";
import WhyTripStoreGrid from "../WhyTripStoreGrid/WhyTripStoreGrid";
import GridComponent from "../GridComponent/GridComponent";

function DesktopWhyTripStore({ data }) {
  const desktopWhyTripStore = data?.list?.items.map((item, index) => {
    return (
      <WhyTripStoreGrid
        key={index}
        title={item.title}
        imageUrl={item.image}
        subtitle={item.subtitle}
      />
    );
  });
  return <GridComponent divs={desktopWhyTripStore} />;
}

export default DesktopWhyTripStore;
