import { FilterResponse } from "../../models/filters/filterResponse"
import { FilterResponseItem } from "../../models/filters/filterResponseItem"
import * as types from "../action.types"

export const getFiltersAction = (data) => {
	return {
		type: types.GET_FILTERS,
		data,
	}
}

export const getFiltersActionSuccess = (values) => {
	let filterResposes = []
	if (values.data) {
		filterResposes = values.data
			.filter((value) => {
				return value.is_visible
			})
			.sort((value1, value2) => {
				return value1.display_order - value2.display_order
			})
			.map((obj) => {
				const filters = obj.filters.map((filter) => {
					return new FilterResponseItem(filter.name, filter.is_selected)
				})
				return new FilterResponse(obj.type, obj.name, obj.input_type, obj.display_order, filters)
			})
	}
	return {
		type: types.GET_FILTERS_SUCCESS,
		filterResposes,
	}
}
