import * as types from "../action.types"

export const getThemeAction = () => {
    return {
        type: types.GET_THEME
    };
};

export const getThemeActionSuccess = values => {
    return {
        type: types.GET_THEME_SUCCESS,
        values
    }
}