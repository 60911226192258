import { TrackerPropertyKey, TrackingEventName } from "../../trackingConstants"
import { TrackableEvent } from "../trackableEvent"

export class ListingFilterOpenEvent extends TrackableEvent {
	constructor(title) {
		let properties = {}
		properties[TrackerPropertyKey.FilterTitle] = title

		super(TrackingEventName.ListingFilterOpen, properties)
	}
}
