import React, { Fragment, PureComponent } from "react";
import { Formik, Field, Form } from "formik";
import InputField from "./inputFeild";
import ButtonComponent from "./buttonComponent";
import Grid from "@material-ui/core/Grid";
import { Typography, withStyles, Checkbox } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { agentAction } from "../../store/actions/agent.action";

const styles = (theme) => ({
  TitlePadding: {
    paddingTop: "12px",
  },

  input: {
    "&::placeholder": {
      color: "#bbc2c8",
    },
    fontSize: "14px",
    color: "#616e78",
    height: 50,
  },
  bodyText: {
    color: "#616E78",
    fontSize: 12,
    marginTop: 8,
  },
  formParent: {
    height: 540,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
      height: "100%",
    },
  },
});

class AgentForm extends PureComponent {
  state = {
    redirect: false,
    logo: undefined,
    lError: "",
    agentData: this.props._agent,
    loading: false,
    code: "",
    checkedVal: false,
  };
  componentWillReceiveProps = (nextprops) => {
    let agentData =
      nextprops &&
      nextprops._agent &&
      nextprops._agent.data &&
      nextprops._agent.data.message;
    this.setState({
      code: agentData,
    });
  };
  apiSubmit = (values) => {
    if (this.state.checkedVal === true) {
      this.props.onSubmit({ ...values });
    }
  };
  componentDidMount = () => {
    this.props.agentAction();
  };
  handleChange = (event) => {
    this.setState({ checkedVal: event.target.checked });
  };
  render() {
    const { initialValues, validationSchema, classes } = this.props;

    // let code = _agent.data && _agent.data.response_code;
    // let msg = _agent.data && _agent.data.message;

    return (
      <Formik
        onSubmit={(e) => this.apiSubmit(e)}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        render={({ dirty, isSubmitting, ...props }) => {
          return (
            <Fragment>
              <Grid>
                <Form noValidate className={classes.formParent}>
                  <Grid md={12} sm={12} xs={12} container item>
                    <Grid
                      md={12}
                      xs={12}
                      sm={12}
                      item
                      container
                      spacing={2}
                      style={{ display: "flex" }}
                    >
                      <Grid md={6} xs={12} sm={12} item>
                        <Typography
                          variant="caption"
                          style={{ color: "#16222b" }}
                        >
                          First Name
                        </Typography>
                        <Field
                          name="firstName"
                          type="text"
                          component={InputField}
                          InputProps={{
                            className: classes.input,
                          }}
                        />
                      </Grid>
                      <Grid md={6} xs={12} sm={12} item>
                        <Typography
                          variant="caption"
                          style={{ color: "#16222b" }}
                        >
                          Last Name
                        </Typography>
                        <Field
                          name="LastName"
                          type="text"
                          component={InputField}
                          InputProps={{
                            className: classes.input,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      md={12}
                      xs={12}
                      sm={12}
                      item
                      container
                      spacing={2}
                      style={{ display: "flex", marginTop: 8 }}
                    >
                      <Grid md={6} xs={12} sm={12} item>
                        <Typography
                          variant="caption"
                          style={{ color: "#16222b" }}
                        >
                          Email
                        </Typography>
                        <Field
                          name="email"
                          type="email"
                          component={InputField}
                        />
                      </Grid>
                      <Grid md={6} xs={12} sm={12} item>
                        <Typography
                          variant="caption"
                          style={{ color: "#16222b" }}
                        >
                          Phone
                        </Typography>
                        <Field
                          name="phone"
                          type="text"
                          component={InputField}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      md={12}
                      xs={12}
                      sm={12}
                      item
                      container
                      spacing={2}
                      style={{ display: "flex", marginTop: 8 }}
                    >
                      <Grid md={6} xs={12} sm={12} item>
                        <Typography
                          variant="caption"
                          style={{ color: "#16222b" }}
                        >
                          Company Name
                        </Typography>
                        <Field
                          name="CompanyName"
                          type="text"
                          component={InputField}
                        />
                      </Grid>
                      <Grid md={6} xs={12} sm={12} item>
                        <Typography
                          variant="caption"
                          style={{ color: "#16222b" }}
                        >
                          Website URL
                        </Typography>

                        <Field
                          name="Website"
                          type="text"
                          component={InputField}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      md={12}
                      xs={12}
                      sm={12}
                      item
                      container
                      style={{ marginTop: 16, paddingRight: 16 }}
                    >
                      <Typography
                        variant="caption"
                        style={{ color: "#16222b" }}
                      >
                        Comment
                      </Typography>
                      <Field
                        name="agent_info"
                        type="text"
                        component={InputField}
                        multiline={true}
                        rows={3}
                        margin="normal"
                        style={{ marginTop: 8 }}
                      />
                    </Grid>
                    <Grid
                      md={12}
                      xs={12}
                      sm={12}
                      item
                      style={{ marginTop: 18 }}
                    >
                      <Typography className={classes.bodyText}>
                        Trip Store Holidays is committed to protecting and
                        respecting your privacy, and we will only use your
                        personal information to administer your account and to
                        provide the products and services you requested from us.
                        From time to time, we would like to contact you about
                        our products and services, as well as other content that
                        may be of interest to you. If you consent to us
                        contacting you for this purpose, please tick below to
                        say how you would like us to contact you:eir worth.
                      </Typography>
                      <Grid
                        md={12}
                        xs={12}
                        sm={12}
                        item
                        style={{ display: "flex", marginTop: 8 }}
                      >
                        <Checkbox
                          checked={this.state.checked}
                          onChange={this.handleChange}
                          style={{
                            background: "transparent",
                            color: "#0B3954",
                            padding: 0,
                          }}
                          inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                        />
                        <Typography
                          style={{
                            color: "#0B3954",
                            fontSize: 12,
                            marginLeft: 8,
                            marginTop: 2,
                          }}
                        >
                          I agree to receive other communications from Trip
                          Store Holidays
                        </Typography>
                      </Grid>

                      <Typography className={classes.bodyText}>
                        You can unsubscribe from these communications at any
                        time. For more information on how to unsubscribe, our
                        privacy practices, and how we are committed to
                        protecting and respecting your privacy, please review
                        our Privacy Policy.
                      </Typography>
                      <Typography className={classes.bodyText}>
                        By clicking submit below, you consent to allow Trip
                        Store Holidays to store and process the personal
                        information submitted above to provide you the content
                        requested.
                      </Typography>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="baseline">
                      <Grid item style={{ marginTop: 30, width: 240 }}>
                        <ButtonComponent
                          type="submit"
                          variant="contained"
                          size="medium"
                          fullWidth
                          onClick={props.onClose}
                        >
                          Submit Request
                        </ButtonComponent>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
            </Fragment>
          );
        }}
      />
    );
  }
}
const mapStateToProps = ({ agentReducer }) => {
  return {
    _agent: agentReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    agentAction,
  })(withStyles(styles)(AgentForm))
);
