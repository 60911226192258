import { put, call } from "redux-saga/effects";
import { getEuropeDestinationService } from "../services/getEuropeDestination.service";

import { getEuropeDestinationActionSuccess } from "../actions/getEuropeDestination.action";
import * as types from "../action.types";
export function* getEuropeDestinationSaga() {
    try {
        const response = yield call(getEuropeDestinationService);
        yield put(getEuropeDestinationActionSuccess(response.data));

    } catch (error) {
        yield put({ type: types.GET_EUROPE_DESTINATION_ERROR, error });
    }
}
