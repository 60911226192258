import { put, call } from "redux-saga/effects"
import * as types from "../action.types"
import { getPackageListingService } from "../services/listing.service"
import { getListingActionSuccess } from "../actions/listing.action"
import ListingResponse from "../../models/listing/listingResponse"

export function* getListingSaga(data) {
	try {
		const response = yield call(getPackageListingService, data)
		const listingResponse = createListingResponse(response.data, response.next_page, response.total_count)
		yield put(getListingActionSuccess(listingResponse))
	} catch (error) {
		yield put({ type: types.GET_LISTING_ERROR, error })
	}
}

function createListingResponse(dataArray, nextPage, totalCount) {
	return new ListingResponse(dataArray, nextPage, totalCount)
}
