import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import configureStore from "./app/store/index.store"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic"
import { CookiesProvider } from "react-cookie"
import theme from "./utils/theme"
import App from "./app/root/App"
import "./index.css"
import axios from "axios"
import * as serviceWorkerRegistration from './serviceWorker';

const baseURL = process.env.REACT_APP_BASE_URL ?? "https://dashboard.tripstoreholidays.com"
axios.defaults.baseURL = `${baseURL}/api`

ReactDOM.render(
	<Provider store={configureStore()}>
		<BreadcrumbsProvider>
			<BrowserRouter>
				<MuiThemeProvider theme={theme}>
					<CookiesProvider>
						<App />
					</CookiesProvider>
				</MuiThemeProvider>
			</BrowserRouter>
		</BreadcrumbsProvider>
	</Provider>,

	document.getElementById("root")
)

serviceWorkerRegistration.register();