// useIntersectionObserver.js
import { useState, useRef, useEffect, useCallback } from 'react';

export const IntersectionObservers = () => {
  const [isVisible, setIsVisible] = useState(false);
  const observerRef = useRef(null);
 const observer = useRef();
  const refValue = useCallback((node) => {

    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      // console.log("the last----",entries)
      // if (entries[0].isIntersecting) {
        // console.log("the last----",entries)
        const firstEntry = entries[0];
        setIsVisible(firstEntry.isIntersecting);
      // }
    },{
      // root: null,
      // rootMargin: '0px',
      threshold:0.8
    });
    if (node) observer.current.observe(node);
  }, []);;

  return { isVisible, observerRef,refValue };
};