import React, { useState } from "react"
import { Container, Grid, Typography, Button, Box } from "@material-ui/core"
import { Link } from "react-router-dom"
import { makeStyles } from "@material-ui/styles"
import PhoneIcon from "@material-ui/icons/Phone"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import logo from "../../assets/images/trip_store_logo-02.png";
import Agent from "../newagent.js/agent"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import LinkedinIcon from "@material-ui/icons/LinkedIn"

const styles = makeStyles((theme) => ({
	root: {
		background: "#224e6d",
		height: "100%",
		alignContent: "center",
		padding: 24,
		// marginTop: 40,
		[theme.breakpoints.down("sm")]: {
			height: "100%",
		},
	},
	Logo: {
		[theme.breakpoints.down("sm")]: {
			marginTop: 24,
		},
	},
	largeText: {
		color: "#fff",
		marginTop: 8,
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	completeGrid: {
		display: "flex",
		flexDirection: "row",
		[theme.breakpoints.down("sm")]: {
			display: "block",
		},
	},
	copyRight: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
	mobileCopyRight: {
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
	social: {
		[theme.breakpoints.down("xs")]: {
			alignContent: "flex-start",
			marginBottom: "10%",
		},
	},
	QuickLinks: {
		alignContent: "flex-end",
		[theme.breakpoints.down("sm")]: {
			alignContent: "flex-start",
		},
	},
	QuickLinksText: {
		color: "#fff",
		fontSize: 20,
		fontWeight: 500,
		[theme.breakpoints.down("sm")]: {
			alignContent: "flex-start",
			fontSize: 16,
			fontWeight: "normal",
		},
	},
	subText: {
		fontSize: 14,
		fontWeight: 400,
		color: "#fff",
		marginTop: 10,
		[theme.breakpoints.down("sm")]: {
			fontSize: 12,
		},
	},
	JoinsubText: {
		fontSize: 14,
		fontWeight: 400,
		color: "#fff",
		marginTop: 10,
		[theme.breakpoints.down("sm")]: {
			fontSize: 12,
		},
	},
	phoneMail: {
		color: "#fff",
		marginLeft: 5,
		fontSize: 14,
		[theme.breakpoints.down("sm")]: {
			fontSize: 12,
		},
	},
	AgentButton: {
		marginTop: 32,
		[theme.breakpoints.down("sm")]: {
			marginBottom: 32,
		},
	},
	links: {
		textDecoration: "none",
	},
	contactGrid: {
		alignContent: "center",
		[theme.breakpoints.down("xs")]: {
			alignContent: "flex-start",
			marginTop: "30%",
		},
	},
	socialIcons: {},
}))
const Footer = () => {
	const classes = styles()
	const [open, setOpen] = useState(false)
	const handleClick = () => {
		setOpen(true)
	}
	const handleClose = () => {
		setOpen(false)
	}

	return (
		<Grid md={12} xs={12} sm={12} container item className={classes.root}>
			<Container>
				<Grid md={12} sm={12} xs={12} container item className={classes.completeGrid}>
					<Grid md={3} xs={12} sm={12} item container direction="column" className={classes.Logo}>
						<img src={logo} alt="logo"   className="logo" style={{width:"140px",height:"45px"}} />
						<Typography variant="caption" className={classes.largeText}>
							Trip Store Holidays is a tour packages marketplace connecting World’s best tour operators with travellers to choose from hundreds of travel packages for their next holiday.
						</Typography>
						<Grid container justifyContent="flex-start" className={classes.AgentButton}>
							<Button onClick={handleClick} style={{ paddingLeft: 0 }}>
								<Typography variant="subtitle1" style={{ color: "#ff8672" }}>
									Become an Agent
								</Typography>
							</Button>
						</Grid>
					</Grid>
					<Grid md={9} sm={12} xs={12} container item justifyContent="space-between">
						<Grid md={3} xs={12} sm={3} item container justifyContent="flex-end">
							<Grid container direction="column" className={classes.social}>
								<Typography className={classes.QuickLinksText}>Follow us on Socials</Typography>
								<Box>
									<Grid container>
										<a href="https://www.facebook.com/tripstoreholidays/">
											<FacebookIcon style={{ fontSize: 40, color: "#fff" }} />
										</a>
										<a href="https://www.instagram.com/tripstoreholidays/">
											<InstagramIcon style={{ fontSize: 40, color: "#fff" }} />
										</a>
										<a href="https://www.linkedin.com/company/trip-store-holidays/">
											<LinkedinIcon style={{ fontSize: 40, color: "#fff" }} />
										</a>
									</Grid>
								</Box>
							</Grid>
						</Grid>
						<Grid md={2} xs={6} sm={2} item container justifyContent="flex-end">
							<Grid container direction="column" className={classes.QuickLinks}>
								<Typography className={classes.QuickLinksText}>Travellers</Typography>
								<a href="/About" className={classes.links}>
									<Typography variant="body2" className={classes.subText}>
										About Trip Store
									</Typography>
								</a>
								<a href="/bookingTs" className={classes.links}>
									<Typography variant="body2" className={classes.subText}>
										Why Trip Store
									</Typography>
								</a>
								{/* <Typography variant="body2" className={classes.subText}>
                  Blog
                </Typography>
                <Typography variant="body2" className={classes.subText}>
                  Destination Guide
                </Typography>
                <Typography variant="body2" className={classes.subText}>
                  Help / FAQ
                </Typography> */}

								<a href="/policy" className={classes.links}>
									<Typography variant="body2" className={classes.subText}>
										Privacy Policy
									</Typography>
								</a>
								<a href="/Conditions" className={classes.links}>
									<Typography variant="body2" className={classes.subText}>
										Terms & Conditions
									</Typography>
								</a>
								{/* <Typography variant="body2" className={classes.subText}>
                  Cookies Policy
                </Typography> */}
							</Grid>
						</Grid>
						<Grid md={2} xs={6} sm={2} item container justifyContent="flex-end">
							<Grid container direction="column" className={classes.QuickLinks}>
								<Typography className={classes.QuickLinksText}>Tour Operators</Typography>
								<Button variant="text" onClick={handleClick} style={{ padding: 0, textTransform: "none", justifyContent: "flex-start" }}>
									<Typography variant="body2" className={classes.JoinsubText}>
										Join us
									</Typography>
								</Button>
								<Link to="/workTs" className={classes.links}>
									<Typography variant="body2" className={classes.subText}>
										Why Work with Trip Store
									</Typography>
								</Link>
								{/* <Typography variant="body2" className={classes.subText}>
                  List your Tours
                </Typography> */}

								<Typography variant="body2" className={classes.subText}>
									<a href="https://dashboard.tripstoreholidays.com/login" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#fff" }}>
										Login
									</a>
								</Typography>
								{/* <Typography variant="body2" className={classes.subText}>
                  Help
                </Typography> */}
							</Grid>
						</Grid>
						<Grid md={3} xs={6} sm={3} item>
							<Grid container justifyContent="center" direction="column" className={classes.contactGrid}>
								<Typography className={classes.QuickLinksText}>Contact Us</Typography>
								<Grid style={{ display: "flex", marginTop: 10 }}>
									<PhoneIcon style={{ color: "#fff", fontSize: "1.2rem" }} />

									<Typography variant="body2" className={classes.phoneMail}>
										+91-9209200041
									</Typography>
								</Grid>
								<Grid style={{ display: "flex", marginTop: 10 }}>
									<MailOutlineIcon style={{ color: "#fff", fontSize: "1.2rem" }} />

									<Typography variant="body2" className={classes.phoneMail}>
										support@tripstoreholidays.com
									</Typography>
								</Grid>
								{/* <Grid style={{ display: "flex", marginTop: 10 }}>
                  <Typography variant="body2" className={classes.phoneMail}>
                    We are hiring
                  </Typography>
                </Grid> */}
								<Grid container justifyContent="flex-start" className={classes.copyRight} style={{ marginTop: 32 }}>
									<Typography variant="overline" style={{ color: "#fff" }}>
										© Copyright 2016 Trip Store. All Rights Reserved
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid xs={12} sm={12} container item justifyContent="flex-start" className={classes.mobileCopyRight} style={{ marginTop: 32, marginBottom: 24 }}>
						<Typography variant="overline" style={{ color: "#fff" }}>
							© Copyright 2016 Trip Store. All Rights Reserved
						</Typography>
					</Grid>
				</Grid>
			</Container>
			<Agent open={open} onClose={handleClose} />
		</Grid>
	)
}
export default Footer
