
import * as types from "../action.types";
export const getOtherDestinationReducer = (state = {}, action) => {
    const response = action.values;
    switch (action.type) {
        case types.GET_OTHER_DESTINATION_SUCCESS:
            return response;
        case types.GET_OTHER_DESTINATION_ERROR:
            return state;
        default:
            return state;
    }
};
