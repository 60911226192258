import React, { useContext, useEffect, useRef, useState } from "react";
import "./customizefrom.css";
import { PrimaryButton, PrimarySubmitButton } from "../../../utils/Buttons";
import ClearIcon from "@material-ui/icons/Clear";
import drawerLogo from "../../assets/images/drawerLogo.png";
import {
  countryCodeList,
  homeSearchPlacesList,
  registerUser,
} from "../Services/services";
import { UserContext } from "../hooks/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormSubmissionSuccess from "../FormSubmissionSuccess/FormSubmissionSuccess";
import { generateLead } from "../Services/services";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorIcon from "@material-ui/icons/Error";
import { CustomiseFormOpenEvent } from "../../../tracking/events/customiseFormOpenEvent";
import { Tracker } from "../../../tracking/tracker";
import { customizeFormSubmitEvent } from "../../../tracking/events/customizeFormSubmitEvent";
import { loginEndEvent } from "../../../tracking/events/loginEndEvent";
import { TrackingView } from "../../../tracking/trackingConstants";
import LoginOtp from "../Auth/Login/LoginOtp";
import { CustomizeFormEndEvent } from "../../../tracking/events/CustomizeFormEndEvent";
import { useHistory, useLocation } from "react-router-dom";
import { getFullUrlPath, LeadSource } from "../../../utils/utility";
import SnackbarComponent from "../Snackbar/Snackbar";
import { MetaLeadEvent } from "../../../tracking/events/leadEvent";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
      width: "25ch",
    },
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--text-secondary-color)",
      },

      "&.Mui-focused fieldset": {
        borderColor: "var(--text-primary-color)",
      },
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "13px",

      color: "var(--text-secondary-color)",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "13px",
      color: "var(--text-primary-color)",
      height: "20px",
    },
    "& .MuiInputLabel-shrink": {
      fontSize: "18px",
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: "var(--text-secondary-color)",
    },

    margin: "10px 0px",
  },
  selectField: {
    flex: 3,
    "& .MuiOutlinedInput-root": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      "& fieldset": {
        borderRight: "0px",
      },
      "&.Mui-focused": {
        borderRight: "2px solid black",
        borderColor: "var(--text-primary-color)",
      },
      "& .MuiSelect-select.MuiSelect-select": {
        textAlign: "right",
        direction: "rtl", // This will make the text start from the right
        textOverflow: "ellipsis", // This will trim the left side if the text is too long
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
  },
  phoneField: {
    flex: 10,
    "& .MuiOutlinedInput-root": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}));

const CustomizeForm = ({
  handleCloseCustomizeModal,
  accessToken,
  setCurrentView,
  origin,
}) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const modalRef = useRef(null);
  const { userInfo } = useContext(UserContext);
  const [customizeDropDownData, setCustomizeDropDownData] = useState([]);
  const [leadId, setLeadId] = useState(null);
  const [contryCodeList, setContryCodeList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [loadingCountryList, setLoadingCountryList] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [isFormsubmitted, setIsFormSubmitted] = useState(false);
  const [isotpView, setIsOtpView] = useState(false);
  const isLoggedIn = localStorage.getItem("access_token");
  const [shouldShowSuccessView, setShouldShowSuccessView] = useState(false);
  const [shouldShowOtpView, setShouldShowOtpView] = useState(false);
  const [error, setError] = useState("");
  const [userEnteredFieldsArray, setUserEnteredFieldsArray] = useState([]);
  const fullUrlPath = getFullUrlPath(location);

  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleOpenSnackbar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };
  const [formData, setFormData] = useState({
    name: userInfo?.name ? userInfo?.name : "",
    email: userInfo?.email ? userInfo?.email : "",
    phone: userInfo?.phone_number ? userInfo?.phone_number : "",
    where: "",
    when: "",
    budget: "",
    countryCode: userInfo?.country_code ? userInfo?.country_code : "",
  });

  const handleCallingCodeChange = (e) => {
    const { value } = e.target;

    setSelectedCountryId(value);
    const selectedCountry = contryCodeList.filter(
      (country) => country.id == value
    );
    if (selectedCountry && selectedCountry.length > 0) {
      const callingCode = selectedCountry[0].calling_code;
      setSelectedCountry(callingCode);
      setFormData({ ...formData, countryCode: callingCode });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setUserEnteredFieldsArray((prevKeysArray) => {
      if (!prevKeysArray.includes(name)) {
        return [...prevKeysArray, name];
      }
      return prevKeysArray;
    });
  };

  const submitLeadForm = async () => {
    setLoadingForm(true);
    const response = await generateLead(
      formData.where,
      formData.when,
      formData.budget,
      formData.name,
      formData.email,
      selectedCountry ? selectedCountry : formData.countryCode,
      formData.phone,
      null,
      null,
      null,
      accessToken,
      fullUrlPath,
      LeadSource.CUSTOMISE
    );

    if (response.status >= 200 && response.status < 300) {
      setLoadingForm(false);
      if (response.data.data.should_verify_otp) {
        setIsOtpView(response.data.data.should_verify_otp);
        setLeadId(response.data.data.lead.id);
        setShouldShowSuccessView(true);
      } else {
        history.push("/thanks");
        sessionStorage.setItem("previousPage", fullUrlPath);
        // setIsFormSubmitted(true);
      }

      Tracker.getInstance().trackEvent(
        new customizeFormSubmitEvent(
          formData.where,
          formData.when,
          formData.budget
        )
      );
      Tracker.getInstance().trackEvent(
        new CustomizeFormEndEvent(userEnteredFieldsArray)
      );

      Tracker.getInstance().trackMetaEvent(new MetaLeadEvent(selectedCountry ? selectedCountry : formData.countryCode, formData.phone));
    }
    if (response.status === 400) {
      handleOpenSnackbar();
      setLoadingForm(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const mobileNumberRegex = /^[1-9]\d{8,14}$/;
    const isValidMobile = mobileNumberRegex.test(formData.phone);
    if (isValidMobile) {
      submitLeadForm();
    } else {
      setError("Please enter number between 9 and 15 digits.");
    }
  };
  const getCustomizeDropdownData = async () => {
    const response = await homeSearchPlacesList();
    if (response.status === 200) {
      setCustomizeDropDownData(response.data.data);
    }
  };
  const getCountryCodeList = async () => {
    setLoadingCountryList(true);
    const response = await countryCodeList();

    if (response.status >= 200 && response.status < 300) {
      setLoadingCountryList(false);
      const defaultCountryCode = response.data.data.countries.filter(
        (data) => data.is_default === true
      );
      setSelectedCountry(defaultCountryCode[0]?.calling_code);
      setSelectedCountryId(defaultCountryCode[0]?.id);

      setContryCodeList(response.data.data.countries);
    }
  };
  useEffect(() => {
    getCountryCodeList();
  }, []);

  useEffect(() => {
    getCustomizeDropdownData();
  }, []);

  useEffect(() => {
    Tracker.getInstance().trackEvent(new CustomiseFormOpenEvent(origin));
  }, []);

  const handleClose = () => {
    handleCloseCustomizeModal();

    Tracker.getInstance().trackEvent(
      new CustomizeFormEndEvent(userEnteredFieldsArray)
    );
  };

  const userEnteredFieldsArrayRef = useRef(userEnteredFieldsArray);

  useEffect(() => {
    userEnteredFieldsArrayRef.current = userEnteredFieldsArray;
  }, [userEnteredFieldsArray]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      Tracker.getInstance().trackEvent(
        new CustomizeFormEndEvent(userEnteredFieldsArrayRef.current)
      );
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <SnackbarComponent
        openSnackBar={openSnackBar}
        handleCloseSnackbar={handleCloseSnackbar}
        handleOpenSnackbar={handleOpenSnackbar}
        message="Something Went Wrong! Please try again."
      />
      {isFormsubmitted ? (
        <FormSubmissionSuccess
          userInfo={userInfo}
          handleCloseCustomizeModal={handleCloseCustomizeModal}
        />
      ) : isotpView ? (
        <LoginOtp
          setIsOtpView={setIsOtpView}
          countryCode={selectedCountry}
          mobileNumber={formData.phone}
          handleCloseCustomizeModal={handleCloseCustomizeModal}
          shouldShowSuccessView={shouldShowSuccessView}
          leadId={leadId}
          // setCurrentView={setCurrentView}
        />
      ) : (
        <div className="animate-slide-up" ref={modalRef}>
          <div className="customize-close-button">
            <div className="customize-cross-container">
              <ClearIcon
                style={{
                  padding: "10px",

                  cursor: "pointer",
                }}
                onClick={handleClose}
              />
            </div>

            <div class="customize-logo-header">
              <img src={drawerLogo} alt="Trip Store Logo" class="login-logo" />
            </div>
            <div style={{ flex: "4" }}></div>
          </div>

          <form
            className="request-call-form"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <div className="customize-form-heading-container">
              <h5>Give us some details</h5>
            </div>

            <TextField
              size="small"
              name="where"
              required
              id="standard-required"
              className={classes.textField}
              value={formData.where}
              onChange={handleChange}
              label="Where would you like to go ?"
              variant="outlined"
              fullWidth
            />

            <TextField
              id="outlined-select-currency-native"
              select
              size="small"
              className={classes.textField}
              name="when"
              label="Select month"
              value={formData.when || ""}
              onChange={handleChange}
              SelectProps={{
                native: true,
                displayEmpty: true,
              }}
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              required
            >
              <option aria-label="None" value="" disabled>
                Please select
              </option>
              {customizeDropDownData?.months?.map((option) => (
                <option
                  key={option}
                  value={option}
                  style={{ padding: "30px 0" }}
                >
                  {option}
                </option>
              ))}
            </TextField>

            <TextField
              id="outlined-select-currency-native"
              select
              size="small"
              className={classes.textField}
              label="Select budget"
              name="budget"
              value={formData.budget}
              onChange={handleChange}
              SelectProps={{
                native: true,
                displayEmpty: true,
              }}
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              required
            >
              <option aria-label="None" value="" disabled>
                Please select
              </option>
              {customizeDropDownData?.price?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>

            <hr />

            <TextField
              size="small"
              name="name"
              required
              type="text"
              id="standard-required"
              className={classes.textField}
              value={formData.name}
              onChange={handleChange}
              label="Full Name"
              variant="outlined"
              autoComplete="name"
              fullWidth
            />

            <TextField
              size="small"
              required
              type="email"
              id="standard-required"
              className={classes.textField}
              name="email"
              value={formData.email}
              onChange={handleChange}
              label="Enter Email"
              variant="outlined"
              autoComplete="email"
              fullWidth
            />
            {isLoggedIn ? (
              <TextField
                size="small"
                type="number"
                disabled
                required
                id="standard-required"
                className={classes.textField}
                name="phone"
                value={formData.countryCode.concat(formData.phone)}
                onChange={handleChange}
                label="Phone number"
                variant="outlined"
                fullWidth
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  size="small"
                  style={{ flex: "4" }}
                  className={`${classes.textField} ${classes.selectField}`}
                  name="countryCode"
                  label="Country"
                  value={selectedCountryId || ""}
                  onChange={handleCallingCodeChange}
                  SelectProps={{
                    native: true,
                    displayEmpty: true,
                  }}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                >
                  {contryCodeList?.map((country) => (
                    <option
                      key={country.id}
                      value={country.id}
                      style={{ padding: "30px 0" }}
                    >
                      {country.name} (+{country.calling_code})
                    </option>
                  ))}
                </TextField>
                <TextField
                  size="small"
                  type="tel"
                  style={{ flex: "8" }}
                  disabled={userInfo ? true : false}
                  required
                  id="phone-number"
                  className={`${classes.textField} ${classes.phoneField}`}
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  label="Phone number"
                  variant="outlined"
                  autoComplete="tel"
                  fullWidth
                />
                {error && (
                  <p className="lead-form-error-text">
                    <ErrorIcon
                      style={{ fontSize: "10px", marginRight: "2px" }}
                    />{" "}
                    {error}
                  </p>
                )}
              </div>
            )}

            <div className="customize-button-container">
              <PrimarySubmitButton className="customize-submit-button">
                {loadingForm ? (
                  <CircularProgress color="inherit" size={12} />
                ) : (
                  " Submit"
                )}
              </PrimarySubmitButton>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default CustomizeForm;
