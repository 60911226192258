import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants"
import { TrackableEvent } from "./trackableEvent"

export class AppOpenEvent extends TrackableEvent {
	constructor(pageName) {
		let properties = {
			[TrackerPropertyKey.BrowsingPage] : pageName,
		}
		super(TrackingEventName.AppOpen, properties)
	}
}
