import React, { Component } from "react"
import { withRouter } from "react-router"
import { Button, Fade, Grid, Modal, Paper, Typography, withStyles } from "@material-ui/core"
import { ExpandMoreOutlined } from "@material-ui/icons"
import { Tracker } from "../../../../tracking/tracker"
import { ListingFilterOpenEvent } from "../../../../tracking/events/listing/listingFilterOpenEvent"
import { ListingFilterItemClickEvent } from "../../../../tracking/events/listing/listingFilterItemClickEvent"
import { ListingFilterItemClearEvent } from "../../../../tracking/events/listing/listingFilterItemClearEvent"
import { ListingFilterItemDoneEvent } from "../../../../tracking/events/listing/listingFilterDoneEvent"

const style = (theme) => ({
	paper: {
		position: "absolute",
		minWidth: 200,
		maxWidth: 500,
		marginLeft: 50,
		marginRight: 20,
		backgroundColor: "#fff",
		[theme.breakpoints.down("sm")]: {
			marginLeft: 0,
		},
	},
	root: {
		minWidth: 50,
		maxWidth: 1000,
		padding: 5,
		// paddingLeft: theme.spacing(2),
		whiteSpace: "nowrap",

		marginBottom: theme.spacing(2),
	},
	buttons: {
		// margin: theme.spacing(2)
		disabled: theme.palette.text.disabled,
	},
	margin: {
		height: theme.spacing(3),
	},
})

class MultiSelectFilter extends Component {
	state = {
		showFilterItems: false,
		defaultColor: "#0b3954",
		selectedValuesDisplayText: "",
		filterResponse: undefined,
		hasChanged: false,
		selectedFiterValues: new Set(),
	}

	showFilterItems = () => {
		Tracker.getInstance().trackEvent(new ListingFilterOpenEvent(this.state.filterResponse.name))

		this.setState({
			showFilterItems: true,
		})
	}

	hideFilterItems = () => {
		this.setState({
			showFilterItems: false,
		})
	}

	handleClear = () => {
		Tracker.getInstance().trackEvent(new ListingFilterItemClearEvent(this.state.filterResponse.name, Array.from(this.state.selectedFiterValues)))

		this.props.onClear(this.state.filterResponse)
		this.setState({
			showFilterItems: false,
			selectedValuesDisplayText: "",
			selectedFiterValues: new Set(),
		})
	}

	handleSubmit = () => {
		const hasChanged = this.state.hasChanged
		if (hasChanged === false) {
			this.setState({
				showFilterItems: false,
			})
		} else if (this.state.selectedFiterValues.size === 0) {
			this.handleClear()
		} else {
			Tracker.getInstance().trackEvent(new ListingFilterItemDoneEvent(this.state.filterResponse.name, Array.from(this.state.selectedFiterValues)))
			this.props.onSubmit(this.state.filterResponse)
			this.setState({
				showFilterItems: false,
			})
		}
	}

	handleFilterItemClick = (filterItem) => {
		this.setState({
			hasChanged: true,
		})
		filterItem.isSelected = !filterItem.isSelected

		let selectedFiterValues = this.state.selectedFiterValues
		if (filterItem.isSelected) {
			selectedFiterValues.add(filterItem.name)
		} else {
			selectedFiterValues.delete(filterItem.name)
		}
		this.setState({
			selectedFiterValues: selectedFiterValues,
		})

		Tracker.getInstance().trackEvent(new ListingFilterItemClickEvent(this.state.filterResponse.name, filterItem.name, filterItem.isSelected))
	}

	componentDidUpdate(prevProps, prevStates) {
		if (prevProps.filterResponse !== this.props.filterResponse) {
			this.updateStateVariablesWithFilterResponse(this.props.filterResponse)
		}
	}

	updateStateVariablesWithFilterResponse = (filterResponse) => {
		this.setState({
			filterResponse: filterResponse,
		})
		if (filterResponse.filters) {
			let selectedFilters = filterResponse.filters
				.filter((obj) => {
					return obj.isSelected === true
				})
				.map((obj) => {
					return obj.name
				})

			let selectedValuesDisplayText = ""
			if (selectedFilters) {
				if (selectedFilters.length > 2) {
					let showLength = selectedFilters.length - 2
					selectedValuesDisplayText = `${selectedFilters.slice(0, 2).join(",")} & ${showLength} others`
				} else {
					selectedValuesDisplayText = selectedFilters.join(",")
				}
			}
			this.setState({
				selectedValuesDisplayText: selectedValuesDisplayText,
				selectedFiterValues: new Set(selectedFilters),
			})
		}
	}

	componentDidMount() {
		// URL Params can be taken in this method
		if (this.props.filterResponse) {
			this.updateStateVariablesWithFilterResponse(this.props.filterResponse)
		}
	}

	render() {
		const { filterResponse, selectedValuesDisplayText, selectedFiterValues } = this.state
		
		const { classes } = this.props
		return (
			<React.Fragment>
				<Grid>
					<Button
						variant="outlined"
						defaultValue="months"
						className={classes.root}
						style={{
							borderRadius: "4px",
							height: 30,

							display: "flex",
							textTransform: "none",
							background: selectedValuesDisplayText.length >= 1 ? this.state.defaultColor : "#fff",
							color: selectedValuesDisplayText.length >= 1 ? "#fff" : "",
						}}
						onClick={this.showFilterItems}
						endIcon={
							selectedValuesDisplayText.length < 1 ? (
								// <Icon style>
								<ExpandMoreOutlined style={{ color: "#616e78" }} />
							) : (
								// </Icon>
								""
							)
						}>
						<Grid container justifyContent="flex-start">
							{selectedValuesDisplayText.length > 0 ? (
								<Typography
									variant="caption"
									style={{
										textAlign: "left",
										marginBottom: "2%",
										color: "#fff",
										// marginLeft: 5,
										// marginRight: "20%"
									}}>
									{selectedValuesDisplayText}
								</Typography>
							) : (
								<Typography
									variant="caption"
									style={{
										color: "#616e78",
										width: "100%",
										textAlign: "left",
										marginBottom: 2,
										// marginRight: "45%",
									}}>
									{filterResponse && filterResponse.name ? filterResponse.name : "error"}
								</Typography>
							)}
						</Grid>
					</Button>
					<Modal
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
						open={this.state.showFilterItems}
						onClose={this.hideFilterItems}
						style={{ top: 108, left: window.innerWidth < 768 ? 12 : 0 }}>
						<Fade in={this.state.showFilterItems}>
							<div>
								<Paper className={classes.paper} elevation={0}>
									<Grid md={12} xs={12} container item justifyContent="space-around">
										<Grid md={6} xs={6} item justifyContent="flex-start" container>
											<Typography
												variant="subtitle1"
												style={{
													marginLeft: 22,
													marginTop: 15,
													color: "#16222b",
													width: "max-content",
													textAlign: "left",
												}}>
												{filterResponse ? "Select " + filterResponse.name : "Select Filter"}
											</Typography>
										</Grid>
										<Grid md={6} xs={6} container item justifyContent="flex-end">
											<Button
												onClick={this.handleClear}
												disabled={selectedFiterValues.size === 0}
												style={{
													textTransform: "none",
													marginTop: 15,
													marginRight: 16,
												}}>
												<Typography variant="caption" style={{ color: selectedFiterValues.size > 0 ? "#16222b" : classes.buttons.disabled }}>
													Clear
												</Typography>
											</Button>
										</Grid>
									</Grid>
									<Grid
										container
										style={{
											paddingLeft: 16,
											paddingRight: 16,
											overflow: "auto",
											maxHeight: 400,
										}}>
										{filterResponse &&
											filterResponse.filters.map((obj, i) => (
												<Grid item key={i}>
													<Button
														size="small"
														variant="outlined"
														className={classes.buttons}
														style={{
															// margin: "12px 0px 12px 0px",
															background: obj && obj.isSelected ? "#0b3954" : "#fff",
															textTransform: "none",
															margin: 5,
															// width: 85
														}}
														onClick={() => this.handleFilterItemClick(obj)}>
														<Typography
															variant="caption"
															style={{
																color: obj && obj.isSelected ? "#fff" : "#616e78",
																whiteSpace: "nowrap",
															}}>
															{obj.name}
														</Typography>
													</Button>
												</Grid>
											))}
									</Grid>
									<Button
										variant="outlined"
										style={{
											color: "#fff",
											background: "#ff6840",
											textTransform: "none",
											width: 80,
											margin: "8px 0px 16px 18px",
										}}
										onClick={this.handleSubmit}>
										<Typography variant="caption"> Done</Typography>
									</Button>
								</Paper>
							</div>
						</Fade>
					</Modal>
				</Grid>
			</React.Fragment>
		)
	}
}

export default withRouter(withStyles(style)(MultiSelectFilter))
