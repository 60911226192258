import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants";
import { TrackableEvent } from "./trackableEvent";

export class DownloadPdfClickEvent extends TrackableEvent {
  constructor(withLogin) {
    let properties = {};

    properties[TrackerPropertyKey.WithLogin] = withLogin;

    super(TrackingEventName.DownloadPdfClick, properties);
  }
}
