import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants"
import { TrackableEvent } from "./trackableEvent"

export class bookingTravelerCountChangeEvent extends TrackableEvent {
	constructor(travelerCount) {
		let properties = {}
        
		properties[TrackerPropertyKey.TravelersCount] = travelerCount
		super(TrackingEventName.BookingTravelerCountChange, properties)
	}
}