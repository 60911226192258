import * as types from "../action.types";

export const getPopularPackageAction = () => {
  return {
    type: types.GET_POPULAR_PACKAGES,
  };
};

export const getPopularPackageActionSuccess = (values) => {
  return {
    type: types.GET_POPULAR_PACKAGES_SUCCESS,
    values,
  };
};
