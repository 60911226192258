import React, { useContext, useEffect, useRef, useState } from "react";
import "./requestcallform.css";
import { PrimarySubmitButton } from "../../../utils/Buttons";
import ClearIcon from "@material-ui/icons/Clear";
import drawerLogo from "../../assets/images/drawerLogo.png";
import { UserContext } from "../hooks/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { TextareaAutosize } from "@material-ui/core";
import FormSubmissionSuccess from "../FormSubmissionSuccess/FormSubmissionSuccess";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { countryCodeList, generateLead } from "../Services/services";
import ErrorIcon from "@material-ui/icons/Error";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  capitalizeSentences,
  getFullUrlPath,
  getSavingAmount,
  LeadSource,
} from "../../../utils/utility";
import { Tracker } from "../../../tracking/tracker";
import { requestCallSubmitEvent } from "../../../tracking/events/requestCallSubmitEvent";
import { RequestCallFormOpenEvent } from "../../../tracking/events/requestCallFormOpenEvent";
import LoginOtp from "../Auth/Login/LoginOtp";
import { RequestCallFormEndEvent } from "../../../tracking/events/RequestCallFormEndEvent";
import { useHistory, useLocation } from "react-router-dom";
import SnackbarComponent from "../Snackbar/Snackbar";
import { MetaLeadEvent } from "../../../tracking/events/leadEvent";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
      width: "25ch",
    },
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--text-secondary-color)",
      },

      "&.Mui-focused fieldset": {
        borderColor: "var(--text-primary-color)",
      },
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "13px",

      color: "var(--text-secondary-color)",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "13px",
      color: "var(--text-primary-color)",
      height: "20px",
    },
    "& .MuiInputLabel-shrink": {
      fontSize: "18px",
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: "var(--text-secondary-color)",
    },

    margin: "10px 0px",
  },
  selectField: {
    // paddingBottom:"5px",
    // backgroundColor:'red',
    flex: 3,
    "& .MuiOutlinedInput-root": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      "& fieldset": {
        borderRight: "0px",
      },
      "&.Mui-focused": {
        borderRight: "2px solid black",
        borderColor: "var(--text-primary-color)",
      },
      "& .MuiSelect-select.MuiSelect-select": {
        textAlign: "right",
        direction: "rtl", // This will make the text start from the right
        textOverflow: "ellipsis", // This will trim the left side if the text is too long
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
  },
  phoneField: {
    flex: 10,
    "& .MuiOutlinedInput-root": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}));

const RequestCallForm = ({
  packageDetails,
  userProfile,
  handleCloseCustomizeModal,
  accessToken,
  origin,
  // setCurrentView,
}) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const modalRef = useRef(null);
  const { userInfo } = useContext(UserContext);
  const [leadId, setLeadId] = useState(null);
  const [isFormsubmitted, setIsFormSubmitted] = useState(false);
  const [contryCodeList, setContryCodeList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const isLoggedIn = localStorage.getItem("access_token");
  const [loadingForm, setLoadingForm] = useState(false);
  const [isotpView, setIsOtpView] = useState(false);
  const [loadingCountryList, setLoadingCountryList] = useState(false);
  const [shouldShowSuccessView, setShouldShowSuccessView] = useState(false);
  const [error, setError] = useState("");
  const [userEnteredFieldsArray, setUserEnteredFieldsArray] = useState([]);
  const fullUrlPath = getFullUrlPath(location);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleOpenSnackbar = () => {
    setOpenSnackBar(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };
  const [formData, setFormData] = useState({
    name: userInfo?.name ? userInfo?.name : "",
    email: userInfo?.email ? userInfo?.email : "",
    phone: userInfo?.phone_number ? userInfo?.phone_number : "",
    countryCode: userInfo?.country_code ? userInfo?.country_code : "",
    message: "",
    travellerCount: 0,
  });

  const submitLeadForm = async () => {
    setLoadingForm(true);
    const response = await generateLead(
      null,
      null,
      null,
      formData.name,
      formData.email,
      selectedCountry ? selectedCountry : formData.countryCode,
      formData.phone,
      packageDetails?.packageId,
      formData.travellerCount,
      formData.message,
      accessToken,
      fullUrlPath,
      LeadSource.PACKAGE_ENQUIRY
    );

    if (response.status >= 200 && response.status < 300) {
      setLoadingForm(false);
      if (response.data.data.should_verify_otp) {
        setLeadId(response.data.data.lead.id);
        setIsOtpView(response.data.data.should_verify_otp);
        setShouldShowSuccessView(true);
      } else {
        history.push("/thanks");
        sessionStorage.setItem('previousPage', fullUrlPath);
        // setIsFormSubmitted(true);
      }

      Tracker.getInstance().trackEvent(
        new requestCallSubmitEvent(
          packageDetails?.packageId,
          packageDetails?.packageName,
          packageDetails?.slug,
          packageDetails?.packageListPrice?.displayValue,
          formData.travellerCount,
          packageDetails?.discount,
          formData.message
        )
      );
      Tracker.getInstance().trackEvent(
        new RequestCallFormEndEvent(userEnteredFieldsArray)
      );
      Tracker.getInstance().trackMetaEvent(new MetaLeadEvent(selectedCountry ? selectedCountry : formData.countryCode, formData.phone));
    }
    if (response.status === 400) {
      handleOpenSnackbar();
      setLoadingForm(false);
    }
  };

  const getCountryCodeList = async () => {
    setLoadingCountryList(true);
    const response = await countryCodeList();

    if (response.status >= 200 && response.status < 300) {
      setLoadingCountryList(false);
      const defaultCountryCode = response.data.data.countries.filter(
        (data) => data.is_default === true
      );
      setSelectedCountry(defaultCountryCode[0]?.calling_code);
      setSelectedCountryName(defaultCountryCode[0]?.name);

      setContryCodeList(response.data.data.countries);
    }
  };
  useEffect(() => {
    getCountryCodeList();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "travellerCount" ? parseInt(value) || "" : value,
    });

    const keyForArray = name === "travellerCount" ? "travelers_count" : name;
    setUserEnteredFieldsArray((prevKeysArray) => {
      if (!prevKeysArray.includes(keyForArray)) {
        return [...prevKeysArray, keyForArray];
      }
      return prevKeysArray;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const mobileNumberRegex = /^[1-9]\d{8,14}$/;
    const isValidMobile = mobileNumberRegex.test(formData.phone);
    if (isValidMobile) {
      submitLeadForm();
    } else {
      setError("Please enter number between 9 and 15 digits.");
    }
  };
  useEffect(() => {
    Tracker.getInstance().trackEvent(
      new RequestCallFormOpenEvent(origin, packageDetails?.packageId)
    );
  }, []);
  const handleClose = () => {
    handleCloseCustomizeModal();

    Tracker.getInstance().trackEvent(
      new RequestCallFormEndEvent(userEnteredFieldsArray)
    );
  };

  const userEnteredFieldsArrayRef = useRef(userEnteredFieldsArray);

  useEffect(() => {
    userEnteredFieldsArrayRef.current = userEnteredFieldsArray;
  }, [userEnteredFieldsArray]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      Tracker.getInstance().trackEvent(
        new RequestCallFormEndEvent(userEnteredFieldsArrayRef.current)
      );
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <SnackbarComponent
        openSnackBar={openSnackBar}
        handleCloseSnackbar={handleCloseSnackbar}
        handleOpenSnackbar={handleOpenSnackbar}
        message="Something Went Wrong! Please try again."
      />
      {isFormsubmitted ? (
        <FormSubmissionSuccess
          userInfo={userInfo}
          handleCloseCustomizeModal={handleCloseCustomizeModal}
        />
      ) : isotpView ? (
        <LoginOtp
          setIsOtpView={setIsOtpView}
          countryCode={selectedCountry}
          mobileNumber={formData.phone}
          shouldShowSuccessView={shouldShowSuccessView}
          handleCloseCustomizeModal={handleCloseCustomizeModal}
          leadId={leadId}
          // setCurrentView={setCurrentView}
        />
      ) : (
        <div ref={modalRef}>
          <div className="request-call-close-button">
            <div className="request-call-cross-container">
              <ClearIcon
                style={{
                  padding: "10px",

                  cursor: "pointer",
                }}
                onClick={handleClose}
              />
            </div>

            <div class="request-call-logo-header">
              <img src={drawerLogo} alt="Trip Store Logo" class="login-logo" />
            </div>
            <div style={{ flex: "4" }}></div>
          </div>

          <form
            className="request-call-form"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <div className="tour-header">
              <div
                style={{
                  flex: "2",
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <LazyLoadImage
                  src={packageDetails?.packageImage}
                  effect="blur"
                  loading="lazy"
                  alt="tour-image"
                  className="tour-image"
                />
              </div>

              <div className="tour-details">
                <div>
                  <h2>{capitalizeSentences(packageDetails?.packageName)}</h2>
                </div>

                <div className="tour-pricing">
                  <span className="current-price">
                    {" "}
                    {packageDetails?.packageListPrice?.displayValue}
                  </span>
                  {packageDetails.shouldShowRetailPrice ? (
                    <>
                      {" "}
                      <span className="original-price">
                        {" "}
                        {packageDetails?.packageRetailPrice?.displayValue}
                      </span>
                      <span className="discount">
                        SAVE ₹
                        {getSavingAmount(
                          packageDetails?.packageRetailPrice?.amount,
                          packageDetails?.packageListPrice?.amount
                        )}
                      </span>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <TextField
              size="small"
              name="name"
              type="text"
              required
              id="standard-required"
              className={classes.textField}
              value={formData.name}
              onChange={handleChange}
              label="Enter full name"
              variant="outlined"
              autoComplete="name"
              fullWidth
            />
            <TextField
              size="small"
              required
              type="email"
              id="standard-required"
              className={classes.textField}
              name="email"
              value={formData.email}
              onChange={handleChange}
              label="Email Id"
              variant="outlined"
              fullWidth
              autoComplete="email"
            />

            {isLoggedIn ? (
              <TextField
                size="small"
                required
                style={{ flex: "9" }}
                id="standard-required"
                className={classes.textField}
                name="phone"
                disabled={userInfo || userProfile ? true : false}
                value={`+${formData.countryCode}${formData.phone}`}
                onChange={handleChange}
                label="Phone Number"
                variant="outlined"
                fullWidth
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  size="small"
                  style={{ flex: "4" }}
                  className={`${classes.textField} ${classes.selectField}`}
                  name="countryCode"
                  label="Country"
                  value={formData.countryCode || selectedCountry || ""}
                  onChange={handleChange}
                  SelectProps={{
                    native: true,
                    displayEmpty: true,
                  }}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                >
                  {contryCodeList?.map((country) => (
                    <option
                      key={country.calling_code}
                      value={country.calling_code}
                      style={{ padding: "30px 0" }}
                    >
                      {country.name} (+{country.calling_code})
                    </option>
                  ))}
                </TextField>
                <TextField
                  size="small"
                  required
                  style={{ flex: "8" }}
                  id="phone-number"
                  className={`${classes.textField} ${classes.phoneField}`}
                  name="phone"
                  type="tel"
                  // disabled={userInfo || userProfile ? true : false}
                  value={formData.phone}
                  onChange={handleChange}
                  label="Phone Number"
                  autoComplete="tel"
                  variant="outlined"
                  fullWidth
                />
                {error && (
                  <p className="request-lead-form-error-text">
                    <ErrorIcon
                      style={{ fontSize: "10px", marginRight: "2px" }}
                    />{" "}
                    {error}
                  </p>
                )}
              </div>
            )}
            <div className="request-call-button-container">
              <PrimarySubmitButton className="request-call-submit-button">
                {loadingForm ? (
                  <CircularProgress color="inherit" size={12} />
                ) : (
                  " Submit"
                )}
              </PrimarySubmitButton>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default RequestCallForm;
