import React, { Component } from "react";
import { Typography, Grid, Box } from "@material-ui/core";
class Compare extends Component {
  render() {
    const defaultProps = {
      color: "#16222b",
      borderColor: "#c4c4c4",
      marginTop: "12px",
      m: 1,
      border: 1,
    };

    return (
      <React.Fragment>
        <Grid container justifyContent="center" style={{width:"100%"}}>
          <Box
            style={{
              // borderStyle: "dashed",
              width: window.innerWidth <= 768 ? 248 : 230,

              padding: window.innerWidth <= 768 ? 10 : null,
              height: window.innerWidth <= 768 ? 70 : 80,
              borderRadius: 4,
              // border:"dash",
              // border: "1px dotted var(--text-primary-color)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1.5px solid var(--primary-background-color)",
            }}
            onClick={this.props.handleClose}
            {...defaultProps}
          >
            <Grid
              md={12}
              item
              container
              stye={{ display: "flex", justifyContent: "center" }}
              // style={{ margin: "15% 5% 5% 0%" }}
              justifyContent="center"
            >
              <Typography
                style={{
                  color:
                    window.innerWidth <= 768
                      ? "var(--text-secondary-color)"
                      : "var(--primary-background-color)",
                  textAlign: "center",
                }}
              >
                Add Package
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </React.Fragment>
    );
  }
}
export default Compare;
