import { put, call } from "redux-saga/effects";
import { getMonthService } from "../services/getMonth.service";

import { getMonthActionSucess } from "../actions/getMonths";
import * as types from "../action.types";
export function* getMonthSaga() {
    try {
        const response = yield call(getMonthService);
        yield put(getMonthActionSucess(response.data));

    } catch (error) {
        yield put({ type: types.GET_MONTHS_ERROR, error });
    }
}
