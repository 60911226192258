import * as Yup from "yup";
export const validation = Yup.object().shape({
  firstName: Yup.string("Enter a firstName")
    .required("Name is required")
    .matches(/^(\w+\s)*\w+$/, "enter valid firstName")
    .max(100, "not more than 100"),
  LastName: Yup.string("Enter a Last Name")
    .matches(/^(\w+\s)*\w+$/, "enter valid LastName")
    .max(100, "not more than 100"),

  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  phone: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "that's not your number"
    )
    .min(10, "that's not your number")
    .max(15, "max is 15")
    .required("Phone number is required"),
  CompanyName: Yup.string("Enter a Company Name")
    .matches(/^(\w+\s)*\w+$/, "enter valid CompanyName")
    .max(100, "not more than 100"),
  Website: Yup.string("Enter a Website "),

  agent_info: Yup.string()
    .trim()
    .min(1, "minimum 1 character")
    .max(400, "maximum 500 character"),
});
