import { takeLatest } from "redux-saga/effects";

import * as types from "../action.types";

import { getDestinationSaga } from "./getDestination.saga";
import { getThemeSaga } from "./getTheme.saga";
import { getSeasonSaga } from "./getSeason.saga";
import { getMonthSaga } from "./getMonths.saga";
import { getInclusionSaga } from "./getInclusion.saga";
import { getPopularPackageSaga } from "./getPopularPackage.saga";
import { getPackageListSaga } from "./getPackagelist.saga";
import { getBannerSaga } from "./getBanner.saga";
import { postRequestCallSaga } from "./postRequestcall.saga";
import { getCompareListSaga } from "./getComparelist.saga";
import { getPackageDetailSaga } from "./getPackageDetail.saga";
import { getRequestPackageSaga } from "./getRequestPacakge.saga";
import { agentSaga } from "./agent.saga";
import { termsConditionSaga } from "./termsConditionSaga";
import { getDeparturecitySaga } from "./getDepartureCity.sage";
import { getMetaDetailSaga } from "./getMeta.saga";
import { getEuropeDestinationSaga } from "./getEuropeDestination.saga";
import { getOtherDestinationSaga } from "./getOtherDestination.saga";
import { getFiltersSaga } from "./filters.saga";
import { getListingSaga } from "./listing.saga";

export default function* watchSagas() {
  yield takeLatest(types.GET_DESTINATION, getDestinationSaga);
  yield takeLatest(types.GET_THEME, getThemeSaga);
  yield takeLatest(types.GET_SEASON, getSeasonSaga);
  yield takeLatest(types.GET_MONTHS, getMonthSaga);
  yield takeLatest(types.GET_INCLUSION, getInclusionSaga);
  yield takeLatest(types.GET_POPULAR_PACKAGES, getPopularPackageSaga);
  yield takeLatest(types.GET_PACKAGE_LIST, getPackageListSaga);
  yield takeLatest(types.GET_BANNER, getBannerSaga);
  yield takeLatest(types.GET_REQUEST_CALL, postRequestCallSaga);
  yield takeLatest(types.GET_COMPARE_LIST, getCompareListSaga);
  yield takeLatest(types.GET_PACKAGE_DETAILS, getPackageDetailSaga);
  yield takeLatest(types.GET_REQUEST_PACKAGE, getRequestPackageSaga);
  yield takeLatest(types.GET_BECOME_AGENT, agentSaga);
  yield takeLatest(types.GET_TERMS_CONDITION, termsConditionSaga);
  yield takeLatest(types.GET_DEPARTURE_CITY, getDeparturecitySaga);
  yield takeLatest(types.GET_META, getMetaDetailSaga);
  yield takeLatest(types.GET_EUROPE_DESTINATION, getEuropeDestinationSaga);
  yield takeLatest(types.GET_OTHER_DESTINATION, getOtherDestinationSaga);
  yield takeLatest(types.GET_FILTERS, getFiltersSaga);
  yield takeLatest(types.GET_LISTING, getListingSaga);
}
