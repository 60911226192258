// import * as types from "../action.types";

import * as types from "../action.types"

export const filtersReducer = (state = {}, action) => {
    const response = action
    switch (action.type) {
        case types.GET_FILTERS_SUCCESS:
            return response
        case types.GET_FILTERS_ERROR:
            return state
        case types.GET_RESPONSE_NULL:
            return { response: null }
        default:
            return state
    }
}
