import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants"
import { TrackableEvent } from "./trackableEvent"

export class PageViewEvent extends TrackableEvent {
	constructor(page) {
		let properties = {}
		properties[TrackerPropertyKey.BrowsingPage] = page
		super(TrackingEventName.PageView, properties)
	}
}
