import { GTMTracker } from "./gtmTracker"
import { MetaPixelTracker } from "./metaPixelTracker"
import { MixpanelTracker } from "./mixpanelTracker"
import { PosthogTracker } from "./posthogTracker"

export class Tracker {
	static getInstance() {
		if (!Tracker.instance) {
			Tracker.instance = new Tracker()
		}
		return Tracker.instance
	}

	constructor() {
		if (Tracker.instance) {
			throw new Error("Use Tracker.getInstance() to get the instance.")
		}

		let mixpanelTracker = new MixpanelTracker()
		this.getMixpanelTracker = function () {
			return mixpanelTracker
		}

		let gtmTracker = new GTMTracker()
		this.getGTMTracker = function () {
			return gtmTracker
		}

		let posthogTracker = new PosthogTracker()
		this.posthogTracker = function () {
			return posthogTracker
		}

		let metaPixelTracker = new MetaPixelTracker()
		this.getMetaPixelTracker = function () {
			return metaPixelTracker
		}
	}

	configure() {
		this.getMixpanelTracker().configure()
		this.getGTMTracker().configure()
		this.posthogTracker().configure()
		this.getMetaPixelTracker().configure()
	}

	setUserIdentifier(phoneNumber) {
		this.getMixpanelTracker().setUserIdentifier(phoneNumber)
		this.posthogTracker().setUserIdentifier(phoneNumber)
		this.getMetaPixelTracker().setUserIdentifier(phoneNumber)
	}

	setUserProperties(name, email) {
		this.getMixpanelTracker().setUserProperties(name, email)
		this.posthogTracker().setUserProperties(name, email)
		this.getMetaPixelTracker().setUserProperties(name, email)
	}

	logout() {
		this.getMixpanelTracker().logout()
		this.posthogTracker().logout()
		this.getMetaPixelTracker().logout()
	}

	/**
	 *
	 * @param TrackableEvent event
	 */
	trackEvent(event) {
		const is_debug = !(process.env.REACT_APP_NODE_ENV === "production")
		this.getMixpanelTracker().trackEvent(event)

		if (!is_debug) {
			this.getGTMTracker().trackEvent(event)
			this.posthogTracker().trackEvent(event)
		}
	}

	trackPageviewEvent(event) {
		const is_debug = !(process.env.REACT_APP_NODE_ENV === "production")
		this.getMixpanelTracker().trackPageviewEvent(event)

		if (!is_debug) {
			this.getGTMTracker().trackPageviewEvent(event)
			this.posthogTracker().trackPageviewEvent(event)
			this.getMetaPixelTracker().trackPageviewEvent(event)
		}
	}

	trackMetaEvent(event) {
		const is_debug = !(process.env.REACT_APP_NODE_ENV === "production")

		if (!is_debug) {
			this.getMetaPixelTracker().trackEvent(event)
		}
	}
}
