import * as types from "../action.types";

export const getEuropeDestinationAction = () => {
    return {
        type: types.GET_EUROPE_DESTINATION
    };
};
export const getEuropeDestinationActionSuccess = values => {
    return {
        type: types.GET_EUROPE_DESTINATION_SUCCESS,
        values
    };
};
