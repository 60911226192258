import React, { useState, useEffect } from "react";
import noConnection from "../../assets/images/no-wifi.png";
import "./nointernetConnection.css";
import { PrimaryButton } from "../../../utils/Buttons";

const NoInternetConnection = (props) => {
  const [isOnline, setOnline] = useState(navigator.onLine);
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  const handleReload = () => {
    window.location.reload();
  };

  function getImageAsBase64(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.send();
    });
  }

  // Usage
  getImageAsBase64(noConnection)
    .then((base64String) => {
      localStorage.setItem("cachedImage", base64String);
    })
    .catch((error) => {
      console.error("Error fetching image:", error);
    });

  const cachedImage = localStorage.getItem("cachedImage");

  if (isOnline) {
    return props.children;
  } else {
    return (
      <>
        <div className="nointernet-container">
          <img src={cachedImage} alt="no image" width="100px" />

          <h3 className="nointernet-heading"> You'r Offline</h3>
          <h5 className="nointernet-mesaage">
            {" "}
            Try Refreshing the page or checking your internet connection.
          </h5>

          <PrimaryButton onClick={handleReload}>Try Again</PrimaryButton>
        </div>
      </>
    );
  }
};

export default NoInternetConnection;
