import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants";
import { TrackableEvent } from "./trackableEvent";

export class requestCallSubmitEvent extends TrackableEvent {
  constructor(
    packageId,
    packageName,
    packageSlug,
    packagePrice,
    travelersCount,
    discount,
    comment
  ) {
    let properties = {};

    properties[TrackerPropertyKey.PackageId] = packageId;
    properties[TrackerPropertyKey.PackageName] = packageName;
    properties[TrackerPropertyKey.PackageSlug] = packageSlug;
    properties[TrackerPropertyKey.PackagePrice] = packagePrice;
    properties[TrackerPropertyKey.TravelersCount] = travelersCount;
    if (discount > 0) properties[TrackerPropertyKey.Discount] = discount;
    if (comment) properties[TrackerPropertyKey.Comment] = comment;

    super(TrackingEventName.FormSubmitRequestCall, properties);
  }
}
