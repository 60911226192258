import React from "react";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { PrimaryButton } from "../../../../utils/Buttons";
import ProfileDropdown from "../../ProfileDropdown/ProfileDropdown";

function Navigation({
  openWhatsApp,
  userFirstName,
  isLoggedIn,
  openUserDropDown,
  handleToggleDropDown,
  handleCloseDropDown,
  source,
  handleCallButton,
  handleOpenLoginModal,
  handleOpenCustomize,
}) {
  return (
    <ul
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div className="single-navigation-div" onClick={openWhatsApp}>
        <WhatsAppIcon
          style={{
            color: "var(--text-secondary-color",
            fontSize: "25px",
          }}
        />
        <span className="list-naviation-text-heading">Chat With Us</span>
      </div>

      <div className="page-navbar-vertical-line"></div>
      <div className="single-navigation-div">
        <a
          className="call-us"
          aria-label="contact"
          href="tel:+91-9209200041"
          onClick={handleCallButton}
        >
          <PhoneOutlinedIcon
            style={{
              color: "var(--text-secondary-color)",
              fontSize: "25px",
            }}
          />
          <span className="list-naviation-text-heading">9209200041</span>
        </a>
      </div>
      <div className="page-navbar-vertical-line"></div>
      {isLoggedIn ? (
        <div className="single-navigation-div">
          <div style={{ width: "25px", height: "25px" }}>
            <ProfileDropdown
              // setOpenUserDropDown={this.setState.bind(this)}
              source={source}
              openUserDropDown={openUserDropDown}
              handleToggleDropDown={handleToggleDropDown}
              handleCloseDropDown={handleCloseDropDown}
            />
          </div>

          <span
            className="list-naviation-text-heading"
            onClick={handleToggleDropDown}
          >
            Hi, {userFirstName}
          </span>
        </div>
      ) : (
        <div
          className="single-navigation-div"
          onClick={() => {
            handleOpenLoginModal();
          }}
          style={{ width: "60%" }}
        >
          <AccountCircleIcon
            style={{
              color: "var(--text-secondary-color)",
              fontSize: "25px",
            }}
          />
          <span className="list-naviation-text-heading">Login</span>
        </div>
      )}

      <div className="single-navigation-div">
        <PrimaryButton
          onClick={() => {
            handleOpenCustomize();
          }}
        >
          {" "}
          Customize
        </PrimaryButton>
      </div>
    </ul>
  );
}

export default Navigation;
