import React from "react"
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete"
import { withRouter } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { TextField, Grid, Typography, Card, Icon, IconButton, InputAdornment, Button } from "@material-ui/core"
import PlaceIcon from "@material-ui/icons/Place"
import SearchIcon from "@material-ui/icons/Search"
import { getPackageListAction } from "../../store/actions/packageList.action"
import { NavLink } from "react-router-dom/cjs/react-router-dom.min"
import { Tracker } from "../../../tracking/tracker"
import { SearchIntentStartEvent } from "../../../tracking/events/searchIntentStartEvent"
import { SearchIntentEndEvent } from "../../../tracking/events/searchIntentEndEvent"
import { SearchCompleteEvent } from "../../../tracking/events/searchCompleteEvent"

const style = (theme) => ({
	input: {
		marginBottom: 10,
		"&:focus": {
			border: "#fff",
		},
	},
	button: {
		"&focus": {
			border: "primary",
		},
	},
	search: {
		position: "absolute",
		right: 0,
		top: 0,
		padding: 10,
		"&:hover": {
			background: "none",
		},
	},
	searchbox: {
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(3),
		},
	},
	textFeild: {
		borderRadius: "8px 0px 0px 8px ",
		color: "#616e78",

		width: "100%",
		height: "30px",
		borderColor: "#e9eaeb",
		bottom: 6,

		[theme.breakpoints.down("sm")]: {
			right: 0,
			marginBottom: 5,
		},
	},
	cards: {
		width: 400,
		vertical: "top",
		horizontal: "left",
		zIndex: 1,
		position: "relative",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
})
var searchOptions = {
	types: ["(regions)"],
}

class SearchBar extends React.Component {
	state = {
		city_name: "",
		country_name: "",
		value: "",
		showButton: false,
		data: "",
		randomText: "",
		completeAddress: "",
		routerLinks: "",
		cityByLink: "",
		StateByLink: "",
		countryByLink: "",
		enterByUserFromLink: "",
		address: JSON.parse(localStorage.getItem("completeAddress")) || [],
		destId: JSON.parse(localStorage.getItem("destId")),
		AllDestination: [],
		googlelist: false,
		dest: false,
	}
	componentDidMount = () => {
		let url = window.location.search
		let routerLinks = window.location.pathname
		let cityParams = new URLSearchParams(url).get("city")
		let countryParams = new URLSearchParams(url).get("country")
		let enterByUsrParams = new URLSearchParams(url).get("enter_by_user")
		this.setState({
			cityByLink: cityParams,
			countryByLink: countryParams,
			enterByUserFromLink: enterByUsrParams,
			routerLinks: routerLinks,
		})
	}

	handleSelect = async (address) => {
		const results = await geocodeByAddress(address)
		let value = results[0].address_components

		// //get City & Country//
		let city = ""
		let country = ""
		let locality = ""
		if (value) {
			for (let i = 0; i < value.length; i++) {
				if (value[i].types[0]) {
					const type = value[i].types[0]
					if (type === "administrative_area_level_2") {
						city = value[i].long_name
					} else if (type === "country") {
						country = value[i].long_name
					} else if (type === "locality") {
						locality = value[i].long_name
					}
				}
			}
		}

		// locatilty added for kaza packages
		this.setState({
			city_name: locality ? locality : city,
			country_name: country,
			data: { city, country },
			address,
			dest: false,
		})
	}

	handleChange = (address) => {
		if (address.length === 0) {
			this.setState({
				dest: true,
				googlelist: false,
			})
		} else {
			this.setState({
				dest: false,
				googlelist: true,
			})
		}

		this.setState({
			address,
			randomText: address,
			city_name: "",
			country_name: "",
			countryByLink: "",
			cityByLink: "",
		})
	}

	apiSubmit = () => {
		if (this.state.city !== undefined) {
			this.setState({
				city_name: this.state.city,
			})
		}

		if (this.state.country !== undefined) {
			this.setState({
				country_name: this.state.country,
			})
		}

		const { country_name, city_name } = this.state

		const cityName = city_name ? city_name : this.state.cityByLink
		const countryName = country_name ? country_name : this.state.countryByLink
		const query = this.state.randomText ? this.state.randomText : this.state.enterByUserFromLink

		Tracker.getInstance().trackEvent(new SearchCompleteEvent(query, city_name, country_name, null))

		if (this.state.routerLinks === "/packages") {
			let params = new URLSearchParams(window.location.search)
			let newParams = new URLSearchParams(window.location.search)
			params.forEach((value, key) => {
				newParams.delete(key)
			})

			if (cityName && cityName.length > 0) {
				if (countryName && countryName.length > 0) {
					newParams.set("city", cityName + ", " + countryName)
				} else {
					newParams.set("city", cityName)
				}
			}

			if (countryName && countryName.length > 0) {
				newParams.set("country", countryName)
			}

			if (!newParams.has("city") && !newParams.has("country") && query && query.length > 0) {
				newParams.set("query", query)
			}

			window.location.href = `/packages?${newParams.toString()}`
		}
	}
	onError = (status, clearSuggestions) => {
		clearSuggestions()
	}

	onFocus = () => {
		Tracker.getInstance().trackEvent(new SearchIntentStartEvent())
		this.destinationApi()
	}

	destinationApi = () => {
		let destinationData = this.props.destination
		let destName =
			destinationData &&
			destinationData.data &&
			destinationData.data.map((obj) => {
				return obj.region_name
			})

		this.setState({
			AllDestination: destName,
			dest: true,
		})
	}

	onBlur = () => {
		Tracker.getInstance().trackEvent(new SearchIntentEndEvent())
		this.handleClose()
	}

	handleClose = () => {
		this.setState({
			dest: false,
			googlelist: false,
		})
	}
	handledestination = (id) => {
		Tracker.getInstance().trackEvent(new SearchCompleteEvent(null, null, null, id.region_name))

		this.setState({
			dest: false,
		})

		if (this.state.routerLinks === "/packages") {
			let params = new URLSearchParams(window.location.search)
			let newParams = new URLSearchParams(window.location.search)
			params.forEach((value, key) => {
				newParams.delete(key)
			})
			newParams.set("destination", id.region_name)

			window.location.href = `/packages?${newParams.toString()}`
		}
	}

	getNavLinkQueryString() {
		const { city_name, country_name, randomText } = this.state
		let queryParams = []

		if (city_name && country_name) {
			queryParams.push("city=" + encodeURIComponent(city_name + ", " + country_name))
			queryParams.push("country=" + encodeURIComponent(country_name))
		} else {
			if (city_name) {
				queryParams.push("city=" + encodeURIComponent(city_name))
			}

			if (country_name) {
				queryParams.push("country=" + encodeURIComponent(country_name))
			}

			if (randomText && (!city_name || !country_name)) {
				queryParams.push("query=" + encodeURIComponent(randomText))
			}
		}

		return queryParams.length > 0 ? "?" + queryParams.join("&") : ""
	}

	render() {
		const { classes } = this.props

		return (
			<PlacesAutocomplete
				value={this.state.address}
				onChange={this.handleChange}
				onSelect={this.handleSelect}
				highlightFirstSuggestion={true}
				onError={this.onError}
				searchOptions={searchOptions}
				shouldFetchSuggestions={true}>
				{({ getInputProps, suggestions, getSuggestionItemProps }) => (
					<div style={{ width: "100%" }}>
						<TextField
							{...getInputProps({
								placeholder: "Search Places ...",
								className: "location-search-input",
							})}
							variant="outlined"
							margin="dense"
							fullWidth
							onFocus={this.onFocus}
							onBlur={this.onBlur}
							className={classes.textFeild}
							placeholder={window.innerWidth < 576 ? "Where would you like to go?" : "Where would you like to go?"}
							inputProps={{
								autoComplete: "autocomplete",
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="start">
										{/* ******************************************* */}
										{!this.state.address ? (
											<IconButton disabled>
												<SearchIcon style={{ position: "relative", left: 20 }} />
											</IconButton>
										) : (
											<NavLink to={`/packages` + this.getNavLinkQueryString()} style={{ textDecoration: "none" }}>
												<IconButton color="primary" onClick={this.apiSubmit} className={classes.search}>
													<SearchIcon />
												</IconButton>
											</NavLink>
										)}
									</InputAdornment>
								),
							}}
						/>

						<div className="autocomplete-dropdown-container" style={{ height: 0, margin: 0, vertical: "top" }}>
							{this.state.googlelist ? (
								<Card className={classes.cards}>
									{suggestions.map((suggestion) => {
										const className = suggestion.active ? "suggestion-item--active" : "suggestion-item"

										const style = suggestion.active
											? {
													backgroundColor: "#fafafa",
													cursor: "pointer",
											  }
											: {
													backgroundColor: "#ffffff",
													cursor: "pointer",
											  }
										return (
											<Grid>
												<div
													{...getSuggestionItemProps(suggestion, {
														className,
														style,
													})}>
													<Grid style={{ display: "flex", margin: 10 }}>
														<Icon color="primary">
															<PlaceIcon />
														</Icon>

														<Typography style={{ color: "#16222b" }}>{suggestion.description}</Typography>
													</Grid>
												</div>
											</Grid>
										)
									})}
								</Card>
							) : this.state.dest ? (
								<Card className={classes.cards}>
									{this.props.destination.response_code === 200
										? this.props.destination &&
										  this.props.destination.data.map((suggestion) => {
												const className = suggestion.active ? "suggestion-item--active" : "suggestion-item"

												const style = suggestion.active
													? {
															backgroundColor: "#fafafa",
															cursor: "pointer",
													  }
													: {
															backgroundColor: "#ffffff",
															cursor: "pointer",
													  }
												return (
													<div
														{...getSuggestionItemProps(suggestion, {
															className,
															style,
														})}>
														<NavLink to={`/packages?destination=${encodeURIComponent(suggestion.region_name)}`} style={{ textDecoration: "none" }}>
															<Button
																fullWidth
																onClick={() => this.handledestination(suggestion)}
																style={{
																	justifyContent: "flex-start",
																	textTransform: "none",
																}}>
																<Icon color="primary" style={{ height: "1.2em" }}>
																	<PlaceIcon />
																</Icon>

																<Typography style={{ color: "#16222b" }}>{suggestion.region_name}</Typography>
															</Button>
														</NavLink>
													</div>
												)
										  })
										: ""}
								</Card>
							) : (
								""
							)}
						</div>
					</div>
				)}
			</PlacesAutocomplete>
		)
	}
}
const mapStateToProps = ({ getPackageListReducer }) => {
	return {
		_packageList: getPackageListReducer,
	}
}

export default withRouter(connect(mapStateToProps, { getPackageListAction })(withStyles(style)(SearchBar)))
