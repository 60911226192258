import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Lottie from "lottie-react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Base64 } from "js-base64";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import CircularProgress from "@material-ui/core/CircularProgress";
import ClearIcon from "@material-ui/icons/Clear";
import loader from "../../assets/images/loaderlottie.json"
import "./calender.css";
import { useHistory } from "react-router-dom";
import { createBookingIntent } from "../Services/services";
import SnackbarComponent, { SnackbarStyle } from "../Snackbar/Snackbar";

import calendarLoader from "../../assets/images/loadingicon.gif";
const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1000",
    overFlow: "hidden",
  },
  paper: {
    outline: "none",
    border: "none",
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
    width: window.innerWidth <= 768 ? "90%" : "580px",
    padding: "10px",
    borderRadius: "5px",
    zIndex: "1000",
    overflowY: window.innerWidth <= 768 ? "scroll" : "none",
    height: window.innerWidth <= 768 ? "500px" : "",
  },
}));
function CalendarComponent({
  handleCloseCalendarModal,
  handleOpenCalendarModal,
  openCalendarModal,
  clickedButton,
  bookingAvailibiltyData,
  loadingBookingButton,
}) {
  const history = useHistory();
  const classes = useStyles();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [validRange, setValidRange] = useState({});
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState("");
  const handleCloseSnackbar = () => {
    setOpenSnackBar(false);
  };
  const handleOpenSnackbar = () => {
    setOpenSnackBar(true);
  };

  const handleDateClick = (arg) => {
    if (clickedButton === "departureDates") {
      return;
    } else {
      const eventData = formattedEvents.find(
        (event) =>
          moment(event.start).format("YYYY-MM-DD") ===
          moment(arg.dateStr).format("YYYY-MM-DD")
      );

      if (eventData) {
        handleCreateBookingIntent(eventData.package_id, eventData.id);
      }
    }
  };

  const formattedEvents = bookingAvailibiltyData.map((event) => ({
    id: event.id,
    start: event.date,
    package_id: event.package_id,
  }));
  const handleDatesSet = (dateInfo) => {
    const today = new Date();
    if (dateInfo.start < new Date(today.getFullYear(), today.getMonth(), 1)) {
      setCurrentDate(today);
    }
  };
  const handleCreateBookingIntent = async (packageId, availabilityId) => {
    const response = await createBookingIntent(packageId, availabilityId);
    if (response.status === 200) {
      const encodedUuid = Base64.encode(response.data.data.intent_id);

      window.location.href = `/booking/${encodedUuid}`;
      handleCloseCalendarModal();
    }
    if (response.status === 400) {
      setError(response.data.message);
      handleOpenSnackbar();
    }
  };
  useEffect(() => {
    if (formattedEvents.length > 0) {
      const dates = formattedEvents.map((event) => new Date(event.start));
      const firstEventDate = new Date(Math.min(...dates));
      const lastEventDate = new Date(Math.max(...dates));

      const firstDayOfMonth = new Date(
        firstEventDate.getFullYear(),
        firstEventDate.getMonth(),
        1
      );
      const lastDayOfMonth = new Date(
        lastEventDate.getFullYear(),
        lastEventDate.getMonth() + 1,
        0
      );

      const firstdate = moment(firstDayOfMonth).format("YYYY-MM-DD");
      const lastdate = moment(lastDayOfMonth).format("YYYY-MM-DD");

      if (validRange.start !== firstdate || validRange.end !== lastdate) {
        setValidRange({
          start: firstdate,
          end: lastdate,
        });
      }
    }
  }, [formattedEvents, validRange]);
  return (
    <>
      <SnackbarComponent
        openSnackBar={openSnackBar}
        handleCloseSnackbar={handleCloseSnackbar}
        handleOpenSnackbar={handleOpenSnackbar}
        message={error}
        snackBarStyle={SnackbarStyle.ERROR}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openCalendarModal}
        onClose={handleCloseCalendarModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          // onClick: handleBackdropClick,
        }}
      >
        <Fade in={openCalendarModal}>
          <div className={classes.paper}>
            {" "}
            {loadingBookingButton ? (
              <div className="loading-container">
                 <Lottie
              animationData={loader}
              loop={true}
              style={{ width: "140px", height: "140px" }}
            />
            
                {/* <img
                  src={calendarLoader}
                  width="100px"
                  height="100px"
                  alt="calendar-loader"
                /> */}
              </div>
            ) : (
              <>
                {" "}
                <div className="calendar-heading-container">
                  {clickedButton === "departureDates" ? (
                    <p
                      style={{
                        margin: "0px",
                        color: "transparent",
                        padding: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      Select the date of travel
                    </p>
                  ) : (
                    <p
                      style={{
                        margin: "0px",
                        padding: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      Select the date of travel
                    </p>
                  )}

                  <div>
                    <p className="dates-dot"></p> Available
                    <ClearIcon
                      style={{
                        padding: "5px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      onClick={handleCloseCalendarModal}
                    />
                  </div>
                </div>
                <FullCalendar
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  showNonCurrentDates={false}
                  
                  // events={formattedEvents}
                  dateClick={handleDateClick}
                  // eventContent={(eventInfo) => (
                  //   <div
                  //     style={{
                  //       cursor: "pointer",
                  //     }}
                  //   >
                  //     <span>{eventInfo.event.id}</span>
                  //   </div>
                  // )}
                  dayCellClassNames={(dateInfo) => {
                    const today = new Date();
                    const cellDate = new Date(dateInfo.date);
                    const hasEvent = formattedEvents.some(
                      (event) =>
                        new Date(event.start).toDateString() ===
                        dateInfo.date.toDateString()
                    );
                    // return hasEvent ? "green-cell" : "";
                    const isPastDateInCurrentMonth =
                      cellDate < today &&
                      cellDate.getDate() !== today.getDate() &&
                      cellDate.getMonth() === today.getMonth() &&
                      cellDate.getFullYear() === today.getFullYear();

                    return hasEvent
                      ? "green-cell"
                      : isPastDateInCurrentMonth
                      ? "disabled-cell"
                      : "";
                  }}
                  datesSet={handleDatesSet}
                  validRange={validRange}
                  headerToolbar={{
                    right: "prev,next",
                    left: "title",
                  }}
                  dayCellDidMount={(info) => {
                    const today = new Date();
                    const cellDate = new Date(info.date);

                    if (
                      cellDate < today &&
                      cellDate.getMonth() === today.getMonth() &&
                      cellDate.getFullYear() === today.getFullYear()
                    ) {
                      info.el.classList.add("fc-day-disabled");
                      info.el.style.pointerEvents = "none";
                    }
                    info.el.addEventListener("click", () => {
                      if (!info.el.classList.contains("fc-day-disabled")) {
                        handleDateClick(info);
                      }
                    });
                  }}
                  dayHeaderFormat={{ weekday: "short" }}
                  titleFormat={{ year: "numeric", month: "short" }}
                 
                />
              </>
            )}
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default CalendarComponent;
