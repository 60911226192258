import React, { Component, createRef, useContext } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import {
  Typography,
  Container,
  Button,
  IconButton,
  Drawer,
} from "@material-ui/core/";
import { withRouter } from "react-router-dom";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import SearchBar from "../../header/searchBar";
import withStyles from "@material-ui/core/styles/withStyles";
// import CustomizeForm from "../../header/customizeForm";
import Agent from "../../newagent.js/agent";
import MobileForm from "../../Banner/RegistrationFormMobile";
// import Logo from "../../../assets/images/tripLogo.png"
import logo from "../../../assets/images/drawerLogo.png";
import LogoIcon from "../../../assets/images/logo_icon.png";
import { getPackageListAction } from "../../../store/actions/packageList.action";
import { getDestinationAction } from "../../../store/actions/getDestination.action";
import { TrackingOrigin } from "../../../../tracking/trackingConstants";
import { getFiltersAction } from "../../../store/actions/filters.action";
import MultiSelectFilter from "../filters/MultiSelectFilter";
import { Tracker } from "../../../../tracking/tracker";
import { ListingFilterClearAllEvent } from "../../../../tracking/events/listing/listingFilterClearAllEvent";
import MobileHeader from "./mobileHeader";
import ModalComponent from "../../ModalComponent/ModalComponent";
import LoginUser from "../../Auth/Login/LoginUser";
import FormModal from "../../FormModal/FormModal";
import CustomizeForm from "../../CustomizeForm/CustomizeForm";
import { loginEndEvent } from "../../../../tracking/events/loginEndEvent";
import { PrimaryButton } from "../../../../utils/Buttons";
import ProfileDropdown from "../../ProfileDropdown/ProfileDropdown";
import "./packagelistnavbar.css";
import MobileNavBar from "../../navbar/MobileNavBar";
import { UserContext } from "../../hooks/UserContext";
import { whatsappMessageUtil } from "../../../../utils/whatsappUtil";
import { whatsappButtonClickedEvent } from "../../../../tracking/events/whatsappButtonClickedEvent";
import { callButtonClickedEvent } from "../../../../tracking/events/callButtonClickedEvent";
import { callButtonClickedWithOriginEvent } from "../../../../tracking/events/callButtonClickedWithOriginEvent";
import Navigation from "./Navigation";

const drawerWidth = 270;
const style = (theme) => ({
  root: {
    backgoundColor: "ff8362",
    boxShadow: "none",
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  paper: {
    width: 340,
  },
  drawer: {
    width: drawerWidth,
  },

  headerLinks: {
    // color: "inherit",
    textDecoration: "none",
    color: "#ff6840",
    // margin: theme.spacing(1),

    "&:hover": {
      color: "#ff8362",
      fontweight: "bold",
    },
    "&:active": {
      color: "#fff",
    },
  },
  title: {
    listStyle: "none",
    display: "inline",
    margin: "0",
    padding: "0",
    width: "100%",
    color: "#ff8362",
    pressable: true,
    init: { scale: 1 },
    press: { scale: 0.8 },
  },

  toolbar: {
    margin: 0,
    // top: 10,
    boxShadow: "none",
  },

  menuIcon: {
    padding: 0,
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(0),
    },
  },
  small: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  filter: {
    overflowX: "scroll",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1),
    },

    alignItems: "flex-start",
  },

  logo: {
    display: "flex",

    alignItems: "start", // Center vertically

    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  logo_icon: {
    display: "flex",
    alignItems: "center", // Center vertically
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  linkContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
});
const HeaderNavLinks = [
  // { link: "Destinations", url: "/" },
  // { link: "Help", url: "/" }
  // { link: "Become an Agent", url: "/package" }
];

class HeaderSection extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      sideDrawer: false,
      monthData: "",
      themeData: [],
      open: false,
      inclusionData: [],
      themestate: "",
      budget: "",
      isLoading: false,
      mobileCustomize: false,
      filterRequest: null,
      isLoadingFilters: false,
      openLoginModal: false,
      isCustomizedButtonClicked: "",
      openCustomizeModal: false,
      currentView: "",
      openUserDropDown: false,
      isMobileNavBarVisible: false,
      isMobileNavBarVisible: false,
    };
    this.anchorRef = createRef(); // Added ref for the dropdown anchor
  }

  componentDidMount = () => {
    if (this.props.filterRequest) {
      this.setState({
        filterRequest: this.props.filterRequest,
      });
      this.fetchFilters(this.props.filterRequest);
    }
    this.props.getDestinationAction();
    
  };

  fetchFilters(filterRequest) {
    if (!this.state.isLoadingFilters) {
      this.setState({
        isLoadingFilters: true,
      });

      this.props.getFiltersAction(filterRequest);
      this.setState({
        isLoadingFilters: false,
      });
    }
  }

  DrawerToggleHandler = () => {
    this.setState({
      sideDrawer: true,
    });
  };
  handleOpenCustomize = () => {
    this.handleOpenCustomizeModal();
  };
  MobileCustomizeRegistrationOpen = () => {
    this.setState({
      mobileCustomize: true,
    });
  };
  RegistrationClose = () => {
    this.setState({
      mobileCustomize: false,
    });
  };
 
  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  handleDrawerClose = () => {
    this.setState({
      sideDrawer: false,
    });
  };

  ClearData = () => {
    Tracker.getInstance().trackEvent(new ListingFilterClearAllEvent());
    this.props.clearData();
  };

  handleFilterClear = (filterResponse) => {
    let params = new URLSearchParams(window.location.search);
    params.delete(filterResponse.type);
    window.location.href = `/packages?${params.toString()}`;
  };

  handleFilterChange = (filterResponse) => {
 
    let selectedFilters = filterResponse.filters
      .filter((obj) => {
        return obj.isSelected === true;
      })
      .map((obj) => {
        return obj.name;
      });

  
    let params = new URLSearchParams(window.location.search);
    params.set(filterResponse.type, selectedFilters.join(":"));

    window.location.href = `/packages?${params.toString()}`;
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps) {
      if (nextProps.filterRequest && !this.state.filterRequest) {
        this.setState({
          filterRequest: nextProps.filterRequest,
        });
        this.fetchFilters(nextProps.filterRequest);
      }
    }
  };
  handleOpenLoginModal = () => {

    this.setState({ openLoginModal: true });
  };
  isLoggedIn = localStorage.getItem("access_token");
  setCurrentView = (newData) => {
    this.setState({ currentView: newData });
  };
  handleCloseLoginModal = () => {
    this.setState({ openLoginModal: false });
    if (!this.isLoggedIn) {
      Tracker.getInstance().trackEvent(
        new loginEndEvent(this.state.currentView)
      );
    }
  };
  handleCloseCustomizeModal = () => {
    this.setState({ openCustomizeModal: false });
   
  };
  handleOpenCustomizeModal = () => {
    this.setState({ openCustomizeModal: true });
  };

  handleToggleDropDown = () => {
    this.setState((prevState) => ({
      openUserDropDown: !prevState.openUserDropDown,
    }));
  };

  handleCloseDropDown = (event) => {
    if (
      this.anchorRef.current &&
      this.anchorRef.current.contains(event.target)
    ) {
      return;
    }
    this.setState({ openUserDropDown: false });
  };

  handleVisibilityChange = (isVisible) => {
    this.setState({ isMobileNavBarVisible: isVisible });
    // isMobileNavBarVisible(isVisible);
  };
  openWhatsApp = () => {
    whatsappMessageUtil();
    Tracker.getInstance().trackEvent(new whatsappButtonClickedEvent("nav_bar"));
  };
  handleCallButton = () => {
    Tracker.getInstance().trackEvent(new callButtonClickedEvent());
    Tracker.getInstance().trackEvent(
      new callButtonClickedWithOriginEvent("nav_bar")
    );
  };
  render() {
    const { classes } = this.props;
    const { _destination, _filters } = this.props;

    const { openLoginModal } = this.state;
    const { userInfo } = this.context;
    const userFirstName = userInfo?.name.split(" ")[0];
 
    return (
      <React.Fragment>
        <FormModal
          openCustomizeModal={this.state.openCustomizeModal}
          handleCloseCustomizeModal={this.handleCloseCustomizeModal}
          children={<CustomizeForm />}
          setCurrentView={this.setCurrentView}
          origin={TrackingOrigin.LeadForm.PackageList}
        />
        <ModalComponent
          openLoginModal={this.state.openLoginModal}
          handleCloseLoginModal={this.handleCloseLoginModal}
          children={<LoginUser />}
          // setCurrentView={setCurrentView}
        />
        <Grid md={12} item justifyContent="flex-start" container>
          <AppBar
            position="fixed"
            style={{
              background: "#fff",

              boxShadow: this.state.visible
                ? "none"
                : " 2px 1px 7px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Toolbar className={classes.toolbar}>
              <Container className={classes.root}>
                {window.innerWidth <= 1000 ? (
                  // <MobileHeader />
                  <MobileNavBar
                    onVisibilityChange={this.handleVisibilityChange}
                    shouldHideOnScroll={false}
                  />
                ) : (
                  <Grid
                    container
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "0px",
                    }}
                  >
                    <Grid sm={4} md={2} item>
                      <NavLink
                        to="/"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <img src={logo} height="100%" width={140} alt="img" />
                      </NavLink>
                    </Grid>
                    <Grid xs={1} item className={classes.logo_icon}>
                      <NavLink
                        to="/"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <img
                          src={logo}
                          height={30}
                          width={30}
                          alt="img"
                          style={{ objectFit: "contain" }}
                        />
                      </NavLink>
                    </Grid>
                    <Grid xs={11} sm={8} md={4} container item>
                      <SearchBar destination={_destination} />
                    </Grid>
                    <Grid md={6} container item>
                      <Navigation
                        openWhatsApp={this.openWhatsApp}
                        userFirstName={userFirstName}
                        isLoggedIn={this.isLoggedIn}
                        openUserDropDown={this.state.openUserDropDown}
                        handleToggleDropDown={this.handleToggleDropDown}
                        handleCloseDropDown={this.handleCloseDropDown}
                        source="page"
                        handleCallButton={this.handleCallButton}
                        handleOpenLoginModal={this.handleOpenLoginModal}
                        handleOpenCustomize={this.handleOpenCustomize}
                      />
                      {/* <ul
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="single-navigation-div"
                           onClick={this.openWhatsApp}
                        >
                          <WhatsAppIcon
                            style={{
                              color: "var(--text-secondary-color",
                              fontSize: "25px",
                            }}
                          />
                          <span className="list-naviation-text-heading">
                            Chat With Us
                          </span>
                        </div>

                        <div className="page-navbar-vertical-line"></div>
                        <div className="single-navigation-div">
                          <a
                            className="call-us"
                            aria-label="contact"
                            href="tel:+91-9209200041"
                            onClick={this.handleCallButton}
                          >
                            <PhoneOutlinedIcon
                              style={{
                                color: "var(--text-secondary-color)",
                                fontSize: "25px",
                              }}
                            />
                            <span className="list-naviation-text-heading">
                              9209200041
                            </span>
                          </a>
                        </div>
                        <div className="page-navbar-vertical-line"></div>
                        {this.isLoggedIn ? (
                          <div className="single-navigation-div"  >
                            <div style={{ width: "25px", height: "25px" }}>
                              <ProfileDropdown
                                // setOpenUserDropDown={this.setState.bind(this)}
                                source="page"
                                openUserDropDown={this.state.openUserDropDown}
                                handleToggleDropDown={this.handleToggleDropDown}
                                handleCloseDropDown={this.handleCloseDropDown}
                              />
                            </div>

                            <span
                              className="list-naviation-text-heading"
                              
                              onClick={this.handleToggleDropDown}
                            >
                              Hi, {userFirstName}
                            </span>
                          </div>
                        ) : (
                          <div
                            className="single-navigation-div"
                            onClick={() => {
                              this.handleOpenLoginModal();
                            }}
                            style={{width:"60%"}}
                          >
                            <AccountCircleIcon
                              style={{
                                color: "var(--text-secondary-color)",
                                fontSize: "25px",
                              }}
                            />
                            <span className="list-naviation-text-heading">
                              Login
                            </span>
                          </div>
                        )}
                   
                        <div className="single-navigation-div">
                          <PrimaryButton
                            onClick={() => {
                              this.handleOpenCustomize();
                            }}
                          >
                            {" "}
                            Customize
                          </PrimaryButton>
                        </div>
                      </ul> */}
                    </Grid>
                    {/* <Grid
                      md={6}
                      item
                      container
                      justifyContent="flex-end"
                      className={classes.linkContainer}
                    >
                      <PrimaryButton
                        onClick={() => {
                          this.handleOpenCustomize();
                        }}
                      >
                        {" "}
                        Customize
                      </PrimaryButton>
                    </Grid> */}
                  </Grid>
                )}

                {/* {_filters &&
                _filters.filterResposes &&
                _filters.filterResposes.length > 0 ? (
                  <Grid
                    style={{
                      marginTop: `${
                        this.state.isMobileNavBarVisible ? "60px" : "0px"
                      }`,
                    }}
                    container
                    justifyContent="space-between"
                    className={classes.filter}
                  >
                    <Grid xs={12} md={12} item container>
                      <div style={{ display: "flex" }}>
                        {_filters.filterResposes.map((obj, index) => (
                          <React.Fragment key={index}>
                            <MultiSelectFilter
                              filterResponse={obj}
                              onSubmit={this.handleFilterChange}
                              onClear={this.handleFilterClear}
                            ></MultiSelectFilter>
                            {index < _filters.filterResposes.length - 1 && (
                              <div style={{ marginRight: 8 }}></div>
                            )}
                          </React.Fragment>
                        ))}
                        <Button
                          variant="text"
                          style={{
                            textTransform: "none",
                            padding: 0,
                            marginLeft: 16,
                            marginBottom: 16,
                          }}
                          onClick={this.ClearData}
                        >
                          <Typography
                            variant="subtitle1"
                            style={{ color: "#224e6d" }}
                          >
                            Clear All
                          </Typography>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                ) : null} */}
              </Container>
            </Toolbar>
          </AppBar>
          <Agent open={this.state.open} onClose={this.handleClose} />
          {/* <CustomizeForm
            openForm={this.state.CustomizeForm}
            onClose={this.handleCloseCustomize}
            origin={TrackingOrigin.LeadForm.PackageList}
          /> */}
          <MobileForm
            open={this.state.mobileCustomize}
            onClose={this.RegistrationClose}
            origin={TrackingOrigin.LeadForm.PackageList}
          />
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ getDestinationReducer, filtersReducer }) => {
  return {
    _destination: getDestinationReducer,
    _filters: filtersReducer,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    getDestinationAction,
    getPackageListAction,
    getFiltersAction,
  })(withStyles(style)(HeaderSection))
);
