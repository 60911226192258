import React from "react"
import { Box, Grid, Typography, withStyles } from "@material-ui/core"
import Actions from "./actions"

const style = (theme) => ({
	actionGrid: {
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		justifyContent: "flex-end",
		alignItems: "flex-end",
	},
	retailPrice: {
		color: "#000",
		fontSize: 20,
		fontWeight: 600,
		textDecoration: "line-through",
		[theme.breakpoints.down("md")]: {
			fontSize: 18,
		},
	},
	salePrice: {
		color: "#ff6840",
		fontSize: 26,
		fontWeight: 600,
		[theme.breakpoints.down("md")]: {
			fontSize: 22,
		},
	},
	helperText: {
		color: "#343434",
		fontSize: 16,
		fontWeight: 300,
		[theme.breakpoints.down("md")]: {
			fontSize: 14,
		},
	},
	retailPriceGrid: {
		display: "flex",
		alignItems: "center",
	},
})

const PricingWithActions = (props) => {
	const { packageData, classes } = props
	const saleInfo = packageData.saleInfo
	const shouldShowRetailPrice = (saleInfo && saleInfo.retailPrice && saleInfo.shouldShowRetailPrice) ?? false
	const retailPrice = saleInfo.retailPrice
	const salePrice = saleInfo.listPrice

	return (
		<React.Fragment>
			<Grid container item xs={5} md={6} direction="column">
				{shouldShowRetailPrice && (
					<Grid container item className={classes.retailPriceGrid}>
						<Typography className={classes.retailPrice}>{retailPrice.displayValue}</Typography>
					</Grid>
				)}
				<Grid container item className={classes.retailPriceGrid}>
					<Typography className={classes.salePrice}>{salePrice.displayValue}</Typography>
					<Box m={0.5} />
					<Typography className={classes.helperText}>Per person</Typography>
				</Grid>
			</Grid>

			<Grid container item xs={7} md={6} className={classes.actionGrid}>
				<Actions packageData={packageData} onPackageDetailClick={props.onPackageDetailClick} onRequestCallClick={props.onRequestCallClick} onWhatsAppClick={props.onWhatsAppClick} shouldShowRetailPrice={shouldShowRetailPrice} />
			</Grid>
		</React.Fragment>
	)
}

export default withStyles(style)(PricingWithActions)
