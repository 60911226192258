import React, { Component, createRef } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import { Typography, Container, Button } from "@material-ui/core/";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import BecomeAnAgent from "../../newagent.js/agent";
import Logo from "../../../assets/images/tripLogo.png";
import logo from "../../../assets/images/drawerLogo.png";
import withStyles from "@material-ui/core/styles/withStyles";
import { getPackageListAction } from "../../../store/actions/packageList.action";
import { TrackingOrigin } from "../../../../tracking/trackingConstants";
import ModalComponent from "../../ModalComponent/ModalComponent";
import LoginUser from "../../Auth/Login/LoginUser";
import FormModal from "../../FormModal/FormModal";
import CustomizeForm from "../../CustomizeForm/CustomizeForm";
import { Tracker } from "../../../../tracking/tracker";
import { loginEndEvent } from "../../../../tracking/events/loginEndEvent";
import { PrimaryButton } from "../../../../utils/Buttons";
import { UserContext } from "../../hooks/UserContext";
import { whatsappButtonClickedEvent } from "../../../../tracking/events/whatsappButtonClickedEvent";
import { callButtonClickedEvent } from "../../../../tracking/events/callButtonClickedEvent";
import { whatsappMessageUtil } from "../../../../utils/whatsappUtil";
import { callButtonClickedWithOriginEvent } from "../../../../tracking/events/callButtonClickedWithOriginEvent";
import Navigation from "../Navbar/Navigation";
import "../../PackagesList/Navbar/packagelistnavbar.css";
import MobileNavBar from "../../navbar/MobileNavBar";

const style = (theme) => ({
  root: {
    backgoundColor: "ff8362",
    boxShadow: "none",
    flexGrow: 1,
  },
  headerLinks: {
    color: "#616e78",
    textDecoration: "none",
    padding: "0 1rem",

    "&:active": {
      color: "#ff8362",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  title: {
    listStyle: "none",
    display: "inline",
    margin: "0",
    padding: "0",
    width: "100%",
    color: "#ff8362",
    pressable: true,
    init: { scale: 1 },
    press: { scale: 0.8 },
  },
  spacer: {
    flex: 0.8,
  },
  toolbar: {
    top: 0,
    margin: 0,

    boxShadow: "none",
  },
  SiderDrawer: {
    trasition: "0.3s ease-out",
  },
  MenuIcon: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    color: "#fff",
  },
  sideDrawerLinks: {
    listStyle: "none",
    display: "inline-block",
    textDecoration: "none",
    margin: "0",

    color: "#16222b",
  },
  filter: {
    minHeight: 80,
    alignItems: "flex-start",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  customizeButton: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

class HeaderSection extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      sideDrawer: false,
      monthData: "",
      themeData: [],
      inclusionData: [],
      monthName: [],
      budget: "",
      themeName: [],
      themestate: "",
      inclusionName: [],
      inclusionid: "",
      inclusionstate: "",
      open: false,

      openLoginModal: false,
      isCustomizedButtonClicked: "",
      openCustomizeModal: false,
      currentView: "",
      openUserDropDown: false,
      isMobileNavBarVisible: false,
      isVisibleNav: false,
    };
    this.anchorRef = createRef();
  }

  handleClick = () => {
    this.setState({
      open: true,
    });
  };
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleOpenCustomize = () => {
    this.handleOpenCustomizeModal();
  };

  handleOpenLoginModal = () => {
    this.setState({ openLoginModal: true });
  };
  isLoggedIn = localStorage.getItem("access_token");
  setCurrentView = (newData) => {
    this.setState({ currentView: newData });
  };
  handleCloseLoginModal = () => {
    this.setState({ openLoginModal: false });
    if (!this.isLoggedIn) {
      Tracker.getInstance().trackEvent(
        new loginEndEvent(this.state.currentView)
      );
    }
  };
  handleCloseCustomizeModal = () => {
    this.setState({ openCustomizeModal: false });
  };
  handleOpenCustomizeModal = () => {
    this.setState({ openCustomizeModal: true });
  };
  openWhatsApp = () => {
    whatsappMessageUtil();
    Tracker.getInstance().trackEvent(new whatsappButtonClickedEvent("nav_bar"));
  };
  handleCallButton = () => {
    Tracker.getInstance().trackEvent(new callButtonClickedEvent());
    Tracker.getInstance().trackEvent(
      new callButtonClickedWithOriginEvent("nav_bar")
    );
  };
  handleVisibilityChange = (isVisible) => {
    this.setState({ isMobileNavBarVisible: isVisible });
    this.props.onVisibilityChange(isVisible);
  };
  handleToggleDropDown = () => {
    this.setState((prevState) => ({
      openUserDropDown: !prevState.openUserDropDown,
    }));
  };
  handleCloseDropDown = (event) => {
    if (
      this.anchorRef.current &&
      this.anchorRef.current.contains(event.target)
    ) {
      return;
    }
    this.setState({ openUserDropDown: false });
  };
  handleVisibilityChange = (isVisible) => {
    this.setState({ isVisibleNav: isVisible });
 
  };
  render() {
    const { classes } = this.props;
    const { openLoginModal } = this.state;
    const { userInfo } = this.context;
    const userFirstName = userInfo?.name.split(" ")[0];

    return (
      <React.Fragment>
        <ModalComponent
          openLoginModal={this.state.openLoginModal}
          handleCloseLoginModal={this.handleCloseLoginModal}
          children={<LoginUser />}
        />
        <FormModal
          openCustomizeModal={this.state.openCustomizeModal}
          handleCloseCustomizeModal={this.handleCloseCustomizeModal}
          children={<CustomizeForm />}
          origin={TrackingOrigin.LeadForm.CompareList}
          setCurrentView={this.setCurrentView}
        />
        {window.innerWidth <= 1000 ? (
          <MobileNavBar onVisibilityChange={this.handleVisibilityChange} shouldHideOnScroll={false} backgroundColor={"var(--primary-background-color)"}/>
        ) : (
          <Grid md={12} item justifyContent="flex-start">
            <AppBar
              position="fixed"
              style={{
                background: "#fff",
                boxShadow: this.state.visible
                  ? "none"
                  : " 2px 1px 7px 0px rgba(0,0,0,0.12)",
              }}
            >
              <Toolbar className={classes.toolbar}>
                <Container>
                  <Grid
                    container
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid xs={12} md={6} item>
                      <div style={{ display: "flex" }}>
                        <NavLink
                          to="/"
                          style={{
                            textDecoration: "none",
                            color: !this.state.visible ? "#ff8362" : "#fff",
                          }}
                        >
                          <img src={logo} height="95%" width={140} alt="img" />
                        </NavLink>
                      </div>
                    </Grid>
                    <Grid md={6} container item>
                      <Navigation
                        openWhatsApp={this.openWhatsApp}
                        userFirstName={userFirstName}
                        isLoggedIn={this.isLoggedIn}
                        openUserDropDown={this.state.openUserDropDown}
                        handleToggleDropDown={this.handleToggleDropDown}
                        handleCloseDropDown={this.handleCloseDropDown}
                        source="page"
                        handleCallButton={this.handleCallButton}
                        handleOpenLoginModal={this.handleOpenLoginModal}
                        handleOpenCustomize={this.handleOpenCustomize}
                      />
                      {/* <Grid
                      md={6}
                      xs={12}
                      container
                      justifyContent="flex-end"
                      className={classes.linkContainer}
                    >
                      <PrimaryButton
                        onClick={() => {
                          this.handleOpenCustomize();
                        }}
                      >
                        Customize
                      </PrimaryButton>
                    </Grid> */}
                    </Grid>
                    {/* <Grid item className={classes.customizeButton}>
                    <PrimaryButton
                      onClick={() => {
                        this.handleOpenCustomize();
                      }}
                    >
                      Customize
                    </PrimaryButton>
                  </Grid> */}
                  </Grid>
                </Container>
              </Toolbar>
            </AppBar>
            <BecomeAnAgent open={this.state.open} onClose={this.handleClose} />
            {/* <CustomizeForm
            openForm={this.state.CustomizeForm}
            onClose={this.handleCloseCustomize}
            origin={TrackingOrigin.LeadForm.CompareList}
          /> */}
          </Grid>
        )}
      </React.Fragment>
    );
  }
}
// export default withStyles(style, { withTheme: true })(HeaderSection);
const mapStateToProps = ({ getPackageListReducer, postCompareListReducer }) => {
  return {
    _packageList: getPackageListReducer,
    _comparelist: postCompareListReducer,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    getPackageListAction,
  })(withStyles(style)(HeaderSection))
);
