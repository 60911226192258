import { put, call } from "redux-saga/effects";
import { getCompareListService } from "../services/getComparelist.service";

import { getCompareListSuccessAction } from "../actions/getComparelist.action";
import * as types from "../action.types";
export function* getCompareListSaga(data) {
  try {
    const response = yield call(getCompareListService, data);

    yield put(getCompareListSuccessAction(response));
  } catch (error) {
    yield put({ type: types.GET_COMPARE_LIST_ERROR, error });
  }
}
