import * as types from "../action.types";

export const getInclusionAction = () => {
    return {
        type: types.GET_INCLUSION
    };
};

export const getInclusionActionSucess = values => {
    return {
        type: types.GET_INCLUSION_SUCCESS,
        values
    }
}