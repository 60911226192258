import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { getScreenSize } from "../../../utils/utility";

const screenSize = getScreenSize().width;

const useStyles = makeStyles({
  root: {
    position: "relative",
    width: "100%",
    backgroundColor: "white",
    padding: screenSize <= 768 ? "0px" : "5px",
    borderRadius: screenSize <= 768 ? "0px" : "5px",
  },
});

function GridComponent({ divs }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {divs}
      </Grid>
    </div>
  );
}

export default GridComponent;
