import { Grid, IconButton, withStyles } from "@material-ui/core"
import React, { Component } from "react"
import { NavLink } from "react-router-dom/cjs/react-router-dom.min"
import LogoIcon from "../../../assets/images/logo_icon.png"
import Logo from "../../../assets/images/tripLogo.png"
import logo from "../../../assets/images/drawerLogo.png";
import SearchBar from "../../header/searchBar"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import { getDestinationAction } from "../../../store/actions/getDestination.action"
import SearchIcon from "@material-ui/icons/Search"

const style = (theme) => ({
	container: {
		height: "60px",
		display: "flex",
		alignItems:"center"
	},

	logo_icon: {
		display: "flex",
		alignItems: "center", // Center vertically
	},

	logo: {
		display: "flex",
      justifyContent: "start",
		alignItems: "center", // Center vertically
	},

	search: {
		display: "flex",

		alignItems: "center", // Center vertically
		marginTop: "100px",
	},
})

class MobileHeader extends Component {
	state = {
		showFullSearch: false,
	}

	handleSearchOpen = () => {
		this.setState({
			showFullSearch: true,
		})
	}

	handleSearchClose = () => {
		this.setState({
			showFullSearch: false,
		})
	}

	render() {
		const { classes } = this.props
		return (
			<React.Fragment>
				{this.state.showFullSearch ? (
					<Grid container className={classes.container}>
						<Grid xs={2} item className={classes.logo_icon}>
							<NavLink
								to="/"
								style={{
									textDecoration: "none",
								}}>
								<img src={LogoIcon} height={40} width={40} alt="img"  />
							</NavLink>
						</Grid>
						<Grid xs={10} container item style={{ marginTop: 2 }}>
							<SearchBar destination={this.props._destination} className={classes.search} />
						</Grid>
					</Grid>
				) : (
					<Grid container className={classes.container}>
						<Grid xs={10} item className={classes.logo} >
							<NavLink
								to="/"
								style={{
									textDecoration: "none",
								}}>
								<img src={logo} height="65px" width="130px" alt="img"  />
							</NavLink>
						</Grid>
						<Grid xs={2} container>
							<IconButton onClick={this.handleSearchOpen}>
								<SearchIcon fontSize="large" />
							</IconButton>
						</Grid>
					</Grid>
				)}
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ getDestinationReducer }) => {
	return {
		_destination: getDestinationReducer,
	}
}
export default withRouter(
	connect(mapStateToProps, {
		getDestinationAction,
	})(withStyles(style)(MobileHeader))
)
