import axios from "axios"

export const getPackageDetailService = (request) => {
	let slug = request.data.params.package_id ?? ""

	return axios
		.get(`/package/${slug}/details`)
		.then((response) => {
			return response
		})
		.catch((err) => {
			return err.response
		})
}
