import * as types from "../action.types";
export const getSeasonReducer = (state = {}, action) => {
    const response = action.values;


    switch (action.type) {
        case types.GET_SEASON_SUCCESS:
            return response;
        case types.GET_SEASON_ERROR:
            return state;
        default:
            return state;
    }
};
