import React, { Component } from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import { Grid } from "@material-ui/core"
import Footer from "../../footer/footer"
import Header from "../../PackageDetails/PackageInformation/termsHeader"
import CommonUI from "../../FooterFiles/Model/model"
import PolicyImage from "../../../assets/images/privacyWithBackground.png"
import { PrivacyData } from "./policydata"
import { Helmet } from "react-helmet"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { getMetaDetailAction } from "../../../store/actions/getMeta.action"
import { Tracker } from "../../../../tracking/tracker"
import { PageViewEvent } from "../../../../tracking/events/pageViewEvent"
import { TrackingPageName } from "../../../../tracking/trackingConstants"
import { NavigationContext } from "../../../navigation/navigationContext"

const style = (theme) => ({
	text: {
		marginTop: "4%",
		[theme.breakpoints.down("sm")]: {
			marginTop: "24%",
		},
	},
})
class TermsCondition extends Component {
	static contextType = NavigationContext

	componentDidMount = () => {
		// Tracking Page View
		this.context.setBrowsingPage(TrackingPageName.PivacyPolicy)

		this.props.getMetaDetailAction({ params: { page: "privacy_policy" } })
		window.scrollTo(0, 0)
	}
	render() {
		const { _metaDetail } = this.props
		return (
			<Grid>
				<Helmet>
					<title>{_metaDetail.data ? _metaDetail.data.data.meta_title : ""}</title>
					<meta charset="UTF-8" />
					<meta name="description" content={_metaDetail.data ? _metaDetail.data.data.meta_description : ""} />
					<meta name="keywords" content={_metaDetail.data ? _metaDetail.data.data.meta_keyword : ""} />
					<meta name="author" content="Tripstore" />
					<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				</Helmet>
				<Header />
				<Grid md={12} xs={12} sm={12} container justify="center" style={{ marginTop: "8%" }}>
					<CommonUI completeData={PrivacyData} Picture={PolicyImage} page={1} />

					<Footer />
				</Grid>
			</Grid>
		)
	}
}

const mapStateToProps = ({ getMetaDetailReducer }) => {
	return {
		_metaDetail: getMetaDetailReducer,
	}
}
export default withRouter(
	connect(mapStateToProps, {
		getMetaDetailAction,
	})(withStyles(style)(TermsCondition))
)
