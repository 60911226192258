import * as types from "../action.types";

export const getCompareListAction = data => {
  return {
    type: types.GET_COMPARE_LIST,
    data
  };
};

export const getCompareListSuccessAction = data => {
  return {
    type: types.GET_COMPARE_LIST_SUCCESS,
    data
  };
};

export const getResponseNullAction = () => {
  return {
    type: types.GET_RESPONSE_NULL
  };
};
