
import { TrackableEvent } from "./trackableEvent"
import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants"

export class ListingFilterItemClickEvent extends TrackableEvent {
	constructor(title, item_title, is_selected) {
		let properties = {}
		properties[TrackerPropertyKey.FilterTitle] = title
		properties[TrackerPropertyKey.ItemTitle] = item_title
		properties[TrackerPropertyKey.IsSelected] = is_selected

		super(TrackingEventName.ListingFilterItemClick, properties)
	}
}