import { put, call } from "redux-saga/effects";
import { termsConditionService } from "../services/termsCondition.service";

import { termsConditionActionSuccess } from "../actions/termsCondition.action";
import * as types from "../action.types";

export function* termsConditionSaga(data) {
  try {
    const response = yield call(termsConditionService, data);

    if (response.response_code === 200) {
      yield put(termsConditionActionSuccess(response.data));
    }
  } catch (error) {
    yield put({ type: types.GET_TERMS_CONDITION_ERROR, error });
  }
}
