//Get Destination Api//
export const GET_DESTINATION = "GET_DESTINATION";
export const GET_DESTINATION_SUCCESS = "GET_DESTINATION_SUCCESS";
export const GET_DESTINATION_ERROR = "GET_DESTINATION_ERROR";

//Get Europe Destination Api//
export const GET_EUROPE_DESTINATION = "GET_EUROPE_DESTINATION";
export const GET_EUROPE_DESTINATION_SUCCESS = "GET_EUROPE_DESTINATION_SUCCESS";
export const GET_EUROPE_DESTINATION_ERROR = "GET_EUROPE_DESTINATION_ERROR";

//Get Other Destination Api//
export const GET_OTHER_DESTINATION = "GET_OTHER_DESTINATION";
export const GET_OTHER_DESTINATION_SUCCESS = "GET_OTHER_DESTINATION_SUCCESS";
export const GET_OTHER_DESTINATION_ERROR = "GET_OTHER_DESTINATION_ERROR";

//Get Theme Api//

export const GET_THEME = "GET_THEME";
export const GET_THEME_SUCCESS = "GET_THEME_SUCCESS";
export const GET_THEME_ERROR = "GET_THEME_ERROR";

//get seasons api//

export const GET_SEASON = "GET_SEASON";
export const GET_SEASON_SUCCESS = "GET_SEASON_SUCCESS";
export const GET_SEASON_ERROR = "GET_SEASON_ERROR";

//get months api//
export const GET_MONTHS = "GET_MONTHS";
export const GET_MONTHS_SUCCESS = "GET_MONTHS_SUCCESS";
export const GET_MONTHS_ERROR = "GET_MONTHS_ERROR";

//get departure city api//
export const GET_DEPARTURE_CITY = "GET_DEPARTURE_CITY";
export const GET_DEPARTURE_CITY_SUCCESS = "GET_DEPARTURE_CITY_SUCCESS";
export const GET_DEPARTURE_CITY_ERROR = "GET_DEPARTURE_CITY_ERROR";

//get inclusion ap//
export const GET_INCLUSION = "GET_INCLUSION";
export const GET_INCLUSION_SUCCESS = "GET_INCLUSION_SUCCESS";
export const GET_INCLUSION_ERROR = "GET_INCLUSION_ERROR";

//get popular packages api//
export const GET_POPULAR_PACKAGES = "GET_POPULAR_PACKAGES";
export const GET_POPULAR_PACKAGES_SUCCESS = "GET_POPULAR_PACKAGES_SUCCESS";
export const GET_POPULAR_PACKAGES_ERROR = "GET_POPULAR_PACKAGES_ERROR";

//get package list api//
export const GET_PACKAGE_LIST = "GET_PACKAGE_LIST";
export const GET_PACKAGE_LIST_SUCCESS = "GET_PACKAGE_LIST_SUCCESS";
export const GET_PACKAGE_LIST_ERROR = "GET_PACKAGE_LIST_ERROR";

//get banner data//
export const GET_BANNER = "GET_BANNER";
export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS";
export const GET_BANNER_ERROR = "GET_BANNER_ERROR";

//request a call api //
export const GET_REQUEST_CALL = "GET_REQUEST_CALL";
export const GET_REQUEST_CALL_SUCCESS = "GET_REQUEST_CALL_SUCCESS";
export const GET_REQUEST_CALL_ERROR = "GET_REQUEST_CALL_ERROR";

//get package details data//
export const GET_PACKAGE_DETAILS = "GET_PACKAGE_DETAILS";
export const GET_PACKAGE_DETAILS_SUCCESS = "GET_PACKAGE_DETAILS_SUCCESS";
export const GET_PACKAGE_DETAILS_ERROR = "GET_PACKAGE_DETAILS_ERROR";

//get compare list//
export const GET_COMPARE_LIST = "GET_COMPARE_LIST";
export const GET_COMPARE_LIST_SUCCESS = "GET_COMPARE_LIST_SUCCESS";
export const GET_COMPARE_LIST_ERROR = "GET_COMPARE_LIST_ERROR";

export const GET_REQUEST_PACKAGE = "GET_REQUEST_PACKAGE";
export const GET_REQUEST_PACKAGE_SUCCESS = "GET_REQUEST_PACKAGE_SUCCESS";
export const GET_REQUEST_PACKAGE_ERROR = "GET_REQUEST_PACKAGE_ERROR";

// resonse null//
export const GET_RESPONSE_NULL = "GET_RESPONSE_NULL";

//become an agent//
export const GET_BECOME_AGENT = "GET_BECOME_AGENT";
export const GET_BECOME_AGENT_SUCCESS = "GET_BECOME_AGENT_SUCCESS";
export const GET_BECOME_AGENT_ERROR = "GET_BECOME_AGENT_ERROR";

//Terms and condition
export const GET_TERMS_CONDITION = "GET_TERMS_CONDITION";
export const GET_TERMS_CONDITION_SUCCESS = "GET_TERMS_CONDITION_SUCCESS";
export const GET_TERMS_CONDITION_ERROR = "GET_TERMS_CONDITION_ERROR";

// Meta tags
export const GET_META = "GET_META";
export const GET_META_SUCCESS = "GET_META_SUCCESS";
export const GET_META_ERROR = "GET_META_ERROR";

// Filters
export const GET_FILTERS = "GET_FILTERS";
export const GET_FILTERS_SUCCESS = "GET_FILTERS_SUCCESS";
export const GET_FILTERS_ERROR = "GET_FILTERS_ERROR";

// Filters
export const GET_LISTING = "GET_LISTING";
export const GET_LISTING_SUCCESS = "GET_LISTING_SUCCESS";
export const GET_LISTING_ERROR = "GET_LISTING_ERROR";