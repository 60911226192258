import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: window.innerWidth <= 768 ? "40px" : "23px",
    height: window.innerWidth <= 768 ? "40px" : "23px",
    color: "black",
    borderRadius: window.innerWidth <= 768 ? "50%" : "",
    backgroundColor: "white",
    cursor: "pointer",
  },
}));

const ProfileIcon = ({ userName, source }) => {
  const classes = useStyles();
  const getInitials = (name) => {
    if (!name) return "";
    const nameParts = name.trim().split(" ");
    if (nameParts.length === 1) {
      return nameParts[0].charAt(0).toUpperCase();
    } else {
      return `${nameParts[0].charAt(0).toUpperCase()}${nameParts[
        nameParts.length - 1
      ]
        .charAt(0)
        .toUpperCase()}`;
    }
  };

  return (
    <Avatar
      className={classes.avatar}
      style={{
        textAlign: "center",
        color: `${source === "page" ? "white" : "var(--text-secondary-color)"}`,
        backgroundColor: `${
          source === "page" ? "var(--text-secondary-color)" : "white"
        }`,
        fontSize: window.innerWidth <= 768 ? "14px" : "11px",
      }}
    >
      {getInitials(userName)}
    </Avatar>
  );
};

export default ProfileIcon;
