import { TrackerPropertyKey, TrackingEventName } from "../../trackingConstants"
import { TrackableEvent } from "../trackableEvent"

export class ListingFilterItemDoneEvent extends TrackableEvent {
	constructor(title, selected_options) {
		let properties = {}
		properties[TrackerPropertyKey.FilterTitle] = title
		properties[TrackerPropertyKey.SelectedOptions] = selected_options

		super(TrackingEventName.ListingFilterDoneClick, properties)
	}
}
