import { TrackerPropertyKey, TrackingEventName } from "../trackingConstants";
import { TrackableEvent } from "./trackableEvent";

export class SearchIntentEndEvent extends TrackableEvent {
  constructor(
    searchTypeLabel,
    searchWhereUserQuery,
    SearchStringWhere,
    SearchStringWhen,
    SearchStringBudget,
    searchWhereAutocompletedSuggestion,
    searchWhereGoogleSuggestion
  ) {
    let properties = {};
    if (searchTypeLabel)
      properties[TrackerPropertyKey.SearchType] = searchTypeLabel;
    if (searchWhereUserQuery)
      properties[TrackerPropertyKey.searchWhereUserQuery] =
        searchWhereUserQuery;
    if (SearchStringWhere)
      properties[TrackerPropertyKey.SearchStringWhere] = SearchStringWhere;
    if (SearchStringWhen)
      properties[TrackerPropertyKey.SearchStringWhen] = SearchStringWhen;
    if (SearchStringBudget)
      properties[TrackerPropertyKey.SearchStringBudget] = SearchStringBudget;
    if (searchWhereAutocompletedSuggestion)
      properties[TrackerPropertyKey.searchWhereAutocompletedSuggestion] =
        searchWhereAutocompletedSuggestion;
    if (searchWhereGoogleSuggestion)
      properties[TrackerPropertyKey.searchWhereGoogleSuggestion] =
        searchWhereGoogleSuggestion;
    super(TrackingEventName.SearchIntentEnd, properties);
  }
}
